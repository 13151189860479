import React, { JSX } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { ReactComponent as RefundIcon } from 'app/assets/icon_refunded.svg';
import { tooltips } from 'app/utils/constants/contentConstants';
import UnigineButton from 'app/components/UnigineButton/UnigineButton';
import UnigineTooltip from 'app/components/Tooltip/Tooltip';
import AddonTableList from 'app/components/AddonTableList/AddonTableList';
import OrderedItem from 'app/components/AddonTableItem/variants/OrderedItem/OrderedItem';
import useAppDispatch from 'app/hooks/useAppDispatch';
import { IOrder } from 'app/interfaces/orders/IOrders';

import { openAddonDialog } from 'app/main/sections/Addon/store/baseInfoSlice';

interface IOrderBody {
  order: IOrder;
}

const OrderBody = ({ order }: IOrderBody): JSX.Element => {
  const dispatch = useAppDispatch();

  const handleItemClick = (id: string): void => {
    dispatch(openAddonDialog({ id }));
  };

  const orderIsFree = !!order && parseInt(order.total, 10) === 0;

  const addons =
    order &&
    order.orderAddonVersions
      .filter((version) => !version.isHidden)
      .map((version) => {
        const {
          addonVersion: { addon, categories, ...addonVersion },
          id: addonVersionId,
          addonDiscount,
          addonPrice,
          addonTax,
          addonTotalPrice,
          isAddedToWithdrawal,
          isRefunded,
        } = version;
        return {
          ...addonVersion,
          ...addon,
          addonDiscount,
          addonPrice,
          addonTax,
          categories,
          addonTotalPrice,
          addonVersionId,
          isAddedToWithdrawal,
          isRefunded,
        };
      });

  const isAllAddonsRefunded = addons
    .filter((addon) => !addon.isFree)
    .every((addon) => addon.isRefunded === true);

  const isOrderDiscounted = order.discount !== '0.00';

  const isRefundButtonShowed =
    (order.orderStatus.slug === 'completed' || order.orderStatus.slug === 'refunded') &&
    !orderIsFree &&
    order.isRefundable &&
    !isAllAddonsRefunded;

  const isOrderRefundable = order.orderAddonVersions.reduce((acc, orderAddonVersion) => {
    const isOrderAddonVersionRefundable =
      orderAddonVersion.isRefundable && !orderAddonVersion.addonVersion.addon.isFree;

    return acc || isOrderAddonVersionRefundable;
  }, false);

  const isSDKButtonShowed =
    order.orderStatus.slug !== 'not_paid' && order.orderStatus.slug !== 'canceled';

  return (
    <>
      <div className="order-page__info order-info">
        <div className="order-info__general">
          <span className="order-info__date">
            Order on{' '}
            <time dateTime={order.createDate}>
              {moment(order.createDate).format('DD-MM-YYYY H:mm')}
            </time>
          </span>
          <span className="order-info__number">#{order.orderNumber}</span>
        </div>
        <div className="order-info__price">
          {orderIsFree ? (
            <strong className="order-info__total order-info__total--free">Free</strong>
          ) : (
            <>
              <span className="order-info__tax">Tax: $ {order.tax}</span>
              {isOrderDiscounted && (
                <span className="order-info__discount">You saved: ${order.discount}</span>
              )}
              <strong className="order-info__total">Total: $ {order.total}</strong>
            </>
          )}
        </div>
      </div>

      <AddonTableList
        isLoading={false}
        items={addons}
        renderItem={(item) => {
          const {
            id,
            title,
            coverFile,
            categories,
            publisher,
            addonPrice: price,
            addonTotalPrice: discountedPrice,
            isFree,
            isRefunded,
          } = item;
          return (
            <OrderedItem
              addon={{ id, coverFile, title, categories, publisher }}
              price={price}
              discountedPrice={discountedPrice}
              isFree={isFree}
              isRefunded={isRefunded}
              showLink={isSDKButtonShowed && !item.isRefunded}
              onClick={(evt) => {
                evt.preventDefault();
                handleItemClick(id);
              }}
            />
          );
        }}
      />

      {isRefundButtonShowed && isOrderRefundable && (
        <div className="order-button__wrapper">
          <Link to={`/orders/${order.id}/refund`} className="order-button--refund">
            <RefundIcon /> Refund
          </Link>
        </div>
      )}

      {isRefundButtonShowed && !isOrderRefundable && (
        <div className="order-button__wrapper">
          <UnigineTooltip title={tooltips.orders.orderNotRefundable}>
            <UnigineButton className="order-button--refund order-button--disabled">
              <RefundIcon /> Refund
            </UnigineButton>
          </UnigineTooltip>
        </div>
      )}
    </>
  );
};

export default OrderBody;
