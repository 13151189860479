import './InsertLinkPopup.scss';

import React, { useState, ReactElement, useEffect } from 'react';
import { Editor } from 'slate';
import { Popover, TextField } from '@mui/material';

import UnigineButton from 'app/components/UnigineButton/UnigineButton';

import { insertLink } from 'app/components/RichTextEditor/utils/link';

interface IInsertLinkPopup {
  open: boolean;
  onClose: () => void;
  editor: Editor;
}

const InsertLinkPopup = ({ open, onClose, editor }: IInsertLinkPopup): ReactElement => {
  const [link, setLink] = useState<string>('');
  const [coordinatesTop, setCoordinatesTop] = useState<number>(0);
  const [coordinatesLeft, setCoordinatesLeft] = useState<number>(0);

  useEffect(() => {
    const coordinates = window.getSelection()?.getRangeAt(0).getBoundingClientRect();
    setCoordinatesTop(Number(coordinates?.top) + 20);
    setCoordinatesLeft(Number(coordinates?.left));
  }, [open]);

  const handleLinkSumbit = (): void => {
    if (link.includes('https://') || link.includes('http://')) {
      insertLink(editor, link);
    } else {
      insertLink(editor, `https://${link}`);
    }
    setLink('');
    onClose();
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === 'Enter') {
      if (link.includes('https://') || link.includes('http://')) {
        insertLink(editor, link);
      } else {
        insertLink(editor, `https://${link}`);
      }
      setLink('');
      onClose();
    }
  };

  return (
    <Popover
      anchorReference="anchorPosition"
      anchorPosition={{ top: coordinatesTop, left: coordinatesLeft }}
      className="insert-link-popover"
      open={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <div className="insert-link-wrapper">
        <TextField
          className="unigine-form-field insert-link-textfield"
          placeholder="Type URL"
          size="small"
          variant="outlined"
          onChange={(e) => setLink(e.target.value)}
          onKeyDown={handleKeyDown}
          value={link}
          autoFocus
        />
        <UnigineButton className="insert-link-button" onClick={handleLinkSumbit}>
          Save link
        </UnigineButton>
      </div>
    </Popover>
  );
};

export default InsertLinkPopup;
