import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from 'app/auth/axiosInstance';
import { RootState } from 'app/store';
import ITag from 'app/interfaces/addons/ITag';

interface IGetTagsParams {
  query: string;
  excepts?: string[];
}

interface ITagsData {
  tags: ITag[];
  total: number;
}

interface ITagsState {
  tags: ITag[];
  total: number;
}

const buildAutocompleteTagsParams = (query: string, excepts?: string[]): IGetTagsParams => {
  const params = excepts ? { query, excepts } : { query };

  return params;
};

export const getTagsData = createAsyncThunk<ITagsData, IGetTagsParams>(
  'publisherPanelSection/getTagsData',
  async ({ query, excepts }, { rejectWithValue }) => {
    try {
      const params = buildAutocompleteTagsParams(query, excepts);
      const response = await axiosInstance({
        method: 'get',
        url: '/tags',
        params,
      });

      return response.data;
    } catch (err: any) {
      return rejectWithValue(err.response?.data);
    }
  }
);

const initialState: ITagsState = {
  tags: [],
  total: 0,
};

const tagsSlice = createSlice({
  name: 'publisherPanelSection',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTagsData.fulfilled, (state, action) => {
        state.tags = action.payload.tags;
        state.total = action.payload.total;
      })
      .addCase(getTagsData.rejected, (state, action) => {
        console.log(action.payload);
      });
  },
});
export const selectTags = ({ publisherPanelSection }: RootState): ITag[] =>
  publisherPanelSection.addonTags.tags;
export const selectTagsTotal = ({ publisherPanelSection }: RootState): number =>
  publisherPanelSection.addonTags.total;

export default tagsSlice.reducer;
