import { Navigate } from 'react-router-dom';

import Category from 'app/main/sections/Addons/Category';
import Search from 'app/main/sections/Addons/Search';

const AddonsConfig = [
  {
    path: '/',
    element: <Navigate to="/all" />,
  },
  {
    path: ':categorySlug',
    element: <Category />,
    children: [{ path: ':page', element: <Category /> }],
  },
  {
    path: '/search',
    element: <Search />,
  },
];

export default AddonsConfig;
