import './Addon.scss';

import { useState, useEffect, FC, useMemo } from 'react';
import { CircularProgress } from '@mui/material';
import { Outlet, useParams, useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import useAppSelector from 'app/hooks/useAppSelector';
import useAppDispatch from 'app/hooks/useAppDispatch';

import StorefrontHeader from 'app/components/StorefrontHeader/StorefrontHeader';
import ErrorPage from 'app/main/errors/ErrorPage';

import {
  getAddonBaseInfo,
  addonBaseInfoSelector,
  addonBaseInfoErrorsSelector,
  getAddonVersionData,
} from 'app/main/sections/Addon/store/baseInfoSlice';

const Addon: FC = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  const { addonId } = useParams();
  const addonVersionId = searchParams.get('version');

  const [isLoading, setIsLoading] = useState(true);

  const errors = useAppSelector(addonBaseInfoErrorsSelector);

  useEffect(() => {
    if (addonId) {
      dispatch(getAddonBaseInfo({ addonId })).finally(() => setIsLoading(false));
    }
  }, [dispatch, addonId]);

  const addonBaseInfo = useAppSelector(addonBaseInfoSelector);

  // the version marked as current in addon data
  const currentVersion = useMemo(() => {
    const versions = addonBaseInfo?.publishedAndRevisionAddonVersions;
    const version = versions?.find((v) => v.isCurrent) || versions?.[0] || null;
    return version;
  }, [addonBaseInfo]);

  // the version selected in version select and displayed on the page
  // if nothing selected, default to current
  const selectedVersionId = addonVersionId || currentVersion?.id || null;

  useEffect(() => {
    if (selectedVersionId) {
      dispatch(getAddonVersionData({ addonVersionId: selectedVersionId }));
    }
  }, [dispatch, selectedVersionId]);

  if (errors) {
    const { data, status } = errors;
    return <ErrorPage status={status} text={data.message} />;
  }

  if (isLoading || !addonBaseInfo) {
    return (
      <>
        <StorefrontHeader />
        <div className="add-on-list__loading-container">
          <CircularProgress color="inherit" className="add-on-list__loading" />
        </div>
      </>
    );
  }

  const { title: addonTitle } = addonBaseInfo;

  return (
    <>
      <Helmet>
        <title>{addonTitle} | Unigine Add-On Store</title>
      </Helmet>

      <StorefrontHeader />

      <Outlet />
    </>
  );
};

export default Addon;
