import { ReactElement, useState } from 'react';
import { Link } from 'react-router-dom';
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { CircularProgress, FormControlLabel, TextField } from '@mui/material';
import moment from 'moment';

import { FormGrid, FormRow } from 'app/components/FormGrid/FormGrid';
import UnigineButton from 'app/components/UnigineButton/UnigineButton';
import CheckboxUnigine from 'app/components/CheckboxUnigine/CheckboxUnigine';
import PriceInput from 'app/components/PriceInput/PriceInput';
import DiscountFields from 'app/components/DiscountFields/DiscountFields';
import Tip from 'app/components/Tip/Tip';
import { formFieldErrors, tips, tooltips } from 'app/utils/constants/contentConstants';
import { regExps } from 'app/configs/appConfig';
import UnigineTooltip from 'app/components/Tooltip/Tooltip';
import { IPublishedAddonEditGeneralInfo } from 'app/interfaces/addons/IAddon';

interface IAddonEditForm {
  title?: string;
  defaultValues: IPublishedAddonEditGeneralInfo;
  onSubmit: (data: IPublishedAddonEditGeneralInfo) => void;
}

const FREE_ONLY = true;

const discountDefaultValue = {
  percentage: 0,
  from: moment(new Date()).format('YYYY-MM-DD'),
  till: null, // moment(new Date()).add(1, 'month').format('YYYY-MM-DD'),
  quantity: null,
};

const AddonEditForm = ({ title, defaultValues, onSubmit }: IAddonEditForm): ReactElement => {
  const { control, handleSubmit, setError } = useForm({
    defaultValues,
    mode: 'onChange',
  });

  const { fields, append, remove } = useFieldArray({ name: 'discounts', control });

  const isFree = useWatch({ control, name: 'isFree' });
  // const price = useWatch({ control, name: 'price' });

  const [isWaiting, setIsWaiting] = useState(false);
  const onSubmitForm = async (data: IPublishedAddonEditGeneralInfo): Promise<void> => {
    setIsWaiting(true);
    if (data.isFree) {
      data.price = '0';
    }

    const discounts = data.discounts.map((discount) => ({
      from: discount.from,
      percentage: discount.percentage,
      quantity: discount.quantity,
      till: discount.till === 'Invalid date' ? null : discount.till,
    }));
    data.discounts = discounts;

    try {
      await onSubmit(data);
    } catch (errors: any) {
      errors.forEach((error: any) => {
        setError(error.name, { message: error.message });
      });
    } finally {
      setIsWaiting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmitForm)} className="add-on-info-edit__form add-on-form">
      <div className="add-on-section__title-wrapper">
        <h1 className="add-on-section__title">
          <Link to="/publisherPanel/add-ons">My Uploaded Add-Ons</Link> / <span>{title}</span>
        </h1>

        <UnigineButton type="submit" className="add-on-info-edit__button" disabled={isWaiting}>
          {isWaiting ? <CircularProgress color="inherit" size={16} /> : 'Save'}
        </UnigineButton>
      </div>
      <FormGrid>
        <FormRow
          required
          label="Title"
          labelledInputId="add-on_section_edit_input_title"
          tooltip={tooltips.addonForm.title}
        >
          <Controller
            control={control}
            name="title"
            rules={{
              required: formFieldErrors.required,
              maxLength: { value: 200, message: formFieldErrors.titleMaxLength },
              pattern: {
                value: regExps.titleCharacters,
                message: formFieldErrors.titleCharacters,
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <UnigineTooltip title={tooltips.addonForm.title}>
                <TextField
                  {...field}
                  size="small"
                  className="unigine-form-field"
                  variant="outlined"
                  fullWidth
                  inputProps={{
                    id: 'add-on_section_edit_input_title',
                  }}
                  error={!!error}
                  helperText={error?.message}
                />
              </UnigineTooltip>
            )}
          />
        </FormRow>

        <FormRow
          label="Price"
          labelledInputId="add_add_on_input_price"
          required={!isFree}
          tooltip={tooltips.addonForm.price}
        >
          <div className="add-on-form__price-section-wrapper">
            <div className="add-on-form__price-section">
              <Controller
                control={control}
                name="price"
                rules={{
                  required: isFree ? '' : formFieldErrors.required,
                  min: { value: isFree ? 0 : 5, message: formFieldErrors.priceMinValue },
                  max: { value: 999999.999, message: formFieldErrors.priceMaxValue },
                }}
                render={({ field, fieldState: { error } }) => (
                  <UnigineTooltip title={tooltips.addonForm.price}>
                    <div>
                      <TextField
                        {...field}
                        size="small"
                        className="unigine-form-field"
                        // required={!isFree && !FREE_ONLY}
                        disabled={!!isFree || FREE_ONLY}
                        InputProps={{
                          inputComponent: PriceInput,
                          id: 'add_add_on_input_price',
                        }}
                        error={!!error}
                        helperText={error?.message}
                      />
                    </div>
                  </UnigineTooltip>
                )}
              />
              <Controller
                control={control}
                name="isFree"
                render={({ field: { onChange, value } }) => (
                  <FormControlLabel
                    label="Free add-on"
                    className="add-on-form__is-free"
                    control={
                      <CheckboxUnigine
                        className="add-on-form__is-free-checkbox"
                        onChange={onChange}
                        checked={value || FREE_ONLY}
                        disabled={FREE_ONLY}
                      />
                    }
                  />
                )}
              />
            </div>

            {FREE_ONLY && (
              <Tip className="add-on-form__price-section-tip" content={tips.freeAddons} />
            )}
          </div>
        </FormRow>

        <FormRow label="Discounts" tooltip={tooltips.addonForm.discounts}>
          <div className="add-on-form__discounts">
            {fields.map((field, idx) => (
              <DiscountFields
                // register={register}
                control={control}
                key={field.id}
                arrayName="discounts"
                index={idx}
                removeRow={() => remove(idx)}
              />
            ))}
            <UnigineTooltip title={tooltips.addonForm.discounts}>
              <UnigineButton
                className="add-on-form__discount-add-btn"
                onClick={() => append({ ...discountDefaultValue })}
                disabled={isFree || FREE_ONLY}
              >
                Add discount
              </UnigineButton>
            </UnigineTooltip>
          </div>
        </FormRow>
      </FormGrid>
    </form>
  );
};

export default AddonEditForm;
