import { Navigate } from 'react-router-dom';

import PublisherPanel from 'app/main/sections/PublisherPanel/PublisherPanel';
import AddonSection from 'app/main/sections/PublisherPanel/sections/AddonSection/AddonSection';
import AddonSectionEdit from 'app/main/sections/PublisherPanel/sections/AddonSection/AddonSectionEdit';
import AddAddonFormSection from 'app/main/sections/PublisherPanel/sections/AddAddonFormSection/AddAddonFormSection';
import PublishedAddonsSection from 'app/main/sections/PublisherPanel/sections/AddonsSection/AddonsSection';
import AddonBaseInfoTab from 'app/main/sections/PublisherPanel/sections/AddonSection/tabs/AddonBaseInfoTab';
import AddonBaseInfoEditTab from 'app/main/sections/PublisherPanel/sections/AddonSection/tabs/AddonBaseInfoEditTab';
import AddonMediaTab from 'app/main/sections/PublisherPanel/sections/AddonSection/tabs/AddonMediaTab';
import AddonChangelogTab from 'app/main/sections/PublisherPanel/sections/AddonSection/tabs/AddonChangelogTab';
import AddonChangelogEditTab from 'app/main/sections/PublisherPanel/sections/AddonSection/tabs/AddonChangelogEditTab';
import AddonPackageTab from 'app/main/sections/PublisherPanel/sections/AddonSection/tabs/AddonPackageTab';
import StartPublishing from 'app/main/sections/PublisherPanel/sections/StartPublishing/StartPublishing';
import SettingsSection from 'app/main/sections/PublisherPanel/sections/SettingsSection/SettingsSection';
import SettingsEditSection from 'app/main/sections/PublisherPanel/sections/SettingsSection/SettingsEditSection';
import SettingsSectionWrapper from 'app/main/sections/PublisherPanel/sections/SettingsSection/SettingsSectionWrapper';
import AddonsManagersSection from 'app/main/sections/PublisherPanel/sections/AddonsManagersSection/AddonsManagersSection';
import OrderAddonVersionsSection from 'app/main/sections/PublisherPanel/sections/OrderAddonVersionsSection/OrderAddonVersionsSection';
import DashboardSection from 'app/main/sections/PublisherPanel/sections/DashboardSection/DashboardSection';
import ReviewsSection from 'app/main/sections/PublisherPanel/sections/ReviewsSection/ReviewsSection';

const PublisherPanelConfig = [
  {
    path: 'publisherPanel/',
    element: <Navigate replace to="add-ons" />,
  },
  {
    path: 'start-publishing',
    element: <StartPublishing />,
  },
  {
    path: 'publisherPanel/',
    element: <PublisherPanel />,
    children: [
      {
        path: 'dashboard',
        element: <DashboardSection />,
      },
      {
        path: 'add-ons',

        element: <PublishedAddonsSection />,
      },
      {
        path: 'add-ons/add',
        element: <AddAddonFormSection />,
      },
      {
        path: 'add-ons/:addonId/edit',
        element: <AddonSectionEdit />,
      },
      {
        path: 'add-ons/:addonId',
        element: <Navigate replace to="base" />,
      },
      {
        path: 'add-ons/:addonId',
        element: <AddonSection />,
        children: [
          {
            path: 'base',
            element: <AddonBaseInfoTab />,
          },
          {
            path: 'base/edit',
            element: <AddonBaseInfoEditTab />,
          },
          {
            path: 'media',
            element: <AddonMediaTab />,
          },
          {
            path: 'package',
            element: <AddonPackageTab />,
          },
          {
            path: 'changelog',
            element: <AddonChangelogTab />,
          },
          {
            path: 'changelog/edit',
            element: <AddonChangelogEditTab />,
          },
        ],
      },
      {
        path: 'orders',
        element: <OrderAddonVersionsSection />,
      },
      {
        path: 'reviews',
        element: <ReviewsSection />,
      },
      {
        path: 'support',
        element: <div>Support</div>,
      },
      {
        path: 'notifications',
        element: <div>Notifications</div>,
      },
      {
        path: 'settings/',
        element: <SettingsSectionWrapper />,
        children: [
          {
            path: '',
            element: <SettingsSection />,
          },
          {
            path: 'edit',
            element: <SettingsEditSection />,
          },
        ],
      },
      {
        path: 'managers',
        element: <AddonsManagersSection />,
      },
    ],
  },
];

export default PublisherPanelConfig;
