import './Refund.scss';

import React, { useState, useEffect, FC } from 'react';
import StorefrontHeader from 'app/components/StorefrontHeader/StorefrontHeader';
import { useNavigate, useParams } from 'react-router-dom';
import FormControlLabel from '@mui/material/FormControlLabel';
import CheckboxUnigine from 'app/components/CheckboxUnigine/CheckboxUnigine';
import { Button, CircularProgress } from '@mui/material';
import { ReactComponent as RefundIcon } from 'app/assets/icon-refunded-white.svg';

import useAppSelector from 'app/hooks/useAppSelector';
import useAppDispatch from 'app/hooks/useAppDispatch';

import ErrorPage from 'app/main/errors/ErrorPage';
import useLoginOnGuests from 'app/hooks/useLoginOnGuests';
import OrderedItem from 'app/components/AddonTableItem/variants/OrderedItem/OrderedItem';

import {
  getOrder,
  orderErrorsSelector,
  orderSelector,
} from 'app/main/sections/Orders/store/orderSlice';
import { requestRefund } from 'app/main/sections/Orders/store/refundSlice';
import { openAddonDialog } from 'app/main/sections/Addon/store/baseInfoSlice';

const Refund: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { orderId } = useParams();

  const [checked, setChecked] = useState<any[]>([]);
  const [checkedAll, setCheckedAll] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const order = useAppSelector(orderSelector);
  const errors = useAppSelector(orderErrorsSelector);

  useLoginOnGuests();

  useEffect(() => {
    if (!orderId) {
      setIsLoading(false);
      return;
    }
    dispatch(getOrder({ orderId }))
      .unwrap()
      .then((data) => {
        const initialState = data.orderAddonVersions.map((orderAddonVersion) => {
          const isOrderAddonVersionRefundable =
            orderAddonVersion.isRefundable && !orderAddonVersion.addonVersion.addon.isFree;
          const isOrderAddonVersionRefunded = orderAddonVersion.isRefunded;

          if (!isOrderAddonVersionRefundable || isOrderAddonVersionRefunded) {
            return 'disabled';
          }
          return false;
        });
        setChecked(initialState);
      })
      .finally(() => setIsLoading(false));
  }, [dispatch, orderId]);

  if (errors) {
    const { data, status } = errors;
    return <ErrorPage status={status} text={data.message} />;
  }

  if (isLoading || !order) {
    return (
      <>
        <StorefrontHeader />
        <div className="main order-page">
          <div className="order-page__spinner">
            <CircularProgress color="inherit" />
          </div>
        </div>
      </>
    );
  }

  const handleItemClick = (id: string): void => {
    dispatch(openAddonDialog({ id }));
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, index: number): void => {
    const newCheckedState = [...checked];
    newCheckedState[index] = event.target.checked;
    setChecked(newCheckedState);
  };

  const checkAll = (): void => {
    const intialChecked = [...checked];
    const newCheckedState = intialChecked.map((checkedOrder) => {
      return checkedOrder === 'disabled' ? 'disabled' : !checkedAll;
    });
    setCheckedAll(!checkedAll);
    setChecked(newCheckedState);
  };

  const onSubmitRefund = (): void => {
    const addonVersionIds: string[] = [];
    checked.forEach((checkedOrder, index) => {
      if (checkedOrder === true) {
        addonVersionIds.push(order.orderAddonVersions[index].id);
      }
    });
    if (orderId) {
      dispatch(requestRefund({ orderId, addonVersionIds }))
        .then(() => {
          dispatch(getOrder({ orderId }));
        })
        .then(() => {
          navigate(`/orders/${orderId}/refund/result`);
        });
    }
  };

  const isAllChecked = checked.every((val) => val === true);
  const isAllDisabled = checked.every((val) => val === 'disabled');

  return (
    <>
      <StorefrontHeader />
      <div className="main order-page">
        <div className="order-page__header">
          <h1 className="order-page__title">Refund request for order #{order.orderNumber}</h1>
        </div>
        <div className="refund-page__main">
          <FormControlLabel
            label="ALL"
            className="refund-select-all"
            control={
              <CheckboxUnigine
                checked={isAllChecked}
                indeterminate={checked.includes(true) && !isAllChecked}
                onChange={checkAll}
                disabled={isAllDisabled}
              />
            }
          />

          <ul className="order-page__add-on-list">
            {order.orderAddonVersions.map((orderAddonVersion, index) => {
              const {
                addonVersion: {
                  coverFile,
                  categories,
                  addon: { id, title, publisher, isFree },
                },
                addonPrice: price,
                addonTotalPrice: discountedPrice,
                isRefundable,

                isRefunded,
              } = orderAddonVersion;

              const isOrderAddonVersionRefundable = isRefundable && !isFree;
              const isOrderAddonVersionRefunded = orderAddonVersion.isRefunded;
              const isAddonDisabled = !isOrderAddonVersionRefundable || isOrderAddonVersionRefunded;

              return (
                <li key={orderAddonVersion.id} className="refund-card">
                  <FormControlLabel
                    label=""
                    control={
                      <CheckboxUnigine
                        checked={isAddonDisabled ? false : checked[index]}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          if (isAddonDisabled) {
                            return false;
                          }
                          return handleChange(e, index);
                        }}
                        disabled={isAddonDisabled}
                      />
                    }
                  />
                  <div className="order-page__add-on-list-item">
                    <OrderedItem
                      addon={{ id, title, coverFile, categories, publisher }}
                      price={price}
                      discountedPrice={discountedPrice}
                      isFree={isFree}
                      isRefunded={isRefunded}
                      onClick={(evt) => {
                        evt.preventDefault();
                        handleItemClick(id);
                      }}
                    />
                  </div>
                </li>
              );
            })}
          </ul>

          <div className="refund-button__wrapper">
            <Button
              className="refund-button"
              onClick={onSubmitRefund}
              disabled={!checked.includes(true)}
            >
              <RefundIcon /> Confirm Refund
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Refund;
