import { FC } from 'react';
import { Close as CloseIcon } from '@mui/icons-material';
import { Dialog, DialogContent, IconButton, TextField, FormHelperText } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';

import { FormGrid, FormRow } from 'app/components/FormGrid/FormGrid';
import RatingFilter from 'app/components/RatingFilter/RatingFilter';
import UnigineButton from 'app/components/UnigineButton/UnigineButton';
import UnigineSelect from 'app/components/UnigineSelect/UnigineSelect';

import IError from 'app/interfaces/IError';

import {
  DEFAULT_REVIEW_DESCRIPTION_LENGTH,
  DEFAULT_REVIEW_DETAILS_LENGTH,
  regExps,
} from 'app/configs/appConfig';
import { formFieldErrors } from 'app/utils/constants/contentConstants';

import './ReviewForm.scss';

interface IAddonVersion {
  id: string;
  versionNumber: string;
}

interface IReviewFormProps {
  open: boolean;
  versions: IAddonVersion[];

  defaultVersion: IAddonVersion;
  defaultValues?: IReviewFormValues | null;

  onSubmit: (data: IReviewFormValues) => void;
  onCancel: () => void;

  isProcessing?: boolean;
  error?: IError | null;
}

interface IReviewFormValues {
  version: string;
  rating: number;
  description: string;
  details: string;
}

const ReviewForm: FC<IReviewFormProps> = ({
  open,
  versions,
  defaultVersion,
  defaultValues,
  onSubmit,
  onCancel,
  isProcessing = false,
  error,
}) => {
  const { control, watch, handleSubmit } = useForm<IReviewFormValues>({
    mode: 'onChange',
    defaultValues: {
      rating: defaultValues?.rating,
      description: defaultValues?.description || '',
      details: defaultValues?.details || '',
      version: versions.find((v) => v.id === defaultValues?.version)?.id || defaultVersion.id,
    },
  });

  const rating = watch('rating');

  const formTitle = defaultValues ? 'Update Review' : 'New Review';

  let buttonText = defaultValues ? 'Update Review' : 'Send Review';
  buttonText = isProcessing ? 'Waiting...' : buttonText;

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="md"
      onClose={onCancel}
      className="dialog dialog--review-form"
    >
      <DialogContent className="dialog__content review-form">
        <form onSubmit={handleSubmit(onSubmit)} className="review-form__form">
          <h2 className="review-form__title">{formTitle}</h2>
          <FormGrid>
            <FormRow required label="Add-On rating" labelClassName="review-form__label">
              <div className="review-form__rating">
                <Controller
                  control={control}
                  name="rating"
                  rules={{ required: true, min: 1 }}
                  render={({ field: { value, onChange } }) => (
                    <RatingFilter defaultValue={value} onChange={onChange} />
                  )}
                />
              </div>
            </FormRow>

            <FormRow required label="Add-On version" labelClassName="review-form__label">
              <Controller
                control={control}
                name="version"
                render={({ field: { onChange, value } }) => (
                  <UnigineSelect
                    name="version"
                    options={versions}
                    value={value}
                    onChange={onChange}
                    getLabel={(version) => version.versionNumber}
                    getValue={(version) => version.id}
                  />
                )}
              />
            </FormRow>

            <FormRow label="Short description" labelClassName="review-form__label">
              <Controller
                control={control}
                name="description"
                rules={{
                  maxLength: {
                    value: DEFAULT_REVIEW_DESCRIPTION_LENGTH,
                    message: formFieldErrors.reviewDescriptionLength,
                  },
                  pattern: {
                    value: regExps.review,
                    message: formFieldErrors.richTextCharacters,
                  },
                }}
                render={({ field: { onChange, value }, fieldState: { error: fieldError } }) => (
                  <TextField
                    className="review-form__field unigine-form-field"
                    name="description"
                    value={value}
                    onChange={onChange}
                    size="small"
                    fullWidth
                    error={!!fieldError}
                    helperText={fieldError?.message}
                  />
                )}
              />
            </FormRow>

            <FormRow label="Details" labelClassName="review-form__label">
              <Controller
                control={control}
                name="details"
                rules={{
                  maxLength: {
                    value: DEFAULT_REVIEW_DETAILS_LENGTH,
                    message: formFieldErrors.reviewDetailsLength,
                  },
                  pattern: {
                    value: regExps.review,
                    message: formFieldErrors.richTextCharacters,
                  },
                }}
                render={({ field: { onChange, value }, fieldState: { error: fieldError } }) => (
                  <TextField
                    className="review-form__field unigine-form-field"
                    name="details"
                    value={value}
                    onChange={onChange}
                    size="small"
                    fullWidth
                    multiline
                    minRows={4}
                    maxRows={10}
                    error={!!fieldError}
                    helperText={fieldError?.message}
                  />
                )}
              />
            </FormRow>
          </FormGrid>

          <div className="review-form__submit">
            <FormHelperText className="review-form__error">
              {error ? error?.data?.message || 'Something went wrong' : ' '}
            </FormHelperText>
            <UnigineButton type="submit" disabled={!rating || isProcessing}>
              {buttonText}
            </UnigineButton>
          </div>
        </form>

        <IconButton aria-label="close" className="dialog__close-button" onClick={onCancel}>
          <CloseIcon />
        </IconButton>
      </DialogContent>
    </Dialog>
  );
};

export default ReviewForm;
