import './AddonTableList.scss';

import { CircularProgress } from '@mui/material';

import { JSX } from 'react';

interface IAddonTableList<T> {
  renderItem: (item: T) => JSX.Element;
  items: T[];
  isLoading: boolean;
  placeholderText?: string;
}

interface IRenderedItem {
  id: string;
}

const AddonTableList = <T extends IRenderedItem>({
  renderItem,
  items,
  isLoading,
  placeholderText,
}: IAddonTableList<T>): JSX.Element => {
  return (
    <div className="add-on-table__container">
      <div className="add-on-table__list-container">
        {isLoading ? (
          <div className="add-on-table__loading-container">
            <CircularProgress color="inherit" />
          </div>
        ) : (
          <ul className="add-on-table__list">
            {items.length === 0 && (
              <div className="add-on-table__no-add-ons">{placeholderText || null}</div>
            )}
            {items.map((item) => (
              <li className="add-on-table__element" key={item.id}>
                {renderItem(item)}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default AddonTableList;
