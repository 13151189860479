import { IAddonVersion } from 'app/interfaces/addons/IAddonVersion';

type GetPreviewSrcParams = Pick<IAddonVersion, 'videoFiles' | 'pictureFiles'> | undefined;

const getPreviewSrc = (addonVersion: GetPreviewSrcParams): string => {
  if (!addonVersion) {
    return '';
  }

  if (addonVersion?.videoFiles.length > 0) {
    return `https://i.ytimg.com/vi/${addonVersion.videoFiles[0].data?.videoId}/mqdefault.jpg`;
  }

  if (addonVersion?.pictureFiles.length > 0) {
    return `${process.env.REACT_APP_STORE_API_URL}/media-files/${addonVersion.pictureFiles[0].id}`;
  }

  return '';
};

export default getPreviewSrc;
