import { useRef, useState, useLayoutEffect, FC } from 'react';
import clsx from 'clsx';
import { Helmet } from 'react-helmet-async';

import { ReactComponent as SliderArrow } from 'app/assets/media-slider-arrow.svg';

import './AddonMediaViewer.scss';
import IAddonMediaFile from 'app/interfaces/addons/IAddonMediaFile';

interface IMediaViewer {
  media: IAddonMediaFile[];
}

const MediaViewer: FC<IMediaViewer> = ({ media }) => {
  const [_activeSlide, setActiveSlide] = useState(0);
  const activeSlide = Math.max(0, Math.min(media.length - 1, _activeSlide));

  const offsetRef = useRef(0);
  const thumbStripRef = useRef<HTMLDivElement>(null);
  const thumbContainerRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (!thumbStripRef.current || !thumbContainerRef.current) {
      return;
    }

    const activeThumb = [...thumbStripRef.current.children][activeSlide];
    const thumbRect = activeThumb.getBoundingClientRect();
    const containerRect = thumbContainerRef.current.getBoundingClientRect();

    const overflow =
      Math.max(0, containerRect.left - thumbRect.left) -
      Math.max(0, thumbRect.right - containerRect.right);

    offsetRef.current += overflow;
    thumbStripRef.current.setAttribute('style', `left: ${offsetRef.current}px;`);
  }, [media, activeSlide]);

  if (!media?.length || media.length === 0) {
    return (
      <div className="media-viewer">
        <div className="media-viewer__slide" />
      </div>
    );
  }

  const activeMedia = media[activeSlide];

  return (
    <>
      <Helmet>
        <>
          {media
            .filter((item) => item.type === 'picture')
            .map((item) => (
              <link key={item.id} rel="preload" href={item.url} as="image" />
            ))}
        </>
      </Helmet>
      <div className="media-viewer">
        <div className="media-viewer__slide">
          {activeMedia.type === 'video' ? (
            <iframe
              className="media-viewer__video"
              src={`https://youtube.com/embed/${activeMedia.data?.videoId}`}
              title={activeMedia.id}
            />
          ) : (
            <img
              className="media-viewer__slide-image"
              key={activeMedia.id}
              src={activeMedia.url}
              alt={activeMedia.id}
            />
          )}
        </div>

        {media && (
          <div className="media-viewer__controls">
            <button
              type="button"
              className="media-viewer__prev"
              onClick={() => setActiveSlide(Math.max(0, activeSlide - 1))}
              disabled={activeSlide === 0}
            >
              <SliderArrow />
            </button>

            <div className="media-viewer__thumbs" ref={thumbContainerRef}>
              <div className="media-viewer__thumbs-strip" ref={thumbStripRef}>
                {media.map((item, idx) => {
                  const previewUrl =
                    item.type === 'video'
                      ? `https://i.ytimg.com/vi/${item.data?.videoId}/mqdefault.jpg`
                      : item.previewUrl ?? item.url;

                  return (
                    <button
                      type="button"
                      key={item.id}
                      className={clsx('media-viewer__thumb', {
                        'media-viewer__thumb--active': idx === activeSlide,
                        'media-viewer__thumb--video': item.type === 'video',
                      })}
                      onClick={() => setActiveSlide(idx)}
                    >
                      <img
                        className="media-viewer__thumb-image"
                        src={previewUrl}
                        alt={`Preview of media item ${idx + 1}`}
                      />
                    </button>
                  );
                })}
              </div>
            </div>

            <button
              type="button"
              className="media-viewer__next"
              onClick={() => setActiveSlide(Math.min(media.length - 1, activeSlide + 1))}
              disabled={activeSlide === media.length - 1}
            >
              <SliderArrow />
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default MediaViewer;
