import { combineReducers } from '@reduxjs/toolkit';
import publishedAddonsInfo from 'app/main/sections/PublisherPanel/sections/AddonsSection/store/publishedAddonsSlice';
import orderAddonVersionsInfo from 'app/main/sections/PublisherPanel/sections/OrderAddonVersionsSection/store/orderAddonVersionsSlice';
import addonsManagers from 'app/main/sections/PublisherPanel/sections/AddonsManagersSection/store/addonsManagersSlice';
import publisherInfo from 'app/main/sections/PublisherPanel/sections/SettingsSection/store/settingsSlice';
import dashboard from 'app/main/sections/PublisherPanel/sections/DashboardSection/store/dashboardSlice';
import addon from 'app/main/sections/PublisherPanel/sections/AddonSection/store/addonInfoSlice';
import addonBaseInfo from 'app/main/sections/PublisherPanel/sections/AddonSection/store/baseInfoSlice';
import packages from 'app/main/sections/PublisherPanel/sections/AddonSection/store/packagesSlice';
import addonTags from 'app/main/sections/PublisherPanel/sections/AddonSection/store/tagsSlice';
import reviewsInfo from 'app/main/sections/PublisherPanel/sections/ReviewsSection/store/reviewsSlice';
import reviewStatusInfo from 'app/main/sections/PublisherPanel/sections/ReviewsSection/store/reviewStatusSlice';

const reducer = combineReducers({
  addon,
  addonBaseInfo,
  addonTags,
  publisherInfo,
  publishedAddonsInfo,
  addonsManagers,
  dashboard,
  orderAddonVersionsInfo,
  packages,
  reviewStatusInfo,
  reviewsInfo,
});

export default reducer;
