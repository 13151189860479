import IOrderStatus from 'app/interfaces/orders/IOrderStatus';

interface IGetOrderStatusIds {
  orderStatusesData: IOrderStatus[] | null;
  orderStatusesParam: string[];
}

const getOrderStatusesIds = ({
  orderStatusesData,
  orderStatusesParam,
}: IGetOrderStatusIds): string[] | null =>
  orderStatusesData &&
  orderStatusesData
    .filter((status) => orderStatusesParam.includes(status.slug))
    .map((status) => status.id);

export default getOrderStatusesIds;
