import './AddonPackageTab.scss';

import { FC, useEffect, useMemo, useRef, useState } from 'react';

import { CircularProgress } from '@mui/material';
import Tip from 'app/components/Tip/Tip';
import UnigineButton from 'app/components/UnigineButton/UnigineButton';

import { packageErrors, tips } from 'app/utils/constants/contentConstants';
import {
  MAX_PACKAGE_SIZE_FOR_ONE_UPLOADING,
  UPLOAD_CHUNK_SIZE,
  CANCEL_UPLOAD,
  RESTART_UPLOAD,
  RESUME_UPLOAD,
  TOKEN_EXPIRED,
  TOKEN_EXPIRED_DELAY,
} from 'app/configs/appConfig';
import useAppDispatch from 'app/hooks/useAppDispatch';
import useAppSelector from 'app/hooks/useAppSelector';

import { ReactComponent as PlusIcon } from 'app/assets/icon_plus.svg';

import AddonPackageUploadingDialog from 'app/components/AddonDialogs/AddonPackageUploadingDialog';
import AddonPackageResumeUploadingDialog from 'app/components/AddonDialogs/AddonPackageResumeUploadingDialog';
import AddonPackageItem from 'app/components/AddonPackageItem/AddonPackageItem';
import AddonAddPackageDialog, {
  IPackageWithFiles,
} from 'app/components/AddonDialogs/AddonAddPackageDialog';
import AddonPackageDeleteDialog from 'app/components/AddonDialogs/AddonPackageDeleteDialog';
import AddonUpdatePackageDialog from 'app/components/AddonDialogs/AddonUpdatePackageDialog';

import {
  clearLocalStorage,
  getDataFromLocalStorage,
  setLocalStorage,
  vaildatePackageFile,
  validateLocalStorageData,
} from 'app/main/sections/PublisherPanel/sections/AddonSection/tabs/AddonPackageHelpers';

import {
  addonBaseInfoSelector,
  getUploadUrl,
  uploadPackage,
  getAddonBaseInfo,
  packagesSelector,
  deletePackage,
  hidePackage,
  addPackage,
  IPackageData,
  updatePackage,
  hidePackageErrorSelector,
} from 'app/main/sections/PublisherPanel/sections/AddonSection/store/baseInfoSlice';
import { addonSelector } from 'app/main/sections/PublisherPanel/sections/AddonSection/store/addonInfoSlice';

const AddonPackageTab: FC = () => {
  const dispatch = useAppDispatch();
  const addonBaseInfo = useAppSelector(addonBaseInfoSelector);
  const addonInfo = useAppSelector(addonSelector);
  const packages = useAppSelector(packagesSelector);
  const hidePackageError = useAppSelector(hidePackageErrorSelector);

  const addonVersionIdCurrent = addonInfo?.addonVersions.filter((version) => version.isCurrent)[0]
    ?.id;

  const [isProcessing, setIsProcessing] = useState(false);
  const [isOpenResumeDialog, setIsOpenResumeDialog] = useState(false);
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
  const [isOpenAddPackageDialog, setIsOpenAddPackageDialog] = useState(false);
  const [isOpenUpdatePackageDialog, setIsOpenUpdatePackageDialog] = useState(false);
  const [isSubmittingPackageDialog, setIsSubmittingPackageDialog] = useState(false);
  const [deletePackageId, setDeletePackageId] = useState<string | null>(null);
  const [editPackageId, setEditPackageId] = useState<string | null>(null);

  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [uploadUrl, setUploadUrl] = useState<string | null>(null);
  const [currentPackageId, setCurrentPackageId] = useState<string | null>(null);
  const [currentPackageName, setCurrentPackageName] = useState<string | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const [totalChunks, setTotalChunks] = useState<number | null>(null);
  const [currentChunkIndex, setCurrentChunkIndex] = useState<number | null>(null);
  const [isTokenRefreshed, setIsTokenRefreshed] = useState(false);
  const [packageUploadingErrors, setPackageUploadingErrors] = useState<{ [key: string]: string }>(
    {}
  );

  const editingPackage = useMemo(() => {
    if (editPackageId) {
      return packages.filter((pkg) => pkg.id === editPackageId)[0];
    }
    return null;
  }, [editPackageId, packages]);

  const abortController = useRef<AbortController | null>(null);
  const choiceFn = useRef<(choice: string) => void>();

  useEffect(() => {
    if (file === null) {
      return;
    }

    if (!isTokenRefreshed) {
      if (currentChunkIndex === null) {
        setCurrentChunkIndex(0);
      } else {
        setIsProcessing(true);
        readAndUploadCurrentChunk();
      }
    } else {
      setIsTokenRefreshed(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentChunkIndex, isTokenRefreshed, file]);

  if (!addonBaseInfo || !addonInfo) {
    return (
      <div className="orders__spinner">
        <CircularProgress color="inherit" />
      </div>
    );
  }

  const setUploadProgressForChunks = (chunkPercent: number): void => {
    if (!totalChunks || currentChunkIndex === null) {
      return;
    }

    const uploadPercent = (1 / totalChunks) * (currentChunkIndex * 100 + chunkPercent);
    setUploadPercentage(uploadPercent);
  };

  const setUploadProgressForOneFile = (uploadPercent: number): void => {
    setUploadPercentage(uploadPercent);
  };

  const clearLocalState = (): void => {
    setIsProcessing(false);
    setFile(null);
    setCurrentChunkIndex(null);
    setCurrentPackageName(null);
    setTotalChunks(null);
    setUploadPercentage(0);
  };

  const readAndUploadCurrentChunk = (): void => {
    const reader = new FileReader();
    if (!file || currentChunkIndex === null) {
      return;
    }
    const from = currentChunkIndex * UPLOAD_CHUNK_SIZE;
    const to = from + UPLOAD_CHUNK_SIZE;
    const blob = file.slice(from, to);

    reader.onload = (e) => uploadChunk(e);
    reader.readAsDataURL(blob);
  };

  const uploadChunk = async (readerEvent: ProgressEvent<FileReader>): Promise<void> => {
    const data = readerEvent.target?.result;
    const params = new URLSearchParams();

    if (!file || !data || currentChunkIndex === null || !totalChunks) {
      return;
    }

    params.set('name', file.name);
    params.set('size', file.size.toString());
    params.set('lastModified', file.lastModified.toString());
    params.set('currentChunkIndex', currentChunkIndex.toString());
    params.set('chunkSize', UPLOAD_CHUNK_SIZE.toString());
    params.set('totalChunks', totalChunks.toString());
    if (currentPackageName) {
      params.set('filenameForDownload', currentPackageName);
    }

    const url = `${uploadUrl}?${params.toString()}`;

    dispatch(
      uploadPackage({
        data,
        uploadUrl: url,
        abortController,
        setUploadProgress: setUploadProgressForChunks,
      })
    )
      .unwrap()
      .then(() => {
        const isLastChunk = currentChunkIndex === totalChunks - 1;

        if (isLastChunk) {
          clearLocalState();

          if (currentPackageId) {
            clearLocalStorage(currentPackageId, setCurrentPackageId);
          }

          if (addonVersionIdCurrent) {
            dispatch(getAddonBaseInfo({ addonVersionId: addonVersionIdCurrent }));
          }
        } else {
          if (currentPackageId) {
            setLocalStorage({
              packageId: currentPackageId,
              lastUploadChunkIndex: currentChunkIndex ?? undefined,
            });
          }

          setCurrentChunkIndex(currentChunkIndex + 1);
        }
      })
      .catch((error) => {
        if (error === TOKEN_EXPIRED) {
          setTimeout(() => {
            setIsTokenRefreshed(true);
          }, TOKEN_EXPIRED_DELAY);
        }
      });
  };

  const uploadOneFile = async ({
    packageFile,
    oneFileUploadUrl,
    packageId,
    packageName,
  }: {
    packageFile: File;
    oneFileUploadUrl: string;
    packageId: string;
    packageName: string;
  }): Promise<void> => {
    const data = new FormData();
    data.append('file', packageFile);
    data.append('filenameForDownload', packageName);

    setIsProcessing(true);

    await dispatch(
      uploadPackage({
        data,
        uploadUrl: oneFileUploadUrl,
        abortController,
        setUploadProgress: setUploadProgressForOneFile,
      })
    )
      .unwrap()
      .finally(() => {
        clearLocalState();
        clearLocalStorage(packageId, setCurrentPackageId);

        if (addonVersionIdCurrent) {
          dispatch(getAddonBaseInfo({ addonVersionId: addonVersionIdCurrent }));
        }
      });
  };

  const initChunkUploading = ({
    chunkUploadUrl,
    packageFile,
    lastUploadChunkIndex,
    updateLocalStorage = false,
    packageId,
    packageName,
  }: {
    chunkUploadUrl: string;
    packageFile: File;
    lastUploadChunkIndex?: number;
    updateLocalStorage?: boolean;
    packageId: string;
    packageName: string;
  }): void => {
    setCurrentPackageName(packageName);
    setUploadUrl(chunkUploadUrl);
    setTotalChunks(Math.ceil(packageFile.size / UPLOAD_CHUNK_SIZE));

    if (lastUploadChunkIndex || lastUploadChunkIndex === 0) {
      setCurrentChunkIndex(lastUploadChunkIndex + 1);
    }

    setFile(packageFile);
    setCurrentPackageId(packageId);

    if (updateLocalStorage) {
      setLocalStorage({ chunkUploadUrl, packageFile, packageId });
    }
  };

  const { status } = addonBaseInfo;

  const resumeUpload = async (): Promise<string> => {
    setIsOpenResumeDialog(true);

    return new Promise((resolve) => {
      choiceFn.current = (choice) => {
        resolve(choice);
        setIsOpenResumeDialog(false);
      };
    });
  };

  const handleCancelUpload = (): void => {
    clearLocalState();

    if (abortController.current) {
      abortController.current.abort(packageErrors.aborted);
    }
  };

  const handleCloseResumeDialog = (): void => {
    choiceFn.current?.(CANCEL_UPLOAD);

    setIsOpenResumeDialog(false);
  };

  const handleResumeUpload = (): void => {
    choiceFn.current?.(RESUME_UPLOAD);

    setIsOpenResumeDialog(false);
  };

  const handleRestartUpload = (): void => {
    choiceFn.current?.(RESTART_UPLOAD);

    setIsOpenResumeDialog(false);
  };

  const handleDelete = (packageId: string): void => {
    setDeletePackageId(packageId);
    setIsOpenDeleteDialog(true);
  };

  const handleEditInfo = (packageId: string): void => {
    setEditPackageId(packageId);
    setIsOpenUpdatePackageDialog(true);
    setIsOpenAddPackageDialog(false);
  };

  const handleHide = async ({
    packageId,
    isHidden,
  }: {
    packageId: string;
    isHidden: boolean;
  }): Promise<void> => {
    await dispatch(hidePackage({ packageId, isHidden: !isHidden }));
  };

  const handleCloseDelete = (): void => {
    setIsOpenDeleteDialog(false);
    setDeletePackageId(null);
  };

  const handleClosePackageDialog = (): void => {
    setIsOpenAddPackageDialog(false);
    setIsOpenUpdatePackageDialog(false);
    setEditPackageId(null);
  };

  const handleAddPackage = (): void => {
    setIsOpenAddPackageDialog(true);
    setIsOpenUpdatePackageDialog(false);
  };

  const handleSubmitPackage = async (packageData: IPackageWithFiles): Promise<void> => {
    setIsSubmittingPackageDialog(true);

    const { files, ...rest } = packageData;
    const packageFile = files?.[0];

    if (addonVersionIdCurrent) {
      const {
        package: { id: packageId, packageName },
        links: { uploadUrl: oneFileUploadUrl, chunkUploadUrl },
      } = await dispatch(
        addPackage({ packageData: rest, addonVersionId: addonVersionIdCurrent })
      ).unwrap();

      setUploadPercentage(0);
      setCurrentPackageId(packageId);
      setIsSubmittingPackageDialog(false);
      setIsOpenAddPackageDialog(false);

      if (packageFile.size <= MAX_PACKAGE_SIZE_FOR_ONE_UPLOADING) {
        uploadOneFile({ packageFile, oneFileUploadUrl, packageId, packageName });
      } else {
        initChunkUploading({
          chunkUploadUrl,
          packageFile,
          updateLocalStorage: true,
          packageId,
          packageName,
        });
      }
    }
  };

  const handleUpdatePackage = async (packageData: IPackageData): Promise<void> => {
    setIsSubmittingPackageDialog(true);

    if (editPackageId) {
      dispatch(updatePackage({ packageData, packageId: editPackageId })).then(() => {
        setIsSubmittingPackageDialog(false);
        setIsOpenUpdatePackageDialog(false);
      });
    }
  };

  const handleConfirmDelete = (): void => {
    if (deletePackageId) {
      dispatch(deletePackage({ packageId: deletePackageId })).then(() => {
        setIsOpenDeleteDialog(false);
      });
    } else {
      setIsOpenDeleteDialog(false);
    }
  };

  const handleReplacePackage = async ({
    target: { files },
    packageId,
    packageName,
  }: {
    target: { files: FileList | null };
    packageId: string;
    packageName: string;
  }): Promise<void> => {
    const packageFile = files?.[0];

    const _error = vaildatePackageFile(packageFile);

    setPackageUploadingErrors((errors) => ({ ...errors, [packageId]: _error }));

    if (_error || !packageFile) {
      return;
    }

    setCurrentPackageId(packageId);
    const dataFromLocalStorage = getDataFromLocalStorage(packageId);
    const isLocalStorageDataValid = validateLocalStorageData({ packageFile, dataFromLocalStorage });

    if (isLocalStorageDataValid) {
      const { chunkUploadUrl, lastUploadChunkIndex } = dataFromLocalStorage;

      if (!chunkUploadUrl || !lastUploadChunkIndex) {
        return;
      }

      const uploadPercentageFromLocalStorage =
        ((lastUploadChunkIndex + 1) / Math.ceil(packageFile.size / UPLOAD_CHUNK_SIZE)) * 100;

      setUploadPercentage(uploadPercentageFromLocalStorage);

      const choice = await resumeUpload();

      if (choice === CANCEL_UPLOAD) {
        return;
      }

      if (choice === RESUME_UPLOAD) {
        initChunkUploading({
          chunkUploadUrl,
          packageFile,
          lastUploadChunkIndex,
          packageId,
          packageName,
        });
        return;
      }

      if (choice === RESTART_UPLOAD) {
        // console.log('upload is restarting...');
      }
    }

    const { oneFileUploadUrl, chunkUploadUrl } = await dispatch(
      getUploadUrl({ packageId })
    ).unwrap();
    setUploadPercentage(0);

    if (packageFile.size <= MAX_PACKAGE_SIZE_FOR_ONE_UPLOADING) {
      uploadOneFile({ packageFile, oneFileUploadUrl, packageId, packageName });
    } else {
      initChunkUploading({
        chunkUploadUrl,
        packageFile,
        updateLocalStorage: true,
        packageId,
        packageName,
      });
    }
  };

  const isAddonVersionStatusDraft = status.slug === 'draft';

  return (
    <section className="add-on-section add-on-section--packages">
      <div className="add-on-section__actions add-on-section__actions--package">
        {isProcessing && (
          <AddonPackageUploadingDialog
            isProcessing={isProcessing}
            uploadPercentage={uploadPercentage}
            handleCancelUpload={handleCancelUpload}
          />
        )}

        {isOpenResumeDialog && (
          <AddonPackageResumeUploadingDialog
            uploadPercentage={uploadPercentage}
            isOpen={isOpenResumeDialog}
            handleResumeUpload={handleResumeUpload}
            handleRestartUpload={handleRestartUpload}
            handleClose={handleCloseResumeDialog}
          />
        )}
      </div>

      <div className="add-on-packages__container">
        {isAddonVersionStatusDraft && (
          <UnigineButton
            className="add-on-packages__button--add-package"
            onClick={handleAddPackage}
          >
            <PlusIcon /> Add Package
          </UnigineButton>
        )}

        <ul className="add-on-packages__list">
          {packages.map((packageInfo) => (
            <AddonPackageItem
              packageInfo={packageInfo}
              key={packageInfo.id}
              onDelete={handleDelete}
              onHide={handleHide}
              onEditInfo={handleEditInfo}
              addonVersionStatus={status}
              onReplace={handleReplacePackage}
              isProcessing={isProcessing}
              packageError={packageUploadingErrors[packageInfo.id]}
              hidePackageError={hidePackageError}
              source="publisher"
            />
          ))}
        </ul>
      </div>

      <Tip className="add-on-section__package-tip" content={tips.package} />

      <AddonPackageDeleteDialog
        isOpen={isOpenDeleteDialog}
        onClose={handleCloseDelete}
        onConfirmDelete={handleConfirmDelete}
      />

      {isOpenAddPackageDialog && (
        <AddonAddPackageDialog
          isOpen={isOpenAddPackageDialog}
          isSubmitting={isSubmittingPackageDialog}
          onClose={handleClosePackageDialog}
          onSubmit={handleSubmitPackage}
        />
      )}

      {isOpenUpdatePackageDialog && (
        <AddonUpdatePackageDialog
          isOpen={isOpenUpdatePackageDialog}
          isSubmitting={isSubmittingPackageDialog}
          onClose={handleClosePackageDialog}
          onSubmit={handleUpdatePackage}
          editingPackage={editingPackage}
        />
      )}
    </section>
  );
};

export default AddonPackageTab;
