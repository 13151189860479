import './InstantPurchaseDialog.scss';

import { ReactElement } from 'react';
import { Dialog } from '@mui/material';
import OrderedItem from 'app/components/AddonTableItem/variants/OrderedItem/OrderedItem';
import ICategory from 'app/interfaces/addons/ICategory';
import ICoverFile from 'app/interfaces/addons/ICoverFile';

interface IInstantPurchaseDialog {
  id: string;
  title: string;
  coverFile?: ICoverFile;
  categories: ICategory[];
  publisher: { id: string; name: string };

  price: string;
  discountedPrice: string;
  isFree: boolean;

  currentVersionId: string;
  open: boolean;
  onClose: () => void;
}

const InstantPurchaseDialog = ({
  id,
  title,
  coverFile,
  categories,
  publisher,
  price,
  isFree,
  open,
  onClose,
}: IInstantPurchaseDialog): ReactElement => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="lg"
      PaperProps={{
        sx: { position: 'fixed', top: '96px' },
        className: 'instant-purchase-dialog',
      }}
    >
      <h1 className="instant-purchase-dialog__title">Added to your Addons!</h1>
      <OrderedItem
        addon={{ id, title, coverFile, categories, publisher }}
        price={price}
        isFree={isFree}
        showLink
      />
    </Dialog>
  );
};

export default InstantPurchaseDialog;
