import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from 'app/auth/axiosInstance';
import IError from 'app/interfaces/IError';
import { RootState } from 'app/store';
import { IContactFormFieldValues } from 'app/interfaces/publisher/IPublisher';

interface IContactPublisherSliceState {
  formValues: IContactFormFieldValues | null;
  error: IError | null;
}

export const contactPublisher = createAsyncThunk<
  any,
  { contactFormData: IContactFormFieldValues },
  { rejectValue: IError }
>('publisherSection/contactPublisher', async ({ contactFormData }, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      method: 'post',
      url: '/message-sender/to-publisher',
      data: contactFormData,
    });
    return response.data;
  } catch (err: any) {
    if (err.response) {
      return rejectWithValue({ data: err.response.data, status: err.response.status });
    }

    return rejectWithValue({
      data: { message: 'Something went wrong to send email to publisher' },
      status: 500,
    });
  }
});

const initialState: IContactPublisherSliceState = {
  formValues: null,
  error: null,
};

const contactPublisherSlice = createSlice({
  name: 'publisherSection/contactPublisher',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(contactPublisher.fulfilled, (state, action) => {
        state.formValues = action.payload;
        state.error = null;
      })
      .addCase(contactPublisher.rejected, (state, action) => {
        state.formValues = null;
        if (action.payload) {
          state.error = action.payload;
        }
      });
  },
});

export const contactPublisherSelector = ({
  publisherSection,
}: RootState): IContactFormFieldValues | null => publisherSection.contactPublisher.formValues;

export const contactPublisherErrorSelector = ({ publisherSection }: RootState): IError | null =>
  publisherSection.contactPublisher.error;

export default contactPublisherSlice.reducer;
