import './UnigineButton.scss';

import { ButtonHTMLAttributes, FC, Ref, forwardRef } from 'react';
import clsx from 'clsx';

interface IUnigineButton extends ButtonHTMLAttributes<HTMLButtonElement> {
  secondary?: boolean;
}

const UnigineButton: FC<IUnigineButton> = forwardRef(
  ({ className, secondary = false, ...props }, ref: Ref<HTMLButtonElement>) => {
    return (
      <button
        ref={ref}
        type="button"
        className={clsx('unigine-button', { 'unigine-button--secondary': secondary }, className)}
        {...props}
      />
    );
  }
);

export default UnigineButton;
