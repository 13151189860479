import { EntityState, createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import IError from 'app/interfaces/IError';
import IAddonSourceCode from 'app/interfaces/addons/IAddonSourceCode';
import ISdkVersion from 'app/interfaces/addons/ISdkVersion';
import { RootState } from 'app/store';

interface IPackageInList {
  id: string;
  packageFileName: string;
  packageStatusInfo: {
    slug: string;
  };
  packageSize: number;
  currentVersionId: string;
  description: string;
  os: string;
  sdkVersions: ISdkVersion[];
  sourceCode: IAddonSourceCode;
  isHidden: boolean;
}

const mockData: IPackageInList[] = [
  {
    id: '2',
    packageFileName: 'User_file_name.upackage',
    packageStatusInfo: {
      slug: 'processing',
    },
    packageSize: 600000,
    currentVersionId: '123',
    description:
      'You must also choose a text that is right for your English level. If the reading is too difficult, you will be frustrated and you will must also choose a text that is right for your English level.',
    os: 'windows',
    sdkVersions: [
      {
        id: '123',
        slug: '2.17',
        value: '2.17',
      },
    ],
    sourceCode: {
      id: '12345555',
      slug: 'C#',
      value: 'C#',
    },
    isHidden: false,
  },
  {
    id: '3',
    packageFileName: 'User_file_name.upackage',
    packageStatusInfo: {
      slug: 'success',
    },
    packageSize: 650000000,
    currentVersionId: '123',
    description:
      'You must also choose a text that is right for your English level. If the reading is too difficult, you will be frustrated and you will must also choose a text that is right for your English level. If the reading is too difficult, you will be frustrated and you will will must also choose a text that. If the reading is too difficult, you will be frustrated and you will will must also choose a text that. You must also choose a text that is right for your English level. If the reading is too difficult, you will be frustrated and you will must also choose a text that is right for your English level. If the reading is too difficult, you will be frustrated and you will will must also choose a text that. If the reading is too difficult, you will be frustrated and you will will must also choose a text that. You must also choose a text that is right for your English level. If the reading is too difficult, you will be frustrated and you will must also choose a text that is right for your English level. If the reading is too difficult, you will be frustrated and you will will must also choose a text that. If the reading is too difficult, you will be frustrated and you will will must also choose a text that. You must also choose a text that is right for your English level. If the reading is too difficult, you will be frustrated and you will must also choose a text that is right for your English level. If the reading is too difficult, you will be frustrated and you will will must also choose a text that. If the reading is too difficult, you will be frustrated and you will will must also choose a text that.',
    os: 'linux',
    sdkVersions: [
      {
        id: '123',
        slug: '2.17',
        value: '2.17',
      },
      {
        id: '1234',
        slug: '2.17.1',
        value: '2.17.1',
      },
    ],
    sourceCode: {
      id: '12345555',
      slug: 'C#',
      value: 'C#',
    },
    isHidden: false,
  },
  {
    id: '4',
    packageFileName: 'User_file_name.upackage',
    packageStatusInfo: {
      slug: 'storage_error',
    },
    packageSize: 650000000,
    currentVersionId: '123',
    description: '',
    os: 'cross',
    sdkVersions: [
      {
        id: '123',
        slug: '2.17',
        value: '2.17',
      },
    ],
    sourceCode: {
      id: '12345555',
      slug: 'C#',
      value: 'C#',
    },
    isHidden: true,
  },
];

const packagesAdapter = createEntityAdapter<IPackageInList>();

interface IPackagesSliceState {
  packages: EntityState<IPackageInList>;
  errors: IError | null;
}

const initialState: IPackagesSliceState = {
  packages: packagesAdapter.getInitialState({}),
  errors: null,
};

export const getPackages = createAsyncThunk<
  IPackageInList[],
  { addonVersionId: string },
  { rejectValue: IError }
>('publisherPanelSection/getPackages', async (props, { rejectWithValue }) => {
  try {
    const getMockData = (): Promise<IPackageInList[]> => {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(mockData);
        }, 1000);
      });
    };

    const packagesData = await getMockData();

    return packagesData;
  } catch (err: any) {
    if (err.response) {
      return rejectWithValue({ data: err.response.data, status: err.response.status });
    }

    return rejectWithValue({ data: { message: 'Something went wrong' }, status: 500 });
  }
});

export const addPackage = createAsyncThunk<string, { packageId: string }, { rejectValue: IError }>(
  'publisherPanelSection/addPackage',
  async ({ packageId }, { rejectWithValue }) => {
    try {
      const getMockData = (): Promise<string> => {
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve(packageId);
          }, 500);
        });
      };

      const addedPackageId = await getMockData();

      return addedPackageId;
    } catch (err: any) {
      if (err.response) {
        return rejectWithValue({ data: err.response.data, status: err.response.status });
      }

      return rejectWithValue({ data: { message: 'Something went wrong' }, status: 500 });
    }
  }
);

export const deletePackage = createAsyncThunk<
  string,
  { packageId: string },
  { rejectValue: IError }
>('publisherPanelSection/deletePackage', async ({ packageId }, { rejectWithValue }) => {
  try {
    const getMockData = (): Promise<string> => {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(packageId);
        }, 500);
      });
    };

    const deletedPackageId = await getMockData();

    return deletedPackageId;
  } catch (err: any) {
    if (err.response) {
      return rejectWithValue({ data: err.response.data, status: err.response.status });
    }

    return rejectWithValue({ data: { message: 'Something went wrong' }, status: 500 });
  }
});

export const hidePackage = createAsyncThunk<
  { packageId: string; isHidden: boolean },
  { packageId: string; isHidden: boolean },
  { rejectValue: IError }
>('publisherPanelSection/hidePackage', async ({ packageId, isHidden }, { rejectWithValue }) => {
  try {
    const getMockData = (): Promise<{ packageId: string; isHidden: boolean }> => {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({ packageId, isHidden });
        }, 500);
      });
    };

    const hiddenPackageInfo = await getMockData();

    return hiddenPackageInfo;
  } catch (err: any) {
    if (err.response) {
      return rejectWithValue({ data: err.response.data, status: err.response.status });
    }

    return rejectWithValue({ data: { message: 'Something went wrong' }, status: 500 });
  }
});

const packagesSlice = createSlice({
  name: 'publisherPanelSection',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPackages.fulfilled, (state, action) => {
        packagesAdapter.setAll(state.packages, action.payload);
      })
      .addCase(getPackages.rejected, (state, action) => {
        state.errors = action.payload ?? {
          data: { message: 'Something went wrong' },
          status: 500,
        };
        packagesAdapter.removeAll(state.packages);
      })
      .addCase(deletePackage.fulfilled, (state, action) => {
        packagesAdapter.removeOne(state.packages, action.payload);
      })
      .addCase(hidePackage.fulfilled, (state, action) => {
        const { packageId, isHidden } = action.payload;

        packagesAdapter.updateOne(state.packages, {
          id: packageId,
          changes: {
            isHidden,
          },
        });
      });
  },
});

export default packagesSlice.reducer;

export const { selectAll: packagesSelector } = packagesAdapter.getSelectors(
  ({ publisherPanelSection }: RootState) => publisherPanelSection.packages.packages
);

export const packagesErrorsSelector = ({ publisherPanelSection }: RootState): IError | null =>
  publisherPanelSection.packages.errors;
