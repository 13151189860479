import ErrorPage from 'app/main/errors/ErrorPage';

import PurchasedAddonsConfig from 'app/main/sections/PurchasedAddons/PurchasedAddonsConfig';
import AddonConfig from 'app/main/sections/Addon/AddonConfig';
import AddonsConfig from 'app/main/sections/Addons/AddonsConfig';
import OrdersConfig from 'app/main/sections/Orders/OrdersConfig';
import PublisherConfig from 'app/main/sections/Publisher/PublisherConfig';
import LoginConfig from 'app/main/sections/Login/LoginConfig';
import LicenseAgreementsConfig from 'app/main/sections/LicenseAgreements/LicenseAgreementsConfig';
import PublisherPanelConfig from 'app/main/sections/PublisherPanel/PublisherPanelConfig';
import AdminPortalConfig from 'app/main/sections/AdminPortal/AdminPortalConfig';
import DocumentationConfig from 'app/main/sections/Documentation/DocumentationConfig';

const routeConfigs = [
  ...AddonConfig,
  ...AddonsConfig,
  ...OrdersConfig,
  ...PublisherConfig,
  ...LoginConfig,
  ...LicenseAgreementsConfig,
  ...PurchasedAddonsConfig,
  ...PublisherPanelConfig,
  ...AdminPortalConfig,
  ...DocumentationConfig,
];

const routes = [
  ...routeConfigs,
  {
    path: '*',
    element: <ErrorPage status={404} text="Page not found" />,
  },
];

export default routes;
