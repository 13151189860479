import { combineReducers } from '@reduxjs/toolkit';
import authReducer from 'app/auth/store';
import unigineReducers from 'app/store/unigine';
import addonReducer from 'app/main/sections/Addon/store';
import addonsListReducer from 'app/main/sections/Addons/store';
import adminPortalReducer from 'app/main/sections/AdminPortal/store';
import ordersReducer from 'app/main/sections/Orders/store';
import publisherPanelReducer from 'app/main/sections/PublisherPanel/store';
import publisherReducer from 'app/main/sections/Publisher/store';
import purchasedAddonsReducer from 'app/main/sections/PurchasedAddons/store';

const rootReducer = combineReducers({
  unigine: unigineReducers,
  auth: authReducer,
  addonSection: addonReducer,
  addonsListSection: addonsListReducer,
  adminPortalSection: adminPortalReducer,
  ordersSection: ordersReducer,
  publisherPanelSection: publisherPanelReducer,
  publisherSection: publisherReducer,
  purchasedAddonsSection: purchasedAddonsReducer,
});

export default rootReducer;
