import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import IProduct from 'app/interfaces/addons/IProduct';
import IError from 'app/interfaces/IError';

import axiosInstance from 'app/auth/axiosInstance';
import { RootState } from 'app/store';

interface IProductsSlieState {
  products: IProduct[] | null;
}

interface IProducts {
  products: IProduct[];
  total: number;
}

const initialState: IProductsSlieState = {
  products: null,
};

export const getProductsData = createAsyncThunk<IProducts, undefined, { rejectValue: IError }>(
  'addonsListSection/getProductsData',
  async (props, { rejectWithValue }) => {
    try {
      const response = await axiosInstance({
        method: 'get',
        url: '/products',
      });
      return response.data;
    } catch (err: any) {
      if (err.response) {
        return rejectWithValue({ data: err.response.data, status: err.response.status });
      }

      return rejectWithValue({ data: { message: 'Something went wrong' }, status: 500 });
    }
  }
);

const productsSlice = createSlice({
  name: 'addonsListSection',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProductsData.fulfilled, (state, action) => {
        const { products } = action.payload;
        state.products = products;
      })
      .addCase(getProductsData.rejected, (state, action) => {
        console.log(action.payload);
      });
  },
});

export const selectProducts = ({ addonsListSection }: RootState): IProduct[] | null =>
  addonsListSection.productsInfo.products;

export default productsSlice.reducer;
