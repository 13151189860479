import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  isAnyOf,
  EntityState,
} from '@reduxjs/toolkit';
import axiosInstance from 'app/auth/axiosInstance';
import IError from 'app/interfaces/IError';
import IManager from 'app/interfaces/IManager';
import { RootState } from 'app/store';

const addonsManagersAdapter = createEntityAdapter<IManager>({});
const addonsManagersOptionsAdapter = createEntityAdapter<IManager>({});

interface IAddonsManagersSliceState {
  managers: EntityState<IManager>;
  managersOptions: EntityState<IManager>;
  errors: IError | null;
}

const initialState: IAddonsManagersSliceState = {
  managers: addonsManagersAdapter.getInitialState({}),
  managersOptions: addonsManagersOptionsAdapter.getInitialState({}),
  errors: null,
};

interface IAddonsManagersList {
  addonsManagers: IManager[];
  addonsManagersOptions: IManager[];
}

export const getAddonsManagers = createAsyncThunk<
  IAddonsManagersList,
  undefined,
  { rejectValue: IError }
>('publisherPanelSection/getAddonsManagers', async (props, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      method: 'get',
      url: '/publisher-panel/publishers/current/users-separated-by-addon-manager',
    });

    return response.data;
  } catch (err: any) {
    if (err.response) {
      return rejectWithValue({ data: err.response.data, status: err.response.status });
    }

    return rejectWithValue({ data: { message: 'Something went wrong' }, status: 500 });
  }
});

export const addManager = createAsyncThunk<
  IManager,
  { manager: IManager },
  { rejectValue: IError }
>('publisherPanelSection/addManager', async ({ manager }, { rejectWithValue }) => {
  try {
    await axiosInstance({
      method: 'post',
      url: `/publisher-panel/publishers/current/users/${manager.id}/add-addon-manager-role`,
    });

    return manager;
  } catch (err: any) {
    if (err.response) {
      return rejectWithValue({ data: err.response.data, status: err.response.status });
    }

    return rejectWithValue({ data: { message: 'Something went wrong' }, status: 500 });
  }
});

export const removeManager = createAsyncThunk<
  IManager,
  { manager: IManager },
  { rejectValue: IError }
>('publisherPanelSection/removeManager', async ({ manager }, { rejectWithValue }) => {
  try {
    await axiosInstance({
      method: 'delete',
      url: `/publisher-panel/publishers/current/users/${manager.id}/delete-addon-manager-role`,
    });

    return manager;
  } catch (err: any) {
    if (err.response) {
      return rejectWithValue({ data: err.response.data, status: err.response.status });
    }

    return rejectWithValue({ data: { message: 'Something went wrong' }, status: 500 });
  }
});

const addonsManagersSlice = createSlice({
  name: 'publisherPanelSection',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAddonsManagers.fulfilled, (state, action) => {
        const { addonsManagers, addonsManagersOptions } = action.payload;
        addonsManagersAdapter.setAll(state.managers, addonsManagers);
        addonsManagersOptionsAdapter.setAll(state.managersOptions, addonsManagersOptions);
        state.errors = null;
      })

      .addCase(addManager.fulfilled, (state, action) => {
        const manager = action.payload;
        addonsManagersAdapter.addOne(state.managers, manager);
        addonsManagersOptionsAdapter.removeOne(state.managersOptions, manager.id);
      })

      .addCase(removeManager.fulfilled, (state, action) => {
        const manager = action.payload;
        addonsManagersAdapter.removeOne(state.managers, manager.id);
        addonsManagersOptionsAdapter.addOne(state.managersOptions, manager);
      })

      .addMatcher(
        isAnyOf(getAddonsManagers.rejected, addManager.rejected, removeManager.rejected),
        (state, action) => {
          state.errors = action.payload ?? {
            data: { message: 'Something went wrong' },
            status: 500,
          };
        }
      );
  },
});

export const { selectAll: addonsManagersSelector } = addonsManagersAdapter.getSelectors(
  ({ publisherPanelSection }: RootState) => publisherPanelSection.addonsManagers.managers
);

export const { selectAll: addonsManagersOptionsSelector } =
  addonsManagersOptionsAdapter.getSelectors(
    ({ publisherPanelSection }: RootState) => publisherPanelSection.addonsManagers.managersOptions
  );

export const addonsManagersErrorsSelector = ({ publisherPanelSection }: RootState): IError | null =>
  publisherPanelSection.addonsManagers.errors;

export default addonsManagersSlice.reducer;
