import Orders from 'app/main/sections/Orders/Orders';
import OrderPaymentStatus from 'app/main/sections/Orders/OrderPaymentStatus';
import Order from 'app/main/sections/Orders/Order';
import Refund from 'app/main/sections/Orders/Refund/Refund';
import RefundResult from 'app/main/sections/Orders/RefundResult/RefundResult';

const OrdersConfig = [
  {
    path: 'orders',
    element: <Orders />,
  },
  {
    path: 'orders/:orderId',
    element: <Order />,
  },
  {
    path: 'orders/:orderId/payment-status',
    element: <OrderPaymentStatus />,
  },
  {
    path: 'orders/:orderId/refund/result',
    element: <RefundResult />,
  },
  {
    path: 'orders/:orderId/refund',
    element: <Refund />,
  },
];

export default OrdersConfig;
