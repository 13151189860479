import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { signInWithCode, userStateSelector } from 'app/auth/store/userSlice';

import useAppDispatch from 'app/hooks/useAppDispatch';

const LoginSuccess: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const STATE = useSelector(userStateSelector);
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const stateFromParams = searchParams.get('state');

  useEffect(() => {
    if (STATE === stateFromParams) {
      const backUrl = localStorage.getItem('backUrl') || '/';
      localStorage.removeItem('backUrl');
      if (code) {
        dispatch(signInWithCode({ code })).then(() => {
          setTimeout(() => {
            navigate(backUrl);
          }, 100);
        });
      }
    }
  }, [STATE, code, dispatch, navigate, stateFromParams]);

  return (
    <div className="main login-success">
      <div className="content-container content-container--main content-container--login-success">
        <section className="login-success__wrapper">
          <div>You were logged in successfully!</div>
        </section>
      </div>
    </div>
  );
};

export default LoginSuccess;
