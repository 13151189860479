import { PayloadAction, createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';

import { userDataGuest, userDataInitial } from 'app/configs/appConfig';
import getUniqueState from 'app/utils/helpers/getUniqueState';
import axiosInstance from 'app/auth/axiosInstance';
import AuthService from 'app/auth/AuthService';

import { IUserData } from 'app/interfaces/IUserData';
import { RootState } from 'app/store';

interface IUserSliceState {
  userState: string;
  userData: IUserData;
  isProcessing: boolean;
}

export interface ITokensAndUserData {
  accessToken: string;
  refreshToken: string | null;
  user: IUserData;
}

export const signInWithCode = createAsyncThunk<
  ITokensAndUserData,
  { code: string },
  { rejectValue: string }
>('auth/signInWithCode', async ({ code }, { rejectWithValue }) => {
  try {
    const response: AxiosResponse<ITokensAndUserData> = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_STORE_API_URL}/token`,
      data: {
        code,
      },
    });

    return response.data;
  } catch (err) {
    return rejectWithValue('Something went wrong');
    // return rejectWithValue({ data: err.response.data, status: err.response.status });
  }
});

export const submitRefreshToken = createAsyncThunk<
  ITokensAndUserData,
  { refreshToken: ITokensAndUserData['refreshToken'] },
  { rejectValue: string }
>('auth/submitRefreshToken', async ({ refreshToken }, { rejectWithValue }) => {
  try {
    const response: AxiosResponse<ITokensAndUserData> = await axiosInstance({
      method: 'post',
      url: '/token/refresh',
      data: {
        refresh_token: refreshToken,
      },
    });

    return response.data;
  } catch (err) {
    return rejectWithValue('Something went wrong');
    // return rejectWithValue({ data: err.response.data, status: err.response.status });
  }
});

export const submitAccessToken = createAsyncThunk<IUserData, void, { rejectValue: string }>(
  'auth/submitAccessToken',
  async (_props, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IUserData> = await axiosInstance({
        method: 'get',
        url: '/users/current',
      });

      return response.data;
    } catch (err) {
      return rejectWithValue('Something went wrong');
    }
  }
);

const initialState: IUserSliceState = {
  userState: getUniqueState(),
  userData: userDataInitial,
  isProcessing: false,
};

const userSlice = createSlice({
  name: 'auth/user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<IUserData>) => {
      state.isProcessing = false;
      state.userData = action.payload;
    },
    logoutUser: (state, action: PayloadAction<{ isProcessing: boolean }>) => {
      const { isProcessing } = action.payload;
      state.isProcessing = isProcessing;
      state.userData = userDataGuest;
      AuthService.setSession({ accessToken: null, refreshToken: null });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signInWithCode.fulfilled, (state, action) => {
        const { accessToken, refreshToken, user } = action.payload;
        state.userData = user;
        AuthService.setSession({ accessToken, refreshToken });
      })
      .addCase(submitAccessToken.fulfilled, (state, action) => {
        state.userData = action.payload;
      })
      .addCase(submitRefreshToken.fulfilled, (state, action) => {
        const { accessToken, refreshToken, user } = action.payload;
        state.userData = user;
        AuthService.setSession({ accessToken, refreshToken });
      })
      .addMatcher(
        isAnyOf(submitAccessToken.rejected, signInWithCode.rejected, submitRefreshToken.rejected),
        (state) => {
          state.userData = userDataGuest;
          AuthService.setSession({ accessToken: null, refreshToken: null });
        }
      );
  },
});

export const { setUser, logoutUser } = userSlice.actions;

export const userSelector = ({ auth }: RootState): IUserSliceState => auth.user;
export const userRolesSelector = ({ auth }: RootState): string[] => auth.user.userData.globalRoles;
export const userIdSelector = ({ auth }: RootState): string | null => auth.user.userData.id;
export const userStateSelector = ({ auth }: RootState): string => auth.user.userState;
export const userIsProcessingSelector = ({ auth }: RootState): boolean => auth.user.isProcessing;

export default userSlice.reducer;
