import moment from 'moment';

import { MAX_PACKAGE_NAME_LENGTH, MAX_PACKAGE_SIZE } from 'app/configs/appConfig';
import { SetStateAction } from 'react';
import { packageErrors } from 'app/utils/constants/contentConstants';

interface ILocalStorageArgs {
  packageId: string;
  chunkUploadUrl?: string;
  packageFile?: File;
  lastUploadChunkIndex?: number;
}

export const setLocalStorage = ({
  chunkUploadUrl,
  packageFile,
  lastUploadChunkIndex,
  packageId,
}: ILocalStorageArgs): void => {
  const chunkUploadUrlExpiration = moment().add(1, 'days').valueOf().toString();
  localStorage.setItem(`chunkUploadUrlExpiration-${packageId}`, chunkUploadUrlExpiration);

  if (chunkUploadUrl) {
    localStorage.setItem(`chunkUploadUrl-${packageId}`, chunkUploadUrl);
  }

  if (packageFile) {
    const fileData = JSON.stringify({
      name: packageFile.name,
      size: packageFile.size,
      lastModified: packageFile.lastModified,
    });
    localStorage.setItem(`fileData-${packageId}`, fileData);
  }

  if (lastUploadChunkIndex || lastUploadChunkIndex === 0) {
    localStorage.setItem(`lastUploadChunkIndex-${packageId}`, `${lastUploadChunkIndex}`);
  }
};

export const clearLocalStorage = (
  packageId: string,
  setCurrentPackageId: {
    (value: SetStateAction<string | null>): void;
    (value: SetStateAction<string | null>): void;
    (arg0: null): void;
  }
): void => {
  localStorage.removeItem(`chunkUploadUrl-${packageId}`);
  localStorage.removeItem(`chunkUploadUrlExpiration-${packageId}`);
  localStorage.removeItem(`fileData-${packageId}`);
  localStorage.removeItem(`lastUploadChunkIndex-${packageId}`);
  setCurrentPackageId(null);
};

export const getDataFromLocalStorage = (
  packageId: string
): {
  chunkUploadUrl: string | null;
  chunkUploadUrlExpiration: string | null;
  isChunkUploadUrlExpired: boolean;
  fileData: any;
  lastUploadChunkIndex: number | null;
} => {
  const chunkUploadUrlFromLocalStorage = localStorage.getItem(`chunkUploadUrl-${packageId}`);
  const chunkUploadUrlExpirationFromLocalStorage = localStorage.getItem(
    `chunkUploadUrlExpiration-${packageId}`
  );
  const fileDataFromLocalStorage = localStorage.getItem(`fileData-${packageId}`);
  const lastUploadChunkIndexFromLocalStorage = localStorage.getItem(
    `lastUploadChunkIndex-${packageId}`
  );

  const fileData = fileDataFromLocalStorage && JSON.parse(fileDataFromLocalStorage);
  const isChunkUploadUrlExpired =
    moment().valueOf() > Number(chunkUploadUrlExpirationFromLocalStorage);

  return {
    chunkUploadUrl: chunkUploadUrlFromLocalStorage,
    chunkUploadUrlExpiration: chunkUploadUrlExpirationFromLocalStorage,
    isChunkUploadUrlExpired,
    fileData,
    lastUploadChunkIndex:
      lastUploadChunkIndexFromLocalStorage !== null
        ? Number(lastUploadChunkIndexFromLocalStorage)
        : null,
  };
};

export const validateFileType = (packageFile: File): boolean => {
  return !!packageFile.name.match(/.+\.upackage$/);
};

export const validateFileSize = (packageFile: File): boolean =>
  packageFile.size <= MAX_PACKAGE_SIZE;

export const validateFileName = (packageFile: File): boolean =>
  packageFile.name.length <= MAX_PACKAGE_NAME_LENGTH;

export const validateLocalStorageData = ({
  packageFile,
  dataFromLocalStorage,
}: {
  packageFile: File;
  dataFromLocalStorage: ReturnType<typeof getDataFromLocalStorage>;
}): boolean => {
  if (dataFromLocalStorage.isChunkUploadUrlExpired) {
    return false;
  }

  if (dataFromLocalStorage.fileData?.name !== packageFile.name) {
    return false;
  }

  if (String(dataFromLocalStorage.fileData?.size) !== String(packageFile.size)) {
    return false;
  }

  if (String(dataFromLocalStorage.fileData?.lastModified) !== String(packageFile.lastModified)) {
    return false;
  }

  if (dataFromLocalStorage.lastUploadChunkIndex === null) {
    return false;
  }

  return true;
};

export const vaildatePackageFile = (packageFile: File | undefined): string => {
  if (!packageFile) {
    return packageErrors.required;
  }

  const isFileTypeValid = validateFileType(packageFile);

  if (!isFileTypeValid) {
    return packageErrors.format;
  }

  const isFileSizeValid = validateFileSize(packageFile);

  if (!isFileSizeValid) {
    return packageErrors.size;
  }

  const isFileNameValid = validateFileName(packageFile);

  if (!isFileNameValid) {
    return packageErrors.nameLength;
  }

  return '';
};
