import './ReviewList.scss';

import { FC } from 'react';

import ReviewCard from 'app/components/ReviewCard/ReviewCard';
import SortControl from 'app/components/SortControl/SortControl';
import useLoggedInUser from 'app/hooks/useLoggedInUser';

import { IReview } from 'app/interfaces/reviews/IReview';

interface IReviewListProps {
  reviews: IReview[];
  currentVersionId: string;
  publisherId: string;
  publisherName: string;
  totalReviews: number;
  onReport: (commentId: string) => void;
  onLike: (commentId: string) => void;
}

const ReviewList: FC<IReviewListProps> = ({
  reviews,
  currentVersionId,
  publisherId,
  publisherName,
  totalReviews,
  onLike,
  onReport,
}) => {
  const { isLoggedIn } = useLoggedInUser();

  return (
    <div className="review-list">
      <div className="review-list__sort-container">
        <SortControl
          label={`Sort ${totalReviews} reviews by `}
          name="sort"
          defaultDirection="DESC"
          options={[
            { value: 'rating', label: 'Rating', defaultDirection: 'DESC' },
            { value: 'createDate', label: 'Date', defaultDirection: 'ASC' },
          ]}
        />
      </div>

      <ul className="review-list__items">
        {reviews.map((review) => (
          <li className="review-list__item" key={review.id}>
            <ReviewCard
              review={review}
              currentVersionId={currentVersionId}
              publisherId={publisherId}
              publisherName={publisherName}
              onLike={() => onLike(review.id)}
              onReport={() => onReport(review.id)}
              isLoggedIn={isLoggedIn}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ReviewList;
