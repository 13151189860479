import './AddonVersionStatusText.scss';
import { FC } from 'react';

interface AddonVersionStatusTextProps {
  status: {
    value: string;
  };
}

const AddonVersionStatusText: FC<AddonVersionStatusTextProps> = ({ status }) => {
  return (
    <span className="add-on-version-status__text">
      <span className="add-on-version-status__description">({status.value})&nbsp;</span>
      <span
        className={`add-on-version-status__circle add-on-version-status__circle--${status.value.toLowerCase()}`}
      />
    </span>
  );
};

export default AddonVersionStatusText;
