import './SettingsEditSection.scss';

import { FC } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import useAppSelector from 'app/hooks/useAppSelector';
import useAppDispatch from 'app/hooks/useAppDispatch';

import { Button, TextField, FormHelperText } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';

import DiscordIcon from 'app/assets/discord.svg';
import InstagramIcon from 'app/assets/instagram.svg';
import FacebookIcon from 'app/assets/facebook.svg';
import LinkedInIcon from 'app/assets/linkedin.svg';
import TwitterIcon from 'app/assets/x.svg';
import YoutubeIcon from 'app/assets/youtube.svg';

import { FormGrid, FormRow } from 'app/components/FormGrid/FormGrid';
import RichTextEditor from 'app/components/RichTextEditor/RichTextEditor';
import { regExps } from 'app/configs/appConfig';
import { formFieldErrors, tooltips } from 'app/utils/constants/contentConstants';

import {
  editCurrentPublisherInfo,
  currentPublisherInfoSelector,
} from 'app/main/sections/PublisherPanel/sections/SettingsSection/store/settingsSlice';

interface IPublisherDataForm {
  name: string;
  email?: string;
  description?: string;
  discord?: string;
  facebook?: string;
  instagram?: string;
  linkedin?: string;
  twitter?: string;
  youtube?: string;
  site?: string;
}

const SettingsEditSection: FC = () => {
  const currentPublisherData = useAppSelector(currentPublisherInfoSelector);
  const defaultDescription = currentPublisherData?.description;

  const defaultName = currentPublisherData?.name ? currentPublisherData.name : '';

  const defaultContacts = currentPublisherData?.contacts;
  const defaultEmail = defaultContacts?.email;
  const defaultDiscord = defaultContacts?.discord;
  const defaultFacebook = defaultContacts?.facebook;
  const defaultInstagram = defaultContacts?.instagram;
  const defaultLinkedin = defaultContacts?.linkedin;
  const defaultTwitter = defaultContacts?.linkedin;
  const defaultYoutube = defaultContacts?.youtube;
  const defaultSiteLink = defaultContacts?.site_link;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { control, handleSubmit } = useForm({
    defaultValues: {
      name: defaultName,
      email: defaultEmail,
      site: defaultSiteLink,
      discord: defaultDiscord,
      instagram: defaultInstagram,
      twitter: defaultTwitter,
      youtube: defaultYoutube,
      facebook: defaultFacebook,
      linkedin: defaultLinkedin,
      description: defaultDescription,
    },
    mode: 'onChange',
  });

  const onFormSubmit = async (publisherDataForm: IPublisherDataForm): Promise<void> => {
    try {
      const {
        name,
        description,
        discord,
        facebook,
        instagram,
        linkedin,
        twitter,
        youtube,
        email,
        site,
      } = publisherDataForm;
      const allPublisherContacts = currentPublisherData?.contacts;
      const dataToUpdate = {
        name,
        description,
        contacts: {
          ...allPublisherContacts,
          discord,
          facebook,
          instagram,
          linkedin,
          twitter,
          youtube,
          email,
          site_link: site,
        },
      };
      await dispatch(editCurrentPublisherInfo(dataToUpdate));
      navigate('/publisherPanel/settings');
    } catch (error: any) {
      console.error('Could not update publisher data:', error.message, '\n', error);
    }
  };

  return (
    <div className="publisher-panel__wrapper--no-header">
      <div className="settings__title-container">
        <h1 className="settings__title">Settings</h1>

        <Button
          type="submit"
          form="update-publisher-data-form"
          className="settings-edit__save-button to-right"
        >
          Save
        </Button>
      </div>
      <form
        id="update-publisher-data-form"
        onSubmit={handleSubmit(onFormSubmit)}
        className="add-add-on__form add-on-form settings-edit__form"
      >
        <FormGrid>
          <FormRow
            required
            label="Name"
            labelledInputId="publisher_settings_name"
            tooltip={tooltips.publisherSettingsForm.name}
          >
            <Controller
              control={control}
              name="name"
              rules={{ required: formFieldErrors.required }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  size="small"
                  className="unigine-form-field"
                  variant="outlined"
                  fullWidth
                  required
                  inputProps={{
                    id: 'publisher_settings_name',
                  }}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </FormRow>

          <FormRow
            required
            label="Contact Email"
            labelledInputId="publisher_settings_email"
            tooltip={tooltips.publisherSettingsForm.email}
          >
            <Controller
              control={control}
              name="email"
              rules={{
                required: formFieldErrors.required,
                pattern: {
                  value: regExps.email,
                  message: formFieldErrors.emailPattern,
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  size="small"
                  className="unigine-form-field"
                  variant="outlined"
                  fullWidth
                  required
                  inputProps={{
                    id: 'publisher_settings_email',
                  }}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </FormRow>

          <FormRow
            label="Site URL"
            labelledInputId="publisher_settings_site"
            tooltip={tooltips.publisherSettingsForm.site_link}
          >
            <Controller
              control={control}
              name="site"
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  size="small"
                  className="unigine-form-field"
                  variant="outlined"
                  fullWidth
                  inputProps={{
                    id: 'publisher_settings_site',
                  }}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </FormRow>

          <FormRow label="Social media links">
            <div className="settings-edit__contacts">
              <Controller
                control={control}
                name="discord"
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    size="small"
                    className="unigine-form-field"
                    variant="outlined"
                    placeholder="Discord"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={DiscordIcon} width="26" alt="discord" />
                        </InputAdornment>
                      ),
                    }}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
              <Controller
                control={control}
                name="instagram"
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    size="small"
                    className="unigine-form-field"
                    variant="outlined"
                    placeholder="Instagram"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={InstagramIcon} width="26" alt="instagram" />
                        </InputAdornment>
                      ),
                    }}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
              <Controller
                control={control}
                name="twitter"
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    size="small"
                    className="unigine-form-field"
                    variant="outlined"
                    placeholder="Twitter"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={TwitterIcon} width="26" alt="twitter" />
                        </InputAdornment>
                      ),
                    }}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
              <Controller
                control={control}
                name="youtube"
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    size="small"
                    className="unigine-form-field"
                    variant="outlined"
                    placeholder="Youtube"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={YoutubeIcon} width="26" alt="youtube" />
                        </InputAdornment>
                      ),
                    }}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
              <Controller
                control={control}
                name="facebook"
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    size="small"
                    className="unigine-form-field"
                    variant="outlined"
                    placeholder="Facebook"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={FacebookIcon} width="26" alt="facebook" />
                        </InputAdornment>
                      ),
                    }}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
              <Controller
                control={control}
                name="linkedin"
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    size="small"
                    className="unigine-form-field"
                    variant="outlined"
                    placeholder="Linked In"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={LinkedInIcon} width="26" alt="linkedin" />
                        </InputAdornment>
                      ),
                    }}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </div>
          </FormRow>
          <FormRow label="Description">
            <Controller
              control={control}
              name="description"
              rules={{
                maxLength: {
                  value: 1000,
                  message: formFieldErrors.richTextLength,
                },
                pattern: {
                  value: regExps.richText,
                  message: formFieldErrors.richTextCharacters,
                },
              }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <>
                  <RichTextEditor initialHtml={value} onChange={onChange} error={error} />
                  {error && <FormHelperText error>{error.message}</FormHelperText>}
                </>
              )}
            />
          </FormRow>
        </FormGrid>
        {/* <Button type="submit" className="settings__edit-button to-right">
          Save
        </Button> */}
      </form>
    </div>
  );
};

export default SettingsEditSection;
