import { JSX } from 'react';
import { FormGroup, FormControl, FormHelperText, FormControlLabel } from '@mui/material';

import CheckboxUnigine from 'app/components/CheckboxUnigine/CheckboxUnigine';

import './CheckboxGroup.scss';
import { FieldError } from 'react-hook-form';

type CheckboxGroupProps<T> = {
  items?: T[];
  selectedValues?: string[];
  onChange?: (values: string[]) => void;
  getIsDisabled?: (item: T) => boolean;
  error?: FieldError;
  id?: string;
} & (T extends { value: string }
  ? { getValue?: (item: T) => string }
  : { getValue: (item: T) => string }) &
  (T extends { label: string }
    ? { getLabel?: (item: T) => string }
    : { getLabel: (item: T) => string });

export default function CheckboxGroup<T>({
  items = [],
  selectedValues = [],
  onChange = () => {},

  getLabel = (item) => item.label,
  getValue = (item) => item.value,
  getIsDisabled = () => false,

  error,
  id,
}: CheckboxGroupProps<T>): JSX.Element {
  return (
    <FormControl error={!!error} id={id} className="checkbox-group">
      <FormGroup className="checkbox-group__items">
        {items.map((item) => {
          const value = getValue(item);
          const label = getLabel(item);

          const disabled = getIsDisabled(item);
          const checked = selectedValues.includes(value);

          const onCheckboxChange = (): void => {
            if (checked) {
              return onChange(selectedValues.filter((v) => v !== value));
            }

            return onChange([...selectedValues, value]);
          };

          return (
            <FormControlLabel
              key={value}
              label={label}
              className="checkbox-group__item"
              control={
                <CheckboxUnigine
                  name={value}
                  onChange={onCheckboxChange}
                  checked={checked}
                  disabled={disabled}
                  className="checkbox-group__checkbox"
                />
              }
            />
          );
        })}
      </FormGroup>
      {error && <FormHelperText>{error?.message}</FormHelperText>}
    </FormControl>
  );
}
