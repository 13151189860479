import './AddonChangelogTab.scss';

import { FC } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';

import EditSquareIcon from 'app/assets/icon_edit_square.svg';
import useAppSelector from 'app/hooks/useAppSelector';

import { addonBaseInfoSelector } from 'app/main/sections/PublisherPanel/sections/AddonSection/store/baseInfoSlice';
import { addonSelector } from 'app/main/sections/PublisherPanel/sections/AddonSection/store/addonInfoSlice';

const AddonChangelogTab: FC = () => {
  const { addonId } = useParams();
  const [searchParams] = useSearchParams();

  const addonBaseInfo = useAppSelector(addonBaseInfoSelector);
  const addonInfo = useAppSelector(addonSelector);

  if (!addonBaseInfo || !addonInfo) {
    return <>Loading...</>;
  }

  const { changeLog, status } = addonBaseInfo;
  const { isBanned } = addonInfo;
  const displayEditChangelogButton = status.value === 'Draft' && !isBanned;

  return (
    <section className="add-on-section">
      <div className="add-on-section__content">
        <div
          className="add-on-section__changelog"
          dangerouslySetInnerHTML={{ __html: changeLog }}
        />
      </div>

      <div className="add-on-section__actions">
        {displayEditChangelogButton && (
          <Link
            className="add-on-section__action-button add-on-section__action-button--edit-content"
            to={`/publisherPanel/add-ons/${addonId}/changelog/edit?${searchParams.toString()}`}
          >
            <img src={EditSquareIcon} alt="Edit add-on changelog" /> Edit Changelog
          </Link>
        )}
      </div>
    </section>
  );
};

export default AddonChangelogTab;
