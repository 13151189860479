import { FC, ReactNode, MouseEventHandler } from 'react';
import { Link, To } from 'react-router-dom';
import clsx from 'clsx';

import ICategory from 'app/interfaces/addons/ICategory';
import CategoryTags from 'app/components/CategoryTags/CategoryTags';
import defaultCover from 'app/assets/asset-card.png';

import './AddonTableItem.scss';

interface AddonTableItemProps {
  addon: {
    id: string;
    title: string;
    coverFile?: { id: string };
    categories: ICategory[];
    publisher?: {
      id: string;
      name: string;
    };
  };
  className?: string;
  headingLevel?: 'h2' | 'h3' | 'h4';
  linkTo?: To;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  children?: ReactNode;
}

interface PriceDisplayProps {
  price: string;
  discountedPrice?: string;
  isFree?: boolean;
  isRefunded?: boolean;
}

const PriceDisplay: FC<PriceDisplayProps> = ({
  price,
  discountedPrice = price,
  isFree = false,
  isRefunded = false,
}) => {
  const isDiscounted = discountedPrice !== price && !isFree && !isRefunded;
  return (
    <div
      className={clsx('table-item-price', {
        'table-item-price--discounted': isDiscounted,
        'table-item-price--refunded': isRefunded,
        'table-item-price--free': isFree,
      })}
    >
      {isDiscounted && <span className="table-item-price__original">$ {price}</span>}
      <span className="table-item-price__value">{isFree ? 'Free' : `$ ${discountedPrice}`}</span>
      {isRefunded && <span className="table-item-price__refunded-notice">Refunded</span>}
    </div>
  );
};

const AddonTableItem: FC<AddonTableItemProps> & {
  PriceDisplay: typeof PriceDisplay;
} = ({ addon, className, headingLevel = 'h2', linkTo, onClick, children }) => {
  const { title, coverFile, categories, publisher } = addon;
  const Heading = headingLevel;

  const isLinkActive = typeof linkTo !== 'undefined';
  const LinkElement = isLinkActive ? (
    <Link to={linkTo} className="add-on-table-item__link" onClick={onClick}>
      <Heading className="add-on-table-item__title">{title}</Heading>
    </Link>
  ) : (
    <div className="add-on-table-item__link add-on-table-item__link--disabled">
      <Heading className="add-on-table-item__title">{title}</Heading>
    </div>
  );

  return (
    <article className={clsx('add-on-table-item', className)}>
      <img
        src={
          coverFile?.id
            ? `${process.env.REACT_APP_STORE_API_URL}/media-files/${coverFile.id}`
            : defaultCover
        }
        alt={title}
        className="add-on-table-item__image"
      />

      <div className="add-on-table-item__info">
        {LinkElement}
        <CategoryTags
          categories={categories}
          className="add-on-table-item__categories add-on-table-item__action"
        />
        {publisher && (
          <div className="add-on-table-item__action">
            <Link
              to={`/publisher/${publisher.id}`}
              className="add-on-table-item__publisher add-on-table-item__publisher--link"
            >
              {publisher.name}
            </Link>
          </div>
        )}
      </div>

      {children && <div className="add-on-table-item__extras">{children}</div>}
    </article>
  );
};

AddonTableItem.PriceDisplay = PriceDisplay;

export default AddonTableItem;
