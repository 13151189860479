import './AddonSection.scss';

import { FC, useEffect, useState } from 'react';
import { Link, Outlet, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import clsx from 'clsx';
import { Button, CircularProgress, FormControlLabel } from '@mui/material';

import EditIcon from 'app/assets/icon_edit.svg';
import PublishIcon from 'app/assets/icon_publish.svg';
import WithdrawIcon from 'app/assets/icon_withdraw.svg';
import PlusIcon from 'app/assets/icon_plus.svg';

import AddonNavigationMenu from 'app/components/AddonNavigationMenu/AddonNavigationMenu';
import AddonStatus from 'app/components/AddonStatus/AddonStatus';
import VersionControl from 'app/components/VersionControl/VersionControl';
import UnigineButton from 'app/components/UnigineButton/UnigineButton';
import CheckboxUnigine from 'app/components/CheckboxUnigine/CheckboxUnigine';
import Tip from 'app/components/Tip/Tip';
import UnigineTooltip from 'app/components/Tooltip/Tooltip';

import { getPlatformData } from 'app/main/sections/Addons/store/platformSlice';
import { getProductsData } from 'app/main/sections/Addons/store/productsSlice';
import { getVersionsData } from 'app/main/sections/Addons/store/versionsSlice';
import { getSourceLanguageData } from 'app/main/sections/Addons/store/sourceLanguagesSlice';

import useAppDispatch from 'app/hooks/useAppDispatch';
import useAppSelector from 'app/hooks/useAppSelector';
import usePermissions from 'app/hooks/usePermissions';

import { tips, tooltips } from 'app/utils/constants/contentConstants';
import getPublisherPanelButtonsInfo from 'app/utils/helpers/getPublisherPanelButtonsInfo';

import {
  addonErrorSelector,
  addonSelector,
  getAddonInfo,
  toggleAddonArchiveStatus,
} from 'app/main/sections/PublisherPanel/sections/AddonSection/store/addonInfoSlice';
import {
  addonBaseInfoErrorSelector,
  addonBaseInfoPublishErrorSelector,
  addonBaseInfoSelector,
  createAddonVersion,
  getAddonBaseInfo,
  publishAddonVersion,
  withdrawAddonVersion,
} from 'app/main/sections/PublisherPanel/sections/AddonSection/store/baseInfoSlice';

import addonNavigationMenuData from 'app/main/sections/PublisherPanel/sections/AddonSection/addonNavigationMenuData';

const AddonSection: FC = () => {
  const dispatch = useAppDispatch();
  const { addonId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [loadingAddonInfo, setLoadingAddonInfo] = useState(true);
  const navigate = useNavigate();
  const { isNoRole } = usePermissions();

  const addonError = useAppSelector(addonErrorSelector);
  const error = useAppSelector(addonBaseInfoErrorSelector);
  const publishError = useAppSelector(addonBaseInfoPublishErrorSelector);
  const [searchParams] = useSearchParams();
  const addonInfo = useAppSelector(addonSelector);
  const addonBaseInfo = useAppSelector(addonBaseInfoSelector);

  useEffect(() => {
    if (!isNoRole && addonId) {
      Promise.all([
        dispatch(getAddonInfo({ addonId })),
        dispatch(getVersionsData()),
        dispatch(getProductsData()),
        dispatch(getPlatformData()),
        dispatch(getSourceLanguageData()),
      ]).finally(() => setLoadingAddonInfo(false));
    }
  }, [addonId, dispatch, isNoRole]);

  useEffect(() => {
    if (!loadingAddonInfo && !addonError && addonInfo) {
      const addonVersionFromParams = searchParams.get('add-on-version');

      const addonVersionIdCurrent = addonInfo.addonVersions.filter(
        (version) => version.isCurrent
      )[0]?.id;

      const addonVersionId = addonVersionFromParams || addonVersionIdCurrent;

      dispatch(getAddonBaseInfo({ addonVersionId })).finally(() => setIsLoading(false));
    }

    if (addonError) {
      setIsLoading(false);
    }
  }, [addonError, addonInfo, dispatch, loadingAddonInfo, searchParams]);

  if (addonError) {
    return (
      <div className="add-on-section-wrapper">
        <div className="add-on-section__add-on-wrapper">
          <AddonStatus status="error" />

          <div className="add-on-section__title-wrapper">
            <h1 className="add-on-section__title">
              <Link to="/publisherPanel/add-ons">My Uploaded Add-Ons</Link>
            </h1>
          </div>
        </div>

        <AddonNavigationMenu addonNavigationMenuData={addonNavigationMenuData} />

        <section className="add-on-section">
          <div className="add-on-section__info">
            <div className="add-on-section__block">{addonError.data.context?.message}</div>
          </div>
        </section>
      </div>
    );
  }

  if (error) {
    return (
      <div className="add-on-section-wrapper">
        <div className="add-on-section__add-on-wrapper">
          <AddonStatus status="error" />

          <div className="add-on-section__title-wrapper">
            <h1 className="add-on-section__title">
              <Link to="/publisherPanel/add-ons">My Uploaded Add-Ons</Link>
            </h1>
          </div>
        </div>

        <AddonNavigationMenu addonNavigationMenuData={addonNavigationMenuData} />

        <section className="add-on-section">
          <div className="add-on-section__info">
            <div className="add-on-section__block">{error.data.context?.message}</div>
          </div>
        </section>
      </div>
    );
  }

  if (isLoading || !addonInfo || !addonId || !addonBaseInfo) {
    return (
      <div className="add-on-section-wrapper">
        <div className="add-on-section__add-on-wrapper">
          <AddonStatus status="loading" />

          <div className="add-on-section__title-wrapper">
            <h1 className="add-on-section__title">
              <Link to="/publisherPanel/add-ons">My Uploaded Add-Ons</Link>
            </h1>
          </div>
        </div>

        <AddonNavigationMenu addonNavigationMenuData={addonNavigationMenuData} />

        <section className="add-on-section add-on-section--loading">
          <div className="orders__spinner">
            <CircularProgress color="inherit" />
          </div>
        </section>
      </div>
    );
  }

  const {
    title,
    price,
    discountedPrice,
    addonVersions,
    addonStatus,
    isArchived,
    isFree,
    isBanned,
  } = addonInfo;

  const { status, id: addonVersionId, packageFileStatus } = addonBaseInfo;

  const packageError =
    packageFileStatus?.slug === 'file_error' || packageFileStatus?.slug === 'storage_error';

  const {
    displayArchiveButton,
    displayAddNewAddonVersionButton,
    displayGetFromArchiveButton,
    displayHideAddonCheckbox,
    displayPublishButton,
    displayWithdrawButton,
    displayEditTitleAndPriceButton,
  } = getPublisherPanelButtonsInfo({ addonStatus, addonBaseInfoStatus: status });

  const isNewVersionButtonDisabled = addonVersions.reduce((total, addonVersion) => {
    return (
      addonVersion.status.value === 'Draft' || addonVersion.status.value === 'Moderation' || total
    );
  }, false);

  const sortAddonVersions = addonVersions
    .map((addonVersion) => {
      let label = addonVersion.versionNumber;

      if (addonVersion.status.value !== 'Published') {
        label = `${addonVersion.versionNumber} (${addonVersion.status.value})`;
      }

      return {
        value: addonVersion.id,
        label,
        isCurrent: addonVersion.isCurrent,
        status: addonVersion.status,
      };
    })
    .sort((a, b) => Number(b.isCurrent) - Number(a.isCurrent));

  const handleToggleArchiveClick = (): void => {
    setIsLoading(true);
    dispatch(toggleAddonArchiveStatus({ addonId, isArchived: !isArchived })).then(() => {
      setIsLoading(false);
    });
  };

  const handleNewAddonVersionClick = (): void => {
    setIsLoading(true);

    dispatch(createAddonVersion({ addonId }))
      .unwrap()
      .then(({ id: createdAddonVersionId }) => {
        dispatch(getAddonInfo({ addonId })).then(() => {
          navigate(
            `/publisherPanel/add-ons/${addonId}/base/edit?add-on-version=${createdAddonVersionId}`
          );
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handlePublishClick = (): void => {
    setIsLoading(true);

    dispatch(publishAddonVersion({ addonVersionId }))
      .unwrap()
      .then(() => {
        dispatch(getAddonInfo({ addonId }));
      })
      .catch((err) => {
        if (process.env.NODE_ENV === 'development') {
          console.log(err);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleWithdrawClick = (): void => {
    setIsLoading(true);

    dispatch(withdrawAddonVersion({ addonVersionId }))
      .unwrap()
      .then(() => {
        dispatch(getAddonInfo({ addonId }));
      })
      .catch((err) => {
        if (process.env.NODE_ENV === 'development') {
          console.log(err);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <Helmet>
        <title>{title} | Unigine Add-On Store</title>
      </Helmet>

      <div className="add-on-section-wrapper">
        <div className="add-on-section__add-on-wrapper">
          <div className="add-on-section__add-on-top-container">
            <AddonStatus status={addonStatus.slug} />

            {status.value === 'Moderation' && (
              <Tip className="add-on-section__status-tip" content={tips.moderation} />
            )}

            <div className="add-on-section__checkbox-container">
              {displayHideAddonCheckbox && (
                <FormControlLabel
                  label="Hide add-on"
                  className="checkbox-group__item"
                  control={
                    <CheckboxUnigine
                      name="hide-add-on"
                      // onChange={onCheckboxChange}
                      // checked={checked}
                      className="checkbox-group__checkbox"
                    />
                  }
                />
              )}
            </div>

            <div className="add-on-section__add-on-actions-container">
              {displayAddNewAddonVersionButton && isNewVersionButtonDisabled && (
                <UnigineTooltip title={tooltips.newAddonVersion}>
                  <UnigineButton className="add-on-section__add-on-action add-on-section__add-on-action--new-version add-on-section__add-on-action--disabled">
                    <img src={PlusIcon} alt="Add Manager" /> New Version
                  </UnigineButton>
                </UnigineTooltip>
              )}

              {displayAddNewAddonVersionButton && !isNewVersionButtonDisabled && (
                <UnigineButton
                  className="add-on-section__add-on-action add-on-section__add-on-action--new-version"
                  onClick={handleNewAddonVersionClick}
                >
                  <img src={PlusIcon} alt="Add Manager" /> New Version
                </UnigineButton>
              )}

              {displayArchiveButton && (
                <UnigineTooltip title={tooltips.archiveAddon}>
                  <UnigineButton
                    className="add-on-section__add-on-action add-on-section__add-on-action--archive"
                    onClick={handleToggleArchiveClick}
                  >
                    Archive
                  </UnigineButton>
                </UnigineTooltip>
              )}

              {displayGetFromArchiveButton && (
                <UnigineTooltip title={tooltips.getFromArchive}>
                  <UnigineButton
                    className="add-on-section__add-on-action add-on-section__add-on-action--archive"
                    onClick={handleToggleArchiveClick}
                  >
                    Get From Archive
                  </UnigineButton>
                </UnigineTooltip>
              )}
            </div>
          </div>

          <div className="add-on-section__title-wrapper">
            <h1 className="add-on-section__title" aria-label={title}>
              <button
                type="button"
                className="add-on-section__title-action"
                aria-label={`Copy "${title}" to clipboard`}
                title={title}
                onClick={() => navigator.clipboard.writeText(title)}
              />
              {title}
            </h1>

            <div className="add-on-section__price-wrapper">
              <div className="add-on-section__price-container">
                {isFree && <span className="add-on-section__price--free">FREE</span>}

                {price !== discountedPrice && !isFree && (
                  <span className="add-on-section__price--line">$ {price}</span>
                )}

                {!isFree && <span className="add-on-section__price">$ {discountedPrice}</span>}
              </div>

              {displayEditTitleAndPriceButton && (
                <Button component={Link} to="edit" className="add-on-section__edit-link">
                  <img src={EditIcon} alt="edit" />
                </Button>
              )}
            </div>
          </div>
        </div>

        <div>
          <div className="add-on-section__version-container">
            <VersionControl
              name="add-on-version"
              label="VERSION"
              versions={sortAddonVersions}
              addon={{ isBanned, status }}
            />

            {(displayPublishButton || displayWithdrawButton) && (
              <Tip
                className={clsx('add-on-section__publishing-tip', {
                  'add-on-section__moderation-tip': displayWithdrawButton,
                })}
                compact
                content={displayPublishButton ? tips.publishButton : tips.withdrawButton}
              />
            )}

            <div className="add-on-section__action-container">
              {displayPublishButton && (
                <UnigineTooltip title={tooltips.publishAddon} className="pre-wrap max-w-400">
                  <UnigineButton
                    className="add-on-section__action-button add-on-section__publish-button"
                    onClick={handlePublishClick}
                    disabled={!!publishError || packageError}
                  >
                    <img src={PublishIcon} alt="Publish add-on" /> Publish
                  </UnigineButton>
                </UnigineTooltip>
              )}

              {displayWithdrawButton && (
                <UnigineTooltip title={tooltips.withdrawAddon}>
                  <UnigineButton
                    className="add-on-section__action-button add-on-section__publish-button"
                    onClick={handleWithdrawClick}
                    disabled={!!publishError || packageError}
                  >
                    <img src={WithdrawIcon} alt="Withdraw add-on" /> Withdraw
                  </UnigineButton>
                </UnigineTooltip>
              )}

              {publishError && (
                <div className="add-on-section__error--text">
                  {publishError.data.context?.message}
                </div>
              )}
            </div>
          </div>
        </div>

        <AddonNavigationMenu addonNavigationMenuData={addonNavigationMenuData} />

        <div>
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default AddonSection;
