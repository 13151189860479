import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from 'app/auth/axiosInstance';
import IError from 'app/interfaces/IError';
import { RootState } from 'app/store';

interface IOrderId {
  orderId: string;
}

interface IPaymentSliceState {
  payment: any;
  errors: IError | null;
}

export const getPaymentInfo = createAsyncThunk<any, IOrderId, { rejectValue: IError }>(
  'ordersSection/getPaymentInfo',
  async ({ orderId }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance({
        method: 'get',
        url: `/account/orders/${orderId}/payments/last`,
      });

      return response.data;
    } catch (err: any) {
      if (err.response) {
        return rejectWithValue({ data: err.response.data, status: err.response.status });
      }

      return rejectWithValue({
        data: { message: 'Something went wrong to get payment info' },
        status: 500,
      });
    }
  }
);

const initialState: IPaymentSliceState = {
  payment: null,
  errors: null,
};

const paymentsSlice = createSlice({
  name: 'ordersSection/payments',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPaymentInfo.fulfilled, (state, action) => {
        state.payment = action.payload;
        state.errors = null;
      })
      .addCase(getPaymentInfo.rejected, (state, action) => {
        state.payment = null;
        if (action.payload) {
          state.errors = action.payload;
        }
      });
  },
});

export const paymentSelector = ({ ordersSection }: RootState): any =>
  ordersSection.payments.payment;
export const paymentErrorsSelector = ({ ordersSection }: RootState): IError | null =>
  ordersSection.payments.errors;

export default paymentsSlice.reducer;
