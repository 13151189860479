import './CategoriesList.scss';

import { JSX } from 'react';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';

import { categoriesSelector } from 'app/store/unigine/categoriesSlice';
import useAppSelector from 'app/hooks/useAppSelector';

const CategoriesList = (): JSX.Element => {
  const categories = useAppSelector(categoriesSelector);

  return (
    <ul className="navigation__list">
      {categories.map((category) => {
        return (
          <li key={category.slug} id={category.slug} className="navigation__item">
            <NavLink
              className={({ isActive }) =>
                clsx('navigation__link_bottom', {
                  'navigation__link--active': isActive,
                })
              }
              to={`/${category.slug}`}
            >
              {category.name}
            </NavLink>
          </li>
        );
      })}
    </ul>
  );
};

export default CategoriesList;
