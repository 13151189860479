import LoginSuccess from 'app/main/sections/Login/LoginSuccess';

const LoginConfig = [
  {
    path: '/sso',
    auth: [],
    element: <LoginSuccess />,
  },
];

export default LoginConfig;
