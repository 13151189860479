import './AdminPortal.scss';

import { FC, useEffect } from 'react';
import { Outlet, useMatch, useNavigate } from 'react-router-dom';
import clsx from 'clsx';

import Sidebar from 'app/components/Sidebar/Sidebar';

import usePermissions from 'app/hooks/usePermissions';
import useLoginOnGuests from 'app/hooks/useLoginOnGuests';
import useAppSelector from 'app/hooks/useAppSelector';

import adminPortalSidebarData from 'app/main/sections/AdminPortal/adminPortalSidebarData';
import { sidebarSelector } from 'app/store/unigine/sidebarSlice';

const AdminPortal: FC = () => {
  const navigate = useNavigate();
  const sidebarData = useAppSelector(sidebarSelector);
  const { notAdminPortalUser, adminPortalUser } = usePermissions();

  const isAddonPage = useMatch(`/adminPortal/add-ons/:addonId/*`);

  const isFixedSidebar = sidebarData === 'true' || sidebarData === true || sidebarData === null;

  useLoginOnGuests();

  useEffect(() => {
    if (notAdminPortalUser && !adminPortalUser) {
      navigate('/all');
    }
  }, [navigate, notAdminPortalUser, adminPortalUser]);

  return (
    <div className="publisher-panel__wrapper">
      <Sidebar itemsData={adminPortalSidebarData} />
      <div
        className={clsx('main--publisher-panel publisher-table main--filters', {
          'main--publisher-add-on': isAddonPage,
          'main--fixed-sidebar': isFixedSidebar,
        })}
      >
        <Outlet />
      </div>
    </div>
  );
};

export default AdminPortal;
