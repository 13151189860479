import { FC, useEffect, useState } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';

import { CircularProgress } from '@mui/material';

import ReviewCard from 'app/components/ReviewCard/ReviewCard';
import ReviewForm from 'app/components/ReviewForm/ReviewForm';
import ReviewList from 'app/components/ReviewList/ReviewList';
import UnigineButton from 'app/components/UnigineButton/UnigineButton';
import PublisherLink from 'app/components/PublisherLink/PublisherLink';
import CategoryTags from 'app/components/CategoryTags/CategoryTags';
import PriceDisplay from 'app/components/PriceDisplay/PriceDisplay';
import PurchaseButton from 'app/components/PurchaseButton/PurchaseButton';
import Tip from 'app/components/Tip/Tip';
import UniginePagination from 'app/components/UniginePagination/UniginePagination';

import useAppDispatch from 'app/hooks/useAppDispatch';
import useAppSelector from 'app/hooks/useAppSelector';
import useInCart from 'app/hooks/useInCart';
import AddonInfobox from 'app/components/AddonHeader/AddonInfobox';
import useLoginGuard from 'app/hooks/useLoginGuard';
import useScrollThreshold from 'app/hooks/useScrollThreshold';

import { STOREFRONT_HEADER_HEIGHT, reviewsPerPage } from 'app/configs/appConfig';
import { tips } from 'app/utils/constants/contentConstants';

import {
  addonBaseInfoSelector,
  addonVersionSelector,
  getFreeAddon,
} from 'app/main/sections/Addon/store/baseInfoSlice';
import {
  addonUserReviewSelector,
  totalReviewsSelector,
  getAddonReviews,
  selectReviews,
  totalPagesSelector,
  addonReviewsErrorSelector,
  postReview,
  postReviewErrorSelector,
  likeReview,
  reportReview,
  reviewsAddonIdSelector,
  updateReview,
  getUserReview,
  addonUserReviewErrorSelector,
} from 'app/main/sections/Addon/store/reviewsSlice';

import './AddonReviews.scss';
import useLoggedInUser from 'app/hooks/useLoggedInUser';
import getPreviewSrc from 'app/utils/helpers/getPreviewSrc';
import { addToCart } from 'app/store/unigine/cartSlice';

const AddonReviews: FC = () => {
  const dispatchOrLogin = useLoginGuard();
  const dispatch = useAppDispatch();
  const { isLoggedIn } = useLoggedInUser();

  const { addonId } = useParams();
  const [searchParams] = useSearchParams();

  const isInCart = useInCart(addonId || null);
  const [loading, setLoading] = useState(true);

  const addon = useAppSelector(addonBaseInfoSelector);
  const addonVersion = useAppSelector(addonVersionSelector);

  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const userReview = useAppSelector(addonUserReviewSelector);
  const reviews = useAppSelector(selectReviews);
  const totalReviews = useAppSelector(totalReviewsSelector);
  const totalPages = useAppSelector(totalPagesSelector);
  const reviewsError = useAppSelector(addonReviewsErrorSelector);
  const userReviewError = useAppSelector(addonUserReviewErrorSelector);
  const postError = useAppSelector(postReviewErrorSelector);
  const reviewsAddonId = useAppSelector(reviewsAddonIdSelector);

  const isStale = !!reviewsAddonId && reviewsAddonId !== addonId;

  const [page, sort, direction, perPage] = [
    searchParams.get('page'),
    searchParams.get('sort'),
    searchParams.get('direction'),
    searchParams.get('perPage'),
  ];

  const currentPage = parseInt(page || '1', 10);
  const currentReviewsPerPage = perPage ? parseInt(perPage, 10) : reviewsPerPage;
  useEffect(() => {
    if (!addonId) {
      return;
    }

    Promise.all([
      dispatch(getUserReview({ addonId })),
      dispatch(
        getAddonReviews({
          addonId,
          page: currentPage,
          sort,
          direction,
          perPage: currentReviewsPerPage,
        })
      ),
    ]).finally(() => {
      setLoading(false);
    });
  }, [dispatch, addonId, currentPage, sort, direction, currentReviewsPerPage]);

  const handleOpenForm = (): void => {
    setIsFormOpen(true);
  };

  const handleSubmitReview: Parameters<typeof ReviewForm>[0]['onSubmit'] = async (reviewData) => {
    const { version: versionId, rating, description, details } = reviewData;
    const action = userReview
      ? updateReview({ ratingId: userReview.ratingId, versionId, rating, description, details })
      : postReview({ rating, versionId, description, details });

    setIsSubmitting(true);
    try {
      await dispatchOrLogin(action)?.unwrap();
      setIsFormOpen(false);
    } catch (err) {
      return;
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleBuy = async (): Promise<void> => {
    setIsProcessing(true);
    if (!addonVersion) {
      return;
    }

    try {
      if (addonId && isFree) {
        await dispatchOrLogin(getFreeAddon({ addonId, versionId: addonVersion?.id }));
        return;
      }

      await dispatchOrLogin(addToCart({ versionId: addonVersion?.id }));
    } catch (err) {
      return;
    } finally {
      setIsProcessing(false);
    }
  };

  const handleReport = (commentId: string): void => {
    dispatchOrLogin(reportReview({ commentId }));
  };

  const handleLike = (commentId: string): void => {
    dispatchOrLogin(likeReview({ commentId }));
  };

  const { anchorRef, isOut } = useScrollThreshold<HTMLDivElement>(STOREFRONT_HEADER_HEIGHT);

  if (!addon || !addonVersion) {
    return null;
  }

  const {
    publishedAndRevisionAddonVersions: versions,
    title,
    publisher,

    price,
    discountedPrice,
    isFree,
    ownedStatus,

    reviewsCount,
  } = addon;

  const isUnavailable = addon?.ownedStatus === 'not available';
  const isPurchased = addon?.ownedStatus === 'purchased';
  const isObtained = addon?.ownedStatus === 'obtained';

  const currentVersion = versions.find((version) => version.isCurrent) || versions[0];

  const canReview = !userReview && isLoggedIn && (isPurchased || isObtained);

  return (
    <div className="add-on-page add-on-reviews-page">
      {!!addon && !!addonVersion && (
        <section className="add-on-reviews-page__add-on-info">
          <div className="add-on-reviews-page__infobox-container" ref={anchorRef}>
            <Link
              to={`/add-on/${addonId}/description?version=${addonVersion.id}`}
              className="add-on-reviews-page__back-link"
            />
            <div className="add-on-reviews-page__infobox add-on-infobox">
              <img
                className="add-on-infobox__image"
                src={getPreviewSrc(addonVersion)}
                alt={title}
              />
              <div className="add-on-infobox__details">
                <PublisherLink publisher={publisher} />
                <h2 className="add-on-infobox__title">{title}</h2>
                <CategoryTags categories={addonVersion.categories} />
              </div>
              <div className="add-on-infobox__price">
                <PriceDisplay
                  className="add-on-infobox__price-display"
                  price={price}
                  discountedPrice={discountedPrice}
                  isFree={isFree}
                  isUnavailable={isUnavailable}
                />
                {isPurchased || isObtained ? (
                  <div className="purchased-notice">{ownedStatus}</div>
                ) : (
                  <PurchaseButton
                    isFree={isFree}
                    isWaiting={isProcessing}
                    ownedStatus={isInCart ? 'in cart' : ownedStatus}
                    onClick={handleBuy}
                  />
                )}
              </div>
            </div>
          </div>

          {isOut && (
            <AddonInfobox.StickyHeader
              addon={{
                ...addon,
                previewSrc: getPreviewSrc(addonVersion),
                categories: addonVersion.categories,
              }}
              offset={STOREFRONT_HEADER_HEIGHT}
              purchaseButtonSlot={
                <PurchaseButton
                  className="add-on-infobox__purchase-btn"
                  isFree={isFree}
                  isWaiting={isProcessing}
                  ownedStatus={isInCart ? 'in cart' : ownedStatus}
                  onClick={handleBuy}
                />
              }
            />
          )}
        </section>
      )}

      <div className="add-on-reviews-page__main main">
        <section className="add-on-reviews-page__reviews">
          <h2 className="add-on-reviews-page__section-title">
            {
              !isStale && reviewsCount === 0 // the data is fresh and there are no reviews
                ? 'No reviews'
                : `Reviews${isStale ? '' : ` (${reviewsCount})`}` // only show the count if data is fresh
            }
          </h2>
          {loading ? (
            <CircularProgress color="inherit" className="reviews__loading" />
          ) : (
            !isStale &&
            !reviewsError && (
              <>
                {canReview && (
                  <div className="add-on-reviews__actions-container">
                    <UnigineButton onClick={handleOpenForm} className="add-on-reviews__review-btn">
                      Leave a Review
                    </UnigineButton>
                  </div>
                )}

                {!isLoggedIn && (
                  <div className="add-on-reviews__actions-container">
                    <Tip className="add-on-reviews__log-in-tip">{tips.logInToReview}</Tip>
                  </div>
                )}

                {userReview && !userReviewError && (
                  <div className="add-on-reviews__user-review">
                    <ReviewCard
                      currentVersionId={currentVersion.id}
                      review={userReview}
                      publisherId={publisher.id}
                      publisherName={publisher.name}
                      isOwnReview
                      actions={
                        <UnigineButton
                          onClick={handleOpenForm}
                          className="add-on-reviews__update-btn"
                        >
                          Update
                        </UnigineButton>
                      }
                    />
                  </div>
                )}

                {totalReviews > 0 && (
                  <ReviewList
                    reviews={reviews}
                    currentVersionId={currentVersion.id}
                    publisherId={publisher.id}
                    publisherName={publisher.name}
                    totalReviews={totalReviews}
                    onReport={handleReport}
                    onLike={handleLike}
                  />
                )}

                {versions && addonVersion && (
                  <ReviewForm
                    versions={versions}
                    defaultVersion={addonVersion}
                    defaultValues={
                      userReview
                        ? {
                            version: userReview.addonVersion.id,
                            rating: userReview.rating,
                            description: userReview.comment.title,
                            details: userReview.comment.body,
                          }
                        : undefined
                    }
                    open={isFormOpen}
                    onCancel={() => setIsFormOpen(false)}
                    onSubmit={handleSubmitReview}
                    isProcessing={isSubmitting}
                    error={postError}
                  />
                )}

                {totalReviews > 0 && (
                  <div className="pagination__container">
                    <UniginePagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      defaultValue={reviewsPerPage}
                    />
                  </div>
                )}
              </>
            )
          )}
        </section>
      </div>
    </div>
  );
};

export default AddonReviews;
