import ReactDOM from 'react-dom';
import './index.scss';
import App from 'app/App';

ReactDOM.render(<App />, document.getElementById('root'));

// to 18 react
// import { createRoot } from 'react-dom/client';
// import './index.scss';
// import App from './app/App';

// const container = document.getElementById('root');
// const root = createRoot(container);
// root.render(<App />);
