import IError from 'app/interfaces/IError';
import { IUserData } from 'app/interfaces/IUserData';

interface IPlatformNames {
  [index: string]: string;
}

export const defaultPerPage = 12;

export const addonsPerPage = 12;
export const purchasedAddonsPerPage = 12;
export const ordersPerPage = 12;
export const orderedAddonsPerPage = 12;
export const reviewsPerPage = 24;
export const moderationAddonsPerPage = 12;

export const platformNames: IPlatformNames = {
  cross: 'Cross-platform',
  windows: 'Windows',
  linux: 'Linux',
};

export type PackageSource = 'storefront' | 'publisher' | 'admin';

export const DEFAULT_MIN_RANGE_PRICE = 5;
export const DEFAULT_MAX_RANGE_PRICE = 9999;
export const MAX_RANGE_PRICE = 999999;
export const MAX_LENGTH_CONTACT_FORM_MESSAGE = 2000;

export const orderAddonVersionsStatusOptions = ['completed', 'refunded'];

export const productSlugsSortedByTier = [
  'unigine_2_community_edition',
  'unigine_2_engineering',
  'unigine_2_sim',
];

export const MAX_PACKAGE_SIZE_FOR_ONE_UPLOADING = 100 * 1024 * 1024; // size in bytes (100 Mb)
export const UPLOAD_CHUNK_SIZE = 25 * 1024 * 1024; // size in bytes (25 Mb)

export const MAX_PACKAGE_SIZE = 21474836480;
export const MAX_PACKAGE_NAME_LENGTH = 200;

export const RESUME_UPLOAD = 'RESUME_UPLOAD';
export const RESTART_UPLOAD = 'RESTART_UPLOAD';
export const CANCEL_UPLOAD = 'CANCEL_UPLOAD';
export const TOKEN_EXPIRED = 'Expired token';
export const TOKEN_EXPIRED_DELAY = 5000; // ms

export const CANCEL_ACTION = 'CANCEL_ACTION';
export const CONFIRM_ACTION = 'CONFIRM_ACTION';

export const regExps = {
  titleCharacters: /^[ a-zA-Z0-9@#+\-%&*\\/,._()<>[\]{}]+$/,
  richText: /^([ a-zA-Z0-9@=:#+\-%&*\\/,;:!"'._()<>[\]{}]|&(lt|gt|amp);)+$/,
  review: /^[a-zA-Z0-9\s\n@#+\-%&*\\/,;:!"'._()<>[\]{}]+$/u,
  tags: /^[a-zA-Z0-9 ]+$/i,
  email: /^[\w.+]+@([\w-]+\.)+[\w-]{2,4}$/g,
};

export const headerMenu = {
  profile: 'Profile',
  purchasedAddons: 'My purchased add-ons',
  orders: 'My Orders',
  publisherWorkspace: 'Publisher Workspace',
  adminWorkspace: 'Admin Workspace',
  logout: 'Logout',
  signIn: 'Sign In',
};

export const userDataInitial: IUserData = {
  company: null,
  id: null,
  name: null,
  globalRoles: ['NO_ROLE'],
};

export const userDataGuest: IUserData = {
  company: null,
  id: null,
  name: null,
  globalRoles: ['GUEST'],
};

export interface IAuthStates {
  onAutoLogin: 'onAutoLogin';
  onNoAccessToken: 'onNoAccessToken';
  onRefreshTokenLogin: 'onRefreshTokenLogin';
  onAutoLogout: 'onAutoLogout';
}

export const authStates: IAuthStates = {
  onAutoLogin: 'onAutoLogin',
  onNoAccessToken: 'onNoAccessToken',
  onRefreshTokenLogin: 'onRefreshTokenLogin',
  onAutoLogout: 'onAutoLogout',
};

export const YOUTUBE_KEY = 'AIzaSyAKcInm39w89ssSRp94spW0wpjOLux6WWc';

export const DEFAULT_HEADER_HEIGHT = 60;
export const STOREFRONT_HEADER_HEIGHT = 124;

export const DEFAULT_REVIEW_DESCRIPTION_LENGTH = 255;
export const DEFAULT_REVIEW_DETAILS_LENGTH = 1000;
export const DEFAULT_REVIEW_REPLY_LENGTH = 1000;

export const defaultError: IError = {
  data: {
    code: 500,
    context: {
      code: 500,
      message: 'Something went wrong',
    },
    message: 'Something went wrong',
  },
  status: 500,
};

export const DEFAULT_PACKAGE_DESCRIPTION_HEIGHT = 88; // 4 lines in px

export const BIG_SLIDER_ADDONS_COUNT = 5;
export const SMALL_SLIDER_ADDONS_COUNT = 3;
