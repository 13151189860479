import './Addon.scss';
import './AddonBaseInfo.scss';

import { FC, useState } from 'react';
import { Link } from 'react-router-dom';

import useLoginGuard from 'app/hooks/useLoginGuard';
import useInCart from 'app/hooks/useInCart';
import useLoggedInUser from 'app/hooks/useLoggedInUser';
import useScrollThreshold from 'app/hooks/useScrollThreshold';

import PublisherLink from 'app/components/PublisherLink/PublisherLink';
import PriceDisplay from 'app/components/PriceDisplay/PriceDisplay';
import CategoryTags from 'app/components/CategoryTags/CategoryTags';
import PurchaseButton from 'app/components/PurchaseButton/PurchaseButton';
import AddonInfobox from 'app/components/AddonHeader/AddonInfobox';
import AddonRating from 'app/components/AddonRating/AddonRating';
import Stars from 'app/components/Stars/Stars';
import DownloadPackage from 'app/components/DownloadPackage/DownloadPackage';
import MediaViewer from 'app/main/sections/Addon/AddonMediaViewer';

import { addToCart } from 'app/store/unigine/cartSlice';
import { getFreeAddon } from 'app/main/sections/Addon/store/baseInfoSlice';

import { STOREFRONT_HEADER_HEIGHT } from 'app/configs/appConfig';
import getPreviewSrc from 'app/utils/helpers/getPreviewSrc';
import combineMedia from 'app/utils/helpers/combineMedia';

import { IAddonVersion } from 'app/interfaces/addons/IAddonVersion';
import { IAddon } from 'app/interfaces/addons/IAddon';
import IPackage from 'app/interfaces/addons/IPackage';
import PackageListDialog from 'app/components/PackageListDialog/PackageListDialog';
import UnigineButton from 'app/components/UnigineButton/UnigineButton';

interface AddonBaseInfoProps {
  addon: IAddon | null;
  addonVersion: IAddonVersion | null;
}

const AddonBaseInfo: FC<AddonBaseInfoProps> = ({ addon, addonVersion }) => {
  const { isLoggedIn } = useLoggedInUser();
  const dispatchOrLogin = useLoginGuard();

  const [isProcessing, setIsProcessing] = useState(false);

  const isInCart = useInCart(addon?.id || null);

  const { anchorRef, isOut } = useScrollThreshold<HTMLDivElement>(STOREFRONT_HEADER_HEIGHT);

  const [packageListOpen, setPackageListOpen] = useState(false);

  // TODO granular empty states?
  if (!addon || !addonVersion) {
    return null;
  }

  const {
    id: addonId,
    title,
    publisher,

    price,
    discountedPrice,
    isFree,
    ownedStatus,

    sdkVersions,
    publishedAndRevisionAddonVersions: publishedVersions,

    ratingCount,
    reviewsCount,
    totalRating,
  } = addon;

  const { categories } = addonVersion;

  const isUnavailable = ownedStatus === 'not available';
  const isPurchased = ownedStatus === 'purchased';
  const isObtained = ownedStatus === 'obtained';

  let singlePackage: IPackage | null = null;
  if (publishedVersions.length === 1 && publishedVersions[0].packages.length === 1) {
    [singlePackage] = publishedVersions[0].packages;
  }

  const handleBuy = async (): Promise<void> => {
    setIsProcessing(true);
    try {
      if (isFree) {
        await dispatchOrLogin(getFreeAddon({ addonId, versionId: addonVersion.id }));
        return;
      }

      await dispatchOrLogin(addToCart({ versionId: addonVersion.id }));
    } finally {
      setIsProcessing(false);
    }
  };

  const media = combineMedia({
    pictureFiles: addonVersion?.pictureFiles || [],
    videoFiles: addonVersion?.videoFiles || [],
  });

  return (
    <>
      <div className="add-on-base-info">
        <div className="add-on-page-infobox" ref={anchorRef}>
          <PublisherLink className="add-on-base-info__publisher" publisher={publisher} />
          <h1 className="add-on-base-info__title">{title}</h1>
          <div className="add-on-base-info__stats">
            <div className="add-on-base-info__stats--stars">
              <Stars totalRating={parseFloat(totalRating)} ratingCount={ratingCount} />
            </div>
            <CategoryTags className="add-on-base-info__categories" categories={categories} />
          </div>
          <div className="add-on-base-info__mobile-media">
            <MediaViewer media={media} />
          </div>

          {isPurchased || isObtained ? (
            <>
              <div className="purchased-notice add-on-base-info__purchased-notice">
                {ownedStatus}
              </div>
              <div className="add-on-base-info__obtained-wrapper">
                {singlePackage ? (
                  <DownloadPackage
                    packageId={singlePackage.id}
                    className="add-on-base-info__download-btn download-button--main"
                    source="storefront"
                  >
                    Download Package
                  </DownloadPackage>
                ) : (
                  <UnigineButton
                    onClick={() => setPackageListOpen(true)}
                    className="add-on-base-info__download-btn download-button--main"
                  >
                    Download Package
                  </UnigineButton>
                )}
              </div>
            </>
          ) : (
            <>
              <PriceDisplay
                className="add-on-base-info__price"
                price={price}
                discountedPrice={discountedPrice}
                isFree={isFree}
                isUnavailable={isUnavailable}
              />
              <PurchaseButton
                className="add-on-base-info__purchase-btn"
                isFree={isFree}
                isWaiting={isProcessing}
                ownedStatus={isInCart ? 'in cart' : ownedStatus}
                onClick={handleBuy}
              />
            </>
          )}
        </div>

        <ul className="add-on-datalist">
          {sdkVersions?.length > 0 && (
            <li>
              Addon supports SDK version{sdkVersions.length > 1 ? 's ' : ' '}
              {sdkVersions?.map((version) => version.value).join(', ')}
            </li>
          )}
          <li>
            <Link className="add-on-datalist__link" to="/eula">
              Standard license
            </Link>
          </li>
        </ul>

        <AddonRating
          totalRating={totalRating}
          reviewsCount={reviewsCount}
          addonId={addonId}
          versionId={addonVersion.id}
          isLoggedIn={isLoggedIn}
        />
      </div>

      {isOut && (
        <AddonInfobox.StickyHeader
          addon={{
            ...addon,
            previewSrc: getPreviewSrc(addonVersion),
            categories: addonVersion.categories,
          }}
          offset={STOREFRONT_HEADER_HEIGHT}
          purchaseButtonSlot={
            <PurchaseButton
              className="add-on-infobox__purchase-btn"
              isFree={isFree}
              isWaiting={isProcessing}
              ownedStatus={isInCart ? 'in cart' : ownedStatus}
              onClick={handleBuy}
            />
          }
        />
      )}

      <PackageListDialog
        versions={addon.publishedAndRevisionAddonVersions}
        canDownload={isPurchased || isObtained}
        open={packageListOpen}
        onClose={() => setPackageListOpen(false)}
      />
    </>
  );
};

export default AddonBaseInfo;
