const mergeSearchParam = (
  params: URLSearchParams,
  name: string,
  value?: string | string[] | null
): URLSearchParams => {
  const newParams = new URLSearchParams(params);
  const paramName = Array.isArray(value) ? `${name}[]` : name;

  if (Array.isArray(value)) {
    newParams.delete(paramName);
    value.forEach((item) => newParams.append(paramName, item));
  } else if (!value) {
    newParams.delete(paramName);
  } else {
    newParams.set(paramName, value);
  }

  return newParams;
};

export default mergeSearchParam;
