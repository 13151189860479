import IAddonVersionStatus from 'app/interfaces/addons/IAddonVersionStatus';

interface IGetAddonVersionStatusIds {
  addonVersionStatusesData: IAddonVersionStatus[] | null;
  addonVersionStatusParam: string[];
}

const getAddonVersionStatusIds = ({
  addonVersionStatusesData,
  addonVersionStatusParam,
}: IGetAddonVersionStatusIds): string[] | null =>
  addonVersionStatusesData &&
  addonVersionStatusesData
    .filter((status) => addonVersionStatusParam.includes(status.slug))
    .map((status) => status.id);

export default getAddonVersionStatusIds;
