import { FC } from 'react';

import { addonBaseInfoSelector } from 'app/main/sections/Addon/store/baseInfoSlice';
import { IAddonTabProps } from 'app/main/sections/Addon/tabs/IAddonTabProps';

import PackageList from 'app/components/PackageList/PackageList';
import useAppSelector from 'app/hooks/useAppSelector';

const AddonPackages: FC<IAddonTabProps> = ({ addonVersion }) => {
  const addon = useAppSelector(addonBaseInfoSelector);
  const canDownload = addon?.ownedStatus === 'purchased' || addon?.ownedStatus === 'obtained';

  return (
    <div className="add-on-store-packages">
      <PackageList packages={addonVersion.packages} canDownload={canDownload} />
    </div>
  );
};

export default AddonPackages;
