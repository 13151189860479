import './AddonCard.scss';

import { FC, MouseEvent, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import clsx from 'clsx';

import addon from 'app/assets/asset1.svg';
import { Card, CardActionArea, CircularProgress, IconButton } from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

import useAppDispatch from 'app/hooks/useAppDispatch';
import useLoginGuard from 'app/hooks/useLoginGuard';
import useInCart from 'app/hooks/useInCart';

import UnigineTooltip from 'app/components/Tooltip/Tooltip';
import Stars from 'app/components/Stars/Stars';
import { tooltips } from 'app/utils/constants/contentConstants';

import InstantPurchaseDialog from 'app/components/InstantPurchaseDialog/InstantPurchaseDialog';

import { addToCart } from 'app/store/unigine/cartSlice';
import { openAddonDialog, getFreeAddon } from 'app/main/sections/Addon/store/baseInfoSlice';
import ICategory from 'app/interfaces/addons/ICategory';
import ICoverFile from 'app/interfaces/addons/ICoverFile';

interface IAddonCard {
  id: string;
  title: string;
  coverFile: ICoverFile | null;
  categories: ICategory[];
  publisher: { id: string; name: string };

  price: string;
  discountedPrice: string;
  isAddonFree: boolean;
  ownedStatus?: string;
  rating: { ratingCount: number; totalRating: string; ratingSum: number; reviewsCount: number };

  currentVersionId: string;
}

const AddonCard: FC<IAddonCard> = ({
  id,
  coverFile,
  title,
  categories,
  publisher,
  price,
  isAddonFree,
  ownedStatus = 'available',
  currentVersionId,
  discountedPrice,
  rating,
}) => {
  const dispatch = useAppDispatch();
  const dispatchOrLogin = useLoginGuard();
  const navigate = useNavigate();

  const isPurchased = ownedStatus === 'purchased';
  const isObtained = ownedStatus === 'obtained';
  const isUnavailable = ownedStatus === 'not available';
  const isInOrder = ownedStatus === 'in order';

  const [adding, setAdding] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const isInCart = useInCart(id);

  const isInSale = price !== discountedPrice;

  function handleItemClick(ev: any, item: { id: string }): void {
    if (window.innerWidth <= 1024) {
      navigate(`/add-on/${id}`);
    } else {
      dispatch(openAddonDialog(item));
    }
  }

  async function handleBuy(e: MouseEvent): Promise<void> {
    e.preventDefault();

    setAdding(true);
    try {
      if (isAddonFree) {
        const result = await dispatchOrLogin(
          getFreeAddon({ addonId: id, versionId: currentVersionId })
        )?.unwrap();

        if (result?.addonId === id) {
          setIsConfirmationOpen(true);
        }

        return;
      }

      await dispatchOrLogin(addToCart({ versionId: currentVersionId }));
    } finally {
      setAdding(false);
    }
  }

  return (
    <>
      <div className={`add-on-card_wrapper ${isPurchased || isObtained ? 'purchased' : ''}`}>
        <Card
          className={`add-on-card ${isPurchased || isObtained ? 'add-on-card--purchased' : ''}`}
        >
          <CardActionArea onClick={(e) => handleItemClick(e, { id })}>
            <div className="add-on-card__image-container">
              <div className="quick-look-rectangle">quick look</div>
              <img
                width="240"
                height="184"
                src={
                  coverFile?.id
                    ? `${process.env.REACT_APP_STORE_API_URL}/media-files/${coverFile.id}`
                    : addon
                }
                className="add-on-card__image"
                alt={title}
              />
            </div>
          </CardActionArea>

          <div className="add-on-card__text-container">
            <Link to={`/add-on/${id}`} className="add-on-card__title-link">
              <span className="add-on-card__autor">{publisher.name}</span>

              <h3 className="add-on-card__title">{title}</h3>
              <div className="add-on-card__reviews">
                <Stars
                  ratingCount={rating.ratingCount}
                  totalRating={parseFloat(rating.totalRating)}
                />
              </div>

              {isUnavailable && (
                <div className="add-on-card__notice add-on-card__notice--unavailable">
                  Unavailable
                </div>
              )}
              {isPurchased && (
                <div className="add-on-card__notice add-on-card__notice--purchased">Purchased</div>
              )}
              {isObtained && (
                <div className="add-on-card__notice add-on-card__notice--purchased">Obtained</div>
              )}
              {!isUnavailable && !isPurchased && !isObtained && (
                <UnigineTooltip title={isInOrder ? tooltips.orders.inOrder : ''}>
                  <div className="add-on-card__price">
                    {isInSale && (
                      <span className="add-on-card__sale-price">$ {discountedPrice}</span>
                    )}
                    <span
                      className={clsx('', {
                        'free-color': isAddonFree,
                        'add-on-card__base-price': isInSale && !isAddonFree,
                      })}
                    >
                      {isAddonFree ? 'FREE' : `$ ${price}`}
                    </span>
                    <IconButton
                      className={clsx('add-on-card__price-buy-button', {
                        'add-on-card__price-buy-button--in-cart': isInCart,
                        'add-on-card__price-buy-button--in-order': isInOrder,
                      })}
                      onClick={(e) => handleBuy(e)}
                      style={{ padding: 0 }}
                      disabled={isUnavailable || isInCart || isInOrder || adding}
                    >
                      {adding ? (
                        <CircularProgress className="add-on-card__buy-button-spinner" size={24} />
                      ) : (
                        <ShoppingCartIcon />
                      )}
                    </IconButton>
                  </div>
                </UnigineTooltip>
              )}
              <div className="add-on-card__category">
                {categories.map((cat) => cat.name).join(', ')}
              </div>
            </Link>
          </div>
        </Card>
      </div>
      {id && (
        <InstantPurchaseDialog
          id={id}
          title={title}
          coverFile={coverFile || undefined}
          categories={categories}
          publisher={publisher}
          price={price}
          isFree={isAddonFree}
          currentVersionId={currentVersionId}
          discountedPrice={discountedPrice}
          open={isConfirmationOpen}
          onClose={() => setIsConfirmationOpen(false)}
        />
      )}
    </>
  );
};

export default AddonCard;
