import './ReviewCard.scss';

import { FC, JSX } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import moment from 'moment';

import Stars from 'app/components/Stars/Stars';

import DefaultAvatarIcon from 'app/assets/avatar-default.svg';
import { ReactComponent as ReportIcon } from 'app/assets/icon_report.svg';
import { ReactComponent as ThumbUpIcon } from 'app/assets/icon_thumb_up_grey.svg';
import { ReactComponent as ReplyIcon } from 'app/assets/icon_reply.svg';
import breakLines from 'app/utils/helpers/breakLines';
import { IReview } from 'app/interfaces/reviews/IReview';

interface IReviewCardProps {
  review: IReview;
  actions?: JSX.Element;
  publisherId: string;
  publisherName: string;
  isOwnReview?: boolean;
  isLoggedIn?: boolean;
  currentVersionId?: string;
  onLike?: () => void;
  onReport?: () => void;
}

const ReviewCard: FC<IReviewCardProps> = ({
  review,
  actions,
  publisherId,
  publisherName,
  isOwnReview = false,
  isLoggedIn = false,
  currentVersionId,
  onReport,
  onLike,
}) => {
  const handleLike = (): void => {
    if (!onLike || review.isLiked) {
      return;
    }

    onLike();
  };

  const handleReport = (): void => {
    if (!onReport || review.isReported) {
      return;
    }

    onReport();
  };

  const handleImageError = (event: { currentTarget: HTMLImageElement }): void => {
    event.currentTarget.onerror = null;
    event.currentTarget.src = DefaultAvatarIcon;
  };

  const versionLabel = !currentVersionId
    ? ''
    : `on ${currentVersionId === review.addonVersion.id ? 'latest' : 'previous'} version (${
        review.addonVersion.number
      })`;

  return (
    <article className="review__container">
      <header className="review__header">
        <div className="review__user-image-container">
          <img
            src={review.user.avatar}
            alt={review.user.name}
            className="review__user-image"
            onError={handleImageError}
          />
        </div>

        <div className="review__header-info-container">
          <div className="review__user-info">
            {isOwnReview ? (
              <span className="review__user-name review__user-name--own">My review on</span>
            ) : (
              <span className="review__user-name">{review.user.name}</span>
            )}

            <div className="review__created">
              {moment(review?.updateDate).format('DD.MM.YYYY hh:mm')} {versionLabel}
            </div>

            {isOwnReview && review.status && (
              <span
                className={`review__status-badge review__status-badge--${review.status.replace(
                  ' ',
                  '-'
                )}`}
              >
                {review.status}
              </span>
            )}
          </div>

          <div className="review__rating-container">
            <Stars totalRating={review.rating} />

            <div className="review__title-container">
              <h3 className="review__title">{review.comment.title}</h3>
            </div>
          </div>
        </div>

        {!!actions && <div className="review__actions">{actions}</div>}
      </header>

      <main
        className={clsx('review__main', {
          'review__main--own': isOwnReview,
        })}
      >
        <div
          className="review__body review__body--full-text"
          dangerouslySetInnerHTML={{ __html: review.comment.body }}
        />
      </main>

      {!isOwnReview && (
        <footer className="review__footer">
          <div className="review__help">
            <div className="review__help-text">Was this review helpful?</div>

            <div className="review__thumbs-container">
              <button
                className={clsx('review__thumbs-button', {
                  'review__thumbs-button--disabled': review.isLiked,
                })}
                type="button"
                onClick={handleLike}
              >
                <ThumbUpIcon
                  className={clsx('review__thumb-up', {
                    'review__thumb-up--liked': review.isLiked,
                  })}
                />
              </button>
            </div>

            {!!review.helpfulCount && <div>({review.helpfulCount})</div>}
          </div>

          <div
            className={clsx('review__report', {
              'review__report--reported': review.isReported,
              'review__report--guest': !isLoggedIn,
            })}
          >
            <div className="review__help-text">
              {review.isReported && isLoggedIn ? 'Review is reported' : 'Report this review'}
            </div>

            <div className="review__report-icon-container">
              <button
                className="review__thumbs-button"
                type="button"
                onClick={handleReport}
                disabled={review.isReported || !isLoggedIn}
              >
                <ReportIcon className="review__report-icon" />
              </button>
            </div>
          </div>
        </footer>
      )}

      {isOwnReview && !!review.helpfulCount && (
        <footer className="review__footer">
          <div className="review__help">
            <div className="review__help-text">
              This review was helpful for {review.helpfulCount} people.
            </div>
          </div>
        </footer>
      )}

      {review?.reply?.length > 0 && (
        <section className="review__answer">
          <header>
            <div className="review__answer-icon-container">
              <ReplyIcon />
            </div>

            <div className="review__header-text">
              {moment(review.reply[0].createDate).format('DD.MM.YYYY hh:mm')} Reply from publisher{' '}
              <Link to={`/publisher/${publisherId}`} className="review__publisher-name">
                {publisherName}
              </Link>
            </div>
          </header>
          <main>{breakLines(review.reply[0].text)}</main>
        </section>
      )}
    </article>
  );
};

export default ReviewCard;
