import IProduct from 'app/interfaces/addons/IProduct';

interface IGetVersionsIds {
  productsData: IProduct[] | null;
  productsParam: string[];
}

const getProductsIds = ({ productsData, productsParam }: IGetVersionsIds): string[] | null =>
  productsData &&
  productsData
    .filter((product) => productsParam.includes(product.slug))
    .map((product) => product.id);

export default getProductsIds;
