import './ReviewDashboardCard.scss';

import moment from 'moment';
import clsx from 'clsx';

import Stars from 'app/components/Stars/Stars';
import UnigineButton from 'app/components/UnigineButton/UnigineButton';
import { FC, JSX } from 'react';
import { IDashboardReview } from 'app/interfaces/reviews/IReview';

interface IReviewDashboardCardProps {
  reviewData: IDashboardReview;
  actions?: JSX.Element;
}

const ReviewDashboardCard: FC<IReviewDashboardCardProps> & {
  ActionButton: typeof ActionButton;
} = ({ reviewData, actions }) => {
  return (
    <div className="review-card__wrapper">
      <div className="review-card__header">
        <img src={reviewData.addon.image} alt={reviewData.addon.title} width={64} height={48} />
        <h2 className="review-card__addon-title">{reviewData.addon.title}</h2>

        {actions && <div className="review-card__button-wrapper">{actions}</div>}
      </div>
      <div className="review-card__autor">
        <p className="autor-name">{reviewData.author.name}</p>
        <p>
          {reviewData.updateDate ? moment(reviewData.updateDate).format('DD-MM-YYYY HH:mm') : ''} on
          version {reviewData.addonVersion.number}
        </p>
      </div>
      <div className="review-card__title-wrapper">
        <Stars totalRating={reviewData.rating} />
        <p className="review-card__title">{reviewData.comment.title}</p>
      </div>
      <div className="report-card__text">{reviewData.comment.body}</div>
    </div>
  );
};

const ActionButton: typeof UnigineButton = ({ className, children, ...props }) => (
  <UnigineButton {...props} className={clsx(className, 'review-card__action')}>
    {children}
  </UnigineButton>
);

ReviewDashboardCard.ActionButton = ActionButton;

export default ReviewDashboardCard;
