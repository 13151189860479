import React, { FC } from 'react';
import moment from 'moment';

import AddonVersionStatusText from 'app/components/AddonVersionStatusText/AddonVersionStatusText';

import AddonTableItem from 'app/components/AddonTableItem/AddonTableItem';
import IAddonVersionStatus from 'app/interfaces/addons/IAddonVersionStatus';
import ICategory from 'app/interfaces/addons/ICategory';

import './PublishedItem.scss';

interface PublishedItemProps {
  id: string;
  title: string;
  coverId: string;
  categories: ICategory[];
  publisher?: {
    id: string;
    email: string;
    name: string;
  };

  versionNumber: string;
  updateDate: string;
  linkTo: string;
  status: string;
  addonVersionStatus: IAddonVersionStatus;
}

const PublishedItem: FC<PublishedItemProps> = ({
  title,
  id,
  categories,
  versionNumber,
  status,
  coverId,
  updateDate,
  addonVersionStatus,
  linkTo,
  publisher,
}) => {
  return (
    <div className="published-item">
      <AddonTableItem
        addon={{ id, title, coverFile: { id: coverId }, categories, publisher }}
        linkTo={linkTo}
        className="published-item__addon"
      >
        <div className="published-item__info">
          <p className="published-item__last-version">
            Latest version {versionNumber}{' '}
            {addonVersionStatus.value !== 'Published' && (
              <AddonVersionStatusText status={addonVersionStatus} />
            )}
          </p>
          <p className="published-info__last-update">
            Last updated on {moment(updateDate).format('YYYY-MM-DD')}
          </p>
        </div>
      </AddonTableItem>

      <div className={`published-item__status published-item__status--${status}`}>
        <p>{status}</p>
      </div>
    </div>
  );
};

export default PublishedItem;
