import ISdkVersion from 'app/interfaces/addons/ISdkVersion';

interface IGetVersionsIds {
  versionsData: ISdkVersion[] | null;
  versionsParam: string[];
}

const getVersionsIds = ({ versionsData, versionsParam }: IGetVersionsIds): string[] | null =>
  versionsData &&
  versionsData
    .filter((version) => versionsParam.includes(version.slug))
    .map((version) => version.id);

export default getVersionsIds;
