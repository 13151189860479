import './FormGrid.scss';

import { JSX, ReactNode } from 'react';

import clsx from 'clsx';

import UnigineTooltip from 'app/components/Tooltip/Tooltip';

interface IFormGridProps {
  className?: string;
  children: ReactNode;
}

interface IFormRowProps {
  label: string;
  labelClassName?: string;
  required?: boolean;
  labelledInputId?: string;
  tooltip?: string;
  children: ReactNode;
}

const FormGrid = ({ className, children }: IFormGridProps): JSX.Element => {
  return <div className={clsx('form-grid', className)}>{children}</div>;
};

const FormRow = ({
  label,
  labelClassName,
  required = false,
  labelledInputId,
  tooltip,
  children,
}: IFormRowProps): JSX.Element => {
  let labelComponent;
  if (labelledInputId) {
    labelComponent = (
      <label
        className={clsx(
          'form-grid__group-label',
          {
            'form-grid__group-label--required': required,
          },
          labelClassName
        )}
        htmlFor={labelledInputId}
      >
        {label}
      </label>
    );
  } else {
    labelComponent = (
      <legend
        className={clsx(
          'form-grid__group-label',
          {
            'form-grid__group-label--required': required,
          },
          labelClassName
        )}
      >
        {label}
      </legend>
    );
  }

  if (tooltip) {
    labelComponent = <UnigineTooltip title={tooltip}>{labelComponent}</UnigineTooltip>;
  }

  return labelledInputId ? (
    <>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      {labelComponent}
      <div className="form-grid__group-content">{children}</div>
    </>
  ) : (
    <fieldset className="form-grid__group">
      {labelComponent}
      <div className="form-grid__group-content">{children}</div>
    </fieldset>
  );
};
export { FormGrid, FormRow };
