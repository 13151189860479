import './Orders.scss';

import { useState, useEffect, FC } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';

import useAppSelector from 'app/hooks/useAppSelector';
import useAppDispatch from 'app/hooks/useAppDispatch';
import useLoginOnGuests from 'app/hooks/useLoginOnGuests';

import ErrorPage from 'app/main/errors/ErrorPage';
import StorefrontHeader from 'app/components/StorefrontHeader/StorefrontHeader';
import FilterForm from 'app/components/FilterForm/FilterForm';
import SortControl from 'app/components/SortControl/SortControl';
import OrderItem from 'app/components/OrderItem/OrderItem';
import getOrderStatusesIds from 'app/utils/helpers/getOrderStatusesIds';
import UniginePagination from 'app/components/UniginePagination/UniginePagination';

import IOrderStatus from 'app/interfaces/orders/IOrderStatus';
import MobileDialog from 'app/components/MobileDialog/MobileDialog';
import MobileButtons from 'app/components/MobileButtons/MobileButtons';
import Tip from 'app/components/Tip/Tip';

import useMobileDialog from 'app/hooks/useMobileDialog';
import { tips } from 'app/utils/constants/contentConstants';

import {
  getOrderStatuses,
  orderStatusesSelector,
} from 'app/main/sections/Orders/store/statusesSlice';
import {
  getOrders,
  ordersSelector,
  totalPagesSelector,
  ordersErrorsSelector,
} from 'app/main/sections/Orders/store/ordersSlice';

import { ordersPerPage } from 'app/configs/appConfig';

const Orders: FC = () => {
  const dispatch = useAppDispatch();
  const {
    isFiltersOpen,
    isSortOpen,
    handleCloseFilters,
    handleCloseSort,
    handleFiltersClick,
    handleSortClick,
  } = useMobileDialog();

  const orders = useAppSelector(ordersSelector);
  const totalPages = useAppSelector(totalPagesSelector);
  const orderStatusesData = useAppSelector(orderStatusesSelector);
  const errors = useAppSelector(ordersErrorsSelector);

  const [searchParams] = useSearchParams();
  const [page, search, sort, direction, perPage] = [
    searchParams.get('page'),
    searchParams.get('search'),
    searchParams.get('sort'),
    searchParams.get('direction'),
    searchParams.get('perPage'),
  ];
  const currentPage = parseInt(page || '1', 10);
  const currentOrdersPerPage = perPage ? parseInt(perPage, 10) : ordersPerPage;

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingDictionaries, setIsLoadingDictionaries] = useState(true);

  useLoginOnGuests();

  useEffect(() => {
    dispatch(getOrderStatuses()).then(() => {
      setIsLoadingDictionaries(false);
    });
  }, [dispatch]);

  useEffect(() => {
    if (!isLoadingDictionaries) {
      setIsLoading(true);
      const orderStatusesParam = searchParams.getAll('orderStatuses[]');

      const orderStatusesIds = getOrderStatusesIds({
        orderStatusesData,
        orderStatusesParam,
      });

      dispatch(
        getOrders({
          page: currentPage,
          sort,
          direction,
          search,
          orderStatuses: orderStatusesIds,
          perPage: currentOrdersPerPage,
        })
      ).finally(() => setIsLoading(false));
    }
  }, [
    dispatch,
    currentPage,
    direction,
    isLoadingDictionaries,
    search,
    searchParams,
    sort,
    orderStatusesData,
    currentOrdersPerPage,
  ]);

  if (errors) {
    const { data, status } = errors;
    return <ErrorPage status={status} text={data.message} />;
  }

  return (
    <>
      <StorefrontHeader />
      <div className="main orders main--filters">
        <MobileButtons handleFiltersClick={handleFiltersClick} handleSortClick={handleSortClick} />

        <div className="orders__columns columns">
          <div className="orders__table columns__column--content">
            <div className="title-wrapper">
              <h1 className="orders__heading">My orders</h1>
              <div className="orders__sort to-right">
                <SortControl
                  name="sort"
                  options={[
                    { value: 'createDate', label: 'Date created', defaultDirection: 'DESC' },
                    { value: 'orderStatus', label: 'Status', defaultDirection: 'ASC' },
                  ]}
                />
              </div>
            </div>

            {isLoading && (
              <div className="orders__spinner">
                <CircularProgress color="inherit" />
              </div>
            )}

            {errors && !isLoading && (
              <span className="orders__error">
                Sorry, something went wrong when loading the data.
              </span>
            )}

            {!errors && !isLoading && orders && (
              <>
                <Tip className="orders__mobile-tip" content={tips.mobileOrders} />

                <ul className="orders__list">
                  {orders.length === 0 && (
                    <div className="add-on-table__no-add-ons">There are no orders</div>
                  )}

                  {orders.map((order) => (
                    <li key={order.id}>
                      <OrderItem order={order} />
                    </li>
                  ))}
                </ul>
              </>
            )}
            {!errors && !isLoading && orders && orders.length !== 0 && (
              <div className="pagination__container">
                <UniginePagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  defaultValue={ordersPerPage}
                />
              </div>
            )}
          </div>

          <div className="orders__filters columns__column--side">
            <FilterForm
              search={{
                name: 'search',
                label: 'Search in my orders',
              }}
              filterParams={[
                {
                  name: 'orderStatuses',
                  title: 'Order status',
                  type: 'checkbox',
                  options: orderStatusesData || [],
                  getLabel: (item: IOrderStatus) => item.value,
                  getValue: (item: IOrderStatus) => item.slug,
                  collapsed: false,
                },
              ]}
            />
          </div>

          <MobileDialog isOpen={isFiltersOpen} handleClose={handleCloseFilters} title="Filters">
            <FilterForm
              search={{
                name: 'search',
                label: 'Search in my orders',
              }}
              filterParams={[
                {
                  name: 'orderStatuses',
                  title: 'Order status',
                  type: 'checkbox',
                  options: orderStatusesData || [],
                  getLabel: (item: IOrderStatus) => item.value,
                  getValue: (item: IOrderStatus) => item.slug,
                  collapsed: false,
                },
              ]}
            />
          </MobileDialog>

          <MobileDialog isOpen={isSortOpen} handleClose={handleCloseSort} title="Sort By">
            <SortControl
              name="sort"
              options={[
                { value: 'orderStatus', label: 'Status', defaultDirection: 'ASC' },
                { value: 'createDate', label: 'Date created', defaultDirection: 'DESC' },
              ]}
            />
          </MobileDialog>
        </div>
      </div>
    </>
  );
};

export default Orders;
