import './Order.scss';

import { useState, useEffect, FC } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';

import useAppSelector from 'app/hooks/useAppSelector';
import useAppDispatch from 'app/hooks/useAppDispatch';
import useLoginOnGuests from 'app/hooks/useLoginOnGuests';

import ErrorPage from 'app/main/errors/ErrorPage';
import StorefrontHeader from 'app/components/StorefrontHeader/StorefrontHeader';
import OrderBody from 'app/components/OrderBody/OrderBody';
import UnigineButton from 'app/components/UnigineButton/UnigineButton';
import AddonDialog from 'app/main/sections/Addon/AddonDialog';
import OrderPaymentDialog from 'app/main/sections/Orders/OrderPaymentDialog';

import {
  cancelPayment,
  getOrder,
  getPaymentToken,
  orderErrorsSelector,
  orderSelector,
} from 'app/main/sections/Orders/store/orderSlice';

const Order: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { orderId } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [awaitingToken, setAwaitingToken] = useState(false);

  const [paymentToken, setPaymentToken] = useState<any>(null);

  const order = useAppSelector(orderSelector);
  const errors = useAppSelector(orderErrorsSelector);

  const isUnpaid = order?.orderStatus?.slug === 'not_paid';

  useLoginOnGuests();

  useEffect(() => {
    if (!orderId) {
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    dispatch(getOrder({ orderId })).finally(() => setIsLoading(false));
  }, [dispatch, orderId]);

  const handlePayOrder = (): void => {
    setAwaitingToken(true);
    if (orderId) {
      dispatch(getPaymentToken({ orderId }))
        .unwrap()
        .then((token) => {
          setPaymentToken(token);
        })
        .finally(() => {
          setAwaitingToken(false);
        });
    }
  };

  const onAbort = (): void => {
    // cancel payment & refresh order data
    if (orderId) {
      dispatch(cancelPayment({ orderId })).then(() => {
        setIsLoading(true);
        setPaymentToken(null);
        dispatch(getOrder({ orderId })).then(() => setIsLoading(false));
      });
    }
  };

  const onSuccess = (): void => {
    setPaymentToken(false);
    setIsLoading(true);
    navigate(`/orders/${orderId}/payment-status`);
  };

  if (errors) {
    const { data, status } = errors;
    return <ErrorPage status={status} text={data.message} />;
  }

  if (isLoading || !order) {
    return (
      <>
        <StorefrontHeader />
        <div className="main order-page">
          <div className="order-page__spinner">
            <CircularProgress color="inherit" />
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <StorefrontHeader />
      <div className="main order-page">
        <div className="order-page__header">
          <h1 className="order-page__title">
            <Link to="/orders" className="order-page__back-link">
              My orders
            </Link>{' '}
            / Order #{order.orderNumber}
          </h1>
          {order.orderStatus && (
            <span className={`order-page__status order-page__status--${order.orderStatus.slug}`}>
              {order.orderStatus.value}
            </span>
          )}
        </div>

        <OrderBody order={order} />

        {isUnpaid && (
          <div className="order-page__pay-order">
            <UnigineButton
              className="order-page__pay-order-btn"
              onClick={handlePayOrder}
              disabled={awaitingToken || !!paymentToken}
            >
              {awaitingToken ? 'Waiting...' : 'Pay order'}
            </UnigineButton>
          </div>
        )}
      </div>

      <AddonDialog hidePurchaseButton />
      <OrderPaymentDialog
        open={!!paymentToken}
        paymentToken={paymentToken}
        onAbort={onAbort}
        onSuccess={onSuccess}
      />
    </>
  );
};

export default Order;
