import './PlatformPoster.scss';

import { FC } from 'react';

import { ReactComponent as CrossIcon } from 'app/assets/icon_cross.svg';
import { ReactComponent as WindowsIcon } from 'app/assets/icon_windows.svg';
import { ReactComponent as LinuxIcon } from 'app/assets/icon_linux.svg';
import IPlatform from 'app/interfaces/addons/IPlatform';

interface IPlatformPoster {
  platform: IPlatform;
}

const PlatformPoster: FC<IPlatformPoster> = ({ platform }) => {
  let poster;

  switch (platform.value) {
    case 'cross':
      poster = (
        <div className="platform-poster platform-poster--cross">
          <CrossIcon /> cross
        </div>
      );
      break;
    case 'windows':
      poster = (
        <div className="platform-poster platform-poster--windows">
          <WindowsIcon /> windows
        </div>
      );
      break;
    case 'linux':
      poster = (
        <div className="platform-poster platform-poster--linux">
          <LinuxIcon /> linux
        </div>
      );
      break;
    default:
      poster = '';
      break;
  }

  return poster;
};

export default PlatformPoster;
