/* eslint-disable jsx-a11y/control-has-associated-label */
import IManager from 'app/interfaces/IManager';
import './AddonsManagerItem.scss';
import { FC } from 'react';

interface IAddonsManagerItem {
  manager: IManager;
  handleClickRemoveManager: (arg: IManager) => void;
}

const AddonsManagerItem: FC<IAddonsManagerItem> = ({ manager, handleClickRemoveManager }) => {
  return (
    <li className="add-ons-managers__item">
      <div className="add-ons-manager__name">{manager.name}</div>
      <div className="add-ons-manager__email">{manager.email}</div>
      <button
        className="add-ons-manager__button"
        onClick={() => handleClickRemoveManager(manager)}
        type="button"
        title={`Remove ${manager.name}`}
      />
    </li>
  );
};

export default AddonsManagerItem;
