import './LinkElement.scss';

import { ReactElement, useState, useRef } from 'react';
import { Editor, Path, Element, Transforms } from 'slate';
import { useSlateStatic, RenderElementProps, ReactEditor } from 'slate-react';

import { IconButton } from '@mui/material';

import LinkOffIcon from '@mui/icons-material/LinkOff';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import EditIcon from '@mui/icons-material/Edit';

import { removeLink } from 'app/components/RichTextEditor/utils/link';
import { CustomText } from 'app/components/RichTextEditor/types';

import EditLinkPopup from 'app/components/RichTextEditor/elements/EditLinkPopup/EditLinkPopup';

interface IRenderLinkElement extends Omit<RenderElementProps, 'element'> {}

interface ILinkElement extends IRenderLinkElement {
  element: {
    type: 'link';
    children: CustomText[];
    href: string;
  };
}

const LinkElement = ({ attributes, element, children }: ILinkElement): ReactElement => {
  const linkRef = useRef(null);
  const [isEdit, setIsEdit] = useState(false);
  const editor = useSlateStatic();

  const handleCloseEdit = (): void => {
    Transforms.deselect(editor);
    setIsEdit(false);
  };

  let parentNodeState: Element | null = null;
  let nodePath: Path | null = null;

  if (editor.selection) {
    const [foundParentNode, _] = Editor.parent(editor, editor?.selection.focus?.path);
    const parentNode = foundParentNode as Element;
    parentNodeState = parentNode;
    const path = ReactEditor.findPath(editor, foundParentNode);
    nodePath = path;
  }

  return (
    <div className="element-link">
      <a {...attributes} href={element.href} className="text-link" ref={linkRef}>
        {children}
      </a>
      {parentNodeState &&
        parentNodeState.type === 'link' &&
        parentNodeState.href === element.href &&
        (isEdit ? (
          <EditLinkPopup
            href={element.href}
            open={isEdit}
            editor={editor}
            onClose={handleCloseEdit}
            anchorEl={linkRef.current}
          />
        ) : (
          <div className="element-link__popup" contentEditable={false}>
            <a href={element.href} rel="noreferrer" target="_blank">
              <OpenInNewIcon />
              {element.href}
            </a>
            <IconButton onClick={() => setIsEdit(true)}>
              <EditIcon />
            </IconButton>
            <IconButton onClick={() => removeLink(editor, { at: nodePath })}>
              <LinkOffIcon />
            </IconButton>
          </div>
        ))}
    </div>
  );
};

export default LinkElement;
