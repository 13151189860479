import 'app/main/sections/LicenseAgreements/EULA/EULA.scss';
import StorefrontHeader from 'app/components/StorefrontHeader/StorefrontHeader';

function ProviderAgreement() {
  return (
    <>
      <StorefrontHeader />
      <div className="main text-wrapper">
        <h2>Unigine Add-On Store Provider Agreement</h2>
        <br />
        <br />
        <p>
          <b>Last updated: 2023-06-30</b>
        </p>
        <br />
        <br />
        <ol className="text-wrapper__ol--main">
          <li>
            <h3>Background</h3>
            <br />
            <br />
            <ol>
              <li>
                Unigine Add-On Store is a site on which Unigine distributes the Add-Ons provided by
                Providers to End Users.
              </li>
              <li>
                This Agreement regulates the legal relationship between Provider as a content
                creator and Unigine as the operator of the Add-On Store.
              </li>
            </ol>
          </li>

          <li>
            <h3>Terms and definitions</h3>
            <br />
            <br />
            <ol>
              <li>
                Agreement – this Unigine Add-On Store Provider Agreement, available via{' '}
                <a
                  href="https://store.unigine.com/provideragreement"
                  target="_blank"
                  rel="noreferrer"
                >
                  link
                </a>
              </li>
              <li>
                Add-On - 3D model, 3D scene, graphic object, software or any other work or content
                purchasable in Store
              </li>
              <li>
                Brand Features - any trade name, trademark, service mark, logo, domain name, and
                other distinctive brand feature of each Party, respectively, as owned (or licensed)
                by such Party.
              </li>
              <li>
                End User – any person, company or other legal entity who purchases Add-Ons via the
                Add-On Store
              </li>
              <li>
                EULA – Unigine Add-On Store End-User License Agreement. Current version of EULA is
                available via{' '}
                <a href="https://store.unigine.com/eula" target="_blank" rel="noreferrer">
                  link
                </a>
              </li>
              <li>
                Final Product — a specialized software solution, developed by End User using the
                Unigine SDK and including a part of release builds of the Unigine SDK engine
              </li>
              <li>
                Intellectual Property Rights - any and all intellectual property rights wherever in
                the world and whenever arising (and including any application), including patent
                rights, copyright, trade secrets, know-how, confidential information, business names
                and domain names, computer programs, trademark laws, service marks, trade names,
                utility models, design rights, semi-conductor topography rights, database rights,
                goodwill or rights to sue for passing off, and any and all other proprietary rights
                worldwide
              </li>
              <li>Party, Parties – Unigine and/or Provider</li>
              <li>
                Personal Account – personal section of the Store, to which you gain access after
                signing up and/or signing in to the Store. In order to use the Store, you should
                sign up and receive user credentials (login, password) to sign in to a Personal
                Account. You may also use user credentials for unigine.com personal account, in case
                you have already registered a personal account at unigine.com
              </li>
              <li>
                Provider, You – any person, company or other legal entity who has registered as
                Provider at the Add-On Store
              </li>
              <li>
                Unigine – Unigine Global LLC, Republic of Armenia, Yerevan, 0006, Shengavit, G.
                Njdeh str 17 bld, registration number: 269.110.1224452 / 2022-03-17, tax identifier:
                02297302
              </li>
              <li>
                Unigine Add-On Store, Add-On Store – Unigine website available via{' '}
                <a href="https://store.unigine.com/" target="_blank" rel="noreferrer">
                  link
                </a>
              </li>
              <li>
                Unigine SDK – a Unigine software development kit (SDK), which enables End User to
                create Final Products.
              </li>
            </ol>
          </li>

          <li>
            <h3>End User license</h3>
            <br />
            <br />
            <ol>
              <li>
                Provider grants to Unigine non-exclusive and worldwide license to any Add-On that
                Provider uploads to the Add-On Store to:
                <ol>
                  <li>
                    Copy, reproduce, distribute, import, lease, publicly perform, publicly display,
                    digitally perform, or transmit Add-Ons;
                  </li>
                  <li>
                    Develop Final Product with Unigine SDK using the Add-Ons, include Add-Ons as a
                    part of the Final Product, distribute Add-Ons as a part of the Final Product.
                  </li>
                  <li>Sublicense Add-Ons to End Users.</li>
                </ol>
              </li>

              <li>
                For the sake of clarity, the right specified in section 3.1.2 is required only for
                the purpose of granting to End Users possibility to use Add-Ons within Unigine SDK
                and create their own products (i.e. sublicensing). Unigine shall not use Add-Ons in
                such ways.
              </li>
              <li>
                Any use of Add-Ons by the End Users shall be subject to the Unigine Add-On Store
                EULA.
              </li>
            </ol>
          </li>

          <li>
            <h3>Subsidiary license</h3>
            <br />
            <br />
            <ol>
              <li>
                Provider grants to Unigine non-exclusive, worldwide and royalty-free license to:
                <ol>
                  <li>
                    Copy, reproduce, perform, distribute, modify, display, and use the Add-Ons for
                    administrative, demonstration, marketing and advertising purposes in connection
                    with the Add-On Store, the Add-Ons and Unigine's products.
                  </li>
                  <li>
                    Create and use samples of the Add-Ons and the contents thereof for the purpose
                    of demonstrating or promoting Add-Ons on the Add-On Store;
                  </li>
                  <li>
                    Display Provider's Brand Features in connection with the Add-On Store, the
                    Add-Ons and other Unigine's products, within or outside the Add-On Store;
                  </li>
                  <li>
                    Use any third-party trademarks, service marks or trade names incorporated in
                    Provider's Add-On in connection with Provider's Add-On;
                  </li>
                  <li>
                    Use name, image and likeness of any individuals represented in Add-On in
                    connection with Add-On.
                  </li>
                </ol>
              </li>
              <li>
                Each Party shall own all right, title and interest relating to its Brand Features.
              </li>
              <li>
                If Provider discontinues the distribution of specific Add-Ons on the Add-On Store,
                Unigine will, after a reasonable amount of time from receipt of notice, cease use of
                the discontinued Add-Ons' Brand Features, except as necessary to allow Unigine to
                fulfill its obligations
              </li>
              <li>
                Nothing in this Agreement gives Provider a right to use any of Unigine's Brand
                Features.
              </li>
            </ol>
          </li>

          <li>
            <h3>Pricing and payments</h3>
            <br />
            <br />
            <ol>
              <li>
                Provider distribute Add-Ons for free. Unigine may update the terms and allow for
                Provider to distribute Add-Ons for a fee. If Provider establishes fee for the Add-On
                later on, Provider may not in the future charge End Users for copies of the Add-Ons
                that those End Users have previously downloaded for free.
              </li>
            </ol>
          </li>

          <li>
            <h3>Provider's support, upgrades of Add-Ons, reinstalls</h3>
            <ol>
              <li>
                Provider will be solely responsible for support and maintenance of Provider's
                Add-Ons and any End Users' complaints about Provider's Add-Ons. Provider's contact
                information will be displayed in each Add-On detail page and made available to End
                User for customer support purposes.
              </li>
              <li>
                Failure to provide adequate support for Provider's Add-Ons may result in low Add-On
                ratings, less prominent Add-On exposure, low sales and billing disputes.
              </li>
              <li>
                Provider will, at no cost to End Users and Unigine, supply via the Add-On Store any
                upgrades or otherwise updated versions of all Add-Ons that a Customer has purchased
                via the Add-On Store. For the avoidance of doubt, this Section applies only where
                the upgraded or otherwise updated Add-On is the same SKU as prior to the upgrade or
                other updating. This section applies also to free Add-Ons.
              </li>
              <li>
                Provider acknowledges and agrees that End Users that purchased an Add-On will have
                the ability to access and download the Add-On from the Add-On Store as long as the
                Add-On Store is available. End User's use of the Add-On shall remain subject to the{' '}
                <a href="https://store.unigine.com/eula" target="_blank" rel="noreferrer">
                  EULA
                </a>
                . For those purposes Unigine may retain copies for such Add-Ons even after
                Provider's takedown of Add-Ons.
              </li>
            </ol>
          </li>

          <li>
            <h3>Use of the Add-On Store by Provider</h3>
            <br />
            <br />
            <ol>
              <li>
                Provider will not engage in any activity with the Add-On Store, including the
                development or distribution of Add-Ons, that interferes with, disrupts, damages, or
                accesses in an unauthorized manner the devices, servers, networks, or other
                properties or services of any third party including, but not limited to End Users,
                Unigine or any network operator.
              </li>
              <li>
                Provider may not use End User information that Provider has obtained from the Add-On
                Store or End User acquired via the Add-On Store to sell or distribute Add-Ons
                outside of the Add-On Store.
              </li>
              <li>
                Provider may not use the Add-On Store to distribute or make available any Add-On
                whose purpose is to facilitate the distribution of Add-Ons outside of the Add-On
                Store.
              </li>
              <li>
                Provider is solely responsible for any Add-Ons Provider distributes through the
                Add-On Store and for the consequences of Provider's actions on the Add-On Store
                (including any loss or damage which Unigine may suffer).
              </li>
              <li>
                Provider is solely responsible for any breach of Provider's obligations under this
                Agreement, any applicable third-party contract or terms of service, or any
                applicable law or regulation, and for the consequences (including any loss or damage
                which Unigine or any third party may suffer) of any such breach.
              </li>
              <li>
                Add-On Ratings.
                <ol>
                  <li>
                    The Add-On Store may allow End Users to rate Add-Ons. Only End Users who
                    download the applicable Add-On will be able to rate it. Add-On ratings may be
                    used to determine the placement of Add-Ons on the Add-On Store with higher rated
                    Add-Ons generally given better placement. However, Unigine reserves the right to
                    display Add-Ons to End Users in a manner that will be determined at its sole
                    discretion.
                  </li>
                  <li>
                    Provider's Add-Ons may be subject to ratings to which Provider may not agree. In
                    addition other factors that Unigine deems relevant such as Provider's history
                    may influence such ratings. Provider may contact Unigine on any questions or
                    concerns regarding such ratings or placement.
                  </li>
                </ol>
              </li>
            </ol>
          </li>

          <li>
            <h3>Content requirements</h3>
            <br />
            <br />
            <ol>
              <li>
                Add-Ons or other Provider's content must meet the following requirements:
                <ol>
                  <li>
                    Add-Ons must comply with Unigine Add-On Store Content Guidelines. Content
                    Guidelines are available via{' '}
                    <a
                      href="https://store.unigine.com/documentation/preparing_addon_packages/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      link
                    </a>
                    . Unigine may amend Content Guidelines from time to time. You must monitor such
                    changes by yourself. If you continue use of the Add-On Store after amendments
                    were made, you agree to be bound by new version of Content Guidelines.
                  </li>
                  <li>Add-Ons must not enable the delivery of services;</li>
                  <li>
                    Add-Ons must not be inaccurate, fraudulent, false, misleading, or deceptive;
                    harmful, obscene, pornographic, defamatory, vulgar, or offensive; racist,
                    violent, harassing, or otherwise objectionable to Unigine or End Users;
                    promoting illegal or harmful activities or substances;
                  </li>
                  <li>
                    Add-Ons must not violate intellectual property rights or any other rights of any
                    third party;
                  </li>
                  <li>
                    Add-Ons must not violate, or encourage any conduct that would violate, any
                    applicable law or regulation or would give rise to liability of any kind; or be
                    subject to injunction;
                  </li>
                  <li>Add-Ons must not be distributed by Provider improperly;</li>
                  <li>Add-Ons must not create liability for Unigine;</li>
                  <li>
                    Assest must not have a virus or be malware, spyware or have an adverse impact on
                    Unigine.
                  </li>
                  <li>
                    Any images, text and materials that are intended to market the Add-Ons that
                    Provider has uploaded to Add-On Store must comply with section 8.1 as well. Such
                    materials be truthful, accurate and must not misrepresent the Add-On, for
                    example – without limitation – by way of screenshots that do not match the
                    content of the Add-On.
                  </li>
                </ol>
              </li>
              <li>
                Unigine does not undertake any obligation to monitor the Add-Ons before or after
                upload to the Add-On Store, but reserves the right to do so at any time at its sole
                discretion.
              </li>
            </ol>
          </li>

          <li>
            <h3>Add-On takedowns</h3>
            <br />
            <br />
            <ol>
              <li>
                Provider's takedowns. Provider may remove Provider's Add-On from future distribution
                via the Add-On Store at its sole discretion at any time. Such removal does not (a)
                affect the license rights of End Users who have purchased Add-Ons prior to takedown;
                (b) remove Add-On from End Users' devices or from any part of Add-On Store where
                previously purchased Add-Ons are stored on behalf of End Users; (c) change or cancel
                Provider's obligation to deliver or support Add-Ons that have been previously
                purchased by End User.
              </li>
              <li>
                Unigine's takedowns.
                <ol>
                  <li>
                    In the event that Provider's Add-Ons do not meet requirements specified in
                    section 8.1, Unigine may
                    <ul>
                      <li>(a) demand that Provider fixes the Add-On;</li>
                      <li>(b) edit or make changes in the Add-On;</li>
                      <li>(c) remove the Add-On from the Add-On store;</li>
                      <li>(d) suspend or ban Provider form the Add-On Store.</li>
                    </ul>
                  </li>
                  <li>
                    Unigine may perform actions specified in section 10.2.1 at its sole discretion
                    and without any liability.
                  </li>
                  <li>
                    Sections 8 and 9 of this Agreement shall apply to Provider's Brand Features as
                    well.
                  </li>
                  <li>
                    In addition to the reasons specified in Section 8.1 Unigine may perform actions
                    specified in section 9.2.1 at its sole discretion after 1 month notice to
                    Provider.
                  </li>
                </ol>
              </li>
            </ol>
          </li>

          <li>
            <h3>Disclaimer of warranties</h3>

            <ol>
              <li>
                The Add-On Store is provided “as is” without warranty of any kind. Unigine may stop
                (permanently or temporarily) providing the Add-On Store or any its features at its
                sole discretion, without prior notice to you. Unigine does not guarantee
                merchantability, fitness for a particular purpose or non-infringement. Unigine has
                no obligation to distribute your Add-Ons and may refuse uploading or otherwise
                distributing your Add-Ons at its sole discretion. Unigine does not promise you
                specific amount of sales or revenue.
              </li>
              <li>
                Your use of the Add-On Store or any materials downloaded or otherwise obtained from
                the Add-On Store is at your sole risk and discretion. Unigine is not responsible for
                any damage to your computer system or other equipment or loss of data that may
                result from such use.
              </li>
              <li>
                Unigine is not responsible for use of the Add-Ons by End Users outside the Add-On
                Store. For instance, Unigine is not responsible for distribution of Add-Ons from
                pirate websites or any other unauthorized use of Add-Ons.
              </li>
            </ol>
          </li>

          <li>
            <h3>Limitation of liability</h3>
            <br />
            <br />
            <ol>
              <li>
                Under no circumstances and no legal theory, whether in tort, negligence, contract or
                otherwise, shall Unigine or its affiliates be liable to Provider or any affiliated
                person for any indirect, special, incidental, punitive, exemplary, consequential, or
                extra-contractual damages of any kind, loss of goodwill, loss of personnel salaries,
                lost profits or revenue, damages due to work stoppage and/or computer failure or
                malfunction, and/or costs of procuring substitute software or services, whether or
                not foreseeable, even if Unigine has been advised of the possibility or probability
                of such damages.
              </li>
              <li>
                Unigine's or its affiliates' total liability under this Agreement shall be limited
                to the amounts paid to Provider by Unigine in the past six months for the Add-Ons
                related to dispute.
              </li>
              <li>
                By accepting this Agreement, you shall be fully liable for validity and correctness
                of the data about you provided by you, including the cases when a third party gains
                access to your account through your fault. Unigine shall not be liable whatsoever in
                case if a third party gains access to the your account. You must take all necessary
                measures to prevent the disclosure of your login and password as well as the use of
                the your account by any third party.
              </li>
            </ol>
          </li>

          <li>
            <h3>Indemnification</h3>
            <br />
            <br />
            <ol>
              <li>
                You will, at your own expense, indemnify and hold Unigine, and its subsidiaries and
                affiliates, and all officers, directors, and employees thereof, harmless from and
                against any and all claims, actions, liabilities, losses, damages, judgments,
                grants, costs, and expenses, including reasonable legal fees (collectively,
                “Claims”), arising out of:
                <ol>
                  <li>
                    Any use of the Add-On Store by you, any party related to you, or any party
                    acting upon your authorization in a manner that is not expressly authorized in
                    this Agreement;
                  </li>
                  <li>
                    Provider's Add-Ons that infringe any intellectual property right of any person
                    or defame any person or violate their right of publicity or privacy;
                  </li>
                  <li>Taxes related to Provider's distribution of Add-Ons via the Add-On Store;</li>
                  <li>Provider's support of Add-Ons.</li>
                </ol>
              </li>
            </ol>
          </li>

          <li>
            <h3>General terms</h3>
            <br />
            <br />
            <ol>
              <li>
                Governing Law. This Agreement shall be construed and interpreted under law of
                England without regard to its conflicts of laws provisions. To the extent permitted
                by law, the provisions of this Agreement shall supersede any provisions of the
                appropriate local and international laws.
              </li>
              <li>
                Severability. If any term or provision of this Agreement is declared void or
                unenforceable in a particular situation, by any judicial or administrative
                authority, this declaration shall not affect the validity of enforceability of the
                remaining terms and provisions hereof or the validity or enforceability of the
                offending term or provision in any other situation.
              </li>
              <li>
                Names and headings. The name of this Agreement as well as headings of sections and
                paragraphs contained herein are incorporated for reference purposes only and shall
                not affect the meaning or interpretation of this Agreement.
              </li>
              <li>
                Non-waiver. The failure of either party to enforce any rights granted hereunder or
                to take action against the other party in the event of any breach hereunder shall
                not be deemed a waiver by that party as to subsequent enforcement of rights or
                subsequent actions in the event of future breaches.
              </li>
              <li>
                Text of the Agreement. Current version of the Agreement is available via{' '}
                <a
                  href="https://store.unigine.com/provideragreement"
                  target="_blank"
                  rel="noreferrer"
                >
                  link
                </a>
                .
              </li>
              <li>
                Acceptance of the Agreement. This Agreement forms legally binding contract between
                you and Unigine. In order to use the Add-On Store, you must first agree to this
                Agreement by checking the box indicating Provider's acceptance of the Agreement
                during your registration in the Add-On Store or prior to publishing any Add-On on
                the Add-On Store. By using the Add-On Store and (or) publishing Add-Ons on the
                Add-On Store you confirm that you have fully accepted this Agreement.
              </li>
              <li>
                Provider expressly accepts and agrees that Unigine shall be entitled to forward
                Provider's name, address and other contact details to any third Party that
                reasonably claims that Provider does not have all necessary intellectual property
                rights, including all necessary patent, trademark, trade secret, copyright or other
                proprietary rights, in and to Provider's Add-Ons. Provider also agrees that the
                handling of personal data in relationship to the distribution of its add-Ons on the
                Add-On Store is governed by the Controller-Controller Add-On Store Addendum found
                here.
              </li>
              <li>
                Amendments to the Agreement. Unigine reserves the right to make amendments to this
                Agreement as it deems appropriate at any time. In case of any discrepancy between
                this Agreement and its current version, the current version shall prevail. By
                continuing to use the Add-On Store after publication of the new version of the
                Agreement, you accept the new version of the Agreement.
              </li>
              <li>
                Termination of the Agreement.
                <ol>
                  <li>
                    If you want to terminate this Agreement, you may do so by ceasing your use of
                    the Add-On Store and removing Add-Ons from the Add-On Store.
                  </li>
                  <li>
                    Without prejudice to any other rights, Unigine may terminate this Agreement if
                    <ul>
                      <li>(a) Provider fails to comply with or breach this Agreement;</li>
                      <li>(b) Unigine is required to do so by the applicable law;</li>
                      <li>(c) Unigine ceases to providing the Add-On Store.</li>
                    </ul>
                  </li>
                </ol>
              </li>
              <li>
                Restrictions. Unigine and you shall comply with all national and international laws
                and regulations applicable to the Add-Ons as well as restrictions concerning
                End-Users, procedures and regions of end-use established in EU and other countries
                and regions including the ones regarding requirements for personal data processing.
              </li>
              <li>
                Communication. Unigine and Provider shall communicate by email or by means of direct
                messages as provided by Add-On Store.
              </li>
              <li>
                Entire agreement. This Agreement constitutes the complete and exclusive agreement
                between you and Unigine concerning subject matter hereof and supersedes all prior or
                contemporaneous oral or written communications, proposals, representations,
                understandings, or agreements not specifically incorporated herein.
              </li>
              <li>
                Assignment. The rights, responsibilities and obligations of Provider under this
                Agreement may not be assigned or transferred by Provider without prior written
                approval of Unigine. Unigine may transfer its rights, responsibilities and
                obligations under this Agreement without Provider's approval.
              </li>
            </ol>
          </li>
        </ol>
      </div>
    </>
  );
}

export default ProviderAgreement;
