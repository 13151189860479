import './Header.scss';

import { useState, JSX } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { Button, Divider, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';

import {
  userIsProcessingSelector,
  userSelector,
  userStateSelector,
} from 'app/auth/store/userSlice';
import onLogin from 'app/utils/onLogin';
import onLogout from 'app/utils/onLogout';

import NavigationList from 'app/components/NavigationList/NavigationList';
import logo from 'app/assets/unigine-logo.svg';
import addonStoreLogo from 'app/assets/addon-store-logo.svg';
import PublisherPanelIcon from 'app/assets/icon_publisher_panel.svg';
import LogoutIcon from 'app/assets/icon_logout.svg';
import MyOrdersIcon from 'app/assets/icon_my_orders.svg';
import MyPurchasedAddonsIcon from 'app/assets/icon_my_purchased_assets.svg';

import DefaultAvatarIcon from 'app/assets/avatar-default.svg';
import useAppDispatch from 'app/hooks/useAppDispatch';
import useAppSelector from 'app/hooks/useAppSelector';
import usePermissions from 'app/hooks/usePermissions';
import useLoginGuard from 'app/hooks/useLoginGuard';
import { headerMenu } from 'app/configs/appConfig';

const Header = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { hideStartPublishingButton, displayPublisherPanelMenuItem, displayAdminPortalMenuItem } =
    usePermissions();
  const dispatchOrLogin = useLoginGuard();

  const userIsProcessing = useAppSelector(userIsProcessingSelector);

  const user = useAppSelector(userSelector);
  const STATE = useAppSelector(userStateSelector);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: { currentTarget: HTMLElement }): void => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleStartPublishingClick = (): void => {
    dispatchOrLogin(() => navigate('/start-publishing'));
  };

  const onErrorImage = (event: { currentTarget: HTMLImageElement }): void => {
    event.currentTarget.onerror = null;
    event.currentTarget.src = DefaultAvatarIcon;
  };

  return (
    <header className="header">
      <div className="content-container content-container--header">
        <div className="header__top-container">
          <div className="header__logo logo logo__container">
            {userIsProcessing ? (
              <div className="logo__link">
                <img src={logo} alt="Unigine logo" className="logo__unigine" />
                <img src={addonStoreLogo} alt="Add-On Store (Experimental)" />
              </div>
            ) : (
              <Link className="logo__link" to="/all">
                <img src={logo} alt="Unigine logo" className="logo__unigine" />
                <img src={addonStoreLogo} alt="Add-On Store (Experimental)" />
              </Link>
            )}
          </div>

          <nav className="header__navigation navigation--top navigation">
            <NavigationList />
          </nav>

          {!hideStartPublishingButton && !userIsProcessing && (
            <div className="header__start-publishing-container">
              <Button className="header__link--publisherPanel" onClick={handleStartPublishingClick}>
                Start publishing
              </Button>
            </div>
          )}

          {user.userData.name && (
            <div className="header__profile to-right">
              <Button
                id="basic-button"
                className="profile__link header__button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
              >
                <span className="header__user-name">
                  {user.userData.firstname
                    ? `${user.userData.firstname} ${user.userData.lastname}`
                    : user.userData.name}
                </span>
                <img
                  className="header__user-image"
                  src={`${process.env.REACT_APP_STORE_DEVELOPER_URL}/.avatars/${user.userData.userGlobalId}.png`}
                  onError={onErrorImage}
                  alt="avatar"
                />
              </Button>
              {!userIsProcessing && (
                <Menu
                  className="header__menu-popup"
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClick={handleClose}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                  anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                >
                  <MenuItem
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_STORE_DEVELOPER_URL}/en/personal`,
                        '_blank'
                      )
                    }
                  >
                    <ListItemIcon>
                      <img className="profile__user-image" src={DefaultAvatarIcon} alt="avatar" />
                    </ListItemIcon>
                    <ListItemText className="menu__profile">{headerMenu.profile}</ListItemText>
                  </MenuItem>
                  <Divider />
                  <Link className="menu__link" to="/purchased">
                    <MenuItem>
                      <ListItemIcon>
                        <img src={MyPurchasedAddonsIcon} alt={headerMenu.purchasedAddons} />
                      </ListItemIcon>
                      <ListItemText>{headerMenu.purchasedAddons}</ListItemText>
                    </MenuItem>
                  </Link>
                  <Link className="menu__link menu__link--mb" to="/orders">
                    <MenuItem>
                      <ListItemIcon>
                        <img src={MyOrdersIcon} alt={headerMenu.orders} />
                      </ListItemIcon>
                      <ListItemText>{headerMenu.orders}</ListItemText>
                    </MenuItem>
                  </Link>
                  {displayPublisherPanelMenuItem && (
                    <Divider className="menu__divider--publisher" />
                  )}
                  {displayPublisherPanelMenuItem && (
                    <Link
                      className="menu__link menu__link--mt menu__link--mb menu__link--publisher"
                      to="/publisherPanel/add-ons"
                    >
                      <MenuItem>
                        <ListItemIcon>
                          <img src={PublisherPanelIcon} alt={headerMenu.publisherWorkspace} />
                        </ListItemIcon>
                        <ListItemText>{headerMenu.publisherWorkspace}</ListItemText>
                      </MenuItem>
                    </Link>
                  )}
                  {displayAdminPortalMenuItem && <Divider className="menu__divider--admin" />}
                  {displayAdminPortalMenuItem && (
                    <Link
                      className="menu__link menu__link--mt menu__link--mb menu__link--admin"
                      to="/adminPortal/add-ons?addonVersionStatus[]=moderation&addonVersionStatus[]=revision"
                    >
                      <MenuItem>
                        <ListItemIcon>
                          <img src={PublisherPanelIcon} alt={headerMenu.adminWorkspace} />
                        </ListItemIcon>
                        <ListItemText>{headerMenu.adminWorkspace}</ListItemText>
                      </MenuItem>
                    </Link>
                  )}
                  <Divider />
                  <MenuItem
                    className="menu__logout"
                    onClick={() => onLogout({ dispatch, location })}
                  >
                    <ListItemIcon>
                      <img src={LogoutIcon} alt="Logout" />
                    </ListItemIcon>
                    <ListItemText>{headerMenu.logout}</ListItemText>
                  </MenuItem>
                </Menu>
              )}
            </div>
          )}

          {!user.userData.name && (
            <div className="header__login header__profile to-right">
              <Button
                className="header__button button"
                variant="text"
                color="primary"
                type="submit"
                startIcon={<LoginOutlinedIcon />}
                onClick={() => onLogin({ STATE, location })}
              >
                {headerMenu.signIn}
              </Button>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
