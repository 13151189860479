import './StartPublishing.scss';

import { useCallback, useEffect, useState, FC } from 'react';
import useAppDispatch from 'app/hooks/useAppDispatch';
import { Link, useNavigate } from 'react-router-dom';

import { Button, CircularProgress, FormControlLabel } from '@mui/material';

import StorefrontHeader from 'app/components/StorefrontHeader/StorefrontHeader';
import CheckboxUnigine from 'app/components/CheckboxUnigine/CheckboxUnigine';

import usePermissions from 'app/hooks/usePermissions';
import useLoginOnGuests from 'app/hooks/useLoginOnGuests';

import { submitRefreshToken } from 'app/auth/store/userSlice';

import { terms } from 'app/utils/constants/contentConstants';
import {
  checkPublisher,
  startPublishing,
} from 'app/main/sections/PublisherPanel/sections/StartPublishing/store/startPublishingSlice';
import providerAgreement from 'app/main/sections/PublisherPanel/sections/StartPublishing//StartPublishingProviderAgreement';

const StartPublishing: FC = () => {
  const dispatch = useAppDispatch();
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [checkedAddonStoreTerms, setCheckedAddonStoreTerms] = useState(false);
  const [checkedXsollaTerms, setCheckedXsollaTerms] = useState(false);

  const navigate = useNavigate();

  const { isAddonManager, isCompanyAdmin } = usePermissions();

  const refreshToken = localStorage.getItem('jwt_refresh_token');

  const handleAcceptClick = useCallback(async () => {
    setIsLoading(true);

    await dispatch(startPublishing())
      .unwrap()
      .then(() => {
        setError(false);

        dispatch(submitRefreshToken({ refreshToken })).then(() => {
          navigate('/publisherPanel/add-ons');
        });
      })
      .catch((err) => {
        setError(err.message);
      })
      .finally(() => setIsLoading(false));
  }, [dispatch, navigate, refreshToken]);

  useLoginOnGuests();

  useEffect(() => {
    if (isAddonManager) {
      navigate('/publisherPanel/add-ons');
    }
  }, [navigate, isAddonManager]);

  useEffect(() => {
    if (isCompanyAdmin && !isAddonManager) {
      dispatch(checkPublisher())
        .unwrap()
        .then((publisher) => {
          if (publisher.id) {
            handleAcceptClick();
          } else {
            setIsLoading(false);
          }
        });
    } else {
      setIsLoading(false);
    }
  }, [dispatch, handleAcceptClick, isAddonManager, isCompanyAdmin]);

  return (
    <>
      <StorefrontHeader />

      <div className="main orders main--filters start-publishing">
        <h1 className="orders__heading">Add-On Store Provider Agreement</h1>
        {isCompanyAdmin && !error && !isLoading && (
          <>
            <div className="start-publishing__text-container-wrapper">
              <div
                className="start-publishing__text-container"
                dangerouslySetInnerHTML={{ __html: providerAgreement }}
              />
            </div>

            <div className="start-publishing__action-container">
              <FormControlLabel
                label={terms.addonStoreAgreement}
                className="checkbox-group__item"
                control={
                  <CheckboxUnigine
                    name="terms-add-on-store-agreement"
                    onChange={() => setCheckedAddonStoreTerms((prevChecked) => !prevChecked)}
                    checked={checkedAddonStoreTerms}
                    className="checkbox-group__checkbox"
                  />
                }
              />

              <FormControlLabel
                label={terms.xsollaAgreement}
                className="checkbox-group__item"
                control={
                  <CheckboxUnigine
                    name="terms-xsolla-agreement"
                    onChange={() => setCheckedXsollaTerms((prevChecked) => !prevChecked)}
                    checked={checkedXsollaTerms}
                    className="checkbox-group__checkbox"
                  />
                }
              />

              <div className="start-publishing__buttons-container">
                <Button
                  className="start-publishing__button-accept"
                  onClick={handleAcceptClick}
                  disabled={!checkedAddonStoreTerms || !checkedXsollaTerms}
                >
                  Accept
                </Button>
                <Link className="start-publishing__button-decline" to="/all">
                  Decline
                </Link>
              </div>
            </div>
          </>
        )}

        {!isCompanyAdmin && !error && !isLoading && (
          <div className="start-publishing__text-container">
            You don't have enough rights to start publishing on behalf of the company. Contact your
            Company Admin.
          </div>
        )}

        {isLoading && (
          <section className="add-on-section add-on-section--loading">
            <div className="orders__spinner">
              <CircularProgress color="inherit" />
            </div>
          </section>
        )}
        {error && !isLoading && <div className="start-publishing__text-container">{error}</div>}
      </div>
    </>
  );
};

export default StartPublishing;
