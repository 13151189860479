import './Sidebar.scss';

import { useState, JSX } from 'react';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';

import { IconButton } from '@mui/material';

import PinIcon from 'app/assets/icon_pin.svg';
import UnpinIcon from 'app/assets/icon_unpin.svg';

import { sidebarSelector, toggleSidebar } from 'app/store/unigine/sidebarSlice';
import { userRolesSelector } from 'app/auth/store/userSlice';
import useAppDispatch from 'app/hooks/useAppDispatch';
import useAppSelector from 'app/hooks/useAppSelector';
import ISidebarItem from 'app/interfaces/ISidebarItem';

interface ISidebarProps {
  itemsData: ISidebarItem[];
}

const Sidebar = ({ itemsData }: ISidebarProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const sidebarData = useAppSelector(sidebarSelector);
  const userRoles = useAppSelector(userRolesSelector);

  const handleToggleFixed = (): void => {
    dispatch(toggleSidebar(!isFixed));
  };

  const [isHovering, setIsHovering] = useState(false);
  const handleMouseEnter = (): void => {
    setIsHovering(true);
  };

  const handleMouseLeave = (): void => {
    setIsHovering(false);
  };

  const isFixed = sidebarData === 'true' || sidebarData === true || sidebarData === null;

  return (
    <nav
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={clsx('sidebar', { 'sidebar--fixed': isFixed, 'sidebar--hover': isHovering })}
    >
      <div className="sidebar__sticky-container">
        <div className="sidebar__header">
          <h1 className="sidebar__menu-title">Menu</h1>
          <IconButton
            className="sidebar__toggle-btn to-right"
            onClick={handleToggleFixed}
            aria-label="Toggle fixed sidebar"
          >
            {isFixed ? <img src={UnpinIcon} alt="unpin" /> : <img src={PinIcon} alt="pin" />}
          </IconButton>
        </div>
        <ul className="sidebar__nav-list">
          {itemsData
            .filter((item) =>
              item.roles.some((role) => {
                return userRoles.includes(role);
              })
            )
            .map((item, index) => {
              return (
                <li key={index} className="sidebar__nav-item">
                  <NavLink
                    to={item.path}
                    className={({ isActive }) =>
                      clsx('sidebar__link', { 'sidebar__link--active': isActive })
                    }
                    aria-label={item.title}
                  >
                    {item.icon}
                    <span className="sidebar__link-text">{item.title}</span>
                  </NavLink>
                </li>
              );
            })}
        </ul>
      </div>
    </nav>
  );
};

export default Sidebar;
