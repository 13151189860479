import './ContactBox.scss';

import { FC } from 'react';
import { IconButton } from '@mui/material';

import InstagramIcon from 'app/assets/instagram.svg';
import DiscordIcon from 'app/assets/discord.svg';
import FacebookIcon from 'app/assets/facebook.svg';
import LinkedInIcon from 'app/assets/linkedin.svg';
import TwitterIcon from 'app/assets/x.svg';
import YoutubeIcon from 'app/assets/youtube.svg';
import EmailIcon from 'app/assets/email.svg';
import IPublisherContacts from 'app/interfaces/publisher/IPublisherContacts';

interface IContactBox {
  contacts: IPublisherContacts;
  onOpen: () => void;
  isLoggedIn?: boolean;
}

const ContactBox: FC<IContactBox> = ({ contacts, onOpen, isLoggedIn }) => {
  return (
    <div className="contact-box">
      <h3 className="contact-box__title">Contacts</h3>
      <a className="contact-box__site" href={contacts.site_link}>
        {contacts.site_link}
      </a>

      <ul className="contact-box__social-list">
        {contacts.instagram && (
          <li className="contact-box__social-item">
            <a
              className="contact-box__social-link"
              href={contacts.instagram}
              target="_blank"
              rel="noreferrer"
            >
              <img src={InstagramIcon} width="32" alt="instagram" />
            </a>
          </li>
        )}
        {contacts.twitter && (
          <li className="contact-box__social-item">
            <a
              className="contact-box__social-link"
              href={contacts.twitter}
              target="_blank"
              rel="noreferrer"
            >
              <img src={TwitterIcon} width="32" alt="twitter" />
            </a>
          </li>
        )}
        {contacts.youtube && (
          <li className="contact-box__social-item">
            <a
              className="contact-box__social-link"
              href={contacts.youtube}
              target="_blank"
              rel="noreferrer"
            >
              <img src={YoutubeIcon} width="32" alt="youtube" />
            </a>
          </li>
        )}
        {contacts.facebook && (
          <li className="contact-box__social-item">
            <a
              className="contact-box__social-link"
              href={contacts.facebook}
              target="_blank"
              rel="noreferrer"
            >
              <img src={FacebookIcon} width="32" alt="facebook" />
            </a>
          </li>
        )}
        {contacts.discord && (
          <li className="contact-box__social-item">
            <a
              className="contact-box__social-link"
              href={contacts.discord}
              target="_blank"
              rel="noreferrer"
            >
              <img src={DiscordIcon} width="32" alt="discord" />
            </a>
          </li>
        )}
        {contacts.linkedin && (
          <li className="contact-box__social-item">
            <a
              className="contact-box__social-link"
              href={contacts.linkedin}
              target="_blank"
              rel="noreferrer"
            >
              <img src={LinkedInIcon} width="32" alt="linkedin" />
            </a>
          </li>
        )}
        {contacts.email && (
          <li className="contact-box__social-item">
            <IconButton disabled={!isLoggedIn} onClick={onOpen}>
              <img src={EmailIcon} width="32" alt="email" />
            </IconButton>
          </li>
        )}
      </ul>
    </div>
  );
};

export default ContactBox;
