import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from 'app/auth/axiosInstance';
import IError from 'app/interfaces/IError';
import { RootState } from 'app/store';

interface IOrderRefundParams {
  orderId: string;
  addonVersionIds: string[];
}

interface IOrderRefundSliceState {
  refund: any;
  errors: IError | null;
}

export const requestRefund = createAsyncThunk<undefined, IOrderRefundParams, { rejectValue: any }>(
  'ordersSection/requestRefund',
  async ({ orderId, addonVersionIds }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance({
        method: 'post',
        url: `/account/orders/${orderId}/refunds`,
        data: {
          order_addon_version_ids: addonVersionIds,
        },
      });

      const data = await response.data;

      // xsolla refused to process
      if (data.transaction_id) {
        return rejectWithValue({ type: 'xsolla', transactionId: data.transaction_id });
      }

      return data;
    } catch (e: any) {
      if (e.response?.data?.context?.code === 3100) {
        return rejectWithValue({ type: 'exists' });
      }

      return rejectWithValue({ type: 'error' });
    }
  }
);

const initialState: IOrderRefundSliceState = {
  refund: null,
  errors: null,
};

const paymentsSlice = createSlice({
  name: 'ordersSection/refund',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(requestRefund.fulfilled, (state, action) => {
        state.refund = action.payload;
        state.errors = null;
      })
      .addCase(requestRefund.rejected, (state, action) => {
        state.refund = null;
        state.errors = action.payload;
      });
  },
});

export const refundSelector = ({ ordersSection }: RootState): any => ordersSection.refund.refund;
export const refundErrorSelector = ({ ordersSection }: RootState): any =>
  ordersSection.refund.errors;

export default paymentsSlice.reducer;
