import './AddonMediaTab.scss';

import { ChangeEvent, FC, MouseEvent, useMemo } from 'react';

import { Button, Card, CardActionArea, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';

import { tips } from 'app/utils/constants/contentConstants';
import AddonMediaDialog from 'app/components/AddonDialogs/AddonMediaDialog';
import Tip from 'app/components/Tip/Tip';

import useAppDispatch from 'app/hooks/useAppDispatch';
import useAppSelector from 'app/hooks/useAppSelector';
import { IPublishedAddonVersionBaseInfo } from 'app/interfaces/addons/IAddonVersion';
import { IPublishedAddon } from 'app/interfaces/addons/IAddon';

import {
  addonBaseInfoSelector,
  openMediaDialog,
  addPictureFile,
  removePictureFile,
  removeVideoFile,
  closeMediaDialog,
  addVideoFile,
  getVideoInfoById,
  AddonMediaDialogSelector,
  addCoverFile,
  IMedia,
} from 'app/main/sections/PublisherPanel/sections/AddonSection/store/baseInfoSlice';
import { addonSelector } from 'app/main/sections/PublisherPanel/sections/AddonSection/store/addonInfoSlice';
import getPublisherPanelButtonsInfo from 'app/utils/helpers/getPublisherPanelButtonsInfo';

const AddonMediaTab: FC = () => {
  const dispatch = useAppDispatch();

  const addonBaseInfo = useAppSelector(addonBaseInfoSelector) as IPublishedAddonVersionBaseInfo;
  const addonInfo = useAppSelector(addonSelector) as IPublishedAddon;
  const mediaInfo = useAppSelector(AddonMediaDialogSelector);

  const { coverFile, id: addonVersionId, status, pictureFiles, videoFiles } = addonBaseInfo;
  const { isBanned, addonStatus } = addonInfo;

  const allowToDeleteMedia = useMemo(() => {
    if (status.value === 'Draft') {
      return true;
    }
    return [...pictureFiles, ...videoFiles].length > 1;
  }, [pictureFiles, status.value, videoFiles]);

  const handleDeleteImageClick = (
    e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
    imageId: string
  ): void => {
    e.stopPropagation();

    dispatch(openMediaDialog({ media: null, deleteImageId: imageId, allowToDeleteMedia }));
  };

  const handleDeleteVideoClick = (
    e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
    videoId: string
  ): void => {
    e.stopPropagation();

    dispatch(openMediaDialog({ media: null, deleteVideoId: videoId, allowToDeleteMedia }));
  };

  const handleMediaCardClick = (media: IMedia): void => {
    dispatch(openMediaDialog({ media }));
  };

  const handleAddImageButton = (): void => {
    dispatch(openMediaDialog({ media: null, isFormImage: true }));
  };

  const handleAddCoverButton = (e: ChangeEvent<HTMLInputElement>): void => {
    const avatarFile = e.target.files?.[0];
    if (avatarFile && ['image/jpeg', 'image/png'].includes(avatarFile.type)) {
      dispatch(addCoverFile({ file: avatarFile, addonVersionId }));
    }
  };

  const handleAddVideoButton = (): void => {
    dispatch(openMediaDialog({ media: null, isFormVideo: true }));
  };

  const displayAddImageButton = status.value === 'Draft' && !isBanned;
  const displayImageTitle = displayAddImageButton || addonBaseInfo.pictureFiles.length > 0;
  const { displayRemoveImageButton, displayRemoveVideoButton } = getPublisherPanelButtonsInfo({
    addonStatus,
  });

  const displayAddVideoButton = status.value === 'Draft' && !isBanned;
  const displayVideoTitle = displayAddVideoButton || addonBaseInfo.videoFiles.length > 0;

  const displayAddCoverButton = status.value === 'Draft' && !isBanned;
  const displayChangeCoverButton = status.value === 'Draft' && !isBanned;

  return (
    <section className="add-on-section add-on-section--media">
      <div className="add-on-section__content">
        <div className="media__container">
          <h2 className="media__list-title">Cover</h2>

          <ul className="media__list">
            {displayAddCoverButton && !addonBaseInfo.coverFile && (
              <li className="media__item media__item--cover">
                <label htmlFor="add-cover" className="media__button media__button--cover">
                  <input
                    className="dialog-media__input--file"
                    type="file"
                    id="add-cover"
                    accept="image/jpeg, image/png"
                    onChange={handleAddCoverButton}
                  />
                </label>
              </li>
            )}

            {!displayAddCoverButton && !addonBaseInfo.coverFile && (
              <li className="media__item media__item--cover">
                <div className="media__card--no-image">
                  <span>No Cover</span>
                </div>
              </li>
            )}

            {addonBaseInfo.coverFile && (
              <li className="media__item media__item--cover">
                <Card className="media__card">
                  <div className="media__action-container">
                    <CardActionArea
                      className="media__image-container"
                      onClick={() => handleMediaCardClick(coverFile)}
                    >
                      <img
                        className="media__image"
                        src={`${process.env.REACT_APP_STORE_API_URL}/media-files/${coverFile?.id}`}
                        alt={coverFile?.url}
                      />
                    </CardActionArea>

                    {displayChangeCoverButton && (
                      <label
                        htmlFor="change-cover"
                        className="media__image-button media__image-button--cover"
                      >
                        <input
                          className="dialog-media__input--file"
                          type="file"
                          id="change-cover"
                          accept="image/jpeg, image/png"
                          onChange={handleAddCoverButton}
                        />
                        <EditIcon />
                      </label>
                    )}
                  </div>

                  <h3 className="media__title">{coverFile?.id}</h3>
                </Card>
              </li>
            )}
          </ul>
        </div>

        <div className="media__container">
          {displayImageTitle && <h2 className="media__list-title">Other images</h2>}

          <ul className="media__list">
            {displayAddImageButton && (
              <li className="media__item">
                <Button
                  className="media__button"
                  onClick={() => handleAddImageButton()}
                  type="button"
                />
              </li>
            )}

            {addonBaseInfo &&
              addonBaseInfo.pictureFiles.map((image) => (
                <li key={image.id} className="media__item">
                  <Card className="media__card">
                    <div className="media__action-container">
                      <CardActionArea
                        className="media__image-container"
                        onClick={() => handleMediaCardClick(image)}
                      >
                        <img
                          className="media__image"
                          src={`${process.env.REACT_APP_STORE_API_URL}/media-files/${image.id}`}
                          alt={image.url}
                        />
                      </CardActionArea>

                      {displayRemoveImageButton && (
                        <IconButton
                          aria-label="close"
                          title="Delete media file"
                          className="media__image-button"
                          onClick={(e) => handleDeleteImageClick(e, image.id)}
                        >
                          <CloseIcon />
                        </IconButton>
                      )}
                    </div>

                    <h3 className="media__title">{image.id}</h3>
                  </Card>
                </li>
              ))}
          </ul>
        </div>

        <div className="media__container">
          {displayVideoTitle && <h2 className="media__list-title">Videos</h2>}

          <ul className="media__list">
            {displayAddVideoButton && (
              <li className="media__item">
                <Button
                  className="media__button"
                  onClick={() => handleAddVideoButton()}
                  type="button"
                />
              </li>
            )}

            {addonBaseInfo &&
              addonBaseInfo.videoFiles.map((video) => (
                <li key={video.id} className="media__item">
                  <Card className="media__card">
                    <div className="media__action-container">
                      <CardActionArea
                        className="media__image-container"
                        onClick={() => handleMediaCardClick(video.data)}
                      >
                        <img
                          className="media__image"
                          src={`https://img.youtube.com/vi/${video.data.videoId}/hqdefault.jpg`}
                          alt={video.data.videoId}
                        />
                      </CardActionArea>

                      {displayRemoveVideoButton && (
                        <IconButton
                          aria-label="close"
                          title="Delete media file"
                          className="media__image-button"
                          onClick={(e) => handleDeleteVideoClick(e, video.id)}
                        >
                          <CloseIcon />
                        </IconButton>
                      )}
                    </div>

                    <h3 className="media__title">{video.data.title}</h3>
                  </Card>
                </li>
              ))}
          </ul>
        </div>
      </div>

      <Tip className="add-on-section__media-tip" compact content={tips.media} />

      <AddonMediaDialog
        type="addon"
        addonVersionId={addonVersionId}
        closeMediaDialog={closeMediaDialog}
        getVideoInfoById={getVideoInfoById}
        addVideoFile={addVideoFile}
        addPictureFile={addPictureFile}
        removePictureFile={removePictureFile}
        removeVideoFile={removeVideoFile}
        mediaInfo={mediaInfo}
      />
    </section>
  );
};

export default AddonMediaTab;
