import './Layout.scss';
import { FC } from 'react';

import { CircularProgress } from '@mui/material';

import HeaderLoading from 'app/components/Header/HeaderLoading';
import FooterLoading from 'app/components/Footer/FooterLoading';

const LayoutLoading: FC = () => {
  return (
    <div className="layout">
      <HeaderLoading />
      <main className="content-wrapper content-wrapper--loading">
        <CircularProgress color="inherit" className="content-wrapper__loading" />
      </main>
      <FooterLoading />
    </div>
  );
};

export default LayoutLoading;
