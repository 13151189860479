import { FC, useState } from 'react';
import { CircularProgress } from '@mui/material';

import UnigineButton from 'app/components/UnigineButton/UnigineButton';
import useAppDispatch from 'app/hooks/useAppDispatch';
import { getPackageDownloadLink } from 'app/main/sections/Addon/store/baseInfoSlice';
import { getAdminPackageDownloadLink } from 'app/main/sections/AdminPortal/sections/AddonSection/store/addonInfoSlice';
import { getPublisherPackageDownloadLink } from 'app/main/sections/PublisherPanel/sections/AddonSection/store/addonInfoSlice';
import { PackageSource } from 'app/configs/appConfig';

type UnigineButtonProps = Parameters<typeof UnigineButton>[0];

interface DownloadPackageProps extends Omit<UnigineButtonProps, 'onClick'> {
  packageId: string;
  isAdminPanel?: boolean;
  isPublisherPanel?: boolean;
  source: PackageSource;
}

const DownloadPackage: FC<DownloadPackageProps> = ({
  packageId,
  disabled,
  children,
  source = 'storefront',
  ...rest
}) => {
  const dispatch = useAppDispatch();
  const [isLinkProcessing, setIsLinkProcessing] = useState(false);

  const handleClick = async (): Promise<void> => {
    setIsLinkProcessing(true);
    try {
      const accessToken = localStorage.getItem('jwt_access_token');
      let tempLinkHref;

      switch (source) {
        case 'admin':
          tempLinkHref = await dispatch(getAdminPackageDownloadLink({ packageId })).unwrap();
          break;
        case 'publisher':
          tempLinkHref = await dispatch(getPublisherPackageDownloadLink({ packageId })).unwrap();
          break;
        case 'storefront':
          tempLinkHref = await dispatch(getPackageDownloadLink({ packageId })).unwrap();
          break;
        default:
          tempLinkHref = await dispatch(getPackageDownloadLink({ packageId })).unwrap();
      }

      const tempLink = document.createElement('a');
      tempLink.href = `${tempLinkHref}?token=${accessToken}`;
      tempLink.target = '_self';
      tempLink.rel = 'noreferrer';
      tempLink.click();
      tempLink.remove();
    } catch (err) {
      console.error('Could not get download link:', err);
    } finally {
      setIsLinkProcessing(false);
    }
  };

  return (
    <UnigineButton {...rest} onClick={handleClick} disabled={disabled || isLinkProcessing}>
      {isLinkProcessing ? <CircularProgress color="inherit" size={16} /> : children}
    </UnigineButton>
  );
};

export default DownloadPackage;
