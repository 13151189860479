import ICategory from 'app/interfaces/addons/ICategory';

interface IGetCategoriesIds {
  categoriesData: ICategory[];
  categoriesParam: string[];
}

const getCategoriesIds = ({ categoriesData, categoriesParam }: IGetCategoriesIds): string[] =>
  categoriesData
    .filter((category) => categoriesParam.includes(category.slug))
    .map((category) => category.id);

export default getCategoriesIds;
