import { useMemo, useState, JSX, FormEventHandler } from 'react';
import { useSearchParams } from 'react-router-dom';

import { IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import mergeSearchParam from 'app/utils/helpers/mergeSearchParam';
import { DEFAULT_MAX_RANGE_PRICE, DEFAULT_MIN_RANGE_PRICE } from 'app/configs/appConfig';

import clsx from 'clsx';
import AnimateHeight from 'react-animate-height';
import ITag from 'app/interfaces/addons/ITag';
import CheckboxGroup from 'app/components/CheckboxGroup/CheckboxGroup';
import PriceFilter from 'app/components/PriceFilter/PriceFilter';
import RatingFilter from 'app/components/RatingFilter/RatingFilter';
import Tags from 'app/components/Tags/Tags';

import './FilterForm.scss';
import RadioGroup from 'app/components/RadioGroup/RadioGroup';

type BaseFilterParam = { name: string; title: string; collapsed?: boolean };

type FilterParam = BaseFilterParam &
  (
    | {
        type: 'checkbox';
        options: unknown[];
        // this needs to be an existential type (https://stackoverflow.com/questions/51815782/typescript-array-of-different-generic-types)
        // which can't be done in TS without rewriting
        // the way these params are passed

        // leaving this as just any for now,
        // the component needs to be refactored eventually
        getLabel: (option: any) => string;
        getValue: (option: any) => string;
      }
    | {
        type: 'tags';
        options: ITag[];
        getLabel?: (option: ITag) => string;
        getValue?: (option: ITag) => string;
      }
    | {
        type: 'radio';
        options: unknown[];
        nullValue?: string;
        getLabel: (option: any) => string;
        getValue: (option: any) => string;
      }
  );

interface IFilterFormParams {
  price?: boolean;
  rating?: boolean;
  search?: {
    name: string;
    label: string;
  } | null;
  filterParams: FilterParam[];
}

const FilterForm = ({
  search,
  price = true,
  filterParams,
  rating = false,
}: IFilterFormParams): JSX.Element => {
  const defaultCollapsedStates = filterParams
    .filter((data) => Object.prototype.hasOwnProperty.call(data, 'collapsed'))
    .map((data) => data.collapsed);

  const [searchParams, setSearchParams] = useSearchParams();
  const [isCollapsed, setIsCollapsed] = useState(defaultCollapsedStates);
  const [isPriceCollapsed, setIsPriceCollapsed] = useState(false);
  const [isRatingCollapsed, setIsRatingCollapsed] = useState(false);

  const formValues = useMemo(() => {
    const values: {
      params: { [others: string]: string[] };
      radioParams: { [others: string]: string };
      search: string;
      rating: number;
      price: {
        mode?: 'all' | 'free' | 'range';
        range?: { min: string | null; max: string | null };
      };
    } = { params: {}, radioParams: {}, price: {}, search: '', rating: 0 };
    if (search) {
      values.search = searchParams.get(search.name) || '';
    }

    if (rating) {
      values.rating = parseInt(searchParams.get('rating') || '0', 10);
    }

    if (price) {
      values.price = {};
      const [min, max] = [searchParams.get('start_price'), searchParams.get('end_price')];
      const isFree = searchParams.get('is_free') === '1';
      const isRangeSet = min !== null || max !== null;

      if (isRangeSet) {
        values.price.range = {
          min: `${parseInt(min || '', 10) || DEFAULT_MIN_RANGE_PRICE}`,
          max: `${parseInt(max || '', 10) || DEFAULT_MAX_RANGE_PRICE}`,
        };
        values.price.mode = 'range';
      }
      if (isFree) {
        values.price.mode = 'free';
      }
      if (!isRangeSet && !isFree) {
        values.price.mode = 'all';
      }
    }

    filterParams.forEach((filterParam) => {
      if (filterParam.type === 'radio') {
        const value = searchParams.get(filterParam.name);
        if (value) {
          values.radioParams[filterParam.name] = value;
        } else if (filterParam.nullValue) {
          values.radioParams[filterParam.name] = filterParam.nullValue;
        }
      } else {
        const value = searchParams.getAll(`${filterParam.name}[]`);
        values.params[filterParam.name] = value;
      }
    });
    return values;
  }, [search, rating, price, filterParams, searchParams]);

  const [searchText, setSearchText] = useState(formValues.search || '');
  const [priceRange, setPriceRange] = useState<{ min: string | null; max: string | null }>(
    formValues.price.range || {
      min: `${DEFAULT_MIN_RANGE_PRICE}`,
      max: `${DEFAULT_MAX_RANGE_PRICE}`,
    }
  );

  const priceToParams = (
    params: URLSearchParams,
    {
      mode,
      range,
    }: { mode?: 'all' | 'free' | 'range'; range?: { min: string | null; max: string | null } }
  ): URLSearchParams => {
    const isFree = mode === 'free';
    const isRange = mode === 'range';

    let newParams = mergeSearchParam(params, 'is_free', isFree ? '1' : null);
    newParams = mergeSearchParam(newParams, 'start_price', isRange ? range?.min : null);
    newParams = mergeSearchParam(newParams, 'end_price', isRange ? range?.max : null);
    return newParams;
  };

  const handleChange = (name: string, value: string | string[], nullValue?: string): void => {
    let newParams = mergeSearchParam(searchParams, name, value === nullValue ? null : value);
    newParams = mergeSearchParam(newParams, 'page', '1');
    setSearchParams(newParams);
  };

  const handlePriceChange = ({
    mode,
    range,
    applyRange,
  }: Parameters<Parameters<typeof PriceFilter>[0]['onChange']>[0]): void => {
    setPriceRange(range);
    if (mode === 'range' && !applyRange) {
      return;
    }
    let newParams = priceToParams(searchParams, { mode, range });
    newParams = mergeSearchParam(newParams, 'page', '1');
    setSearchParams(newParams);
  };

  const handleRatingChange = (value: number): void => {
    let newParams = mergeSearchParam(searchParams, 'rating', `${value}`);
    newParams = mergeSearchParam(newParams, 'page', '1');
    setSearchParams(newParams);
  };

  const handleReset = (): void => {
    setPriceRange({ min: `${DEFAULT_MIN_RANGE_PRICE}`, max: `${DEFAULT_MAX_RANGE_PRICE}` });

    let resetParams = searchParams;
    if (search) {
      resetParams = mergeSearchParam(resetParams, search?.name, null);
    }
    resetParams = priceToParams(resetParams, { mode: 'all' });

    filterParams.forEach((filterParam) => {
      if (filterParam.type === 'radio') {
        resetParams = mergeSearchParam(resetParams, filterParam.name, null);
      } else resetParams = mergeSearchParam(resetParams, filterParam.name, []);
    });

    resetParams = mergeSearchParam(resetParams, 'rating', null);
    resetParams = mergeSearchParam(resetParams, 'page', '1');

    setSearchParams(resetParams);
    setSearchText('');
    setIsCollapsed(defaultCollapsedStates);
    setIsPriceCollapsed(false);
    setIsRatingCollapsed(false);
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = (evt) => {
    evt.preventDefault();
    let newParams = searchParams;

    if (search) {
      newParams = mergeSearchParam(newParams, search.name, searchText);
    }
    newParams = mergeSearchParam(newParams, 'page', '1');
    setSearchParams(newParams);
  };

  const handleCollapse = (index: number): void => {
    setIsCollapsed((prevState) => prevState.map((item, idx) => (idx === index ? !item : item)));
  };

  return (
    <form className="filter-form" onSubmit={handleSubmit}>
      <div className="filter-form__header">
        <h2 className="filter-form__title">Filters</h2>
        <input
          type="reset"
          value="Clear filters"
          className="filter-form__reset"
          onClick={handleReset}
        />
      </div>
      {search && (
        <div className="filter-form__search">
          <input
            className="filter-form__search-input"
            type="search"
            value={searchText}
            placeholder={search.label || ''}
            onChange={(evt) => setSearchText(evt.target.value)}
          />

          <button
            className="filter-form__search-submit-btn"
            type="submit"
            aria-label="Submit search"
          >
            <SearchIcon />
          </button>
        </div>
      )}

      {price && (
        <fieldset className="filter-form__group" key="price">
          <div className="filter-form__group-title-wrapper">
            <legend className="filter-form__group-title">Price</legend>
            <IconButton
              className="filter-form__group-collapse-button"
              onClick={() => setIsPriceCollapsed(!isPriceCollapsed)}
            >
              <ArrowForwardIosIcon
                className={clsx('filter-form__group-icon', {
                  'rotate-down': isPriceCollapsed,
                })}
              />
            </IconButton>
          </div>
          <AnimateHeight duration={300} height={isPriceCollapsed ? 0 : 'auto'}>
            <PriceFilter
              mode={formValues.price.mode || 'all'}
              range={priceRange}
              onChange={handlePriceChange}
            />
          </AnimateHeight>
        </fieldset>
      )}

      {rating && (
        <fieldset className="filter-form__group" key="rating">
          <div className="filter-form__group-title-wrapper">
            <legend className="filter-form__group-title">Rating</legend>
            <IconButton
              className="filter-form__group-collapse-button"
              onClick={() => setIsRatingCollapsed(!isRatingCollapsed)}
            >
              <ArrowForwardIosIcon
                className={clsx('filter-form__group-icon', {
                  'rotate-down': isRatingCollapsed,
                })}
              />
            </IconButton>
          </div>
          <AnimateHeight duration={300} height={isRatingCollapsed ? 0 : 'auto'}>
            <RatingFilter defaultValue={formValues.rating} onChange={handleRatingChange} />
          </AnimateHeight>
        </fieldset>
      )}

      {filterParams.map((filterParam, index) => {
        const { name, title, type, options, getLabel, getValue } = filterParam;

        return (
          <fieldset className="filter-form__group" key={name}>
            <div className="filter-form__group-title-wrapper">
              <legend className="filter-form__group-title">{title}</legend>
              {type !== 'tags' && (
                <IconButton
                  className="filter-form__group-collapse-button"
                  onClick={() => handleCollapse(index)}
                >
                  <ArrowForwardIosIcon
                    className={clsx('filter-form__group-icon', {
                      'rotate-down': isCollapsed[index],
                    })}
                  />
                </IconButton>
              )}
            </div>
            {type === 'checkbox' && (
              <AnimateHeight duration={300} height={isCollapsed[index] ? 0 : 'auto'}>
                <CheckboxGroup
                  items={options}
                  selectedValues={formValues.params[name]}
                  onChange={(values) => handleChange(name, values)}
                  getLabel={getLabel}
                  getValue={getValue}
                />
              </AnimateHeight>
            )}
            {type === 'tags' && (
              <Tags
                tags={options}
                selectedValues={formValues.params[name]}
                onChange={(values) => handleChange(name, values)}
                getLabel={getLabel}
                getValue={getValue}
              />
            )}
            {type === 'radio' && (
              <RadioGroup
                options={options}
                selectedValue={formValues.radioParams[name] || getValue(options[0])}
                getValue={getValue}
                getLabel={getLabel}
                onChange={(_, value) => handleChange(name, value, filterParam.nullValue)}
              />
            )}
          </fieldset>
        );
      })}
    </form>
  );
};

export default FilterForm;
