import { JSX } from 'react';

import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import clsx from 'clsx';

import './CheckboxUnigine.scss';

const CheckboxUnigine = ({ ...props }: CheckboxProps): JSX.Element => {
  return (
    <Checkbox
      {...props}
      className={clsx('checkbox-unigine', props.className)}
      checkedIcon={
        <span
          className={clsx('checkbox-unigine__icon checkbox-unigine__icon--checked', {
            'checkbox-unigine--disabled': props.disabled,
          })}
        >
          <span className="checkbox-unigine__fill" />
          <span className="checkbox-unigine__outline" />
        </span>
      }
      icon={
        <span
          className={clsx('checkbox-unigine__icon', {
            'checkbox-unigine--disabled': props.disabled,
          })}
        >
          <span className="checkbox-unigine__outline" />
        </span>
      }
      indeterminateIcon={
        <span
          className={clsx('checkbox-unigine__icon', {
            'checkbox-unigine__icon--indeterminate': props.indeterminate,
          })}
        >
          <span className="checkbox-unigine__fill" />
          <span className="checkbox-unigine__outline" />
        </span>
      }
      // disabled={
      //   <span className="checkbox-unigine--disabled">
      //       <span className="checkbox-unigine__outline" />
      //   </span>
      // }
    />
  );
};
export default CheckboxUnigine;
