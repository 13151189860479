import IPlatform from 'app/interfaces/addons/IPlatform';

interface IGetPlatformIds {
  platformData: IPlatform[] | null;
  platformParam: string[];
}

const getPlatformIds = ({ platformData, platformParam }: IGetPlatformIds): string[] | null =>
  platformData &&
  platformData.filter((platform) => platformParam.includes(platform.id)).map((os) => os.id);

export default getPlatformIds;
