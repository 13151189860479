import './AddonsManagersSection.scss';

import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { CircularProgress } from '@mui/material';

import AddonsManagerItem from 'app/components/ManagersComponents/AddonsManagerItem';
import AddonsManagerAutocomplete from 'app/components/ManagersComponents/AddonsManagerAutocomplete';

import usePermissions from 'app/hooks/usePermissions';
import useAppDispatch from 'app/hooks/useAppDispatch';
import useAppSelector from 'app/hooks/useAppSelector';

import IManager from 'app/interfaces/IManager';

import {
  addonsManagersErrorsSelector,
  addonsManagersSelector,
  getAddonsManagers,
  removeManager,
} from 'app/main/sections/PublisherPanel/sections/AddonsManagersSection/store/addonsManagersSlice';

const AddonsManagersSection: FC = () => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const { publisherPanelUserAdmin, isNoRole } = usePermissions();

  const errors = useAppSelector(addonsManagersErrorsSelector);
  const managers = useAppSelector(addonsManagersSelector);

  useEffect(() => {
    if (isNoRole) {
      return;
    }

    if (!publisherPanelUserAdmin) {
      navigate('/publisherPanel/add-ons');
    }
  }, [isNoRole, navigate, publisherPanelUserAdmin]);

  useEffect(() => {
    if (!isNoRole) {
      dispatch(getAddonsManagers()).then(() => setIsLoading(false));
    }
  }, [dispatch, isNoRole]);

  if (isLoading) {
    return (
      <div className="orders__spinner">
        <CircularProgress color="inherit" />
      </div>
    );
  }

  if (errors) {
    const { data } = errors;
    return (
      <span className="orders__error">{data?.context?.message || 'Something went wrong'}</span>
    );
  }

  const handleClickRemoveManager = (manager: IManager): void => {
    dispatch(removeManager({ manager }));
  };

  return (
    <div className="publisher-panel__wrapper--no-header">
      <h1 className="add-on-managers__title">Add-Ons Managers</h1>
      <div className="add-ons-managers__wrapper">
        <p className="add-ons-managers__text">Managers are allowed to create and update add-ons.</p>

        <AddonsManagerAutocomplete />

        <ul className="add-ons-managers__list">
          {managers &&
            managers.map((manager, i) => (
              <AddonsManagerItem
                key={i}
                handleClickRemoveManager={handleClickRemoveManager}
                manager={manager}
              />
            ))}
        </ul>
      </div>
    </div>
  );
};

export default AddonsManagersSection;
