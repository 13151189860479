/* eslint-disable class-methods-use-this */
import jwtDecode from 'jwt-decode';
import { authStates, IAuthStates } from 'app/configs/appConfig';

interface DecodedToken {
  exp: number;
}

class AuthService {
  getAuthState = (): IAuthStates[keyof IAuthStates] => {
    const accessToken = this.getAccessToken();
    const refreshToken = this.getRefreshToken();

    if (!accessToken) {
      return authStates.onNoAccessToken;
    }

    if (this.isAuthTokenValid(accessToken)) {
      return authStates.onAutoLogin;
    }

    if (refreshToken) {
      return authStates.onRefreshTokenLogin;
    }

    return authStates.onAutoLogout;
  };

  setSession = ({
    accessToken,
    refreshToken,
  }: {
    accessToken: string | null;
    refreshToken: string | null;
  }): void => {
    if (accessToken && refreshToken) {
      localStorage.setItem('jwt_access_token', accessToken);
      localStorage.setItem('jwt_refresh_token', refreshToken);
    } else {
      localStorage.removeItem('jwt_access_token');
      localStorage.removeItem('jwt_refresh_token');
    }
  };

  isAuthTokenValid = (accessToken: string | null): boolean => {
    try {
      if (!accessToken) {
        return false;
      }
      const decoded: DecodedToken = jwtDecode(accessToken);
      const currentTime = Date.now() / 1000;

      if (decoded.exp < currentTime) {
        return false;
      }

      return true;
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  getAccessToken = (): string | null => {
    return localStorage.getItem('jwt_access_token');
  };

  getRefreshToken = (): string | null => {
    return localStorage.getItem('jwt_refresh_token');
  };
}

const instance = new AuthService();

export default instance;
