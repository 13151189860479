import './SearchForm.scss';

import { JSX } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { IconButton, InputBase, Paper } from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';

const Search = (): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const searchText = searchParams.get('q') || '';

  const methods = useForm({
    mode: 'onTouched',
    defaultValues: {
      searchText,
    },
  });

  const { control, handleSubmit } = methods;

  const handleSearchSubmit: Parameters<typeof handleSubmit>[0] = (data) => {
    if (pathname !== '/search') {
      navigate(`/search?q=${data.searchText}`);
    } else {
      setSearchParams({ q: data.searchText });
    }
  };

  return (
    <Paper component="form" className="search-wrapper" onSubmit={handleSubmit(handleSearchSubmit)}>
      <Controller
        name="searchText"
        control={control}
        render={({ field: { onChange, value } }) => (
          <InputBase
            className="search-input"
            placeholder="Search for add-ons"
            value={value}
            onChange={onChange}
            inputProps={{ 'aria-label': 'search for add-ons' }}
          />
        )}
      />

      <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
        <SearchIcon style={{ color: '#DADADA' }} />
      </IconButton>
    </Paper>
  );
};

export default Search;
