import './Tags.scss';

import { JSX } from 'react';
import ITag from 'app/interfaces/addons/ITag';

interface ITagsProps {
  tags: ITag[];
  selectedValues: string[];
  onChange: (values: string[]) => void;
  getLabel?: (tag: ITag) => string;
  getValue?: (tag: ITag) => string;
}

export default function Tags({
  tags,
  selectedValues,
  onChange,
  getLabel = (item) => item.canonicalName,
  getValue = (item) => item.value,
}: ITagsProps): JSX.Element {
  return (
    <div className="tags">
      {tags.map((tag) => {
        const label = getLabel(tag);
        const value = getValue(tag);
        const selected = selectedValues.includes(value);

        return (
          <button
            key={tag.id}
            className={`tag${selected ? ' tag--selected' : ''}`}
            type="button"
            onClick={() => {
              if (selected) {
                return onChange(selectedValues.filter((v) => v !== value));
              }
              return onChange([...selectedValues, value]);
            }}
          >
            {label}
          </button>
        );
      })}
    </div>
  );
}
