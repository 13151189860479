import IAddonStatus from 'app/interfaces/addons/IAddonStatus';

interface IGetPublisherPanelButtonsInfoProps {
  addonStatus: IAddonStatus;
  addonBaseInfoStatus?: IAddonStatus;
}

interface IGetPublisherPanelButtonsInfoReturn {
  displayArchiveButton: boolean;
  displayAddNewAddonVersionButton: boolean;
  displayGetFromArchiveButton: boolean;
  displayHideAddonCheckbox: boolean;
  displayPublishButton: boolean;
  displayWithdrawButton: boolean;
  displayEditTitleAndPriceButton: boolean;
  displayRemoveVideoButton: boolean;
  displayRemoveImageButton: boolean;
}

const getPublisherPanelButtonsInfo = ({
  addonStatus,
  addonBaseInfoStatus,
}: IGetPublisherPanelButtonsInfoProps): IGetPublisherPanelButtonsInfoReturn => {
  const displayArchiveButton =
    addonStatus.value === 'Draft' ||
    addonStatus.value === 'Moderation' ||
    addonStatus.value === 'Published' ||
    addonStatus.value === 'Revision';
  const displayAddNewAddonVersionButton =
    addonStatus.value === 'Draft' ||
    addonStatus.value === 'Moderation' ||
    addonStatus.value === 'Published' ||
    addonStatus.value === 'Revision';
  const displayGetFromArchiveButton = addonStatus.value === 'Archived';
  const displayHideAddonCheckbox =
    addonStatus.value === 'Published' || addonStatus.value === 'Revision';

  const displayPublishButton =
    !!addonBaseInfoStatus &&
    addonBaseInfoStatus.value === 'Draft' &&
    addonStatus.value !== 'Banned';
  const displayWithdrawButton =
    !!addonBaseInfoStatus &&
    addonBaseInfoStatus.value === 'Moderation' &&
    addonStatus.value !== 'Banned';
  const displayEditTitleAndPriceButton =
    addonStatus.value === 'Draft' ||
    addonStatus.value === 'Moderation' ||
    addonStatus.value === 'Published' ||
    addonStatus.value === 'Revision' ||
    addonStatus.value === 'Archived';

  const displayRemoveVideoButton =
    addonStatus.value === 'Draft' ||
    addonStatus.value === 'Moderation' ||
    addonStatus.value === 'Published' ||
    addonStatus.value === 'Revision' ||
    addonStatus.value === 'Archived';

  const displayRemoveImageButton =
    addonStatus.value === 'Draft' ||
    addonStatus.value === 'Moderation' ||
    addonStatus.value === 'Published' ||
    addonStatus.value === 'Revision' ||
    addonStatus.value === 'Archived';

  return {
    displayArchiveButton,
    displayAddNewAddonVersionButton,
    displayGetFromArchiveButton,
    displayHideAddonCheckbox,
    displayPublishButton,
    displayWithdrawButton,
    displayEditTitleAndPriceButton,
    displayRemoveVideoButton,
    displayRemoveImageButton,
  };
};

export default getPublisherPanelButtonsInfo;
