import { defaultPerPage } from 'app/configs/appConfig';

export interface IRowPaginationParams {
  page?: number;
  perPage?: number;
  search?: string | null;

  categories?: string[] | string | null;
  products?: string[] | null;
  versions?: string[] | null;
  tags?: string[] | null;
  statuses?: number[] | null;
  lastAddonVersionStatuses?: string[] | null;
  orderStatuses?: string[] | null;
  platform?: string[] | null;
  sourceLanguages?: string[] | null;

  sort?: string | null;
  direction?: string | null;

  price?: {
    mode: string | null;
    min: string | null;
    max: string | null;
  };
  rating?: string | null;

  isChecked?: string | null;
  isBanned?: string | null;
  isReplied?: string | null;
  searchTitle?: string;
}

export interface IPaginationParams {
  limit: number;
  skip: number;
  search?: string;
  categories?: string[] | string;
  products?: string[];
  versions?: string[];
  tags?: string[];
  statuses?: number[] | string[];
  last_addon_version_statuses?: string[];
  orderStatuses?: string[];
  platform?: string[];
  source_codes?: string[];
  is_free?: string | null;
  start_price?: string | null;
  end_price?: string | null;
  rating?: string | null;
  isChecked?: string | null;
  isBanned?: string | null;
  isReplied?: string | null;
  searchTitle?: string;

  [key: `order[${string}]`]: string;
}

const getPaginationParams: (arg: IRowPaginationParams) => IPaginationParams = ({
  page = 1,
  perPage = defaultPerPage,
  search = '',
  categories,
  products,
  versions,
  tags,
  sort,
  direction,
  statuses,
  lastAddonVersionStatuses,
  orderStatuses,
  platform,
  sourceLanguages,
  price,
  isChecked,
  isBanned,
  isReplied,
  rating,
  searchTitle,
}) => {
  const params: IPaginationParams = {
    limit: perPage,
    skip: (page - 1) * perPage,
  };

  if (search) {
    params.search = search;
  }

  if (products) {
    params.products = products;
  }

  if (versions) {
    params.versions = versions;
  }

  if (categories) {
    params.categories = categories;
  }

  if (tags) {
    params.tags = tags;
  }

  if (statuses) {
    params.statuses = statuses;
  }

  if (lastAddonVersionStatuses) {
    params.last_addon_version_statuses = lastAddonVersionStatuses;
  }

  if (orderStatuses) {
    params.orderStatuses = orderStatuses;
  }

  if (platform) {
    params.platform = platform;
  }

  if (sourceLanguages) {
    params.source_codes = sourceLanguages;
  }

  if (sort && direction) {
    params[`order[${sort}]`] = direction;
  }

  if (price) {
    params.is_free = price.mode;
    params.start_price = price.min;
    params.end_price = price.max;
  }

  if (rating) {
    params.rating = rating;
  }

  if (isChecked) {
    params.isChecked = isChecked;
  }

  if (isBanned) {
    params.isBanned = isBanned;
  }

  if (isReplied) {
    params.isReplied = isReplied;
  }

  if (searchTitle) {
    params.searchTitle = searchTitle;
  }

  return params;
};

export default getPaginationParams;
