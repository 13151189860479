import './OrderPaymentDialog.scss';

import { useEffect, useRef, useState, ReactElement } from 'react';
import { Dialog } from '@mui/material';

import UnigineButton from 'app/components/UnigineButton/UnigineButton';

const paymentProcessorUrl = 'https://sandbox-secure.xsolla.com/paystation3/';

const PaymentState = {
  PREPARING: 'PREPARING',
  PROCESSING: 'PROCESSING',
  FAILED: 'FAILED',
  SUCCESSFUL: 'SUCCESSFUL',
};

interface IOrderPaymentDialog {
  open: boolean;
  paymentToken: any;
  onSuccess: () => void;
  onAbort: () => void;
}

const OrderPaymentDialog = ({
  open,
  paymentToken,
  onSuccess,
  onAbort,
}: IOrderPaymentDialog): ReactElement => {
  const [paymentState, setPaymentState] = useState(PaymentState.PREPARING);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  useEffect(() => {
    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  const handleMessage = (message: any): void => {
    if (message.source !== iframeRef.current?.contentWindow) {
      return;
    }

    const messageData = JSON.parse(message.data);
    if (process.env.NODE_ENV === 'development') {
      console.log(messageData);
    }

    if (messageData.command === 'click-btn-pay') {
      setPaymentState(PaymentState.PROCESSING);
    }

    const paymentStatus = messageData?.data?.paymentInfo?.status;
    if (paymentStatus === 'troubled') {
      setPaymentState(PaymentState.FAILED);
    }

    if (paymentStatus === 'done') {
      setPaymentState(PaymentState.SUCCESSFUL);
    }
  };

  const handleClose = (): void => {
    switch (paymentState) {
      case PaymentState.PREPARING:
      case PaymentState.FAILED:
        onAbort();
        break;
      case PaymentState.SUCCESSFUL:
        onSuccess();
        break;
      default:
        break;
    }
  };

  return (
    <Dialog open={open} key={paymentToken} className="payment-dialog" fullWidth maxWidth="md">
      <div className="payment-dialog__content">
        <iframe
          title="Order payment interface"
          src={`${
            /* process.env.REACT_APP_PAYMENT_PROCESSOR_URL */ paymentProcessorUrl
          }?access_token=${paymentToken}`}
          className="payment-dialog__frame"
          ref={iframeRef}
        />
      </div>
      <UnigineButton onClick={handleClose} disabled={paymentState === PaymentState.PROCESSING}>
        {paymentState === PaymentState.PREPARING && 'Cancel payment'}
        {paymentState === PaymentState.PROCESSING && 'Waiting...'}
        {paymentState === PaymentState.FAILED && 'Cancel payment'}
        {paymentState === PaymentState.SUCCESSFUL && 'To order status'}
      </UnigineButton>
    </Dialog>
  );
};

export default OrderPaymentDialog;
