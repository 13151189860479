import './AddonStatus.scss';

import { JSX } from 'react';
import { tooltips } from 'app/utils/constants/contentConstants';
import UnigineTooltip from 'app/components/Tooltip/Tooltip';

interface IAddonStatusProps {
  status: string;
}

const AddonStatus = ({ status }: IAddonStatusProps): JSX.Element => {
  let component = <div className={`add-on-status add-on-status--${status}`}>{status}</div>;

  if (tooltips.addonStatuses[status]) {
    component = <UnigineTooltip title={tooltips.addonStatuses[status]}>{component}</UnigineTooltip>;
  }

  return component;
};

export default AddonStatus;
