import {
  createSlice,
  createAsyncThunk,
  isAnyOf,
  CaseReducer,
  PayloadAction,
} from '@reduxjs/toolkit';
import axiosInstance from 'app/auth/axiosInstance';

import { IPublishedAddonGeneralInfo } from 'app/interfaces/addons/IAddon';
import IError from 'app/interfaces/IError';
import { RootState } from 'app/store';

interface IModerationAddonGeneralInfo extends IPublishedAddonGeneralInfo {
  publisher: {
    id: string;
    name: string;
    email: string;
  };
}

interface IPublishedAddonSliceState {
  addonInfo: IModerationAddonGeneralInfo | null;
  error: IError | null;
}

interface IGetAddonInfoParams {
  addonId: string;
}

interface ItoggleAddonArchiveStatusParams extends IGetAddonInfoParams {
  isArchived: boolean;
}

interface ItoggleAddonBannedStatusParams extends IGetAddonInfoParams {
  isBanned: boolean;
}

export const getAddonInfo = createAsyncThunk<
  IModerationAddonGeneralInfo,
  IGetAddonInfoParams,
  { rejectValue: IError }
>('adminPortalSection/getAddonInfo', async ({ addonId }, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      method: 'get',
      url: `/admin-panel/addons/${addonId}`,
    });

    const addonData = await response.data;

    return addonData;
  } catch (err: any) {
    if (err.response) {
      return rejectWithValue({ data: err.response.data, status: err.response.status });
    }

    return rejectWithValue({
      data: { message: 'Something went wrong while fetching addon info' },
      status: 500,
    });
  }
});

export const toggleAddonArchiveStatus = createAsyncThunk<
  IModerationAddonGeneralInfo,
  ItoggleAddonArchiveStatusParams,
  { rejectValue: IError }
>(
  'adminPortalSection/toggleAddonArchiveStatus',
  async ({ addonId, isArchived }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance({
        method: 'put',
        url: `/admin-panel/addons/${addonId}`,
        data: {
          is_archived: isArchived,
        },
      });

      const addonData = await response.data;

      return addonData;
    } catch (err: any) {
      if (err.response) {
        return rejectWithValue({ data: err.response.data, status: err.response.status });
      }

      return rejectWithValue({
        data: { message: 'Something went wrong while updating addon info' },
        status: 500,
      });
    }
  }
);

export const toggleAddonBanStatus = createAsyncThunk<
  IModerationAddonGeneralInfo,
  ItoggleAddonBannedStatusParams,
  { rejectValue: IError }
>('adminPortalSection/toggleAddonBanStatus', async ({ addonId, isBanned }, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      method: 'put',
      url: `/admin-panel/addons/${addonId}`,
      data: {
        is_banned: isBanned,
      },
    });

    const addonData = await response.data;

    return addonData;
  } catch (err: any) {
    if (err.response) {
      return rejectWithValue({ data: err.response.data, status: err.response.status });
    }

    return rejectWithValue({
      data: { message: 'Something went wrong while updating addon info' },
      status: 500,
    });
  }
});

export const getAdminPackageDownloadLink = createAsyncThunk<
  string,
  { packageId: string },
  { rejectValue: IError }
>('addonSection/getAdminPackageDownloadLink', async ({ packageId }, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      method: 'get',
      url: `/admin-panel/packages/${packageId}/download-link`,
    });
    const addonPackageLink = await response.data;
    return addonPackageLink;
  } catch (err: any) {
    if (err.response) {
      return rejectWithValue({ data: err.response.data, status: err.response.status });
    }

    return rejectWithValue({
      data: { message: 'Something went wrong to get admin package download link' },
      status: 500,
    });
  }
});

const updateAddonInfo: CaseReducer<
  IPublishedAddonSliceState,
  PayloadAction<IModerationAddonGeneralInfo>
> = (state, action) => {
  state.addonInfo = action.payload;
  state.error = null;
};

const updateAddonError: CaseReducer<
  IPublishedAddonSliceState,
  PayloadAction<IError | undefined>
> = (state, action) => {
  state.addonInfo = null;
  state.error = action.payload || { status: 500, data: { message: 'Something went wrong.' } };
};

const initialState: IPublishedAddonSliceState = {
  addonInfo: null,
  error: null,
};

const addonSlice = createSlice({
  name: 'adminPortalSection',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        isAnyOf(
          getAddonInfo.fulfilled,
          toggleAddonBanStatus.fulfilled,
          toggleAddonArchiveStatus.fulfilled
        ),
        updateAddonInfo
      )
      .addMatcher(
        isAnyOf(
          getAddonInfo.rejected,
          toggleAddonBanStatus.rejected,
          toggleAddonArchiveStatus.rejected
        ),
        updateAddonError
      );
  },
});

export const addonSelector = ({
  adminPortalSection,
}: RootState): IModerationAddonGeneralInfo | null => adminPortalSection.addon.addonInfo;

export const addonErrorSelector = ({ adminPortalSection }: RootState): IError | null =>
  adminPortalSection.addon.error;

export default addonSlice.reducer;
