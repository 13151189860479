import './AddonDescription.scss';

import { FC } from 'react';
import { Link } from 'react-router-dom';

import DataList from 'app/components/DataList/DataList';

import { IAddonTabProps } from 'app/main/sections/Addon/tabs/IAddonTabProps';

const AddonDescription: FC<IAddonTabProps> = ({ addonVersion }) => {
  if (!addonVersion?.description) {
    return null;
  }

  const { description, tags } = addonVersion;

  return (
    <div className="add-on-description">
      <DataList className="add-on-description__datalist">
        <DataList.Item label="Version">
          <span className="add-on-description__version">{addonVersion.versionNumber}</span>
        </DataList.Item>

        <DataList.Item label="Supported SDK">
          {addonVersion.sdkVersions?.map((v) => v.value).join(', ')}
        </DataList.Item>

        <DataList.Item label="Categories">
          {addonVersion.categories.map((c) => c.name).join(', ')}
        </DataList.Item>

        {description && (
          <DataList.Item label="Description">
            <div
              className="add-on-description__text"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </DataList.Item>
        )}
      </DataList>

      {tags?.length > 0 && (
        <div className="add-on-description__tags-container">
          <h3 className="add-on-description__tags-title">Tags</h3>

          <ul className="add-on-description__tags-list">
            {tags.map((tag) => {
              return (
                <li className="add-on-description__tags-item" key={tag.id}>
                  <Link className="tag" to={`/all?tags%5B%5D=${tag.value}`}>
                    {tag.canonicalName}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default AddonDescription;
