import './ReviewReplyForm.scss';

import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Close as CloseIcon } from '@mui/icons-material';
import { CircularProgress, Dialog, DialogContent, IconButton, TextField } from '@mui/material';

import ReviewDashboardCard from 'app/components/ReviewDashboardCard/ReviewDashboardCard';
import UnigineButton from 'app/components/UnigineButton/UnigineButton';

import { IPublisherReview } from 'app/interfaces/reviews/IReview';
import { formFieldErrors } from 'app/utils/constants/contentConstants';
import { DEFAULT_REVIEW_REPLY_LENGTH, regExps } from 'app/configs/appConfig';

interface IReviewReplyFormProps {
  open?: boolean;
  isWaiting?: boolean;
  review: IPublisherReview;
  defaultValue?: string;
  onSubmit: (data: IReviewReplyFormValues) => void;

  onCancel: () => void;
}

interface IReviewReplyFormValues {
  text: string;
}

const ReviewReplyForm: FC<IReviewReplyFormProps> = ({
  open = false,
  isWaiting = false,
  review,
  defaultValue,
  onSubmit,
  onCancel,
}) => {
  const { control, watch, handleSubmit } = useForm<IReviewReplyFormValues>({
    mode: 'onChange',
    defaultValues: {
      text: defaultValue || '',
    },
  });

  const replyText = watch('text');

  const handleClose = (): void => {
    if (isWaiting) {
      return undefined;
    }
    return onCancel();
  };

  return (
    <Dialog open={open} fullWidth maxWidth="md" onClose={onCancel} className="dialog">
      <DialogContent className="dialog__content review-reply-form">
        <h2 className="review-reply-form__title">
          {defaultValue ? 'Edit reply on review' : 'Reply to review'}
        </h2>

        {review && <ReviewDashboardCard reviewData={review} />}

        <form onSubmit={handleSubmit(onSubmit)} className="review-reply-form__form">
          <div className="review-reply-form__label">
            Reply text:
            <Controller
              name="text"
              control={control}
              rules={{
                maxLength: {
                  value: DEFAULT_REVIEW_REPLY_LENGTH,
                  message: formFieldErrors.reviewReplyLength,
                },
                pattern: {
                  value: regExps.review,
                  message: formFieldErrors.richTextCharacters,
                },
              }}
              render={({ field: { onChange, value }, fieldState: { error: fieldError } }) => (
                <TextField
                  className="review-reply-form__field unigine-form-field"
                  name="text"
                  value={value}
                  onChange={onChange}
                  size="small"
                  fullWidth
                  multiline
                  minRows={4}
                  maxRows={10}
                  disabled={isWaiting}
                  error={!!fieldError}
                  helperText={fieldError?.message}
                />
              )}
            />
          </div>

          <UnigineButton
            className="review-reply-form__submit-btn"
            type="submit"
            disabled={!replyText || isWaiting}
          >
            {isWaiting ? <CircularProgress color="inherit" size={18} /> : 'Submit reply'}
          </UnigineButton>
        </form>

        <IconButton aria-label="close" className="dialog__close-button" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogContent>
    </Dialog>
  );
};

export default ReviewReplyForm;
