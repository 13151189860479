import { createSlice, createAsyncThunk, createEntityAdapter, EntityState } from '@reduxjs/toolkit';

import getPaginationParams from 'app/utils/helpers/getPaginationParams';
import adaptError from 'app/utils/helpers/adaptError';
import { defaultError } from 'app/configs/appConfig';
import axiosInstance from 'app/auth/axiosInstance';
import {
  ILikeReviewData,
  IPostReviewData,
  IReview,
  IReviewData,
  IReportData,
} from 'app/interfaces/reviews/IReview';
import { RootState } from 'app/store';
import IError from 'app/interfaces/IError';

interface IReviewsData {
  comments: IReviewData[];
  total: number;
  addonId: string | null;
  perPage: number;
}

interface IReviewsParams {
  addonId: string | null;
  page?: number;
  sort?: string | null;
  direction?: string | null;
  perPage: number;
}

interface IPostReviewParams {
  versionId: string;
  rating: number;
  description?: string;
  details?: string;
}

interface ILikeReviewParams {
  commentId: string;
}

interface IReviewsSliceState {
  addonId: string | null;
  reviews: EntityState<IReview>;
  totalPages: number;
  totalReviews: number;
  userReview: IReview | null;
  userReviewError: IError | null;
  reviewsError: IError | null;
  postError: IError | null;
}

const adaptReview = (reviewData: IReviewData): IReview | null => {
  if (!reviewData) {
    return null;
  }

  let status: IReviewData['status'];

  // isChecked may not be present at all,
  // in which case neither condition is met
  // so status stays undefined
  if (reviewData.isChecked === true) {
    status = 'published';
  }
  if (reviewData.isChecked === false) {
    status = 'being checked';
  }

  status = reviewData.isBanned ? 'banned' : status;

  return {
    id: reviewData.id,
    ratingId: reviewData.rating.id,
    status: reviewData.status || status,
    user: {
      avatar: `${process.env.REACT_APP_STORE_DEVELOPER_URL}/.avatars/${reviewData.rating.user.userGlobalId}.png`,
      name: `${reviewData.rating.user.firstname} ${reviewData.rating.user.lastname}`,
    },

    createDate: reviewData.rating.createDate,
    updateDate: reviewData.rating.updateDate,

    addonVersion: {
      id: reviewData.rating.addonVersion.id,
      number: reviewData.rating.addonVersion.versionNumber,
    },

    rating: reviewData.rating.rating,
    isLiked: reviewData.hasUserHelpful,
    isReported: reviewData.hasReported || false,
    helpfuls: reviewData.helpfuls,
    helpfulCount: reviewData.helpfulCount,

    comment: {
      title: reviewData.header,
      body: reviewData.text,
    },

    reply: reviewData.filteredChildren || [],
  };
};

const adaptPostReview = ({ rating }: IPostReviewData): IReview | null => {
  return {
    id: rating.comment?.id || '',
    ratingId: rating.id,

    status: 'being checked',

    user: {
      avatar: `${process.env.REACT_APP_STORE_DEVELOPER_URL}/.avatars/${rating.user.userGlobalId}.png`,
      name: `${rating.user.firstname} ${rating.user.lastname}`,
    },

    createDate: rating.createDate,
    updateDate: rating.updateDate,

    addonVersion: {
      id: rating.addonVersion.id,
      number: rating.addonVersion.versionNumber,
    },

    rating: rating.rating,
    isLiked: rating.hasUserHelpful,
    isReported: rating.hasReported || false,
    helpfulCount: 0,
    helpfuls: [],

    comment: {
      title: rating.comment?.header || '',
      body: rating.comment?.text || '',
    },

    reply: [],
  };
};

export const getAddonReviews = createAsyncThunk<
  IReviewsData,
  IReviewsParams,
  { rejectValue: IError }
>(
  'addonSection/getAddonReviews',
  async ({ addonId, page = 1, sort, direction, perPage }, { rejectWithValue }) => {
    const params = getPaginationParams({
      page,
      perPage,
      sort,
      direction,
    });

    try {
      const response = await axiosInstance({
        url: `addons/${addonId}/comments`,
        params,
      });

      const { userComment, comments, total } = response.data;

      return {
        userComment,
        comments,
        total,
        addonId,
        perPage,
      };
    } catch (err: any) {
      return rejectWithValue(adaptError(err));
    }
  }
);

export const getUserReview = createAsyncThunk<
  IReviewData,
  { addonId: string },
  { rejectValue: IError }
>('addonSection/getUserReview', async ({ addonId }, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      url: `addons/${addonId}/user-comment`,
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(adaptError(err));
  }
});

export const postReview = createAsyncThunk<
  IPostReviewData,
  IPostReviewParams,
  { rejectValue: IError }
>(
  'addonSection/postReview',
  async ({ versionId, rating, description, details }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance({
        method: 'post',
        url: '/ratings',
        data: {
          addon_version_id: versionId,
          rating,
          header: description,
          text: details,
        },
      });

      return response.data;
    } catch (err: any) {
      if (err?.response?.status === 403) {
        return rejectWithValue({
          status: 403,
          data: { message: "You can't leave a comment on this addon." },
        });
      }
      return rejectWithValue(adaptError(err));
    }
  }
);

export const updateReview = createAsyncThunk<
  IPostReviewData,
  IPostReviewParams & { ratingId: string },
  { rejectValue: IError }
>(
  'addonSection/updateReview',
  async ({ ratingId, versionId, rating, description, details }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance({
        method: 'put',
        url: `/ratings/${ratingId}`,
        data: {
          addon_version_id: versionId,
          rating,
          header: description,
          text: details,
        },
      });

      return response.data;
    } catch (err: any) {
      return rejectWithValue(adaptError(err));
    }
  }
);

export const likeReview = createAsyncThunk<
  ILikeReviewData,
  ILikeReviewParams,
  { rejectValue: IError }
>('addonSection/likeReview', async ({ commentId }, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      method: 'post',
      url: '/helpful',
      data: {
        commentId,
      },
    });

    return response.data;
  } catch (err: any) {
    return rejectWithValue(adaptError(err));
  }
});

export const reportReview = createAsyncThunk<
  IReportData,
  { commentId: string },
  { rejectValue: IError }
>('addonSection/reportReview', async ({ commentId }, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      method: 'post',
      url: 'review-reports',
      data: { comment_id: commentId },
    });

    return { ...response.data, commentId };
  } catch (err: any) {
    return rejectWithValue(adaptError(err));
  }
});

const reviewsAdapter = createEntityAdapter<IReview>({});
const initialState: IReviewsSliceState = {
  addonId: null,
  reviews: reviewsAdapter.getInitialState({}),
  totalPages: 1,
  totalReviews: 0,
  userReview: null,
  userReviewError: null,
  reviewsError: null,
  postError: null,
};
const reviewsSlice = createSlice({
  name: 'addonSection',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAddonReviews.fulfilled, (state, action) => {
        const { comments, total, addonId, perPage } = action.payload;
        reviewsAdapter.setAll(
          state.reviews,
          comments.flatMap((item) => {
            const adapted = adaptReview(item);
            return adapted === null ? [] : [adapted];
          })
        );

        const totalPages = Math.ceil(total / perPage);
        state.totalPages = totalPages;
        state.totalReviews = total;
        state.addonId = addonId;
        state.reviewsError = null;
      })
      .addCase(getAddonReviews.rejected, (state, action) => {
        reviewsAdapter.removeAll(state.reviews);
        state.totalPages = 1;
        state.totalReviews = 0;
        state.reviewsError = action.payload || null;
      })
      .addCase(getUserReview.fulfilled, (state, action) => {
        state.userReview = adaptReview(action.payload);
        state.userReviewError = null;
      })
      .addCase(getUserReview.rejected, (state, action) => {
        state.userReview = null;
        state.userReviewError = action.payload || null;
      })
      .addCase(postReview.fulfilled, (state, action) => {
        state.postError = null;
        state.userReview = adaptPostReview(action.payload);
      })
      .addCase(postReview.rejected, (state, action) => {
        state.postError = action.payload || defaultError;
      })
      .addCase(updateReview.fulfilled, (state, action) => {
        state.postError = null;
        state.userReview = adaptPostReview(action.payload);
      })
      .addCase(updateReview.rejected, (state, action) => {
        state.postError = action.payload || defaultError;
      })
      .addCase(likeReview.fulfilled, (state, action) => {
        const { comment } = action.payload;
        reviewsAdapter.updateOne(state.reviews, {
          id: comment.id,
          changes: {
            helpfulCount: comment.helpfulCount,
            isLiked: true,
          },
        });
      })
      .addCase(reportReview.fulfilled, (state, action) => {
        const { commentId } = action.payload;
        reviewsAdapter.updateOne(state.reviews, { id: commentId, changes: { isReported: true } });
      });
  },
});

export const { selectAll: selectReviews, selectById: selectReviewById } =
  reviewsAdapter.getSelectors((state: RootState) => state.addonSection.addonReviews.reviews);

export const addonUserReviewSelector = ({ addonSection }: RootState): IReview | null =>
  addonSection.addonReviews.userReview;
export const addonUserReviewErrorSelector = ({ addonSection }: RootState): IError | null =>
  addonSection.addonReviews.userReviewError;
export const addonReviewsErrorSelector = ({ addonSection }: RootState): IError | null =>
  addonSection.addonReviews.reviewsError;
export const postReviewErrorSelector = ({ addonSection }: RootState): IError | null =>
  addonSection.addonReviews.postError;
export const totalPagesSelector = ({ addonSection }: RootState): number =>
  addonSection.addonReviews.totalPages;
export const totalReviewsSelector = ({ addonSection }: RootState): number =>
  addonSection.addonReviews.totalReviews;
export const reviewsAddonIdSelector = ({ addonSection }: RootState): string | null =>
  addonSection.addonReviews.addonId;

export default reviewsSlice.reducer;
