import IPictureFile from 'app/interfaces/addons/IPictureFile';
import IVideoFile from 'app/interfaces/addons/IVideoFile';
import IAddonMediaFile from 'app/interfaces/addons/IAddonMediaFile';

interface CombineMediaParams {
  pictureFiles: IPictureFile[];
  videoFiles: IVideoFile[];
}

const combineMedia = ({ pictureFiles, videoFiles }: CombineMediaParams): IAddonMediaFile[] => {
  const imageMedia = pictureFiles.map(({ id }) => ({
    id,
    type: 'picture',
    url: `${process.env.REACT_APP_STORE_API_URL}/media-files/${id}`,
    previewUrl: `${process.env.REACT_APP_STORE_API_URL}/media-files/${id}?isPreview=1`,
  }));

  const videoMedia = videoFiles.map(({ id, data }) => ({
    id,
    data,
    type: 'video',
  }));

  return [...videoMedia, ...imageMedia];
};

export default combineMedia;
