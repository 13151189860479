import clsx from 'clsx';
import './DataList.scss';

import { FC, ReactNode, ReactElement } from 'react';

type DataListChild = ReactElement<DataListItemProps, typeof Item> | '' | boolean | null | undefined;

interface DataListProps {
  children: DataListChild | DataListChild[];
  className?: string;
}

interface DataListItemProps {
  label: string;
  children: ReactNode;
}

const DataList: FC<DataListProps> & { Item: typeof Item } = ({ children, className }) => (
  <dl className={clsx('data-list', className)}>{children}</dl>
);

const Item: FC<DataListItemProps> = ({ label, children }) => (
  <>
    <dt className="data-list-item__label">{label}</dt>
    <dd className="data-list-item__content">{children}</dd>
  </>
);

DataList.Item = Item;

export default DataList;
