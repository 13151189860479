import { createSlice, createAsyncThunk, EntityState, createEntityAdapter } from '@reduxjs/toolkit';
import axiosInstance from 'app/auth/axiosInstance';
import { defaultError } from 'app/configs/appConfig';
import IError from 'app/interfaces/IError';
import {
  IPublisherReviewData,
  IPublisherReview,
  IReportData,
  IReviewReply,
} from 'app/interfaces/reviews/IReview';
import { RootState } from 'app/store';
import adaptError from 'app/utils/helpers/adaptError';
import getPaginationParams, { IRowPaginationParams } from 'app/utils/helpers/getPaginationParams';

interface IPublisherReviewsData {
  comments: IPublisherReviewData[];
  total: number;
  perPage: number;
}
interface IPublisherReviewsSliceState {
  reviews: EntityState<IPublisherReview>;
  totalReviews: number;
  totalPages: number;
  error: IError | null;
}

const adaptReview = (reviewData: IPublisherReviewData): IPublisherReview => ({
  id: reviewData.id,

  addonVersion: {
    id: reviewData.rating.addonVersion.id,
    number: reviewData.rating.addonVersion.versionNumber,
  },

  addon: {
    title: reviewData.rating.addonVersion.addon.title,
    image: `${process.env.REACT_APP_STORE_API_URL}/media-files/${reviewData.rating.addonVersion.coverFile.id}`,
  },

  author: {
    name: `${reviewData.rating.user.firstname} ${reviewData.rating.user.lastname}`,
  },

  rating: reviewData.rating.rating,
  comment: {
    title: reviewData.header,
    body: reviewData.text,
  },

  isReported: reviewData.isReported,

  createDate: reviewData.createDate,
  updateDate: reviewData.updateDate,
  reply: reviewData.filteredChildren || [],
});

const reviewsAdapter = createEntityAdapter<IPublisherReview>({});

export const getReviewsData = createAsyncThunk<
  IPublisherReviewsData,
  IRowPaginationParams,
  { rejectValue: IError }
>('publisherPanelSection/getReviewsData', async (props, { rejectWithValue }) => {
  const params = getPaginationParams(props);
  try {
    const response = await axiosInstance({
      method: 'get',
      url: '/publisher-panel/comments',
      params,
    });

    return { ...response.data, perPage: props.perPage };
  } catch (err: any) {
    if (err.response?.data?.context?.message) {
      return rejectWithValue({ data: err.response.data, status: err.response.status });
    }

    return rejectWithValue(defaultError);
  }
});

export const postReply = createAsyncThunk<
  IReviewReply & { reviewId: string },
  { reviewId: string; text: string },
  { rejectValue: IError }
>('publisherPanelSection/postReply', async ({ reviewId, text }, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      method: 'post',
      url: '/publisher-panel/comments',
      data: {
        comment_id: reviewId,
        text,
      },
    });

    const replyData = response.data;

    return { ...replyData, reviewId };
  } catch (err: any) {
    return rejectWithValue(adaptError(err));
  }
});

export const updateReply = createAsyncThunk<
  IReviewReply & { reviewId: string },
  { replyId: string; reviewId: string; text: string },
  { rejectValue: IError }
>('publisherPanelSection/updateReply', async ({ replyId, reviewId, text }, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      method: 'put',
      url: `/publisher-panel/comments/${replyId}`,
      data: {
        comment_id: reviewId,
        text,
      },
    });

    const replyData = response.data;

    return { ...replyData, reviewId };
  } catch (err: any) {
    return rejectWithValue(adaptError(err));
  }
});

export const reportReview = createAsyncThunk<
  IReportData,
  { commentId: string },
  { rejectValue: IError }
>('publisherPanelSection/reportReview', async ({ commentId }, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      method: 'post',
      url: 'review-reports',
      data: { comment_id: commentId },
    });

    return { ...response.data, commentId };
  } catch (err: any) {
    if (err.response) {
      return rejectWithValue({ data: err.response?.data, status: err.response?.status });
    }
    return rejectWithValue(defaultError);
  }
});

const initialState: IPublisherReviewsSliceState = {
  reviews: reviewsAdapter.getInitialState(),
  totalReviews: 0,
  totalPages: 1,
  error: null,
};

const reviewsSlice = createSlice({
  name: 'publisherPanelSection',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getReviewsData.fulfilled, (state, action) => {
        const { comments, total, perPage } = action.payload;
        reviewsAdapter.setAll(state.reviews, comments.map(adaptReview));
        state.totalReviews = total;
        state.totalPages = Math.ceil(total / perPage);

        state.error = null;
      })
      .addCase(getReviewsData.rejected, (state, action) => {
        state.error = action.payload || defaultError;
      })
      .addCase(postReply.fulfilled, (state, action) => {
        const { reviewId, ...reply } = action.payload;
        reviewsAdapter.updateOne(state.reviews, {
          id: reviewId,
          changes: { reply: [reply] },
        });
      })
      .addCase(updateReply.fulfilled, (state, action) => {
        const { reviewId, ...reply } = action.payload;
        reviewsAdapter.updateOne(state.reviews, {
          id: reviewId,
          changes: { reply: [reply] },
        });
      })
      .addCase(reportReview.fulfilled, (state, action) => {
        const { commentId } = action.payload;
        reviewsAdapter.updateOne(state.reviews, { id: commentId, changes: { isReported: true } });
      });
  },
});

export const { selectAll: reviewsInfoSelector } = reviewsAdapter.getSelectors(
  ({ publisherPanelSection }: RootState) => publisherPanelSection.reviewsInfo.reviews
);

export const reviewsTotalPagesSelector = ({ publisherPanelSection }: RootState): number =>
  publisherPanelSection.reviewsInfo.totalPages;

export const reviewsErrorSelector = ({ publisherPanelSection }: RootState): IError | null =>
  publisherPanelSection.reviewsInfo.error;

export default reviewsSlice.reducer;
