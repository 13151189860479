import './PublisherPanel.scss';

import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useMatch, useNavigate } from 'react-router-dom';
import clsx from 'clsx';

import Sidebar from 'app/components/Sidebar/Sidebar';

import usePermissions from 'app/hooks/usePermissions';
import useLoginOnGuests from 'app/hooks/useLoginOnGuests';

import { sidebarSelector } from 'app/store/unigine/sidebarSlice';
import publisherPanelSidebarData from 'app/main/sections/PublisherPanel/publisherPanelSidebarData';

const PublisherPanel: FC = () => {
  const navigate = useNavigate();
  const sidebarData = useSelector(sidebarSelector);
  const { notPublisherPanelUser, publisherPanelUser } = usePermissions();

  const isAddonPage = useMatch(`/publisherPanel/add-ons/:addonId/*`);

  const isFixedSidebar = sidebarData === 'true' || sidebarData === true || sidebarData === null;

  useLoginOnGuests();

  useEffect(() => {
    if (notPublisherPanelUser && !publisherPanelUser) {
      navigate('/all');
    }
  }, [navigate, notPublisherPanelUser, publisherPanelUser]);

  return (
    <div className="publisher-panel__wrapper">
      <Sidebar itemsData={publisherPanelSidebarData} />
      <div
        className={clsx('main--publisher-panel publisher-table main--filters', {
          'main--publisher-add-on': isAddonPage,
          'main--fixed-sidebar': isFixedSidebar,
        })}
      >
        <Outlet />
      </div>
    </div>
  );
};

export default PublisherPanel;
