import { createSlice, createAsyncThunk, createEntityAdapter, EntityState } from '@reduxjs/toolkit';
import axiosInstance from 'app/auth/axiosInstance';
import IError from 'app/interfaces/IError';
import IOrderAddonVersion from 'app/interfaces/orders/IOrderAddonVersion';
import { RootState } from 'app/store';
import getPaginationParams, { IRowPaginationParams } from 'app/utils/helpers/getPaginationParams';

const orderAddonVersionsAdapter = createEntityAdapter<IOrderAddonVersion>();

interface IOrderAddonVersionsSliceState {
  totalPages: number;
  orderAddonVersions: EntityState<IOrderAddonVersion>;
  errors: IError | null;
}

const initialState: IOrderAddonVersionsSliceState = {
  orderAddonVersions: orderAddonVersionsAdapter.getInitialState(),
  totalPages: 1,
  errors: null,
};

interface IOrderAddonVersionsList {
  orderAddonVersions: IOrderAddonVersion[];
  total: number;
  perPage: number;
}

export const getOrderAddonVersions = createAsyncThunk<
  IOrderAddonVersionsList,
  IRowPaginationParams,
  { rejectValue: IError }
>('publisherPanelSection/getOrderAddonVersions', async (props, { rejectWithValue }) => {
  try {
    const params = getPaginationParams(props);

    const response = await axiosInstance({
      url: '/publisher-panel/order-addon-versions',
      method: 'get',
      params,
    });

    return { ...response.data, perPage: props.perPage };
  } catch (err: any) {
    if (err.response) {
      return rejectWithValue({ data: err.response.data, status: err.response.status });
    }

    return rejectWithValue({ data: { message: 'Something went wrong' }, status: 500 });
  }
});

const orderAddonVersionsSlice = createSlice({
  name: 'publisherPanelSection',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOrderAddonVersions.fulfilled, (state, action) => {
        const { orderAddonVersions, total, perPage } = action.payload;
        const totalPages = Math.ceil(total / perPage);

        orderAddonVersionsAdapter.setAll(state.orderAddonVersions, orderAddonVersions);
        state.totalPages = totalPages;
        state.errors = null;
      })
      .addCase(getOrderAddonVersions.rejected, (state, action) => {
        orderAddonVersionsAdapter.removeAll(state.orderAddonVersions);
        state.totalPages = 1;
        state.errors = action.payload ?? {
          data: { message: 'Something went wrong' },
          status: 500,
        };
      });
  },
});

export const { selectAll: orderAddonVersionsSelector } = orderAddonVersionsAdapter.getSelectors(
  ({ publisherPanelSection }: RootState) =>
    publisherPanelSection.orderAddonVersionsInfo.orderAddonVersions
);
export const orderAddonVersionsTotalPagesSelector = ({
  publisherPanelSection,
}: RootState): number => publisherPanelSection.orderAddonVersionsInfo.totalPages;
export const orderAddonVersionsErrorsSelector = ({
  publisherPanelSection,
}: RootState): IError | null => publisherPanelSection.orderAddonVersionsInfo.errors;

export default orderAddonVersionsSlice.reducer;
