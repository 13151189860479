import './AddonsList.scss';

import { FC } from 'react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/bundle';

import clsx from 'clsx';

import { CircularProgress } from '@mui/material';

import AddonCard from 'app/components/AddonCard/AddonCard';

import { IAddonInList } from 'app/interfaces/addons/IAddon';

import { BIG_SLIDER_ADDONS_COUNT, SMALL_SLIDER_ADDONS_COUNT } from 'app/configs/appConfig';

interface IAddonsList {
  children: IAddonInList[];
  isLoading: boolean;
  noAddonText: string;
  sliderSize?: 'small' | 'big';
  isPublisherPage?: boolean;
}

const bigSliderBreakpoints = {
  320: {
    slidesPerView: 1,
    spaceBetween: 30,
  },
  768: {
    slidesPerView: 2,
    spaceBetween: 30,
  },
  1024: {
    slidesPerView: 3,
    spaceBetween: 30,
  },
  1280: {
    slidesPerView: 4,
    spaceBetween: 40,
  },
  1340: {
    slidesPerView: 4,
    spaceBetween: 70,
  },
  1366: {
    slidesPerView: 4,
    spaceBetween: 75,
  },
  1530: {
    slidesPerView: 5,
    spaceBetween: 30,
  },
};

const smallSliderBreakpoints = {
  320: {
    slidesPerView: 1,
    spaceBetween: 30,
  },
  1025: {
    slidesPerView: 2,
    spaceBetween: 30,
  },
  1280: {
    slidesPerView: 3,
    spaceBetween: 30,
  },
};

const AddonsList: FC<IAddonsList> = ({
  children,
  isLoading,
  noAddonText,
  sliderSize,
  isPublisherPage = false,
}) => {
  const isBigSlider = sliderSize === 'big' && children.length > BIG_SLIDER_ADDONS_COUNT;
  const isSmallSlider = sliderSize === 'small' && children.length > SMALL_SLIDER_ADDONS_COUNT;

  return (
    <div
      className={clsx('add-on-list__container', {
        'publisher-add-on-list__container': isPublisherPage && sliderSize === 'small',
      })}
    >
      <div className="add-on-list__list-container">
        {isLoading && (
          <div className="add-on-list__loading-container">
            <CircularProgress color="inherit" className="add-on-list__loading" />
          </div>
        )}

        {!isLoading && !isBigSlider && !isSmallSlider && (
          <ul
            className={clsx('add-on-list__list', {
              'publisher-add-on-list__list': isPublisherPage && sliderSize === 'small',
            })}
          >
            {children.length === 0 && <div className="add-on-list__no-add-ons">{noAddonText}</div>}
            {children.map((addonVersion, i) => {
              const addonRating = {
                ratingCount: addonVersion.addon.ratingCount,
                ratingSum: addonVersion.addon.ratingSum,
                reviewsCount: addonVersion.addon.reviewsCount,
                totalRating: addonVersion.addon.totalRating,
              };
              return (
                <li className="add-on-list__item" key={`${addonVersion.id}-${i}`}>
                  <AddonCard
                    id={addonVersion.addon.id}
                    title={addonVersion.addon.title}
                    coverFile={addonVersion.coverFile}
                    categories={addonVersion.categories}
                    publisher={addonVersion.addon.publisher}
                    price={addonVersion.addon.price}
                    discountedPrice={addonVersion.addon.discountedPrice}
                    isAddonFree={addonVersion.addon.isFree}
                    ownedStatus={addonVersion.addon.ownedStatus}
                    currentVersionId={addonVersion.id}
                    rating={addonRating}
                  />
                </li>
              );
            })}
          </ul>
        )}

        {!isLoading && (isBigSlider || isSmallSlider) && (
          <Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            slidesPerView={3}
            spaceBetween={30}
            breakpoints={isBigSlider ? bigSliderBreakpoints : smallSliderBreakpoints}
            navigation
            pagination={{ clickable: true }}
          >
            {children.map((addonVersion, i) => {
              const addonRating = {
                ratingCount: addonVersion.addon.ratingCount,
                ratingSum: addonVersion.addon.ratingSum,
                reviewsCount: addonVersion.addon.reviewsCount,
                totalRating: addonVersion.addon.totalRating,
              };
              return (
                <SwiperSlide
                  className="add-on-list__item add-on-list__item--slider"
                  key={`${addonVersion.id}-${i}`}
                >
                  <AddonCard
                    id={addonVersion.addon.id}
                    title={addonVersion.addon.title}
                    coverFile={addonVersion.coverFile}
                    categories={addonVersion.categories}
                    publisher={addonVersion.addon.publisher}
                    price={addonVersion.addon.price}
                    discountedPrice={addonVersion.addon.discountedPrice}
                    isAddonFree={addonVersion.addon.isFree}
                    ownedStatus={addonVersion.addon.ownedStatus}
                    currentVersionId={addonVersion.id}
                    rating={addonRating}
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
        )}
      </div>
    </div>
  );
};

export default AddonsList;
