import { FC } from 'react';
import { Dialog, DialogContent, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface IAddonDialogVideo {
  videoId: string;
  handleClose: () => void;
  isOpen: boolean;
}

const AddonDialogVideo: FC<IAddonDialogVideo> = ({ videoId, handleClose, isOpen }) => (
  <Dialog open={isOpen} fullWidth maxWidth="md" onClose={handleClose} className="dialog">
    <DialogContent className="dialog-media__content">
      <iframe
        width="900"
        height="506"
        src={`https://www.youtube.com/embed/${videoId}`}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />

      <IconButton aria-label="close" className="dialog__close-button" onClick={handleClose}>
        <CloseIcon />
      </IconButton>
    </DialogContent>
  </Dialog>
);

export default AddonDialogVideo;
