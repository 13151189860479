import React, { FC, useCallback, useEffect, useState } from 'react';

import AuthService from 'app/auth/AuthService';

import { authStates } from 'app/configs/appConfig';
import { logoutUser, submitAccessToken, submitRefreshToken } from 'app/auth/store/userSlice';
import LayoutLoading from 'app/components/Layout/LayoutLoading';
import useAppDispatch from 'app/hooks/useAppDispatch';

interface IAuth {
  children: React.ReactNode;
}

const Auth: FC<IAuth> = ({ children }) => {
  const [isWaitingAuthCheck, setIsWaitingAuthCheck] = useState(true);
  const dispatch = useAppDispatch();

  const jwtCheck = useCallback(async () => {
    const authState = AuthService.getAuthState();
    const refreshToken = AuthService.getRefreshToken();

    switch (authState) {
      case authStates.onAutoLogin:
        return dispatch(submitAccessToken());

      case authStates.onRefreshTokenLogin:
        return dispatch(submitRefreshToken({ refreshToken }));

      case authStates.onNoAccessToken:
        return dispatch(logoutUser({ isProcessing: false }));

      case authStates.onAutoLogout:
        return dispatch(logoutUser({ isProcessing: false }));

      default:
        return dispatch(logoutUser({ isProcessing: false }));
    }
  }, [dispatch]);

  useEffect(() => {
    jwtCheck()
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsWaitingAuthCheck(false);
      });
  }, [jwtCheck]);

  // children has to be wrapped in a fragment because it can be an array,
  // but eslint doesn't know that
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return isWaitingAuthCheck ? <LayoutLoading /> : <>{children}</>;
};

export default Auth;
