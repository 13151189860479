import './ErrorPage.scss';

import { FC } from 'react';
import { Link } from 'react-router-dom';

import { motion } from 'framer-motion';

import Typography from '@mui/material/Typography';

import StorefrontHeader from 'app/components/StorefrontHeader/StorefrontHeader';
import error500 from 'app/assets/error500.svg';
import error404 from 'app/assets/error404.svg';
import error403 from 'app/assets/error403.svg';

interface IErrorProps {
  text: string;
  status: number;
}

const ErrorPage: FC<IErrorProps> = ({ text, status }) => {
  const errorSelector = (errorCode: number): { image: string; text: string } => {
    switch (errorCode) {
      case 403:
        return { image: error403, text: 'Access Denied' };
      case 404:
        return { image: error404, text: 'Oops! Page Not Found' };
      default:
        return { image: error500, text: 'Internal Server error' };
    }
  };

  const error = errorSelector(status);

  return (
    <>
      <StorefrontHeader />
      <div className="main 404">
        <div className="content-container content-container--main content-container--404">
          <img src={error.image} width={528} height={354} alt="errorImage" />
          <motion.div
            initial={{ opacity: 0, scale: 0.6 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ delay: 0.1 }}
          >
            <Typography variant="h1" color="inherit" className="error404__title">
              {error.text}
            </Typography>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 40 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
          >
            {status === 403 ? (
              <Typography variant="h5" color="textSecondary" className="error404__text">
                Unfortunately, you don’t have the permissions required to access this page. Go back
                to
                <Link to="/all" className="text-link">
                  {' '}
                  Add-On Store
                </Link>
                , check out Support, or Log in.
              </Typography>
            ) : (
              ''
            )}
            {status === 404 ? (
              <Typography variant="h5" color="textSecondary" className="error404__text">
                Sorry, the page you are looking for doesn’t exist or has been moved.
              </Typography>
            ) : (
              ''
            )}
          </motion.div>

          <Link className="error404__link" to="/all">
            Return to homepage
          </Link>
        </div>
      </div>
    </>
  );
};

export default ErrorPage;
