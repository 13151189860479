import './RichTextEditor.scss';

import { forwardRef, useCallback, useState, JSX, HTMLAttributes } from 'react';
import { createEditor, Transforms, Editor, Element as SlateElement, Text, Range } from 'slate';
import {
  Editable,
  Slate,
  withReact,
  useSlate,
  RenderElementProps,
  RenderLeafProps,
} from 'slate-react';
import { withHistory } from 'slate-history';
import clsx from 'clsx';
import pipe from 'lodash/fp/pipe';

import {
  FormatBold,
  FormatItalic,
  FormatStrikethrough,
  FormatListBulleted,
  FormatListNumbered,
  Link,
} from '@mui/icons-material';

import InsertLinkPopup from 'app/components/RichTextEditor/elements/InsertLinkPopup/InsertLinkPopup';
import LinkElement from 'app/components/RichTextEditor/elements/LinkElement/LinkElement';

import deserialize from 'app/components/RichTextEditor/utils/deserialize';
import serialize from 'app/components/RichTextEditor/utils/serialize';

const withLinks = (editor: Editor): Editor => {
  const { isInline } = editor;
  editor.isInline = (element) => (element.type === 'link' ? true : isInline(element));
  return editor;
};

const createEditorWithPlugins = pipe(withReact, withHistory, withLinks);

interface IRichTextEditorProps extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> {
  initialHtml?: string;
  onChange: (value: string) => void;
  error: any;
}

const formatOptions = [
  { name: 'bold', label: 'Bold', isBlock: false, Icon: FormatBold },
  { name: 'italic', label: 'Italic', isBlock: false, Icon: FormatItalic },
  { name: 'strike', label: 'Strike-through', isBlock: false, Icon: FormatStrikethrough },
  { name: 'ul', label: 'Bullet list', isBlock: true, Icon: FormatListBulleted },
  { name: 'ol', label: 'Numbered list', isBlock: true, Icon: FormatListNumbered },
] as const;

const isListOption = (option: SlateElement['type']): option is 'ul' | 'ol' =>
  option === 'ul' || option === 'ol';

const toggleBlock = (editor: Editor, format: SlateElement['type']): void => {
  const isActive = isBlockActive(editor, format);
  const isList = isListOption(format);

  Transforms.unwrapNodes(editor, {
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && isListOption(n.type),
    split: true,
  });

  const newProperties = {
    type: format,
  };
  if (isList) {
    newProperties.type = 'li';
  }
  if (isActive) {
    newProperties.type = 'p';
  }

  Transforms.setNodes(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

const toggleMark = (editor: Editor, format: keyof Omit<Text, 'text'>): void => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const isBlockActive = (editor: Editor, format: string): boolean => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
    })
  );

  return !!match;
};

const isMarkActive = (editor: Editor, format: keyof Omit<Text, 'text'>): boolean => {
  const marks = Editor.marks(editor);
  return !!marks && !!marks[format];
};

const Element = ({ attributes, children, element }: RenderElementProps): JSX.Element => {
  switch (element.type) {
    case 'ul':
      return <ul {...attributes}>{children}</ul>;
    case 'ol':
      return <ol {...attributes}>{children}</ol>;
    case 'li':
      return <li {...attributes}>{children}</li>;
    case 'link':
      return (
        <LinkElement attributes={attributes} element={element}>
          {children}
        </LinkElement>
      );
    default:
      return <p {...attributes}>{children}</p>;
  }
};

const Leaf = ({ attributes, children, leaf }: RenderLeafProps): JSX.Element => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.strike) {
    children = <span className="richtext__strike">{children}</span>;
  }

  return <span {...attributes}>{children}</span>;
};

const Toolbar = (): JSX.Element => {
  const editor = useSlate();
  const { selection } = editor;

  const [open, setOpen] = useState<boolean>(false);

  const handleOpenLinkPopover = (): void => {
    setOpen(true);
  };

  const handleCloseLinkPopover = (): void => {
    setOpen(false);
    Transforms.deselect(editor);
  };

  const isLinkButtonDisabled = selection === null || Range.isCollapsed(selection);

  return (
    <div className="richtext__controls">
      {formatOptions.map(({ name, label, isBlock, Icon }) => {
        const isActive = isBlock ? isBlockActive(editor, name) : isMarkActive(editor, name);

        return (
          <button
            className={clsx('richtext__btn', { 'richtext__btn--active': isActive })}
            key={name}
            type="button"
            aria-label={label}
            onMouseDown={(evt) => {
              evt.preventDefault();
              return isBlock ? toggleBlock(editor, name) : toggleMark(editor, name);
            }}
          >
            <Icon fontSize="small" />
          </button>
        );
      })}
      <button
        className={clsx('richtext__btn', { 'richtext__btn--disabled': isLinkButtonDisabled })}
        key="link"
        type="button"
        aria-label="Link"
        disabled={isLinkButtonDisabled}
        onClick={() => handleOpenLinkPopover()}
      >
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link fontSize="small" />
      </button>
      {selection && (
        <InsertLinkPopup open={open} onClose={handleCloseLinkPopover} editor={editor} />
      )}
    </div>
  );
};

const RichTextEditor = forwardRef<HTMLDivElement, IRichTextEditorProps>(
  ({ initialHtml = '', onChange, error, ...restProps }, ref) => {
    // deserialize the html once on component mount to use as initial value
    const [initialValue] = useState(() => deserialize(initialHtml));
    const [editor] = useState(() => createEditorWithPlugins(createEditor()));

    const renderElement = useCallback((props: RenderElementProps) => <Element {...props} />, []);
    const renderLeaf = useCallback((props: RenderLeafProps) => <Leaf {...props} />, []);

    return (
      <Slate editor={editor} value={initialValue} onChange={() => onChange(serialize(editor))}>
        <div ref={ref} className={clsx('richtext', { 'richtext--error': !!error })} {...restProps}>
          <Toolbar />
          <Editable
            className="richtext__textbox"
            renderElement={renderElement}
            renderLeaf={renderLeaf}
          />
        </div>
      </Slate>
    );
  }
);

export default RichTextEditor;
