import './Cart.scss';

import { forwardRef, useEffect, useState, useRef, JSX, ButtonHTMLAttributes } from 'react';
import { useNavigate } from 'react-router-dom';
import { Close, ShoppingCart } from '@mui/icons-material';
import { Popover, Badge, Button, IconButton, SvgIcon } from '@mui/material';

import { ReactComponent as CartIcon } from 'app/assets/icon_cart.svg';
import { ReactComponent as CartFullIcon } from 'app/assets/icon_cart_full.svg';

import useAppDispatch from 'app/hooks/useAppDispatch';
import useAppSelector from 'app/hooks/useAppSelector';
import AddonTableList from 'app/components/AddonTableList/AddonTableList';
import CartItem from 'app/components/AddonTableItem/variants/CartItem/CartItem';
import Tip from 'app/components/Tip/Tip';
import { tips } from 'app/utils/constants/contentConstants';
import { userIdSelector } from 'app/auth/store/userSlice';
import {
  cartContentsSelector,
  cartInfoSelector,
  checkout,
  getCartData,
  removeFromCart,
} from 'app/store/unigine/cartSlice';

type CartInfo = ReturnType<typeof cartInfoSelector>;
type CartContents = ReturnType<typeof cartContentsSelector>;

interface ICartButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  cartInfo: CartInfo;
}

interface ICartProps {
  ButtonComponent?: typeof CartButton;
  anchorEl?: Element | null;
}

interface ICartModalProps {
  cartInfo: CartInfo;
  cartContents: CartContents;
  isLoading?: boolean;
  onClose: () => void;
}

const Cart = ({ ButtonComponent = CartButton, anchorEl = null }: ICartProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const cartButtonRef = useRef<HTMLButtonElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const cartInfo = useAppSelector(cartInfoSelector);
  const cartContents = useAppSelector(cartContentsSelector);
  const isLoggedIn = !!useAppSelector(userIdSelector);

  useEffect(() => {
    if (cartInfo || !isLoggedIn) {
      return;
    }

    setIsLoading(true);
    dispatch(getCartData()).finally(() => setIsLoading(false));
  }, [dispatch, cartInfo, isLoggedIn]);

  return (
    <>
      <ButtonComponent
        ref={cartButtonRef}
        cartInfo={cartInfo}
        onClick={() => setIsOpen((open) => !open)}
      />

      <Popover
        className="cartpopover"
        open={isOpen}
        anchorEl={anchorEl || cartButtonRef.current || null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: -20, horizontal: 'right' }}
        onClose={() => setIsOpen(false)}
        slotProps={{
          paper: {
            square: true,
          },
          root: {
            BackdropProps: { sx: { backgroundColor: 'rgba(0, 0, 0, 0.5)' } },
          },
        }}
      >
        <CartModal
          isLoading={isLoading}
          cartInfo={cartInfo}
          cartContents={cartContents}
          onClose={() => setIsOpen(false)}
        />
      </Popover>
    </>
  );
};

const CartButton = forwardRef<HTMLButtonElement, ICartButtonProps>(({ onClick, cartInfo }, ref) => (
  <IconButton onClick={onClick} ref={ref} className="cart__anchor-button">
    <Badge
      className="cart__anchor-button-count"
      invisible={!cartInfo?.totalItems}
      badgeContent={cartInfo?.totalItems}
    >
      <SvgIcon
        component={cartInfo?.totalItems && cartInfo?.totalItems > 0 ? CartFullIcon : CartIcon}
      />
    </Badge>
  </IconButton>
));

const CartModal = ({
  isLoading = false,
  cartInfo,
  cartContents,
  onClose,
}: ICartModalProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [removingId, setRemovingId] = useState<string | null>(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const cartIsEmpty = cartContents.length === 0;

  const handleRemove = (cartItemId: string): void => {
    if (removingId) {
      return;
    }

    setRemovingId(cartItemId);
    dispatch(removeFromCart({ cartItemIds: [cartItemId] })).finally(() => setRemovingId(null));
  };

  const handleCheckout = (): void => {
    setIsProcessing(true);
    dispatch(checkout())
      .unwrap()
      .then((orderId) => {
        navigate(`/orders/${orderId}`);
      });
  };

  return (
    <div className="cart">
      <div className="cart__header">
        <h2 className="cart__heading">Cart</h2>
        <IconButton onClick={onClose} className="cart__close-btn">
          <Close />
        </IconButton>
      </div>

      {cartIsEmpty ? (
        <div className="cart__placeholder">
          <ShoppingCart fontSize="medium" />
          Your shopping cart is empty.
        </div>
      ) : (
        <>
          <div className="cart__contents">
            <AddonTableList
              isLoading={isLoading}
              items={cartContents}
              renderItem={(cartItem) => (
                <CartItem
                  cartItem={cartItem}
                  isRemoving={removingId === cartItem.id}
                  onRemove={() => handleRemove(cartItem.id)}
                />
              )}
            />
          </div>

          {cartInfo && (
            <dl className="cart__subtotal">
              <dt className="cart__subtotal-header">
                Subtotal
                <br />
                <small className="cart__subtotal-hint">Taxes/VAT calculated at checkout</small>
              </dt>
              <dd className="cart__subtotal-value">${cartInfo.subtotal.toFixed(2)}</dd>
            </dl>
          )}

          <Button className="cart__checkout" onClick={handleCheckout} disabled={isProcessing}>
            <ShoppingCart />
            {isProcessing ? 'Waiting...' : 'Checkout'}
          </Button>

          <Tip className="cart__mobile-tip" content={tips.mobileCart} />
        </>
      )}
    </div>
  );
};

export default Cart;
