import './AddonsSection.scss';

import { FC, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { CircularProgress } from '@mui/material';

import ErrorPage from 'app/main/errors/ErrorPage';
import AddonTableList from 'app/components/AddonTableList/AddonTableList';
import FilterForm from 'app/components/FilterForm/FilterForm';
import SortControl from 'app/components/SortControl/SortControl';
import AddonDialog from 'app/main/sections/Addon/AddonDialog';
import PublishedItem from 'app/components/AddonTableItem/variants/PublishedItem/PublishedItem';
import UniginePagination from 'app/components/UniginePagination/UniginePagination';

import useAppDispatch from 'app/hooks/useAppDispatch';
import useAppSelector from 'app/hooks/useAppSelector';
import usePermissions from 'app/hooks/usePermissions';

import getCategoriesIds from 'app/utils/helpers/getCategoriesIds';
import getProductsIds from 'app/utils/helpers/getProductsIds';
import getTagsCanonicalNames from 'app/utils/helpers/getTagsCanonicalNames';
import getSourceLanguageIds from 'app/utils/helpers/getSourceLanguageIds';
import getAddonVersionStatusIds from 'app/utils/helpers/getAddonVersionStatusIds';
import getConcreteCategories from 'app/utils/helpers/getConcreteCategories';

import { categoriesSelector } from 'app/store/unigine/categoriesSlice';
import { getProductsData, selectProducts } from 'app/main/sections/Addons/store/productsSlice';
import {
  getTagsData,
  getTopTagsData,
  selectTags,
  selectTopTags,
} from 'app/main/sections/Addons/store/tagsSlice';
import {
  getAddonVersionStatusData,
  selectAddonVersionStatus,
} from 'app/main/sections/Addons/store/addonVersionStatusSlice';
import {
  getSourceLanguageData,
  selectSourceLanguages,
} from 'app/main/sections/Addons/store/sourceLanguagesSlice';
import {
  getPublishedAddonsInfo,
  publishedAddonsErrorsSelector,
  publishedAddonsSelector,
  totalPagesSelector,
} from 'app/main/sections/AdminPortal/sections/AddonsSection/store/publishedAddonsSlice';

import { moderationAddonsPerPage } from 'app/configs/appConfig';

const PublishedAddonsSection: FC = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const { isNoRole } = usePermissions();

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingDictionaries, setIsLoadingDictionaries] = useState(true);

  const categories = useAppSelector(categoriesSelector);
  const categoriesData = useMemo(() => getConcreteCategories(categories), [categories]);

  const productsData = useAppSelector(selectProducts);
  const tagsData = useAppSelector(selectTags);
  const topTagsData = useAppSelector(selectTopTags);
  const sourceLanguagesData = useAppSelector(selectSourceLanguages);
  const addonVersionStatusesData = useAppSelector(selectAddonVersionStatus);

  const publishedAddonsData = useAppSelector(publishedAddonsSelector);
  const totalPages = useAppSelector(totalPagesSelector);
  const errors = useAppSelector(publishedAddonsErrorsSelector);

  const [page, search, sort, direction, perPage] = [
    searchParams.get('page'),
    searchParams.get('search'),
    searchParams.get('sort'),
    searchParams.get('direction'),
    searchParams.get('perPage'),
  ];
  const currentPage = parseInt(page || '1', 10);
  const currentModerationAddonsPerPage = perPage ? parseInt(perPage, 10) : moderationAddonsPerPage;

  useEffect(() => {
    Promise.all([
      dispatch(getProductsData()),
      dispatch(getSourceLanguageData()),
      dispatch(getAddonVersionStatusData()),
      dispatch(getTagsData()),
      dispatch(getTopTagsData()),
    ]).then(() => {
      setIsLoadingDictionaries(false);
    });
  }, [dispatch]);

  useEffect(() => {
    if (!isLoadingDictionaries && !isNoRole) {
      setIsLoading(true);

      const productsParam = searchParams.getAll('products[]');
      const tagsParam = searchParams.getAll('tags[]');
      const categoriesParam = searchParams.getAll('categories[]');
      const sourceLanguageParam = searchParams.getAll('source[]');
      const addonVersionStatusParam = searchParams.getAll('addonVersionStatus[]');

      const price = {
        mode: searchParams.get('is_free'),
        min: searchParams.get('start_price'),
        max: searchParams.get('end_price'),
      };

      const categoriesIds = getCategoriesIds({ categoriesData, categoriesParam });
      const productsIds = getProductsIds({ productsData, productsParam });
      const tagsCanonicalNames = getTagsCanonicalNames({ tagsData, tagsParam });
      const sourceLanguageIds = getSourceLanguageIds({ sourceLanguagesData, sourceLanguageParam });
      const statusIds = getAddonVersionStatusIds({
        addonVersionStatusesData,
        addonVersionStatusParam,
      });

      dispatch(
        getPublishedAddonsInfo({
          perPage: currentModerationAddonsPerPage,
          page: currentPage,
          categories: categoriesIds,
          products: productsIds,
          tags: tagsCanonicalNames,
          sourceLanguages: sourceLanguageIds,
          search,
          lastAddonVersionStatuses: statusIds,
          price,
          sort,
          direction,
        })
      ).then(() => setIsLoading(false));
    }
  }, [
    addonVersionStatusesData,
    categoriesData,
    currentPage,
    direction,
    dispatch,
    isLoadingDictionaries,
    isNoRole,
    productsData,
    search,
    searchParams,
    sort,
    sourceLanguagesData,
    tagsData,
    currentModerationAddonsPerPage,
  ]);

  if (errors) {
    const { data, status } = errors;
    return <ErrorPage status={status} text={data.message} />;
  }

  return (
    <div className="add-on-table__wrapper columns publisher-panel__wrapper--no-header">
      <div className="add-on-table__column add-on-table__column--main columns__column--content">
        <div className="add-on-table__title-wrapper">
          <h1 className="add-on-table__title">Add-Ons for Moderation</h1>

          <SortControl
            name="sort"
            options={[
              { value: 'title', label: 'Title' },
              { value: 'status', label: 'Version status' },
            ]}
          />
        </div>

        {isLoading && (
          <div className="add-on-list__loading-container">
            <CircularProgress color="inherit" className="add-on-list__loading" />
          </div>
        )}

        {!isLoading && publishedAddonsData && (
          <AddonTableList
            items={publishedAddonsData}
            placeholderText="No published add-ons"
            isLoading={isLoading}
            renderItem={(item) => (
              <PublishedItem
                id={item.id}
                title={item.title}
                coverId={item.lastAddonVersion.coverFile?.id}
                categories={item.lastAddonVersion.categories}
                versionNumber={item.lastAddonVersion.versionNumber}
                status={item.addonStatus.slug}
                updateDate={item.lastAddonVersion.updateDate}
                addonVersionStatus={item.lastAddonVersion.status}
                linkTo={`/adminPortal/add-ons/${item.id}`}
                publisher={item.publisher}
              />
            )}
          />
        )}

        {!isLoading && publishedAddonsData && publishedAddonsData.length !== 0 && (
          <div className="pagination__container">
            <UniginePagination
              currentPage={currentPage}
              totalPages={totalPages}
              defaultValue={moderationAddonsPerPage}
            />
          </div>
        )}
      </div>

      {!isLoadingDictionaries && (
        <div className="add-on-table__column add-on-table__column--side columns__column--side">
          <FilterForm
            search={{
              name: 'search',
              label: 'Search in uploaded add-ons',
            }}
            filterParams={[
              {
                name: 'categories',
                title: 'Categories',
                type: 'checkbox',
                options: categoriesData || [],
                getLabel: (item) => item.name,
                getValue: (item) => item.slug,
                collapsed: false,
              },
              {
                name: 'products',
                title: 'Products',
                type: 'checkbox',
                options: productsData || [],
                getLabel: (item) => item.name,
                getValue: (item) => item.slug,
                collapsed: false,
              },
              {
                name: 'addonVersionStatus',
                title: 'Latest Version Status',
                type: 'checkbox',
                options: addonVersionStatusesData || [],
                getLabel: (item) => item.value,
                getValue: (item) => item.slug,
                collapsed: false,
              },
              {
                name: 'source',
                title: 'Source code',
                type: 'checkbox',
                options: sourceLanguagesData || [],
                getLabel: (item) => item.value,
                getValue: (item) => item.slug,
                collapsed: false,
              },
              {
                name: 'tags',
                title: 'Tags',
                type: 'tags',
                options: topTagsData || [],
                getLabel: (item) => item.value,
                getValue: (item) => item.value,
              },
            ]}
          />
        </div>
      )}

      <AddonDialog hidePurchaseButton />
    </div>
  );
};

export default PublishedAddonsSection;
