import './OrderAddonVersionsSection.scss';

import { FC, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { CircularProgress } from '@mui/material';

import AddonDialog from 'app/main/sections/Addon/AddonDialog';
import ErrorPage from 'app/main/errors/ErrorPage';

import AddonTableList from 'app/components/AddonTableList/AddonTableList';
import FilterForm from 'app/components/FilterForm/FilterForm';
import SortControl from 'app/components/SortControl/SortControl';
import AddonOrderItem from 'app/components/AddonTableItem/variants/AddonOrderItem/AddonOrderItem';
import UniginePagination from 'app/components/UniginePagination/UniginePagination';

import useAppDispatch from 'app/hooks/useAppDispatch';
import useAppSelector from 'app/hooks/useAppSelector';

import getOrderStatusesIds from 'app/utils/helpers/getOrderStatusesIds';
import getOrderStatusesOptions from 'app/utils/helpers/getOrderStatusesOptions';

import IOrderStatus from 'app/interfaces/orders/IOrderStatus';

import {
  getOrderStatuses,
  orderStatusesSelector,
} from 'app/main/sections/Orders/store/statusesSlice';
import { userSelector } from 'app/auth/store/userSlice';
import {
  getOrderAddonVersions,
  orderAddonVersionsErrorsSelector,
  orderAddonVersionsSelector,
  orderAddonVersionsTotalPagesSelector,
} from 'app/main/sections/PublisherPanel/sections/OrderAddonVersionsSection/store/orderAddonVersionsSlice';

import { orderedAddonsPerPage } from 'app/configs/appConfig';

const OrderAddonVersionsSection: FC = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingDictionaries, setIsLoadingDictionaries] = useState(true);

  const orderStatusesData = useAppSelector(orderStatusesSelector);
  const orderAddonVersionsData = useAppSelector(orderAddonVersionsSelector);
  const totalPages = useAppSelector(orderAddonVersionsTotalPagesSelector);
  const errors = useAppSelector(orderAddonVersionsErrorsSelector);
  const user = useAppSelector(userSelector);

  const [page, search, sort, direction, perPage] = [
    searchParams.get('page'),
    searchParams.get('search'),
    searchParams.get('sort'),
    searchParams.get('direction'),
    searchParams.get('perPage'),
  ];
  const currentPage = parseInt(page || '1', 10);
  const currentOrderedAddonsPerPage = perPage ? parseInt(perPage, 10) : orderedAddonsPerPage;

  const orderStatusesOptions = useMemo(
    () => getOrderStatusesOptions({ orderStatusesData }),
    [orderStatusesData]
  );

  useEffect(() => {
    dispatch(getOrderStatuses()).then(() => {
      setIsLoadingDictionaries(false);
    });
  }, [dispatch]);

  useEffect(() => {
    if (!isLoadingDictionaries) {
      setIsLoading(true);

      const orderStatusesParam = searchParams.getAll('orderStatuses[]');
      const orderStatusesIds = getOrderStatusesIds({ orderStatusesData, orderStatusesParam });

      const price = {
        mode: searchParams.get('is_free'),
        min: searchParams.get('start_price'),
        max: searchParams.get('end_price'),
      };

      dispatch(
        getOrderAddonVersions({
          perPage: currentOrderedAddonsPerPage,
          page: currentPage,
          orderStatuses: orderStatusesIds,
          search,
          price,
          sort,
          direction,
        })
      ).then(() => setIsLoading(false));
    }
  }, [
    currentPage,
    direction,
    dispatch,
    isLoadingDictionaries,
    orderStatusesData,
    search,
    searchParams,
    sort,
    currentOrderedAddonsPerPage,
  ]);

  if (errors) {
    const { data, status } = errors;
    return <ErrorPage status={status} text={data.message} />;
  }

  return (
    <div className="add-on-table__wrapper columns add-on-orders__wrapper">
      <div className="add-on-table__column add-on-table__column--main columns__column--content">
        <div className="add-on-table__title-wrapper">
          <h1 className="add-on-table__title">Orders on my add-ons</h1>

          <SortControl
            name="sort"
            options={[
              { value: 'orderDate', label: 'Date', defaultDirection: 'DESC' },
              { value: 'addonTitle', label: 'Title', defaultDirection: 'ASC' },
            ]}
            defaultDirection="DESC"
          />
        </div>

        {isLoading && (
          <div className="add-on-list__loading-container">
            <CircularProgress color="inherit" className="add-on-list__loading" />
          </div>
        )}

        {!isLoading && orderAddonVersionsData && (
          <AddonTableList
            items={orderAddonVersionsData}
            placeholderText="No add-on orders"
            isLoading={isLoading}
            renderItem={(item) => <AddonOrderItem addon={item} userName={user.userData.name} />}
          />
        )}

        {!isLoading && orderAddonVersionsData && orderAddonVersionsData.length !== 0 && (
          <div className="pagination__container">
            <UniginePagination
              currentPage={currentPage}
              totalPages={totalPages}
              defaultValue={orderedAddonsPerPage}
            />
          </div>
        )}
      </div>

      <div className="add-on-table__column add-on-table__column--side columns__column--side">
        <FilterForm
          search={{
            name: 'search',
            label: 'Search in add-on orders',
          }}
          filterParams={[
            {
              name: 'orderStatuses',
              title: 'Status',
              type: 'checkbox',
              options: orderStatusesOptions || [],
              getLabel: (item: IOrderStatus) => item.value,
              getValue: (item: IOrderStatus) => item.slug,
              collapsed: false,
            },
          ]}
        />
      </div>

      <AddonDialog hidePurchaseButton />
    </div>
  );
};

export default OrderAddonVersionsSection;
