import PurchasedAddons from 'app/main/sections/PurchasedAddons/PurchasedAddons';

const PurchasedAddonsConfig = [
  {
    path: '/purchased',
    element: <PurchasedAddons />,
  },
];

export default PurchasedAddonsConfig;
