import { globalRoles } from 'app/utils/constants/permissions';

import { ReactComponent as IconDashboard } from 'app/assets/sidebarIcons/icon_dashboard.svg';
import { ReactComponent as IconGear } from 'app/assets/sidebarIcons/icon_settings.svg';
import { ReactComponent as IconReviews } from 'app/assets/sidebarIcons/icon_reviews.svg';
import { ReactComponent as IconSupport } from 'app/assets/sidebarIcons/icon_support.svg';
import { ReactComponent as IconUpload } from 'app/assets/sidebarIcons/icon_upload.svg';
import { ReactComponent as IconOrders } from 'app/assets/sidebarIcons/icon_orders.svg';
import { ReactComponent as IconNotifications } from 'app/assets/sidebarIcons/icon_notifications.svg';

const publisherPanelSidebarData = [
  {
    title: 'Dashboard',
    path: '/publisherPanel/dashboard',
    roles: [globalRoles.companyAdmin],
    icon: <IconDashboard />,
  },
  {
    title: 'My Uploaded Add-Ons',
    path: '/publisherPanel/add-ons',
    roles: [globalRoles.addonManager, globalRoles.companyAdmin],
    icon: <IconUpload />,
  },
  {
    title: 'Add-Ons orders',
    path: '/publisherPanel/orders',
    roles: [globalRoles.addonManager, globalRoles.companyAdmin],
    icon: <IconOrders />,
  },
  {
    title: 'Reviews',
    path: '/publisherPanel/reviews',
    roles: [globalRoles.addonManager, globalRoles.companyAdmin],
    icon: <IconReviews />,
  },
  {
    title: 'Support',
    path: '/publisherPanel/support',
    roles: [globalRoles.addonFuture],
    icon: <IconSupport />,
  },
  {
    title: 'Notifications',
    path: '/publisherPanel/notifications',
    roles: [globalRoles.addonFuture],
    icon: <IconNotifications />,
  },
  {
    title: ' Publisher Info',
    path: '/publisherPanel/settings',
    roles: [globalRoles.companyAdmin],
    icon: <IconGear />,
  },
  {
    title: ' Add-On Managers',
    path: '/publisherPanel/managers',
    roles: [globalRoles.companyAdmin],
    icon: <IconGear />,
  },
];

export default publisherPanelSidebarData;
