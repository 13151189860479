import './packageFileStatus.scss';

import { FC } from 'react';
import successIcon from 'app/assets/icon_success.svg';
import processingIcon from 'app/assets/icon_hourglass.svg';
import errorIcon from 'app/assets/icon_storage_error.svg';

import { packageFileStatusMessages } from 'app/utils/constants/contentConstants';

interface IPackageFileStatus {
  packageStatusInfo: {
    id: string;
    value: string;
    slug: string;
  };
}

const PackageFileStatus: FC<IPackageFileStatus> = ({ packageStatusInfo }) => {
  const { slug } = packageStatusInfo;
  let text;
  switch (slug) {
    case 'success':
      text = (
        <div className="package-status package-status--success">
          <img src={successIcon} alt="sucess" />
          {packageFileStatusMessages.success}
        </div>
      );
      break;
    case 'processing':
      text = (
        <div className="package-status package-status--processing">
          <img src={processingIcon} alt="processing" />
          {packageFileStatusMessages.processing}
        </div>
      );
      break;
    case 'file_error':
      text = (
        <div className="package-status package-status--error">
          <img src={errorIcon} alt="error" />
          {packageFileStatusMessages.fileError}
        </div>
      );
      break;
    case 'storage_error':
      text = (
        <div className="package-status package-status--error">
          <img src={errorIcon} alt="error" />
          {packageFileStatusMessages.storageError}
        </div>
      );
      break;
    case 'no_package_file':
      text = <div className="package-status" />;
      break;
    default:
      text = <div>Unknown package status</div>;
      break;
  }
  return text;
};

export default PackageFileStatus;
