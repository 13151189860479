import { DEFAULT_HEADER_HEIGHT } from 'app/configs/appConfig';
import { Ref, useEffect, useRef, useState } from 'react';

const useScrollThreshold = <T extends Element>(
  topOffset: number = DEFAULT_HEADER_HEIGHT
): {
  anchorRef: Ref<T>;
  isOut: boolean;
} => {
  const anchorRef = useRef<T | null>(null);
  const [isOut, setIsOut] = useState(false);

  useEffect(() => {
    const checkBounds = (): void => {
      if (!anchorRef.current) {
        return;
      }

      const { bottom } = anchorRef.current.getBoundingClientRect();
      if (bottom < topOffset) {
        setIsOut(true);
      } else {
        setIsOut(false);
      }
    };

    document.addEventListener('scroll', checkBounds);
    checkBounds();

    return () => document.removeEventListener('scroll', checkBounds);
  }, [topOffset]);

  return { anchorRef, isOut };
};

export default useScrollThreshold;
