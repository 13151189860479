import './AddonMediaDialog.scss';

import { FC, FormEvent, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import useAppDispatch from 'app/hooks/useAppDispatch';

import AddonDialogVideo from 'app/components/AddonDialogs/AddonDialogVideo';
import AddonDialogImage from 'app/components/AddonDialogs/AddonDialogImage';
import AddonDialogVideoForm from 'app/components/AddonDialogs/AddonDialogVideoForm';
import AddonDialogImageForm from 'app/components/AddonDialogs/AddonDialogImageForm';
import AddonDialogMediaDelete from 'app/components/AddonDialogs/AddonDialogMediaDelete';

import { IAddonMediaDialog } from 'app/components/AddonDialogs/types';

import { IVideoInfo } from 'app/interfaces/addons/IVideoFile';

const AddonMediaDialog: FC<IAddonMediaDialog> = ({
  type,
  addonVersionId,
  mediaInfo,
  closeMediaDialog,
  addPictureFile,
  removePictureFile,
  addVideoFile,
  removeVideoFile,
  getVideoInfoById,
}) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [images, setImages] = useState<File[]>([]);
  const [videoId, setVideoId] = useState('');
  const [video, setVideo] = useState<IVideoInfo | null>(null);

  const {
    isOpen = false,
    media,
    isFormImage,
    isFormVideo,
    deleteImageId,
    deleteVideoId,
    allowToDeleteMedia,
  } = mediaInfo;

  useEffect(() => {
    setVideoId('');
    setVideo(null);
    dispatch(closeMediaDialog());
  }, [closeMediaDialog, dispatch, location]);

  useEffect(() => {
    if (videoId && getVideoInfoById) {
      dispatch(getVideoInfoById(videoId))
        .unwrap()
        .then((response) => {
          setVideo(response);
        })
        .catch(() => {
          setVideo(null);
        });
    }
  }, [dispatch, getVideoInfoById, videoId]);

  const handleClose = (): void => {
    setVideoId('');
    setVideo(null);
    setImages([]);
    dispatch(closeMediaDialog());
  };

  const handleSubmitVideoForm = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    if (!video || !addVideoFile) {
      handleClose();
      return;
    }
    if (type === 'addon') {
      dispatch(addVideoFile({ videoFile: video, addonVersionId })).then(() => {
        handleClose();
      });
    } else {
      dispatch(addVideoFile({ videoFile: video })).then(() => {
        handleClose();
      });
    }
  };

  const handleSubmitImageForm = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    if (!addPictureFile) {
      handleClose();
      return;
    }
    if (type === 'addon') {
      dispatch(addPictureFile({ images, addonVersionId })).then(() => {
        handleClose();
      });
    } else {
      dispatch(addPictureFile({ images })).then(() => {
        handleClose();
      });
    }

    setImages([]);
  };

  const handleConfirmDeleteImage = (): void => {
    if (type !== 'addon' || typeof deleteImageId !== 'string') {
      return;
    }
    if (!deleteImageId) {
      handleClose();
      return;
    }

    dispatch(removePictureFile({ imageId: deleteImageId, addonVersionId })).then(() => {
      handleClose();
    });
  };

  const handleConfirmDeleteVideo = (): void => {
    if (type !== 'addon' || typeof deleteVideoId !== 'string') {
      return;
    }
    if (!deleteVideoId) {
      handleClose();
      return;
    }
    dispatch(removeVideoFile({ videoId: deleteVideoId, addonVersionId })).then(() => {
      handleClose();
    });
  };

  if (isFormImage) {
    return (
      <AddonDialogImageForm
        isOpen={isOpen}
        handleClose={handleClose}
        handleSubmitImageForm={handleSubmitImageForm}
        images={images}
        setImages={setImages}
      />
    );
  }

  if (isFormVideo) {
    return (
      <AddonDialogVideoForm
        isOpen={isOpen}
        handleClose={handleClose}
        handleSubmitVideoForm={handleSubmitVideoForm}
        video={video}
        videoId={videoId}
        setVideoId={setVideoId}
      />
    );
  }

  if (media?.videoId) {
    return <AddonDialogVideo videoId={media.videoId} isOpen={isOpen} handleClose={handleClose} />;
  }

  if (deleteImageId) {
    return (
      <AddonDialogMediaDelete
        handleConfirmDelete={handleConfirmDeleteImage}
        handleClose={handleClose}
        isOpen={isOpen}
        allowToDeleteMedia={allowToDeleteMedia}
        mediaLabel="image"
      />
    );
  }

  if (deleteVideoId) {
    return (
      <AddonDialogMediaDelete
        handleConfirmDelete={handleConfirmDeleteVideo}
        handleClose={handleClose}
        isOpen={isOpen}
        allowToDeleteMedia={allowToDeleteMedia}
        mediaLabel="video"
      />
    );
  }

  return media?.url && media?.id ? (
    <AddonDialogImage url={media?.url} id={media?.id} handleClose={handleClose} isOpen={isOpen} />
  ) : null;
};

export default AddonMediaDialog;
