import './PublisherLink.scss';

import { FC, useState, useEffect } from 'react';
import clsx from 'clsx';

import defaultAvatar from 'app/assets/avatar-default.svg';

import { Link } from 'react-router-dom';
import { Skeleton } from '@mui/material';

import useAppDispatch from 'app/hooks/useAppDispatch';
import useAppSelector from 'app/hooks/useAppSelector';

import {
  publisherInfoSelector,
  getPublisherData,
} from 'app/main/sections/Publisher/store/publisherSlice';

import { IPublisherInAddon } from 'app/interfaces/publisher/IPublisher';

interface IPublisherLink {
  publisher: IPublisherInAddon;
  className?: string;
}

const PublisherLink: FC<IPublisherLink> = ({ publisher, className }) => {
  const [isAvatarLoading, setIsAvatarLoading] = useState(true);
  const dispatch = useAppDispatch();
  const fullPublisherInfo = useAppSelector(publisherInfoSelector);

  useEffect(() => {
    if (publisher) {
      dispatch(getPublisherData({ publisherId: publisher.id }))
        .unwrap()
        .then(() => setIsAvatarLoading(false));
    }
  }, [dispatch, publisher]);

  return (
    <Link to={`/publisher/${publisher.id}`} className={clsx('publisher-link', className)}>
      {isAvatarLoading ? (
        <Skeleton className="publisher-link__skeleton" variant="circular" width={24} height={24} />
      ) : (
        <img
          src={
            fullPublisherInfo?.icon24File
              ? `${process.env.REACT_APP_STORE_API_URL}/media-files/${fullPublisherInfo?.icon24File.id}`
              : defaultAvatar
          }
          alt={publisher?.name}
          className="publisher-link__image"
        />
      )}
      {publisher?.name}
    </Link>
  );
};

export default PublisherLink;
