import { FC } from 'react';
import './AddonPackageDialog.scss';

import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogContent, IconButton } from '@mui/material';

import UnigineButton from 'app/components/UnigineButton/UnigineButton';
import UnigineTooltip from 'app/components/Tooltip/Tooltip';
import { tooltips } from 'app/utils/constants/contentConstants';

interface IResumeDialogProps {
  isOpen: boolean;
  uploadPercentage: number;
  handleResumeUpload: () => void;
  handleRestartUpload: () => void;
  handleClose: () => void;
}

const AddonPackageResumeUploadingDialog: FC<IResumeDialogProps> = ({
  isOpen,
  uploadPercentage,
  handleResumeUpload,
  handleRestartUpload,
  handleClose,
}) => (
  <Dialog
    open={isOpen}
    fullWidth
    maxWidth="xs"
    className="dialog dialog--package"
    onClose={handleClose}
  >
    <DialogContent className="dialog-uploading__content">
      <h3 className="dialog-uploading__title">
        You've already uploaded {Math.round(uploadPercentage)}% of this file.
      </h3>

      <div className="dialog-uploading__actions-container">
        <UnigineTooltip title={tooltips.restartUploading}>
          <UnigineButton
            type="button"
            className="dialog-uploading__button dialog-uploading__button--white"
            onClick={handleRestartUpload}
          >
            Restart
          </UnigineButton>
        </UnigineTooltip>

        <UnigineTooltip title={tooltips.resumeUploading}>
          <UnigineButton
            type="button"
            className="dialog-uploading__button"
            onClick={handleResumeUpload}
          >
            Resume
          </UnigineButton>
        </UnigineTooltip>
      </div>

      <IconButton aria-label="close" className="dialog__close-button" onClick={handleClose}>
        <CloseIcon />
      </IconButton>
    </DialogContent>
  </Dialog>
);

export default AddonPackageResumeUploadingDialog;
