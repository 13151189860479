import React, { FC } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';

import store from 'app/store';
import Auth from 'app/auth/Auth';
import Layout from 'app/components/Layout/Layout';

const App: FC = () => {
  return (
    <React.StrictMode>
      <HelmetProvider>
        <Provider store={store}>
          <Auth>
            <BrowserRouter>
              <Helmet>
                <title>Unigine Add-On Store</title>
              </Helmet>
              <div className="App">
                <Layout />
              </div>
            </BrowserRouter>
          </Auth>
        </Provider>
      </HelmetProvider>
    </React.StrictMode>
  );
};

export default App;
