import './UniginePagination.scss';

import { FC } from 'react';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import {
  Pagination,
  PaginationItem,
  PaginationProps,
  Select,
  MenuItem,
  InputLabel,
  SelectChangeEvent,
} from '@mui/material';
import PreviousIcon from '@mui/icons-material/West';
import NextIcon from '@mui/icons-material/East';

import { defaultPerPage } from 'app/configs/appConfig';
import getPaginationLink from 'app/utils/helpers/getPaginationLink';
import mergeSearchParam from 'app/utils/helpers/mergeSearchParam';

interface UniginePaginationProps extends PaginationProps {
  currentPage: number;
  totalPages: number;
  defaultValue?: number;
}

const UniginePagination: FC<UniginePaginationProps> = ({
  currentPage,
  totalPages,
  defaultValue = defaultPerPage,
  ...props
}) => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const currentPerPage = searchParams.get('perPage');

  const handleChangeAddonValue = (e: SelectChangeEvent<number>): void => {
    const addonsPerPage = e.target.value;
    let newParams = mergeSearchParam(searchParams, 'perPage', addonsPerPage.toString());
    newParams = mergeSearchParam(newParams, 'page', '1');
    setSearchParams(newParams);
  };

  return (
    <div className="pagination__wrapper">
      <Pagination
        {...props}
        className="pagination"
        size="large"
        page={currentPage}
        count={totalPages}
        renderItem={(item) => (
          <PaginationItem
            slots={{ previous: PreviousIcon, next: NextIcon }}
            component={Link}
            to={getPaginationLink({ location, page: item.page })}
            {...item}
          />
        )}
      />
      <div className="pagination__select-container">
        <InputLabel id="pagination-value">Per page</InputLabel>
        <Select
          labelId="pagination-value"
          className="unigine-select pagination-select"
          MenuProps={{
            PaperProps: {
              sx: {
                bgcolor: '#2a323b',
                color: '#eff3f7',
              },
            },
            disableScrollLock: true,
          }}
          value={currentPerPage ? parseInt(currentPerPage, 10) : defaultValue}
          onChange={handleChangeAddonValue}
        >
          <MenuItem value={12}>12</MenuItem>
          <MenuItem value={24}>24</MenuItem>
          <MenuItem value={36}>36</MenuItem>
          <MenuItem value={48}>48</MenuItem>
        </Select>
      </div>
    </div>
  );
};

export default UniginePagination;
