import { forwardRef } from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';

const PriceInput = forwardRef<
  HTMLInputElement,
  Omit<NumericFormatProps, 'defaultValue'> & { defaultValue?: string | number | readonly string[] }
>((props, ref) => (
  <NumericFormat
    {...props}
    defaultValue={props.defaultValue?.toString()}
    getInputRef={ref}
    allowNegative={false}
    allowLeadingZeros={false}
    decimalScale={2}
    fixedDecimalScale
    valueIsNumericString
  />
));

export default PriceInput;
