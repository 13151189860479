import './PriceFilter.scss';

import { JSX, KeyboardEvent } from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import RadioGroup from 'app/components/RadioGroup/RadioGroup';

const modes = ['all', 'free', 'range'] as const;

interface IPriceFilterProps {
  mode: 'all' | 'free' | 'range';
  range: { min: string | null; max: string | null };
  onChange: (priceFilterValue: {
    mode: IPriceFilterProps['mode'];
    range: IPriceFilterProps['range'];
    applyRange: boolean;
  }) => void;
}

const isValidMode = (value: string): value is (typeof modes)[number] => {
  return (modes as readonly string[]).includes(value);
};

const PriceField = ({ ...props }: Omit<TextFieldProps, 'className'>): JSX.Element => (
  <TextField className="price-filter__input" {...props} />
);

const PriceFilter = ({ mode, range, onChange }: IPriceFilterProps): JSX.Element => {
  const isInvalidRange = parseInt(range.min || '', 10) > parseInt(range.max || '', 10);
  const isIncompleteRange = range.min === null || range?.max === null;

  const handleChangeRange = (value: string, field: 'min' | 'max'): void => {
    const parsed = parseInt(value, 10);
    const newRange = { ...range, [field]: Number.isNaN(parsed) ? '0' : value };
    onChange({ mode, range: newRange, applyRange: false });
  };

  const applyRange = (): void => {
    if (isInvalidRange) {
      return;
    }
    onChange({ mode: 'range', range, applyRange: true });
  };

  const handleBlur = (): void => applyRange();

  const handleKeyDown = (evt: KeyboardEvent): void => {
    if (evt.key !== 'Enter') {
      return;
    }

    applyRange();
  };

  return (
    <RadioGroup
      selectedValue={mode}
      onChange={(_, value) => {
        if (value === 'range' && isIncompleteRange) {
          return;
        }

        if (!isValidMode(value)) {
          return;
        }

        onChange({ mode: value, range, applyRange: value === 'range' });
      }}
      options={[
        { label: 'All', value: 'all' },
        { label: 'Free', value: 'free' },
        {
          label: (
            <div>
              <PriceField
                error={isInvalidRange}
                value={range?.min}
                onChange={(evt) => handleChangeRange(evt.target.value, 'min')}
                onBlur={handleBlur}
                onKeyDown={handleKeyDown}
              />
              {' — '}
              <PriceField
                error={isInvalidRange}
                value={range?.max}
                onChange={(evt) => handleChangeRange(evt.target.value, 'max')}
                onBlur={handleBlur}
                onKeyDown={handleKeyDown}
              />
            </div>
          ),
          value: 'range',
        },
      ]}
    />
  );
};

export default PriceFilter;
