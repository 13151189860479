import IError from 'app/interfaces/IError';
import { createSlice, createAsyncThunk, createEntityAdapter, EntityState } from '@reduxjs/toolkit';
import axiosInstance from 'app/auth/axiosInstance';

import { IPurchasedAddonInList } from 'app/interfaces/addons/IAddon';
import getPaginationParams, { IRowPaginationParams } from 'app/utils/helpers/getPaginationParams';
import { RootState } from 'app/store';

const purchasedAddonsAdapter = createEntityAdapter<IPurchasedAddonInList>({});

interface IPurchasedAddonsInfoSliceState {
  addons: EntityState<IPurchasedAddonInList>;
  errors: IError | null;
  totalPages: number;
}

const initialState: IPurchasedAddonsInfoSliceState = {
  addons: purchasedAddonsAdapter.getInitialState({}),
  errors: null,
  totalPages: 1,
};

interface IPurchasedAddonsList {
  addons: IPurchasedAddonInList[];
  total: number;
  perPage: number;
}

export const getPurchasedAddonsInfo = createAsyncThunk<
  IPurchasedAddonsList,
  IRowPaginationParams,
  { rejectValue: IError }
>('purchasedAddonsSection/getPurchasedAddonsInfo', async (props, { rejectWithValue }) => {
  try {
    const params = getPaginationParams(props);

    const response = await axiosInstance({
      method: 'get',
      url: '/account/purchased-addons',
      params,
    });

    return { ...response.data, perPage: props.perPage };
  } catch (err: any) {
    if (err.response) {
      return rejectWithValue({ data: err.response.data, status: err.response.status });
    }

    return rejectWithValue({ data: { message: 'Something went wrong' }, status: 500 });
  }
});

const purchasedAddonsInfoSlice = createSlice({
  name: 'purchasedAddonsSection',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPurchasedAddonsInfo.fulfilled, (state, action) => {
        const { addons, total, perPage } = action.payload;
        const totalPages = Math.ceil(total / perPage);

        purchasedAddonsAdapter.setAll(state.addons, addons);
        state.totalPages = totalPages;
        state.errors = null;
      })
      .addCase(getPurchasedAddonsInfo.rejected, (state, action) => {
        purchasedAddonsAdapter.removeAll(state.addons);
        state.totalPages = 1;
        if (action.payload) {
          state.errors = action.payload;
        }
      });
  },
});

export const { selectAll: purchasedAddonsSelector } = purchasedAddonsAdapter.getSelectors(
  ({ purchasedAddonsSection }: RootState) => purchasedAddonsSection.purchasedAddonsInfo.addons
);

export const purchasedAddonsErrorsSelector = ({
  purchasedAddonsSection,
}: RootState): IError | null => purchasedAddonsSection.purchasedAddonsInfo.errors;

export const totalPagesSelector = ({ purchasedAddonsSection }: RootState): number =>
  purchasedAddonsSection.purchasedAddonsInfo.totalPages;

export default purchasedAddonsInfoSlice.reducer;
