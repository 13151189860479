import './Documentation.scss';

import { FC } from 'react';
import { Link } from 'react-router-dom';
import StorefrontHeader from 'app/components/StorefrontHeader/StorefrontHeader';
import { ReactComponent as DocIcon } from 'app/assets/icon_doc.svg';
import { ReactComponent as QuickGuidIcon } from 'app/assets/icon_quick_guide.svg';

const Documentation: FC = () => {
  return (
    <>
      <StorefrontHeader />
      <div className="main docs__wrapper">
        <h1 className="docs__title">Documentation</h1>
        <Link className="docs__link" target="_blank" to="/documentation/general_information">
          General Information
        </Link>

        <div className="docs__columns">
          <div className="docs__column">
            <h2 className="docs__subtitle">For users</h2>

            <Link
              className="docs__link"
              target="_blank"
              to="/documentation/purchasing_downloading_addon_packages"
            >
              <DocIcon className="docs__icon--doc" />
              Purchasing/Downloading Add&#8209;On Packages on the Add&#8209;On Store
            </Link>
            <Link className="docs__link" target="_blank" to="/documentation/using_addon_packages">
              <DocIcon className="docs__icon--doc" />
              Using Add&#8209;On Packages in Your Projects
            </Link>
          </div>

          <div className="docs__column">
            <h2 className="docs__subtitle">For publishers</h2>

            <Link
              className="docs__link"
              target="_blank"
              to="/documentation/preparing_addon_packages"
            >
              <DocIcon className="docs__icon--doc" />
              Preparing Add&#8209;On Packages for Publishing
            </Link>
            <Link
              className="docs__link"
              target="_blank"
              to="/documentation/publishing_addon_packages"
            >
              <DocIcon className="docs__icon--doc" />
              Publishing Add&#8209;On Packages on Store
            </Link>

            <h3 className="docs__subtitle docs__subtitle--secondary">Quick guides</h3>
            <Link
              className="docs__link"
              target="_blank"
              to="/documentation/quick_guides/register_publisher.html"
            >
              <QuickGuidIcon className="docs__icon--quick-guide" />
              Becoming a Publisher
            </Link>
            <Link
              className="docs__link"
              target="_blank"
              to="/documentation/quick_guides/publish_content.html"
            >
              <QuickGuidIcon className="docs__icon--quick-guide" />
              Submitting Content Packs
            </Link>
            <Link
              className="docs__link"
              target="_blank"
              to="/documentation/quick_guides/publish_cs.html"
            >
              <QuickGuidIcon className="docs__icon--quick-guide" />
              Submitting Add&#8209;Ons with C# Logic
            </Link>
            <Link
              className="docs__link"
              target="_blank"
              to="/documentation/quick_guides/publish_plugin_editor.html"
            >
              <QuickGuidIcon className="docs__icon--quick-guide" />
              Submitting Editor Plugins
            </Link>
            <Link
              className="docs__link"
              target="_blank"
              to="/documentation/quick_guides/publish_plugin_engine.html"
            >
              <QuickGuidIcon className="docs__icon--quick-guide" />
              Submitting Engine Plugins
            </Link>
            <Link
              className="docs__link"
              target="_blank"
              to="/documentation/quick_guides/updating_published_addon.html"
            >
              <QuickGuidIcon className="docs__icon--quick-guide" />
              Updating Published Add&#8209;Ons
            </Link>
          </div>

          <div className="docs__column">
            <h2 className="docs__subtitle">FOR ADMINISTRATORS</h2>
            <Link className="docs__link" target="_blank" to="/documentation/management_tools">
              Management Tools
            </Link>
          </div>
        </div>

        <h2 className="docs__title docs-last-title">Legal information</h2>
        <Link target="_blank" className="docs__link" to="/eula">
          UNIGINE Add&#8209;On Store End-User License Agreement
        </Link>
        <Link target="_blank" className="docs__link" to="/provider-agreement">
          UNIGINE Add&#8209;On Store Provider Agreement
        </Link>
      </div>
    </>
  );
};

export default Documentation;
