interface IRefundConstraints {
  requestId: string;
  apiResult: string;
}

interface IRefundConstraintsResult {
  title: string;
  text: string;
}

const RefundConstraints = ({
  requestId,
  apiResult = 'success',
}: IRefundConstraints): IRefundConstraintsResult => {
  switch (apiResult) {
    case 'success':
      return {
        title: 'YOUR refund HAS BEEN processed SuCcesfully!',
        text: `Money transfer operations will take some time. <br /> For all other questions regarding the refund, please contact XSolla Support <a href="link" target="blank">link</a>. YouYou will need to provide the request ID: ${requestId}.`,
      };
    case 'xsolla':
      return {
        title: 'Something went Wrong with your refund Request.',
        text: `Please contact XSolla Support <a href="link" target="blank">link</a> for clarification. ${
          requestId
            ? `To apply for refund, you should
        specify the transaction ID: ${requestId}.`
            : ''
        }`,
      };
    case 'exists':
      return {
        title: 'refund Request For this order already exists.',
        text: 'Please wait until it is processed.',
      };
    default:
      return {
        title: 'Error',
        text: 'Unexpected answer.',
      };
  }
};

export default RefundConstraints;
