import './PurchaseButton.scss';

import { FC, useCallback } from 'react';
import clsx from 'clsx';
import { ShoppingCart } from '@mui/icons-material';

import Tooltip from 'app/components/Tooltip/Tooltip';
import UnigineButton from 'app/components/UnigineButton/UnigineButton';
import { tooltips } from 'app/utils/constants/contentConstants';
import helpIcon from 'app/assets/icon-help.svg';

interface IPurchaseButton {
  className?: string;
  ownedStatus?: string;
  isFree: boolean;
  isWaiting: boolean;
  onClick: () => void;
}

const PurchaseButton: FC<IPurchaseButton> = ({
  className,
  ownedStatus,
  isFree,
  isWaiting,
  onClick,
}) => {
  const isUnavailable = ownedStatus === 'not available';
  const isInOrder = ownedStatus === 'in order';
  const isInCart = ownedStatus === 'in cart';

  let buttonText = isFree ? 'Get for Free' : 'Add to Cart';
  buttonText = isInCart ? 'In Cart' : buttonText;
  buttonText = isInOrder ? 'In Order' : buttonText;
  buttonText = isUnavailable ? 'Unavailable for your SDK edition' : buttonText;
  buttonText = isWaiting ? 'Waiting...' : buttonText;

  const purchasable = !isUnavailable && !isInOrder;
  const disabled = isInCart || isUnavailable || isInOrder || isWaiting;

  const handleClick = useCallback(() => {
    if (disabled) {
      return null;
    }

    return onClick();
  }, [disabled, onClick]);

  return (
    <UnigineButton
      className={clsx(
        'purchase-button',
        {
          'purchase-button--in-cart': isInCart,
          'purchase-button--unavailable': !purchasable,
        },
        className
      )}
      disabled={disabled}
      onClick={handleClick}
    >
      {purchasable && !isFree && <ShoppingCart />}
      {buttonText}
      {isInOrder && (
        <Tooltip title={tooltips.orders.inOrder}>
          <img src={helpIcon} alt={tooltips.orders.inOrder} />
        </Tooltip>
      )}
      {isUnavailable && (
        <Tooltip title={tooltips.orders.unavailable}>
          <img src={helpIcon} alt={tooltips.orders.unavailable} />
        </Tooltip>
      )}
    </UnigineButton>
  );
};

export default PurchaseButton;
