import {
  createSlice,
  createAsyncThunk,
  isAnyOf,
  CaseReducer,
  PayloadAction,
  createEntityAdapter,
  EntityState,
} from '@reduxjs/toolkit';

import { defaultError } from 'app/configs/appConfig';
import axiosInstance from 'app/auth/axiosInstance';

import { RootState } from 'app/store';
import IError from 'app/interfaces/IError';
import IPackage from 'app/interfaces/addons/IPackage';
import { IPublishedAddonVersionBaseInfo } from 'app/interfaces/addons/IAddonVersion';
import { IBaseInfoMediaDialog } from 'app/main/sections/PublisherPanel/sections/AddonSection/store/baseInfoSlice';

const packagesAdapter = createEntityAdapter<IPackage>();

interface IBaseInfoSliceState {
  mediaDialog: IBaseInfoMediaDialog;
  info: IPublishedAddonVersionBaseInfo | null;
  packages: EntityState<IPackage>;
  errors: {
    baseInfoError: IError | null;
    publishError: IError | null;
    packageError: string | null;
  };
}

export const getAddonBaseInfo = createAsyncThunk<
  IPublishedAddonVersionBaseInfo,
  { addonVersionId: string },
  { rejectValue: IError }
>('adminPortalSection/getAddonBaseInfo', async ({ addonVersionId }, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      method: 'get',
      url: `/admin-panel/addon-versions/${addonVersionId}`,
    });

    const addonBaseInfo = await response.data;

    return addonBaseInfo;
  } catch (err: any) {
    if (err.response?.data?.context?.message) {
      return rejectWithValue({ data: err.response.data, status: err.response.status });
    }

    return rejectWithValue(defaultError);
  }
});

export const publishAddonVersion = createAsyncThunk<
  IPublishedAddonVersionBaseInfo,
  { addonVersionId: string },
  { rejectValue: IError }
>('adminPortalSection/publishAddonVersion', async ({ addonVersionId }, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      method: 'put',
      url: `/admin-panel/addon-versions/${addonVersionId}/published`,
    });

    const addonBaseInfo = await response.data;

    return addonBaseInfo;
  } catch (err: any) {
    if (err.response?.data?.context?.message) {
      return rejectWithValue({ data: err.response.data, status: err.response.status });
    }

    return rejectWithValue(defaultError);
  }
});

export const rejectAddonVersion = createAsyncThunk<
  IPublishedAddonVersionBaseInfo,
  { addonVersionId: string },
  { rejectValue: IError }
>('adminPortalSection/rejectAddonVersion', async ({ addonVersionId }, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      method: 'PUT',
      url: `/admin-panel/addon-versions/${addonVersionId}/draft`,
    });

    const addonBaseInfo = await response.data;

    return addonBaseInfo;
  } catch (err: any) {
    if (err.response?.data?.context?.message) {
      return rejectWithValue({ data: err.response.data, status: err.response.status });
    }

    return rejectWithValue(defaultError);
  }
});

const updateBaseInfo: CaseReducer<
  IBaseInfoSliceState,
  PayloadAction<IPublishedAddonVersionBaseInfo>
> = (state, action) => {
  state.info = action.payload;
  if (action.payload.packages) {
    const { packages } = action.payload;
    packagesAdapter.setAll(state.packages, packages);
  }
  state.errors = {
    baseInfoError: null,
    publishError: null,
    packageError: null,
  };
};

const updateBaseInfoError: CaseReducer<IBaseInfoSliceState, PayloadAction<IError | undefined>> = (
  state,
  action
) => {
  state.info = null;
  state.errors.baseInfoError = action.payload || defaultError;
};

const updatePublishError: CaseReducer<IBaseInfoSliceState, PayloadAction<IError | undefined>> = (
  state,
  action
) => {
  state.errors.publishError = action.payload || defaultError;
};

const initialState: IBaseInfoSliceState = {
  mediaDialog: {
    isOpen: false,
    media: null,
  },
  info: null,
  packages: packagesAdapter.getInitialState({}),
  errors: {
    baseInfoError: null,
    publishError: null,
    packageError: null,
  },
};

const baseInfoSlice = createSlice({
  name: 'adminPortalSection',
  initialState,
  reducers: {
    openMediaDialog: (state, action) => {
      state.mediaDialog.isOpen = true;
      state.mediaDialog.media = action.payload.media;
    },
    closeMediaDialog: (state) => {
      state.mediaDialog.isOpen = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAddonBaseInfo.rejected, updateBaseInfoError)
      .addMatcher(
        isAnyOf(
          getAddonBaseInfo.fulfilled,
          publishAddonVersion.fulfilled,
          rejectAddonVersion.fulfilled
        ),
        updateBaseInfo
      )

      .addMatcher(
        isAnyOf(publishAddonVersion.rejected, rejectAddonVersion.rejected),
        updatePublishError
      );
  },
});

export const { openMediaDialog, closeMediaDialog } = baseInfoSlice.actions;

export const AddonMediaDialogSelector = ({ adminPortalSection }: RootState): IBaseInfoMediaDialog =>
  adminPortalSection.addonBaseInfo.mediaDialog;

export const addonBaseInfoSelector = ({
  adminPortalSection,
}: RootState): IPublishedAddonVersionBaseInfo | null => adminPortalSection.addonBaseInfo.info;

export const { selectAll: packagesSelector, selectById: packageByIdSelect } =
  packagesAdapter.getSelectors(
    ({ adminPortalSection }: RootState) => adminPortalSection.addonBaseInfo.packages
  );

export const addonBaseInfoErrorSelector = ({ adminPortalSection }: RootState): IError | null =>
  adminPortalSection.addonBaseInfo.errors.baseInfoError;

export const addonBaseInfoPublishErrorSelector = ({
  adminPortalSection,
}: RootState): IError | null => adminPortalSection.addonBaseInfo.errors.publishError;

export const addonPackageErrorSelector = ({ adminPortalSection }: RootState): string | null =>
  adminPortalSection.addonBaseInfo.errors.packageError;

export default baseInfoSlice.reducer;
