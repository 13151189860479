import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

import store from 'app/store';
import AuthService from 'app/auth/AuthService';
import { submitRefreshToken } from 'app/auth/store/userSlice';

interface RequestConfig extends AxiosRequestConfig {
  _isRetry?: boolean;
}

interface FailedRequest {
  resolve: (value: AxiosResponse) => void;
  reject: (reason?: any) => void;
  config: RequestConfig;
}

let hashOnClient: string | null = null;
let isRefreshing: boolean = false;
let failedRequestsQueue: FailedRequest[] = [];

const axiosInstance: AxiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_STORE_API_URL}`,
});

const addRequestToQueue = (config: RequestConfig): Promise<AxiosResponse> => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    failedRequestsQueue.push({ resolve, reject, config });
  });
};

const retryFailedRequests = (token: string): void => {
  failedRequestsQueue.forEach((request) => {
    if (request.config.headers) {
      request.config.headers.Authorization = `Bearer ${token}`;
    } else {
      request.config.headers = { Authorization: `Bearer ${token}` };
    }

    axiosInstance(request.config)
      .then((response) => {
        request.resolve(response);
      })
      .catch((error) => {
        request.reject(error);
      });
  });
  failedRequestsQueue = [];
};

axiosInstance.interceptors.request.use(
  (config) => {
    if (!config.headers['Content-Type']) {
      config.headers['Content-Type'] = 'application/json';
    }

    const accessToken = AuthService.getAccessToken();

    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error) => {
    throw error;
  }
);

axiosInstance.interceptors.response.use(
  async (response) => {
    const hashOnServer = response.headers?.['x-need-refresh-token'];

    if (hashOnServer && hashOnClient !== hashOnServer) {
      hashOnClient = hashOnServer;

      const refreshToken = AuthService.getRefreshToken();

      if (refreshToken) {
        store.dispatch(submitRefreshToken({ refreshToken }));
      }
    }

    return response;
  },
  async (error) => {
    const originalRequest = error.config as RequestConfig;

    const isUploadChunk =
      originalRequest.url?.startsWith(`${process.env.REACT_APP_STORE_STORAGE_URL}`) &&
      originalRequest.method === 'post';

    if (
      error.response.status === 401 &&
      !originalRequest._isRetry &&
      originalRequest.url !== '/token/refresh'
    ) {
      originalRequest._isRetry = true;
      const refreshToken = AuthService.getRefreshToken();

      if (!refreshToken) {
        throw error;
      }

      if (isRefreshing && !isUploadChunk) {
        return addRequestToQueue(originalRequest);
      }

      if (!isUploadChunk) {
        addRequestToQueue(originalRequest);
      }

      isRefreshing = true;

      store
        .dispatch(submitRefreshToken({ refreshToken }))
        .unwrap()
        .then((res) => {
          const { accessToken } = res;
          retryFailedRequests(accessToken);
          isRefreshing = false;
        })
        .catch((refreshError) => {
          isRefreshing = false;
          throw refreshError;
        });
    }

    throw error;
  }
);

export default axiosInstance;
