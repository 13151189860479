import { Editor, Transforms, Element } from 'slate';
import { ReactEditor } from 'slate-react';

import { CustomElement } from 'app/components/RichTextEditor/types';

export const createLinkNode = (href: string, text: string): CustomElement => ({
  type: 'link',
  href,
  children: [{ text }],
});

export const insertLink = (editor: Editor, url: string): void => {
  if (!url) return;
  const { selection } = editor;
  const link = createLinkNode(url, 'New Link');
  ReactEditor.focus(editor);

  if (selection) {
    const [foundParentNode, _] = Editor.parent(editor, selection.focus?.path);
    // Remove the Link node if we're inserting a new link node inside of another
    // link. type, children and href
    const parentNode = foundParentNode as Element;
    if (parentNode?.type === 'link') {
      removeLink(editor);
    }
    // Wrap the currently selected range of text into a Link
    Transforms.wrapNodes(editor, link, { split: true });
    Transforms.collapse(editor, { edge: 'end' });
  }
};

export const editLink = (editor: Editor, url: string): void => {
  if (!url) return;
  const link = createLinkNode(url, 'New Link');
  const { selection } = editor;
  if (selection) {
    const [foundParentNode, _] = Editor.parent(editor, selection.focus.path);
    const parentNode = foundParentNode as Element;
    const path = ReactEditor.findPath(editor, foundParentNode);
    if (parentNode.type === 'link') {
      Transforms.wrapNodes(editor, link, { split: true, at: path });
      Transforms.unwrapNodes(editor, {
        match: (n) =>
          !Editor.isEditor(n) &&
          Element.isElement(n) &&
          n.type === 'link' &&
          n.href === parentNode.href,
      });
    }
  }
};

export const removeLink = (editor: Editor, opts = {}): void => {
  Transforms.unwrapNodes(editor, {
    ...opts,
    match: (n) => !Editor.isEditor(n) && Element.isElement(n) && n.type === 'link',
  });
};
