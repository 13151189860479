import './MobileDialog.scss';

import { FC, ReactNode } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { ReactComponent as CloseIcon } from 'app/assets/icon_close.svg';
import UnigineButton from 'app/components/UnigineButton/UnigineButton';

interface IMobileDialogProps {
  isOpen: boolean;
  handleClose: () => void;
  title: string;
  children: ReactNode;
}

const MobileDialog: FC<IMobileDialogProps> = ({ isOpen, title, handleClose, children }) => (
  <Dialog fullScreen open={isOpen} onClose={handleClose} scroll="paper" className="dialog-mobile">
    <DialogTitle className="dialog-mobile__title">
      <IconButton color="inherit" onClick={handleClose} aria-label="close">
        <CloseIcon />
      </IconButton>
      {title}
    </DialogTitle>
    <DialogContent className="dialog-mobile__content">{children}</DialogContent>
    <DialogActions className="dialog-mobile__actions">
      <UnigineButton onClick={handleClose}>View Results</UnigineButton>
    </DialogActions>
  </Dialog>
);

export default MobileDialog;
