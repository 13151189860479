import axiosInstance from 'app/auth/axiosInstance';

import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
  EntityState,
  EntitySelectors,
} from '@reduxjs/toolkit';
import type { RootState } from 'app/store';
import ICartContent from 'app/interfaces/cart/ICartContent';

const cartAdapter = createEntityAdapter<ICartContent>();

interface ICartInfo {
  subtotal: number;
  totalItems: number;
}

interface ICartSliceState {
  contents: EntityState<ICartContent>;
  info: ICartInfo | null;
  error: null;
}

interface ICartData {
  cart_items: ICartContent[];
  sub_total: number;
  total: number;
}

interface ICartRemoveData {
  deleted_ids: string[];
  sub_total: number;
  total: number;
}

interface IAddToCartParams {
  versionId: string;
}

interface IRemoveFromCartParams {
  cartItemIds: string[];
}

const getCartData = createAsyncThunk<ICartData, undefined>('cart/getCartData', async () => {
  const response = await axiosInstance({
    url: `/cart`,
    method: 'get',
  });

  return response.data;
});

const addToCart = createAsyncThunk<ICartData, IAddToCartParams>(
  'cart/addToCart',
  async ({ versionId }) => {
    const response = await axiosInstance({
      url: `/cart`,
      method: 'post',
      data: {
        addon_version_id: versionId,
      },
    });

    return response.data;
  }
);

const removeFromCart = createAsyncThunk<ICartRemoveData, IRemoveFromCartParams>(
  'cart/removeFromCart',
  async ({ cartItemIds }) => {
    const response = await axiosInstance({
      url: `/cart`,
      method: 'delete',
      data: {
        cart_item_ids: cartItemIds,
      },
    });

    return response.data;
  }
);

const checkout = createAsyncThunk('cart/checkout', async () => {
  const response = await axiosInstance({
    url: `/orders`,
    method: 'post',
  });

  if (response.data) {
    return response.data.id;
  }

  return null;
});

const initialState: ICartSliceState = {
  contents: cartAdapter.getInitialState(),
  info: null,
  error: null,
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCartData.fulfilled, (state, action) => {
        const { cart_items: cartItems, total: totalItems, sub_total: subtotal } = action.payload;
        cartAdapter.setAll(state.contents, cartItems);
        state.info = {
          totalItems,
          subtotal,
        };
      })

      .addCase(addToCart.fulfilled, (state, action) => {
        const { cart_items: cartItems, total: totalItems, sub_total: subtotal } = action.payload;
        cartAdapter.setAll(state.contents, cartItems);
        state.info = {
          totalItems,
          subtotal,
        };
      })

      .addCase(removeFromCart.fulfilled, (state, action) => {
        const { deleted_ids: deletedIds, total: totalItems, sub_total: subtotal } = action.payload;
        cartAdapter.removeMany(state.contents, deletedIds);
        state.info = {
          totalItems,
          subtotal,
        };
      })

      .addCase(checkout.fulfilled, () => {
        return { ...initialState };
      });
  },
});

export { getCartData, addToCart, removeFromCart, checkout };

export const cartInfoSelector = ({ unigine }: RootState): ICartInfo | null => unigine.cart.info;
export const { selectAll: cartContentsSelector }: EntitySelectors<ICartContent, RootState> =
  cartAdapter.getSelectors((state) => state.unigine.cart.contents);

export default cartSlice.reducer;
