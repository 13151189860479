import { jsx } from 'slate-hyperscript';
import { Descendant } from 'slate';

type MarkAttributes = { [key: string]: boolean };
const deserialize = (el: string | Node, markAttributes: MarkAttributes = {}): Descendant[] => {
  if (typeof el === 'string') {
    if (!el) {
      return [{ type: 'p', children: [{ text: '' }] }];
    }
    el = new DOMParser().parseFromString(el, 'text/html').body;
  }

  if (el.nodeType === Node.TEXT_NODE) {
    return [jsx('text', markAttributes, el.textContent)];
  }
  if (el.nodeType !== Node.ELEMENT_NODE) {
    return [{ type: 'p', children: [{ text: '' }] }];
  }

  const nodeAttributes = { ...markAttributes };

  switch (el.nodeName) {
    case 'STRONG':
      nodeAttributes.bold = true;
      break;
    case 'EM':
      nodeAttributes.italic = true;
      break;
    case 'DEL':
      nodeAttributes.strike = true;
      break;
    default:
      break;
  }

  const children = Array.from(el.childNodes).flatMap((node) => deserialize(node, nodeAttributes));
  if (children.length === 0) {
    children.push(jsx('text', nodeAttributes, ''));
  }

  switch (el.nodeName) {
    case 'BODY':
      return jsx('fragment', {}, children);
    case 'UL':
      return [jsx('element', { type: 'ul' }, children)];
    case 'OL':
      return [jsx('element', { type: 'ol' }, children)];
    case 'LI':
      return [jsx('element', { type: 'li' }, children)];
    case 'P':
      return [jsx('element', { type: 'p' }, children)];
    case 'A':
      if (el instanceof HTMLAnchorElement) {
        return [jsx('element', { type: 'link', href: el.href }, children)];
      }
      return children;
    default:
      return children;
  }
};

export default deserialize;
