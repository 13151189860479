import { createSlice, createAsyncThunk, createEntityAdapter, EntityState } from '@reduxjs/toolkit';

import getPaginationParams, { IRowPaginationParams } from 'app/utils/helpers/getPaginationParams';
import axiosInstance from 'app/auth/axiosInstance';
import { RootState } from 'app/store';
import IError from 'app/interfaces/IError';
import { IPublishedAddonInList } from 'app/interfaces/addons/IAddon';

const publishedAddonsAdapter = createEntityAdapter<IPublishedAddonInList>();

interface IPublishedAddonsInfoSliceState {
  totalPages: number;
  addons: EntityState<IPublishedAddonInList>;
  errors: IError | null;
}

const initialState: IPublishedAddonsInfoSliceState = {
  addons: publishedAddonsAdapter.getInitialState({}),
  errors: null,
  totalPages: 1,
};

interface IAddonsList {
  addons: IPublishedAddonInList[];
  total: number;
  perPage: number;
}

export const getPublishedAddonsInfo = createAsyncThunk<
  IAddonsList,
  IRowPaginationParams,
  { rejectValue: IError }
>('publisherPanelSection/getPublishedAddonsInfo', async (props, { rejectWithValue }) => {
  try {
    const params = getPaginationParams(props);

    const response = await axiosInstance({
      method: 'get',
      url: '/publisher-panel/addons',
      params,
    });

    return { ...response.data, perPage: props.perPage };
  } catch (err: any) {
    if (err.response) {
      return rejectWithValue({ data: err.response.data, status: err.response.status });
    }

    return rejectWithValue({ data: { message: 'Something went wrong' }, status: 500 });
  }
});

const publishedAddonsInfoSlice = createSlice({
  name: 'publisherPanelSection',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPublishedAddonsInfo.fulfilled, (state, action) => {
        const { addons, total, perPage } = action.payload;
        const totalPages = Math.ceil(total / perPage);

        publishedAddonsAdapter.setAll(state.addons, addons);
        state.totalPages = totalPages;
        state.errors = null;
      })
      .addCase(getPublishedAddonsInfo.rejected, (state, action) => {
        state.totalPages = 1;
        state.errors = action.payload ?? {
          data: { message: 'Something went wrong' },
          status: 500,
        };
      });
  },
});

export const { selectAll: publishedAddonsSelector } = publishedAddonsAdapter.getSelectors(
  ({ publisherPanelSection }: RootState) => publisherPanelSection.publishedAddonsInfo.addons
);

export const publishedAddonsErrorsSelector = ({
  publisherPanelSection,
}: RootState): IError | null => publisherPanelSection.publishedAddonsInfo.errors;

export const totalPagesSelector = ({ publisherPanelSection }: RootState): number =>
  publisherPanelSection.publishedAddonsInfo.totalPages;

export default publishedAddonsInfoSlice.reducer;
