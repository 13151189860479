import { createSlice, createAsyncThunk, createEntityAdapter, EntityState } from '@reduxjs/toolkit';
import axiosInstance from 'app/auth/axiosInstance';

import getPaginationParams, { IRowPaginationParams } from 'app/utils/helpers/getPaginationParams';
import { getFreeAddon } from 'app/main/sections/Addon/store/baseInfoSlice';
import { RootState } from 'app/store';
import { IAddonInList } from 'app/interfaces/addons/IAddon';
import IError from 'app/interfaces/IError';

const addonsListAdapter = createEntityAdapter<IAddonInList>();

interface IAddonListSliceState {
  totalPages: number;
  addonVersions: EntityState<IAddonInList>;
  errors: IError | null;
}

interface IAddonsList {
  addonVersions: IAddonInList[];
  total: number;
  perPage: number;
}

export const getAddonsVersionsListData = createAsyncThunk<
  IAddonsList,
  IRowPaginationParams,
  { rejectValue: IError }
>('addonsListSection/getAddonsListData', async (props, { rejectWithValue }) => {
  try {
    const params = getPaginationParams(props);

    const response = await axiosInstance({
      method: 'get',
      url: '/addon-versions',
      params,
    });

    return { ...response.data, perPage: props.perPage };
  } catch (err: any) {
    if (err.response) {
      return rejectWithValue({ data: err.response.data, status: err.response.status });
    }

    return rejectWithValue({ data: { message: 'Something went wrong' }, status: 500 });
  }
});

const initialState: IAddonListSliceState = {
  totalPages: 1,
  addonVersions: addonsListAdapter.getInitialState({}),
  errors: null,
};

const addonsListSlice = createSlice({
  name: 'addonsListSection',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAddonsVersionsListData.fulfilled, (state, action) => {
        const { addonVersions, total, perPage } = action.payload;
        const totalPages = Math.ceil(total / perPage);

        addonsListAdapter.setAll(state.addonVersions, addonVersions);
        state.totalPages = totalPages;
        state.errors = null;
      })
      .addCase(getAddonsVersionsListData.rejected, (state, action) => {
        addonsListAdapter.removeAll(state.addonVersions);
        if (action.payload) {
          state.errors = action.payload;
        }
      })

      .addCase(getFreeAddon.fulfilled, (state, action) => {
        const { versionId } = action.payload;
        const addonInfo = state.addonVersions.entities[versionId]?.addon;
        if (addonInfo) {
          addonsListAdapter.updateOne(state.addonVersions, {
            id: versionId,
            changes: { addon: { ...addonInfo, ownedStatus: 'purchased' } },
          });
        }
      });
  },
});

export const { selectAll: selectAddonsVersions, selectById: selectAddonVersionById } =
  addonsListAdapter.getSelectors(
    ({ addonsListSection }: RootState) => addonsListSection.addonsList.addonVersions
  );

export const addonsListErrorsSelector = ({ addonsListSection }: RootState): IError | null =>
  addonsListSection.addonsList.errors;

export const totalPagesSelector = ({ addonsListSection }: RootState): number =>
  addonsListSection.addonsList.totalPages;

export default addonsListSlice.reducer;
