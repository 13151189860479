import { Dialog, DialogContent, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import UnigineButton from 'app/components/UnigineButton/UnigineButton';
import { FC } from 'react';

interface IAddonDialogMediaDelete {
  isOpen: boolean;
  handleClose: () => void;
  mediaLabel: string;
  allowToDeleteMedia?: boolean;
  handleConfirmDelete: () => void;
}

const AddonDialogMediaDelete: FC<IAddonDialogMediaDelete> = ({
  handleConfirmDelete,
  allowToDeleteMedia = false,
  handleClose,
  mediaLabel,
  isOpen,
}) => (
  <Dialog open={isOpen} fullWidth maxWidth="xs" onClose={handleClose} className="dialog">
    <DialogContent className="dialog-media__content dialog-media__content--confirm">
      {allowToDeleteMedia && (
        <>
          <div className="dialog-media__text">
            The {mediaLabel} will be deleted permanently.
            <br />
            Are you sure you want to continue?
          </div>
          <div className="dialog-media__buttons-container">
            <UnigineButton
              onClick={handleConfirmDelete}
              className="dialog-media__button dialog-media__button--confirm"
            >
              Yes
            </UnigineButton>
            <UnigineButton
              onClick={handleClose}
              className="dialog-media__button dialog-media__button--confirm"
            >
              No
            </UnigineButton>
          </div>
        </>
      )}

      {!allowToDeleteMedia && (
        <>
          <div className="dialog-media__text">
            The add-on version should be illustrated
            <br />
            with at least one image or video.
          </div>

          <div className="dialog-media__buttons-container">
            <UnigineButton
              onClick={handleClose}
              className="dialog-media__button dialog-media__button--confirm"
            >
              OK
            </UnigineButton>
          </div>
        </>
      )}

      <IconButton aria-label="close" className="dialog__close-button" onClick={handleClose}>
        <CloseIcon />
      </IconButton>
    </DialogContent>
  </Dialog>
);

export default AddonDialogMediaDelete;
