import { createSlice, createAsyncThunk, createEntityAdapter, EntityState } from '@reduxjs/toolkit';
import axiosInstance from 'app/auth/axiosInstance';

import { IOrderInList } from 'app/interfaces/orders/IOrders';
import getPaginationParams, { IRowPaginationParams } from 'app/utils/helpers/getPaginationParams';
import IError from 'app/interfaces/IError';
import { RootState } from 'app/store';

interface IOrdersSliceState {
  totalPages: number;
  orders: EntityState<IOrderInList>;
  errors: IError | null;
}

interface IOrdersList {
  orders: IOrderInList[];
  total: number;
  perPage: number;
}

export const getOrders = createAsyncThunk<
  IOrdersList,
  IRowPaginationParams,
  { rejectValue: IError }
>('ordersSection/getOders', async (props, { rejectWithValue }) => {
  try {
    const params = getPaginationParams(props);
    const response = await axiosInstance({
      method: 'get',
      url: '/account/orders',
      params,
    });

    return { ...response.data, perPage: props.perPage };
  } catch (err: any) {
    if (err.response) {
      return rejectWithValue({ data: err.response.data, status: err.response.status });
    }

    return rejectWithValue({ data: { message: 'Something went wrong' }, status: 500 });
  }
});

const ordersAdapter = createEntityAdapter<IOrderInList>({});

const initialState: IOrdersSliceState = {
  totalPages: 1,
  orders: ordersAdapter.getInitialState([]),
  errors: null,
};

const ordersSlice = createSlice({
  name: 'ordersSection/orders',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOrders.fulfilled, (state, action) => {
        const { orders, total, perPage } = action.payload;
        const totalPages = Math.ceil(total / perPage);
        ordersAdapter.setAll(state.orders, orders);
        state.totalPages = totalPages;
        state.errors = null;
      })
      .addCase(getOrders.rejected, (state, action) => {
        ordersAdapter.removeAll(state.orders);
        if (action.payload) {
          state.errors = action.payload;
        }
      });
  },
});

export const { selectAll: ordersSelector } = ordersAdapter.getSelectors(
  ({ ordersSection }: RootState) => ordersSection.ordersData.orders
);
export const totalPagesSelector = ({ ordersSection }: RootState): number =>
  ordersSection.ordersData.totalPages;
export const ordersErrorsSelector = ({ ordersSection }: RootState): IError | null =>
  ordersSection.ordersData.errors;

export default ordersSlice.reducer;
