import './AddonsSection.scss';

import { FC, useEffect, useMemo, useState } from 'react';
import { useSearchParams, Link } from 'react-router-dom';

import { CircularProgress } from '@mui/material';

import AddonTableList from 'app/components/AddonTableList/AddonTableList';
import FilterForm from 'app/components/FilterForm/FilterForm';
import SortControl from 'app/components/SortControl/SortControl';
import AddonDialog from 'app/main/sections/Addon/AddonDialog';
import PublishedItem from 'app/components/AddonTableItem/variants/PublishedItem/PublishedItem';
import UniginePagination from 'app/components/UniginePagination/UniginePagination';
import ErrorPage from 'app/main/errors/ErrorPage';

import useAppDispatch from 'app/hooks/useAppDispatch';
import useAppSelector from 'app/hooks/useAppSelector';
import usePermissions from 'app/hooks/usePermissions';
import getConcreteCategories from 'app/utils/helpers/getConcreteCategories';

import ISdkVersion from 'app/interfaces/addons/ISdkVersion';
import IProduct from 'app/interfaces/addons/IProduct';
import IAddonSourceCode from 'app/interfaces/addons/IAddonSourceCode';
import ITag from 'app/interfaces/addons/ITag';
import ICategory from 'app/interfaces/addons/ICategory';
import IAddonStatus from 'app/interfaces/addons/IAddonStatus';

import getCategoriesIds from 'app/utils/helpers/getCategoriesIds';
import getVersionsIds from 'app/utils/helpers/getVersionsIds';
import getProductsIds from 'app/utils/helpers/getProductsIds';
import getTagsCanonicalNames from 'app/utils/helpers/getTagsCanonicalNames';
import getSourceLanguageIds from 'app/utils/helpers/getSourceLanguageIds';
import getStatusIds from 'app/utils/helpers/getStatusIds';

import { categoriesSelector } from 'app/store/unigine/categoriesSlice';
import { getProductsData, selectProducts } from 'app/main/sections/Addons/store/productsSlice';
import {
  getTagsData,
  getTopTagsData,
  selectTags,
  selectTopTags,
} from 'app/main/sections/Addons/store/tagsSlice';
import { getVersionsData, selectVersions } from 'app/main/sections/Addons/store/versionsSlice';
import { getStatusData, selectStatus } from 'app/main/sections/Addons/store/statusSlice';
import {
  getSourceLanguageData,
  selectSourceLanguages,
} from 'app/main/sections/Addons/store/sourceLanguagesSlice';
import {
  getPublishedAddonsInfo,
  publishedAddonsErrorsSelector,
  publishedAddonsSelector,
  totalPagesSelector,
} from 'app/main/sections/PublisherPanel/sections/AddonsSection/store/publishedAddonsSlice';

import { purchasedAddonsPerPage } from 'app/configs/appConfig';

const PublishedAddonsSection: FC = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const { isNoRole } = usePermissions();

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingDictionaries, setIsLoadingDictionaries] = useState(true);

  const categories = useAppSelector(categoriesSelector);
  const categoriesData = useMemo(() => getConcreteCategories(categories), [categories]);

  const productsData = useAppSelector(selectProducts);
  const versionsData = useAppSelector(selectVersions);
  const tagsData = useAppSelector(selectTags);
  const topTagsData = useAppSelector(selectTopTags);
  const statusData = useAppSelector(selectStatus);
  const sourceLanguagesData = useAppSelector(selectSourceLanguages);

  const publishedAddonsData = useAppSelector(publishedAddonsSelector);
  const totalPages = useAppSelector(totalPagesSelector);
  const errors = useAppSelector(publishedAddonsErrorsSelector);

  const [page, search, sort, direction, perPage] = [
    searchParams.get('page'),
    searchParams.get('search'),
    searchParams.get('sort'),
    searchParams.get('direction'),
    searchParams.get('perPage'),
  ];

  const currentPage = parseInt(page || '1', 10);
  const currentAddonsPerPage = perPage ? parseInt(perPage, 10) : purchasedAddonsPerPage;

  useEffect(() => {
    Promise.all([
      dispatch(getVersionsData()),
      dispatch(getProductsData()),
      dispatch(getTagsData()),
      dispatch(getTopTagsData()),
      dispatch(getStatusData()),
      dispatch(getSourceLanguageData()),
    ]).then(() => {
      setIsLoadingDictionaries(false);
    });
  }, [dispatch]);

  useEffect(() => {
    if (!isLoadingDictionaries && !isNoRole) {
      setIsLoading(true);

      const productsParam = searchParams.getAll('products[]');
      const tagsParam = searchParams.getAll('tags[]');
      const categoriesParam = searchParams.getAll('categories[]');
      const versionsParam = searchParams.getAll('versions[]');
      const statusParam = searchParams.getAll('status[]');
      const sourceLanguageParam = searchParams.getAll('source[]');

      const price = {
        mode: searchParams.get('is_free'),
        min: searchParams.get('start_price'),
        max: searchParams.get('end_price'),
      };

      const categoriesIds = getCategoriesIds({ categoriesData, categoriesParam });
      const versionsIds = getVersionsIds({ versionsData, versionsParam });
      const productsIds = getProductsIds({ productsData, productsParam });
      const tagsCanonicalNames = getTagsCanonicalNames({ tagsData, tagsParam });
      const sourceLanguageIds = getSourceLanguageIds({ sourceLanguagesData, sourceLanguageParam });
      const statusIds = getStatusIds({ statusData, statusParam });

      dispatch(
        getPublishedAddonsInfo({
          perPage: currentAddonsPerPage,
          page: currentPage,
          categories: categoriesIds,
          products: productsIds,
          versions: versionsIds,
          tags: tagsCanonicalNames,
          statuses: statusIds,
          sourceLanguages: sourceLanguageIds,
          search,
          price,
          sort,
          direction,
        })
      ).then(() => setIsLoading(false));
    }
  }, [
    categoriesData,
    currentPage,
    direction,
    dispatch,
    isLoadingDictionaries,
    isNoRole,
    productsData,
    search,
    searchParams,
    sort,
    sourceLanguagesData,
    statusData,
    tagsData,
    versionsData,
    currentAddonsPerPage,
  ]);

  if (errors) {
    const { data, status } = errors;
    return <ErrorPage status={status} text={data.message} />;
  }

  return (
    <>
      <div className="add-on-table__header">
        <Link to="/publisherPanel/add-ons/add" className="add-add-on-button">
          + Create Add-On
        </Link>
      </div>
      <div className="add-on-table__wrapper columns">
        <div className="add-on-table__column add-on-table__column--main columns__column--content">
          <div className="add-on-table__title-wrapper">
            <h1 className="add-on-table__title">My Uploaded Add-Ons</h1>

            <SortControl
              name="sort"
              options={[
                { value: 'title', label: 'Title' },
                { value: 'status', label: 'Version status' },
                { value: 'totalRating', label: 'Rating' },
              ]}
            />
          </div>

          {isLoading && (
            <div className="add-on-list__loading-container">
              <CircularProgress color="inherit" className="add-on-list__loading" />
            </div>
          )}

          {!isLoading && publishedAddonsData && (
            <AddonTableList
              items={publishedAddonsData}
              placeholderText="No published add-ons"
              isLoading={isLoading}
              renderItem={(item) => (
                <PublishedItem
                  id={item.id}
                  title={item.title}
                  coverId={item.lastAddonVersion.coverFile?.id}
                  categories={item.lastAddonVersion.categories}
                  versionNumber={item.lastAddonVersion.versionNumber}
                  status={item.addonStatus.slug}
                  updateDate={item.lastAddonVersion.updateDate}
                  addonVersionStatus={item.lastAddonVersion.status}
                  linkTo={`/publisherPanel/add-ons/${item.id}`}
                />
              )}
            />
          )}

          {!isLoading && publishedAddonsData && publishedAddonsData.length !== 0 && (
            <div className="pagination__container">
              <UniginePagination
                currentPage={currentPage}
                totalPages={totalPages}
                defaultValue={purchasedAddonsPerPage}
              />
            </div>
          )}
        </div>

        {!isLoadingDictionaries && (
          <div className="add-on-table__column add-on-table__column--side columns__column--side">
            <FilterForm
              search={{
                name: 'search',
                label: 'Search in uploaded add-ons',
              }}
              filterParams={[
                {
                  name: 'categories',
                  title: 'Categories',
                  type: 'checkbox',
                  options: categoriesData || [],
                  getLabel: (item: ICategory) => item.name,
                  getValue: (item: ICategory) => item.slug,
                  collapsed: false,
                },
                {
                  name: 'versions',
                  title: 'Supported versions',
                  type: 'checkbox',
                  options: versionsData || [],
                  getLabel: (item: ISdkVersion) => item.value,
                  getValue: (item: ISdkVersion) => item.slug,
                  collapsed: false,
                },
                {
                  name: 'products',
                  title: 'Products',
                  type: 'checkbox',
                  options: productsData || [],
                  getLabel: (item: IProduct) => item.name,
                  getValue: (item: IProduct) => item.slug,
                  collapsed: false,
                },
                {
                  name: 'status',
                  title: 'Status',
                  type: 'checkbox',
                  options: statusData || [],
                  getLabel: (item: IAddonStatus) => item.value,
                  getValue: (item: IAddonStatus) => item.slug,
                  collapsed: false,
                },
                {
                  name: 'source',
                  title: 'Source code',
                  type: 'checkbox',
                  options: sourceLanguagesData || [],
                  getLabel: (item: IAddonSourceCode) => item.value,
                  getValue: (item: IAddonSourceCode) => item.slug,
                  collapsed: false,
                },
                {
                  name: 'tags',
                  title: 'Tags',
                  type: 'tags',
                  options: topTagsData || [],
                  getLabel: (item: ITag) => item.value,
                  getValue: (item: ITag) => item.value,
                },
              ]}
            />
          </div>
        )}

        <AddonDialog hidePurchaseButton />
      </div>
    </>
  );
};

export default PublishedAddonsSection;
