import { Dialog, DialogContent, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import UnigineButton from 'app/components/UnigineButton/UnigineButton';
import { FC } from 'react';

interface IAddonPackageDeleteDialog {
  isOpen: boolean;
  onClose: () => void;
  onConfirmDelete: () => void;
}

const AddonPackageDeleteDialog: FC<IAddonPackageDeleteDialog> = ({
  onConfirmDelete,
  onClose,
  isOpen,
}) => (
  <Dialog open={isOpen} fullWidth maxWidth="xs" onClose={onClose} className="dialog">
    <DialogContent className="dialog-media__content dialog-media__content--confirm">
      <div className="dialog-media__text">
        The package will be deleted permanently.
        <br />
        Are you sure you want to continue?
      </div>
      <div className="dialog-media__buttons-container">
        <UnigineButton
          onClick={onConfirmDelete}
          className="dialog-media__button dialog-media__button--confirm"
        >
          Yes
        </UnigineButton>
        <UnigineButton
          onClick={onClose}
          className="dialog-media__button dialog-media__button--confirm"
        >
          No
        </UnigineButton>
      </div>

      <IconButton aria-label="close" className="dialog__close-button" onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </DialogContent>
  </Dialog>
);

export default AddonPackageDeleteDialog;
