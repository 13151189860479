import { cartContentsSelector } from 'app/store/unigine/cartSlice';
import useAppSelector from 'app/hooks/useAppSelector';

const useInCart = (addonId: string | null): boolean => {
  const cartContents = useAppSelector(cartContentsSelector);
  if (!addonId) {
    return false;
  }

  return !!cartContents.find((cartItem) => cartItem.addonVersion.addon.id === addonId);
};

export default useInCart;
