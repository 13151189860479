import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import axiosInstance from 'app/auth/axiosInstance';
import IError from 'app/interfaces/IError';
import { IOrder } from 'app/interfaces/orders/IOrders';
import { RootState } from 'app/store';

interface IOrderId {
  orderId: string;
}

interface IOrderSliceState {
  order: IOrder | null;
  errors: IError | null;
  actionError: any; // TO DO figure out action error type
}

export const getOrder = createAsyncThunk<IOrder, IOrderId, { rejectValue: IError }>(
  'ordersSection/getOrder',
  async ({ orderId }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance({
        method: 'get',
        url: `/account/orders/${orderId}`,
      });

      return response.data;
    } catch (err: any) {
      if (err.response) {
        return rejectWithValue({ data: err.response.data, status: err.response.status });
      }

      return rejectWithValue({
        data: { message: 'Something went wrong to get order info' },
        status: 500,
      });
    }
  }
);

export const getPaymentToken = createAsyncThunk<any, IOrderId>(
  'ordersSection/getPaymentToken',
  async ({ orderId }) => {
    const response = await axiosInstance({
      method: 'post',
      url: `/order/${orderId}/getPaymentToken`,
      data: {
        return_url: `${process.env.REACT_APP_STORE_HOST_URL}/orders/${orderId}`,
      },
    });

    const { token } = await response.data;
    return token;
  }
);

export const cancelPayment = createAsyncThunk<void, IOrderId, { rejectValue: IError }>(
  'ordersSection/cancelPayment',
  async ({ orderId }) => {
    await axiosInstance({
      method: 'post',
      url: `/orders/${orderId}/payments/cancel`,
    });
  }
);

const initialState: IOrderSliceState = {
  order: null,
  errors: null,
  actionError: null,
};

const orderSlice = createSlice({
  name: 'ordersSection/order',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOrder.fulfilled, (state, action) => {
        state.order = action.payload;
        state.errors = null;
      })
      .addCase(getOrder.rejected, (state, action) => {
        state.order = null;
        if (action.payload) {
          state.errors = action.payload;
        }
      })
      .addMatcher(isAnyOf(getPaymentToken.pending, cancelPayment.pending), (state) => {
        state.actionError = null;
      })
      .addMatcher(isAnyOf(getPaymentToken.rejected, cancelPayment.rejected), (state, action) => {
        if (action.payload) {
          state.actionError = action.payload;
        }
      });
  },
});

export const orderSelector = ({ ordersSection }: RootState): IOrder | null =>
  ordersSection.displayedOrder.order;

export const orderErrorsSelector = ({ ordersSection }: RootState): IError | null =>
  ordersSection.displayedOrder.errors;

export default orderSlice.reducer;
