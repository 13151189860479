import IAddonStatus from 'app/interfaces/addons/IAddonStatus';

interface IGetStatusIds {
  statusData: IAddonStatus[] | null;
  statusParam: string[];
}

const getStatusIds = ({ statusData, statusParam }: IGetStatusIds): number[] | null =>
  statusData &&
  statusData.filter((status) => statusParam.includes(status.slug)).map((status) => status.id);

export default getStatusIds;
