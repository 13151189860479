import { combineReducers } from '@reduxjs/toolkit';

import addonReviews from 'app/main/sections/Addon/store/reviewsSlice';
import addonBaseInfo from 'app/main/sections/Addon/store/baseInfoSlice';

const reducer = combineReducers({
  addonBaseInfo,
  addonReviews,
});

export default reducer;
