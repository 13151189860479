import './ReviewsSection.scss';

import { useState, useEffect, useMemo, FC } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';

import useAppDispatch from 'app/hooks/useAppDispatch';
import useAppSelector from 'app/hooks/useAppSelector';

import PublisherReviewCard from 'app/components/PublisherReviewCard/PublisherReviewCard';
import SortControl from 'app/components/SortControl/SortControl';
import FilterForm from 'app/components/FilterForm/FilterForm';
import ReviewReplyForm from 'app/components/ReviewReplyForm/ReviewReplyForm';
import UniginePagination from 'app/components/UniginePagination/UniginePagination';

import {
  getReviewsData,
  postReply,
  reportReview,
  reviewsErrorSelector,
  reviewsInfoSelector,
  reviewsTotalPagesSelector,
  updateReply,
} from 'app/main/sections/PublisherPanel/sections/ReviewsSection/store/reviewsSlice';

import { reviewsPerPage } from 'app/configs/appConfig';

const ReviewsSection: FC = () => {
  const [searchParams] = useSearchParams();
  const [page, sort, direction, isReplied, searchTitle, perPage] = [
    searchParams.get('page'),
    searchParams.get('sort') || undefined,
    searchParams.get('direction') || undefined,
    searchParams.get('isReplied') || undefined,
    searchParams.get('searchTitle') || undefined,
    searchParams.get('perPage'),
  ];

  const currentPage = parseInt(page || '1', 10);
  const currentReviewsPerPage = perPage ? parseInt(perPage, 10) : reviewsPerPage;

  const [loading, setLoading] = useState(true);
  const dispatch = useAppDispatch();

  const reviewsInfo = useAppSelector(reviewsInfoSelector);
  const reviewsError = useAppSelector(reviewsErrorSelector);
  const totalPages = useAppSelector(reviewsTotalPagesSelector);

  const [replyingToReviewId, setReplyingToReviewId] = useState<string | null>(null);
  const [isSubmittingReply, setIsSubmittingReply] = useState(false);
  const replyingToReview = useMemo(
    () => reviewsInfo?.find((review) => review.id === replyingToReviewId),
    [reviewsInfo, replyingToReviewId]
  );
  const existingReplyText = useMemo(() => replyingToReview?.reply[0]?.text, [replyingToReview]);

  useEffect(() => {
    setLoading(true);
    dispatch(
      getReviewsData({
        perPage: currentReviewsPerPage,
        page: currentPage,
        sort,
        direction,
        isReplied,
        searchTitle,
      })
    ).finally(() => setLoading(false));
  }, [dispatch, currentPage, sort, direction, isReplied, searchTitle, currentReviewsPerPage]);

  const handleReport = (reviewId: string): void => {
    dispatch(reportReview({ commentId: reviewId }));
  };
  const handleReply = (reviewId: string): void => {
    setReplyingToReviewId(reviewId);
  };

  const handleSubmitReply: Parameters<typeof ReviewReplyForm>[0]['onSubmit'] = async (
    reviewReplyData
  ) => {
    if (!replyingToReviewId || !replyingToReview) {
      return;
    }
    const { text } = reviewReplyData;

    const action =
      replyingToReview.reply.length > 0
        ? updateReply({ replyId: replyingToReview.reply[0].id, reviewId: replyingToReviewId, text })
        : postReply({ reviewId: replyingToReviewId, text });

    setIsSubmittingReply(true);
    dispatch(action).then(() => {
      setReplyingToReviewId(null);
      setIsSubmittingReply(false);
    });
  };

  const handleCancelReply = (): void => {
    setReplyingToReviewId(null);
  };

  return (
    <div className="publisher-panel__wrapper--no-header">
      <div className="add-on-table__wrapper columns">
        <div className="add-on-table__column add-on-table__column--main columns__column--content">
          <div className="add-on-table__title-wrapper">
            <h1 className="add-on-table__title">Reviews</h1>

            <SortControl
              name="sort"
              options={[
                { value: 'title', label: 'Add-On' },
                { value: 'createDate', label: 'Date' },
                { value: 'rating', label: 'Rating' },
              ]}
            />
          </div>

          {loading && (
            <div className="add-on-list__loading-container">
              <CircularProgress color="inherit" className="add-on-list__loading" />
            </div>
          )}

          {reviewsError && !loading && (
            <div className="add-on-list__loading-container">{reviewsError.data.message}</div>
          )}

          {!reviewsError && !loading && reviewsInfo && (
            <div className="reviews-section__cards-wrapper">
              {reviewsInfo.map((review) => (
                <PublisherReviewCard
                  key={review.id}
                  reviewData={review}
                  onReply={() => handleReply(review.id)}
                  onReport={() => handleReport(review.id)}
                  onEditReply={() => handleReply(review.id)}
                />
              ))}
            </div>
          )}

          {!reviewsError && !loading && reviewsInfo && reviewsInfo.length !== 0 && (
            <div className="pagination__container">
              <UniginePagination
                currentPage={currentPage}
                totalPages={totalPages}
                defaultValue={reviewsPerPage}
              />
            </div>
          )}
        </div>
        <div className="add-on-table__column add-on-table__column--side columns__column--side">
          <FilterForm
            price={false}
            search={{
              name: 'searchTitle',
              label: 'Search by Add-On',
            }}
            filterParams={[
              {
                name: 'isReplied',
                title: 'Status',
                type: 'radio',
                options: [
                  { value: 'all', label: 'All' },
                  { value: 'true', label: 'Replied' },
                  { value: 'false', label: 'Unreplied' },
                ],
                nullValue: 'all',
                getLabel: (item) => item.label,
                getValue: (item) => item.value,
                collapsed: false,
              },
            ]}
          />
        </div>
        {replyingToReview && (
          <ReviewReplyForm
            key={replyingToReviewId}
            open={!!replyingToReviewId}
            isWaiting={isSubmittingReply}
            review={replyingToReview}
            defaultValue={existingReplyText}
            onSubmit={handleSubmitReply}
            onCancel={handleCancelReply}
          />
        )}
      </div>
    </div>
  );
};

export default ReviewsSection;
