import './AddAddonFormSection.scss';

import { useEffect, useState, useMemo, useId, FC } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import useAppSelector from 'app/hooks/useAppSelector';
import useAppDispatch from 'app/hooks/useAppDispatch';
import { useForm, useFieldArray, useWatch, Controller } from 'react-hook-form';

import moment from 'moment';
import { FormControlLabel, TextField, FormHelperText } from '@mui/material';

import CheckboxGroup from 'app/components/CheckboxGroup/CheckboxGroup';
import { FormGrid, FormRow } from 'app/components/FormGrid/FormGrid';
import UnigineButton from 'app/components/UnigineButton/UnigineButton';
import RichTextEditor from 'app/components/RichTextEditor/RichTextEditor';
import TagsAutocomplete from 'app/components/TagsAutocomplete/TagsAutocomplete';
import CheckboxUnigine from 'app/components/CheckboxUnigine/CheckboxUnigine';
import DiscountFields from 'app/components/DiscountFields/DiscountFields';
import PriceInput from 'app/components/PriceInput/PriceInput';
import Tip from 'app/components/Tip/Tip';
import CreateAddonNotification from 'app/components/CreateAddonNotification/CreateAddonNotification';

import { categoriesSelector } from 'app/store/unigine/categoriesSlice';
import { getProductsData, selectProducts } from 'app/main/sections/Addons/store/productsSlice';
import { formFieldErrors, tips, tooltips } from 'app/utils/constants/contentConstants';
import { productSlugsSortedByTier, regExps } from 'app/configs/appConfig';
import getConcreteCategories from 'app/utils/helpers/getConcreteCategories';

import UnigineTooltip from 'app/components/Tooltip/Tooltip';
import { addAddon } from 'app/main/sections/PublisherPanel/sections/AddAddonFormSection/store/addAddonSlice';

import { IAddAddonFieldValues } from 'app/interfaces/addons/IAddAddon';
import IProduct from 'app/interfaces/addons/IProduct';

const FREE_ONLY = true;

const productDefaultValue: IProduct = {
  id: '',
  name: '',
  slug: '',
};

const discountDefaultValue = {
  percentage: 0,
  from: moment(new Date()).format('YYYY-MM-DD'),
  till: null,
  quantity: null,
};

const AddAddonFormSection: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const formId = useId();
  const [isLoading, setIsLoading] = useState(true);
  const categories = useAppSelector(categoriesSelector);
  const concreteCategories = useMemo(() => getConcreteCategories(categories), [categories]);

  const productsData = useAppSelector(selectProducts);

  useEffect(() => {
    Promise.all([dispatch(getProductsData())]).then(() => {
      setIsLoading(false);
    });
  }, [dispatch]);

  const { control, handleSubmit, setError } = useForm<IAddAddonFieldValues>({
    mode: 'onTouched',
    defaultValues: {
      title: '',
      price: '0',
      isFree: true,
      discounts: [],
      categories: [],
      products: [],
      tags: [],
      description: '',
      requirements: '',
    },
  });

  const { fields, append, remove } = useFieldArray({ name: 'discounts', control });

  const isFree = useWatch({ control, name: 'isFree' });
  const selectedProducts = useWatch({ control, name: 'products' });

  // list of products from "narrowest" to "broadest" feature set.
  // Any add-on that works with a given product
  // must work with all "broader" products as well.
  const productsSortedByTier = useMemo(
    () =>
      productSlugsSortedByTier.map((slug) => {
        const foundProduct = productsData?.find((prod) => prod.slug === slug);
        if (!foundProduct) {
          return productDefaultValue;
        }
        return foundProduct;
      }),
    [productsData]
  );

  // lock the product options broader than the narrowest one selected
  const disabledProducts = useMemo(() => {
    const narrowestProductIndex = productsSortedByTier.findIndex((prod) =>
      selectedProducts.find((prodId) => prod?.id === prodId)
    );
    if (narrowestProductIndex < 0) {
      return [];
    }
    return productsSortedByTier
      .slice(narrowestProductIndex + 1, productsSortedByTier.length)
      .map((prod) => prod?.id);
  }, [productsSortedByTier, selectedProducts]);

  // auto-select all broader options
  const handleProductChange = (newSelectedProducts: string[]): string[] => {
    const narrowestProductIndex = productsSortedByTier.findIndex((prod) =>
      newSelectedProducts.find((prodId) => prod?.id === prodId)
    );
    if (narrowestProductIndex < 0) {
      return newSelectedProducts;
    }
    return productsSortedByTier
      .slice(narrowestProductIndex, productsSortedByTier.length)
      .map((prod) => prod?.id);
  };

  const onFormSubmit = async (addonData: IAddAddonFieldValues): Promise<void> => {
    try {
      const { id } = await dispatch(addAddon({ addonData })).unwrap();
      navigate(`/publisherPanel/add-ons/${id}`);
    } catch (err: any) {
      const {
        data: { context },
      } = err;
      if (Array.isArray(context)) {
        context.forEach((error) => setError(error.name, { message: error.message }));
      } else {
        console.error('Could not create add-on:', err.message, '\n', err);
      }
    }
  };

  const handleScroll = (elementId: string): void => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  if (isLoading) {
    return null;
  }

  return (
    <div className="add-add-on publisher-panel__wrapper--no-header">
      <h1 className="add-add-on__title">
        <Link to="/publisherPanel/add-ons" className="add-add-on__title-link">
          My Uploaded Add-Ons
        </Link>{' '}
        / Create add-on
      </h1>

      <form onSubmit={handleSubmit(onFormSubmit)} className="add-add-on__form add-on-form">
        <FormGrid>
          <FormRow
            required
            label="Title"
            labelledInputId="add_add_on_input_title"
            tooltip={tooltips.addonForm.title}
          >
            <Controller
              control={control}
              name="title"
              rules={{
                required: formFieldErrors.required,
                maxLength: { value: 200, message: 'Title must be at most 200 characters long' },
                pattern: {
                  value: /^[ a-zA-Z0-9@#+\-%&*\\/,._()<>[\]{}]+$/,
                  message:
                    'Title can only contain Latin characters, numbers, spaces, and the characters @ # + - % & * \\ / , . _ ( ) [ ] { } < >',
                },
              }}
              render={({ field, fieldState: { error } }) => {
                if (error) {
                  handleScroll(`${formId}_title`);
                }
                return (
                  <UnigineTooltip title={tooltips.addonForm.title}>
                    <TextField
                      {...field}
                      size="small"
                      className="unigine-form-field"
                      variant="outlined"
                      fullWidth
                      required
                      inputProps={{
                        id: `${formId}_title`,
                      }}
                      error={!!error}
                      helperText={error?.message}
                    />
                  </UnigineTooltip>
                );
              }}
            />
          </FormRow>

          <FormRow
            label="Price"
            labelledInputId="add_add_on_input_price"
            required={!isFree || !FREE_ONLY}
            tooltip={tooltips.addonForm.price}
          >
            <div className="add-on-form__price-section-wrapper">
              <div className="add-on-form__price-section">
                <Controller
                  control={control}
                  name="price"
                  rules={{
                    required: isFree || FREE_ONLY ? '' : formFieldErrors.required,
                    min: {
                      value: isFree || FREE_ONLY ? 0 : 5,
                      message: formFieldErrors.priceMinValue,
                    },
                    max: { value: 999999.999, message: formFieldErrors.priceMaxValue },
                  }}
                  render={({ field, fieldState: { error } }) => {
                    if (error) {
                      handleScroll(`${formId}_input_price`);
                    }
                    return (
                      <UnigineTooltip title={tooltips.addonForm.price}>
                        <div>
                          <TextField
                            {...field}
                            size="small"
                            className="unigine-form-field"
                            required={!isFree && !FREE_ONLY}
                            disabled={!!isFree || FREE_ONLY}
                            InputProps={{
                              inputComponent: PriceInput,
                              id: `${formId}_input_price`,
                            }}
                            error={!!error}
                            helperText={error?.message}
                          />
                        </div>
                      </UnigineTooltip>
                    );
                  }}
                />
                <Controller
                  control={control}
                  name="isFree"
                  render={({ field: { onChange, value } }) => (
                    <FormControlLabel
                      label="Free add-on"
                      className="add-on-form__is-free"
                      control={
                        <CheckboxUnigine
                          className="add-on-form__is-free-checkbox"
                          onChange={onChange}
                          checked={value || FREE_ONLY}
                          disabled={FREE_ONLY}
                        />
                      }
                    />
                  )}
                />
              </div>
              {FREE_ONLY && (
                <Tip className="add-on-form__price-section-tip" content={tips.freeAddons} />
              )}
            </div>
          </FormRow>

          <FormRow label="Discounts" tooltip={tooltips.addonForm.discounts}>
            <div className="add-on-form__discounts">
              {fields.map((field, idx) => (
                <DiscountFields
                  // register={register}
                  control={control}
                  key={field.id}
                  arrayName="discounts"
                  index={idx}
                  removeRow={() => remove(idx)}
                />
              ))}
              <UnigineTooltip title={tooltips.addonForm.discounts}>
                <UnigineButton
                  className="add-on-form__discount-add-btn"
                  onClick={() => append({ ...discountDefaultValue })}
                  disabled={isFree || FREE_ONLY}
                >
                  Add discount
                </UnigineButton>
              </UnigineTooltip>
            </div>
          </FormRow>

          <FormRow required label="Categories" tooltip={tooltips.addonForm.categories}>
            <Controller
              control={control}
              name="categories"
              rules={{
                validate: (value) => value.length > 0 || 'Please select at least one value',
              }}
              render={({ field: { onChange, value }, fieldState: { error } }) => {
                if (error && value.length === 0) {
                  handleScroll(`${formId}_categories`);
                }
                return (
                  <CheckboxGroup
                    id={`${formId}_categories`}
                    items={concreteCategories}
                    selectedValues={value}
                    getLabel={(item) => item.name}
                    getValue={(item) => item.id}
                    onChange={onChange}
                    error={error}
                  />
                );
              }}
            />
          </FormRow>

          <FormRow required label="Required Licences" tooltip={tooltips.addonForm.products}>
            <Controller
              control={control}
              name="products"
              rules={{
                validate: (value) => value.length > 0 || 'Please select at least one value',
              }}
              render={({ field: { onChange, value }, fieldState: { error } }) => {
                if (error && value.length === 0) {
                  handleScroll(`${formId}_products`);
                }
                return (
                  <CheckboxGroup
                    id={`${formId}_products`}
                    items={productsData || []}
                    selectedValues={value}
                    getLabel={(item) => item.name}
                    getValue={(item) => item.id}
                    getIsDisabled={(item) => disabledProducts.includes(item.id)}
                    onChange={(newSelection) => onChange(handleProductChange(newSelection))}
                    error={error}
                  />
                );
              }}
            />
          </FormRow>

          <FormRow label="Tags" tooltip={tooltips.addonForm.tags}>
            <Controller
              control={control}
              name="tags"
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <>
                  <UnigineTooltip title={tooltips.addonForm.tags}>
                    <TagsAutocomplete
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      variant="outlined"
                      validation={{
                        pattern: regExps.tags,
                        message: formFieldErrors.tagsCharacters,
                      }}
                    />
                  </UnigineTooltip>
                  {error && <FormHelperText error>{error.message}</FormHelperText>}
                </>
              )}
            />
          </FormRow>

          <FormRow label="Description" required tooltip={tooltips.addonForm.description}>
            <Controller
              control={control}
              name="description"
              rules={{
                maxLength: {
                  value: 1000,
                  message: formFieldErrors.richTextLength,
                },
                pattern: {
                  value: regExps.richText,
                  message: formFieldErrors.richTextCharacters,
                },
                validate: (value) =>
                  (value.length > 0 && value !== '<p></p>') || formFieldErrors.required,
              }}
              render={({ field: { onChange, value }, fieldState: { error } }) => {
                if (error && value === '<p></p>') {
                  handleScroll(`${formId}_description`);
                }
                return (
                  <>
                    <UnigineTooltip title={tooltips.addonForm.description}>
                      <RichTextEditor
                        id={`${formId}_description`}
                        initialHtml={value}
                        onChange={onChange}
                        error={error}
                      />
                    </UnigineTooltip>
                    {error && <FormHelperText error>{error.message}</FormHelperText>}
                  </>
                );
              }}
            />
          </FormRow>

          <FormRow label="System requirements" tooltip={tooltips.addonForm.sysreq}>
            <Controller
              control={control}
              name="requirements"
              rules={{
                maxLength: {
                  value: 1000,
                  message: formFieldErrors.richTextLength,
                },
                pattern: {
                  value: regExps.richText,
                  message: formFieldErrors.richTextCharacters,
                },
              }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <>
                  <UnigineTooltip title={tooltips.addonForm.sysreq}>
                    <RichTextEditor initialHtml={value} onChange={onChange} error={error} />
                  </UnigineTooltip>
                  {error && <FormHelperText error>{error.message}</FormHelperText>}
                </>
              )}
            />
          </FormRow>
        </FormGrid>
        <UnigineButton type="submit" className="add-on-form__submit-btn">
          Create Add-On
        </UnigineButton>
      </form>
      <CreateAddonNotification />
    </div>
  );
};

export default AddAddonFormSection;
