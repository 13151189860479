import { userIdSelector, userSelector } from 'app/auth/store/userSlice';
import useAppSelector from 'app/hooks/useAppSelector';
import { IUserData } from 'app/interfaces/IUserData';

type UseLoggedInUser = () => { isLoggedIn: boolean; userData: IUserData };

const useLoggedInUser: UseLoggedInUser = () => {
  const { userData } = useAppSelector(userSelector);
  const userId = useAppSelector(userIdSelector);
  const isLoggedIn = !!userId;

  return { isLoggedIn, userData };
};

export default useLoggedInUser;
