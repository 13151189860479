import './PurchasedAddons.scss';

import { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { CircularProgress } from '@mui/material';

import ErrorPage from 'app/main/errors/ErrorPage';
import AddonDialog from 'app/main/sections/Addon/AddonDialog';

import AddonTableList from 'app/components/AddonTableList/AddonTableList';
import StorefrontHeader from 'app/components/StorefrontHeader/StorefrontHeader';
import FilterForm from 'app/components/FilterForm/FilterForm';
import SortControl from 'app/components/SortControl/SortControl';
import UniginePagination from 'app/components/UniginePagination/UniginePagination';

import useLoginOnGuests from 'app/hooks/useLoginOnGuests';
import useMobileDialog from 'app/hooks/useMobileDialog';
import useAppDispatch from 'app/hooks/useAppDispatch';
import useAppSelector from 'app/hooks/useAppSelector';
import getConcreteCategories from 'app/utils/helpers/getConcreteCategories';
import getCategoriesIds from 'app/utils/helpers/getCategoriesIds';
import getVersionsIds from 'app/utils/helpers/getVersionsIds';
import getProductsIds from 'app/utils/helpers/getProductsIds';
import getTagsCanonicalNames from 'app/utils/helpers/getTagsCanonicalNames';

import ISdkVersion from 'app/interfaces/addons/ISdkVersion';
import IProduct from 'app/interfaces/addons/IProduct';
import ITag from 'app/interfaces/addons/ITag';
import ICategory from 'app/interfaces/addons/ICategory';

import { getProductsData, selectProducts } from 'app/main/sections/Addons/store/productsSlice';
import {
  getTagsData,
  getTopTagsData,
  selectTags,
  selectTopTags,
} from 'app/main/sections/Addons/store/tagsSlice';
import { getVersionsData, selectVersions } from 'app/main/sections/Addons/store/versionsSlice';
import { userSelector } from 'app/auth/store/userSlice';
import { categoriesSelector } from 'app/store/unigine/categoriesSlice';

import PurchasedItem from 'app/components/AddonTableItem/variants/PurchasedItem/PurchasedItem';
import MobileDialog from 'app/components/MobileDialog/MobileDialog';
import MobileButtons from 'app/components/MobileButtons/MobileButtons';

import {
  getPurchasedAddonsInfo,
  purchasedAddonsErrorsSelector,
  purchasedAddonsSelector,
  totalPagesSelector,
} from 'app/main/sections/PurchasedAddons/store/purchasedAddonsSlice';

import { openAddonDialog } from 'app/main/sections/Addon/store/baseInfoSlice';

import { purchasedAddonsPerPage } from 'app/configs/appConfig';

const PurchasedAddons: FC = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingDictionaries, setIsLoadingDictionaries] = useState(true);
  const {
    isFiltersOpen,
    isSortOpen,
    handleCloseFilters,
    handleCloseSort,
    handleFiltersClick,
    handleSortClick,
  } = useMobileDialog();

  const categories = useAppSelector(categoriesSelector);
  const categoriesData = useMemo(() => getConcreteCategories(categories), [categories]);

  const productsData = useAppSelector(selectProducts);
  const versionsData = useAppSelector(selectVersions);
  const tagsData = useAppSelector(selectTags);
  const topTagsData = useAppSelector(selectTopTags);
  const purchasedAddonsData = useAppSelector(purchasedAddonsSelector);
  const totalPages = useAppSelector(totalPagesSelector);
  const errors = useAppSelector(purchasedAddonsErrorsSelector);
  const user = useAppSelector(userSelector);

  const [page, search, sort, direction, perPage] = [
    searchParams.get('page'),
    searchParams.get('search'),
    searchParams.get('sort'),
    searchParams.get('direction'),
    searchParams.get('perPage'),
  ];

  const currentPage = parseInt(page || '1', 10);
  const currentPurchasedAddonsPerPage = perPage ? parseInt(perPage, 10) : purchasedAddonsPerPage;

  useLoginOnGuests();

  const handleItemClick = (id: string): void => {
    if (window.innerWidth <= 1024) {
      navigate(`/add-on/${id}`);
    } else {
      dispatch(openAddonDialog({ id }));
    }
  };

  useEffect(() => {
    Promise.all([
      dispatch(getVersionsData()),
      dispatch(getProductsData()),
      dispatch(getTagsData()),
      dispatch(getTopTagsData()),
    ]).then(() => {
      setIsLoadingDictionaries(false);
    });
  }, [dispatch]);

  useEffect(() => {
    if (!isLoadingDictionaries) {
      setIsLoading(true);

      const productsParam = searchParams.getAll('products[]');
      const tagsParam = searchParams.getAll('tags[]');
      const categoriesParam = searchParams.getAll('categories[]');
      const versionsParam = searchParams.getAll('versions[]');

      const categoriesIds = getCategoriesIds({ categoriesData, categoriesParam });
      const versionsIds = getVersionsIds({ versionsData, versionsParam });
      const productsIds = getProductsIds({ productsData, productsParam });
      const tagsCanonicalNames = getTagsCanonicalNames({ tagsData, tagsParam });

      dispatch(
        getPurchasedAddonsInfo({
          perPage: currentPurchasedAddonsPerPage,
          page: currentPage,
          categories: categoriesIds,
          products: productsIds,
          versions: versionsIds,
          tags: tagsCanonicalNames,
          search,
          sort,
          direction,
        })
      ).then(() => setIsLoading(false));
    }
  }, [
    categoriesData,
    dispatch,
    isLoadingDictionaries,
    searchParams,
    versionsData,
    productsData,
    tagsData,
    search,
    currentPage,
    sort,
    direction,
    currentPurchasedAddonsPerPage,
  ]);

  if (errors) {
    const { data, status } = errors;
    return <ErrorPage status={status} text={data.message} />;
  }

  return (
    <>
      <StorefrontHeader />
      <div className="main add-on-table main--filters">
        <MobileButtons handleFiltersClick={handleFiltersClick} handleSortClick={handleSortClick} />

        <div className="add-on-table__wrapper columns">
          <div className="add-on-table__column add-on-table__column--main columns__column--content">
            <div className="title-wrapper">
              <h1 className="add-on-table__title">Purchased add-ons</h1>
              <div className="orders__sort to-right">
                <SortControl
                  name="sort"
                  options={[
                    { value: 'title', label: 'Title' },
                    { value: 'totalRating', label: 'Rating' },
                    { value: 'publisherName', label: 'Publisher' },
                    { value: 'orderNumber', label: 'Order number' },
                  ]}
                />
              </div>
            </div>

            {isLoading && (
              <div className="add-on-list__loading-container">
                <CircularProgress color="inherit" className="add-on-list__loading" />
              </div>
            )}

            {errors && !isLoading && (
              <span className="orders__error">
                Sorry, something went wrong when loading the data.
              </span>
            )}

            {!errors && !isLoading && purchasedAddonsData && (
              <AddonTableList
                items={purchasedAddonsData}
                placeholderText="No purchased add-ons"
                isLoading={isLoading}
                renderItem={(item) => (
                  <PurchasedItem
                    addon={{
                      id: item.id,
                      title: item.title,
                      publisher: { id: item.publisherId, name: item.publisherName },
                      categories: item.categories || [],
                      coverFile: { id: item.previewFileId },
                    }}
                    orderNumber={item.orderNumber}
                    orderDate={item.orderDate}
                    ownerName={item.ownerName}
                    userName={user.userData.name}
                    onClick={(evt) => {
                      evt.preventDefault();
                      handleItemClick(item.id);
                    }}
                  />
                )}
              />
            )}
            {!errors && !isLoading && purchasedAddonsData && purchasedAddonsData.length !== 0 && (
              <div className="pagination__container">
                <UniginePagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  defaultValue={purchasedAddonsPerPage}
                />
              </div>
            )}
          </div>

          <div className="add-on-table__column add-on-table__column--side columns__column--side">
            <FilterForm
              search={{
                name: 'search',
                label: 'Search in my add-ons',
              }}
              filterParams={[
                {
                  name: 'categories',
                  title: 'Categories',
                  type: 'checkbox',
                  options: categoriesData,
                  getLabel: (item: ICategory) => item.name,
                  getValue: (item: ICategory) => item.slug,
                  collapsed: false,
                },
                {
                  name: 'versions',
                  title: 'Supported versions',
                  type: 'checkbox',
                  options: versionsData || [],
                  getLabel: (item: ISdkVersion) => item.value,
                  getValue: (item: ISdkVersion) => item.slug,
                  collapsed: false,
                },
                {
                  name: 'products',
                  title: 'Products',
                  type: 'checkbox',
                  options: productsData || [],
                  getLabel: (item: IProduct) => item.name,
                  getValue: (item: IProduct) => item.slug,
                  collapsed: false,
                },
                {
                  name: 'tags',
                  title: 'Tags',
                  type: 'tags',
                  options: topTagsData || [],
                  getLabel: (item: ITag) => item.value,
                  getValue: (item: ITag) => item.value,
                },
              ]}
            />
          </div>

          <AddonDialog hidePurchaseButton />

          <MobileDialog isOpen={isFiltersOpen} handleClose={handleCloseFilters} title="Filters">
            <FilterForm
              search={{
                name: 'search',
                label: 'Search in my add-ons',
              }}
              filterParams={[
                {
                  name: 'categories',
                  title: 'Categories',
                  type: 'checkbox',
                  options: categoriesData || [],
                  getLabel: (item) => item.name,
                  getValue: (item) => item.slug,
                  collapsed: false,
                },
                {
                  name: 'versions',
                  title: 'Supported versions',
                  type: 'checkbox',
                  options: versionsData || [],
                  getLabel: (item) => item.value,
                  getValue: (item) => item.slug,
                  collapsed: false,
                },
                {
                  name: 'products',
                  title: 'Products',
                  type: 'checkbox',
                  options: productsData || [],
                  getLabel: (item) => item.name,
                  getValue: (item) => item.slug,
                  collapsed: false,
                },
                {
                  name: 'tags',
                  title: 'Tags',
                  type: 'tags',
                  options: topTagsData || [],
                  getLabel: (item) => item.value,
                  getValue: (item) => item.value,
                },
              ]}
            />
          </MobileDialog>
          <MobileDialog isOpen={isSortOpen} handleClose={handleCloseSort} title="Sort By">
            <SortControl
              name="sort"
              options={[
                { value: 'title', label: 'Title' },
                { value: 'publisherName', label: 'Publisher' },
                { value: 'orderNumber', label: 'Order number' },
              ]}
            />
          </MobileDialog>
        </div>
      </div>
    </>
  );
};

export default PurchasedAddons;
