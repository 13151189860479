import { Dialog, DialogContent, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import UnigineButton from 'app/components/UnigineButton/UnigineButton';
import { withdrawalStatus } from 'app/utils/constants/contentConstants';
import { ReactElement } from 'react';

interface IDashboardWithdrawalStatusDialog {
  onClose: () => void;
  status: string;
}

const DashboardWithdrawalStatusDialog = ({
  onClose,
  status,
}: IDashboardWithdrawalStatusDialog): ReactElement => {
  let title;
  let description;

  if (status === 'SUCCESS') {
    title = withdrawalStatus.titleSuccess;
    description = withdrawalStatus.descriptionSuccess;
  }

  if (status === 'FAILED') {
    title = withdrawalStatus.titleFailure;
    description = withdrawalStatus.descriptionFailure;
  }

  if (status === 'EXPIRED') {
    title = withdrawalStatus.titleFailure;
    description = withdrawalStatus.descriptionExpired;
  }

  return (
    <Dialog
      open={!!status}
      className="payment-dialog payment-dialog--payout-status dialog"
      fullWidth
      onClose={onClose}
      maxWidth="lg"
    >
      <DialogContent className="payment-dialog__content--status">
        <h2 className="payment-dialog__title">{title}</h2>

        {description && (
          <div
            className="payment-dialog__description"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}

        <IconButton aria-label="close" className="dialog__close-button" onClick={onClose}>
          <CloseIcon />
        </IconButton>

        <div className="payment-dialog__action-container">
          <UnigineButton onClick={onClose}>Close</UnigineButton>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default DashboardWithdrawalStatusDialog;
