import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from 'app/auth/axiosInstance';
import { RootState } from 'app/store';
import IError from 'app/interfaces/IError';
import IDashboardInfo from 'app/interfaces/dashboard/IDashboardInfo';

interface IDashboardSliceState {
  info: IDashboardInfo | null;
  error: IError | null;
  actionError: any;
}

export const getDashboardInfo = createAsyncThunk<
  IDashboardInfo,
  undefined,
  { rejectValue: IError }
>('publisherPanelSection/getDashboardInfo', async (props, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      method: 'get',
      url: '/publisher-panel/dashboard',
    });

    const dashboardData = response.data;

    return dashboardData;
  } catch (err: any) {
    if (err.response) {
      return rejectWithValue({ data: err.response.data, status: err.response.status });
    }

    return rejectWithValue({
      data: { message: 'Something went wrong to get dashboard data' },
      status: 500,
    });
  }
});

export const getPaymentToken = createAsyncThunk(
  'publisherPanelSection/getPaymentToken',
  async (props, { rejectWithValue }) => {
    try {
      const response = await axiosInstance({
        method: 'post',
        url: '/payouts/get-token',
      });

      const { token } = response.data;

      return token;
    } catch (err: any) {
      if (err.response) {
        return rejectWithValue({ data: err.response.data, status: err.response.status });
      }

      return rejectWithValue({
        data: { message: 'Something went wrong to get payment token' },
        status: 500,
      });
    }
  }
);

export const cancelPayout = createAsyncThunk<string, undefined, { rejectValue: IError }>(
  'ordersSection/cancelPayout',
  async (props, { rejectWithValue }) => {
    try {
      console.log('cancelPayout...');
      return 'cancel';
    } catch (err: any) {
      if (err.response) {
        return rejectWithValue({ data: err.response.data, status: err.response.status });
      }

      return rejectWithValue({
        data: { message: 'Something went wrong with cancel payout' },
        status: 500,
      });
    }
  }
);

const initialState: IDashboardSliceState = {
  info: null,
  error: null,
  actionError: null,
};

const dasboardInfoSlice = createSlice({
  name: 'publisherPanelSection',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDashboardInfo.fulfilled, (state, action) => {
        state.info = action.payload;
        state.error = null;
      })
      .addCase(getDashboardInfo.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
        }
      })
      .addCase(getPaymentToken.pending, (state) => {
        state.actionError = null;
      })
      .addCase(getPaymentToken.rejected, (state, action) => {
        if (action.payload) {
          state.actionError = action.payload;
        }
      });
  },
});

export const dashboardErrorSelector = ({ publisherPanelSection }: RootState): IError | null =>
  publisherPanelSection.dashboard.error;

export const dashboardActionErrorSelector = ({ publisherPanelSection }: RootState): any =>
  publisherPanelSection.dashboard.actionError;

export const dashboardInfoSelector = ({
  publisherPanelSection,
}: RootState): IDashboardInfo | null => publisherPanelSection.dashboard.info;

export default dasboardInfoSlice.reducer;
