import './AddonVersionStatus.scss';

import { JSX } from 'react';

import { tooltips } from 'app/utils/constants/contentConstants';

import UnigineTooltip from 'app/components/Tooltip/Tooltip';

interface IAddonVersionStatusProps {
  status?: string;
}

const AddonVersionStatus = ({ status = 'draft' }: IAddonVersionStatusProps): JSX.Element => {
  let component = (
    <div className={`add-on-version-status  add-on-version-status--${status.toLowerCase()}`}>
      {status.charAt(0)}
    </div>
  );

  const tooltip = tooltips.addonVersionStatuses[status.toLowerCase()];

  if (tooltip) {
    component = <UnigineTooltip title={tooltip}>{component}</UnigineTooltip>;
  }

  return component;
};

export default AddonVersionStatus;
