import './DashboardSection.scss';

import { useEffect, useState, FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { Skeleton } from '@mui/material';

import useAppSelector from 'app/hooks/useAppSelector';
import useAppDispatch from 'app/hooks/useAppDispatch';
import usePermissions from 'app/hooks/usePermissions';

import DashboardItem from 'app/components/DashboardItem/DashboardItem';

import {
  cancelPayout,
  dashboardErrorSelector,
  dashboardActionErrorSelector,
  dashboardInfoSelector,
  getDashboardInfo,
  getPaymentToken,
} from 'app/main/sections/PublisherPanel/sections/DashboardSection/store/dashboardSlice';

import DashboardWithdrawalDialog from 'app/main/sections/PublisherPanel/sections/DashboardSection//DashboardWithdrawalDialog';
import DashboardWithdrawalStatusDialog from 'app/main/sections/PublisherPanel/sections/DashboardSection//DashboardWithdrawalStatusDialog';

const DashboardSection: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const dashboardData = useAppSelector(dashboardInfoSelector);
  const error = useAppSelector(dashboardErrorSelector);
  const actionError = useAppSelector(dashboardActionErrorSelector);

  const [isLoading, setIsLoading] = useState(false);
  const [awaitingToken, setAwaitingToken] = useState(false);
  const [paymentToken, setPaymentToken] = useState<any>(null);
  const [withdrawalStatus, setWithdrawalStatus] = useState('');
  const { publisherPanelUserAdmin, isNoRole } = usePermissions();

  useEffect(() => {
    if (isNoRole) {
      return;
    }

    if (!publisherPanelUserAdmin) {
      navigate('/publisherPanel/add-ons');
    }
  }, [isNoRole, navigate, publisherPanelUserAdmin]);

  useEffect(() => {
    setIsLoading(true);
    dispatch(getDashboardInfo()).finally(() => setIsLoading(false));
  }, [dispatch]);

  useEffect(() => {
    if (actionError) {
      setWithdrawalStatus('FAILED');
    }
  }, [actionError]);

  if (error) {
    return (
      <div className="publisher-panel__wrapper--no-header">
        <h1 className="dashboard__title">Dashboard</h1>
        <div className="dashboard__error">{error.data.message}</div>
      </div>
    );
  }

  const handleWithdrawal = (): void => {
    setAwaitingToken(true);
    dispatch(getPaymentToken())
      .unwrap()
      .then((token) => {
        setPaymentToken(token);
      })
      .finally(() => {
        setAwaitingToken(false);
      });
  };

  const onAbort = (status: string): void => {
    dispatch(cancelPayout()).then(() => {
      setPaymentToken(null);

      if (status) {
        setWithdrawalStatus(status);
      }
    });
  };

  const onSuccess = (): void => {
    setPaymentToken(false);
    setIsLoading(true);
    dispatch(getDashboardInfo()).finally(() => setIsLoading(false));
    // setWithdrawalStatus('SUCCESS');
  };

  const handleCloseStatusDialog = (): void => {
    setWithdrawalStatus('');
  };

  return (
    <div className="publisher-panel__wrapper--no-header">
      <h1 className="dashboard__title">Dashboard</h1>

      <div className="dashboard__section">
        <h2 className="dashboard__subtitle">Total income:</h2>

        <div className="dashboard__value dashboard__value--income">
          {!isLoading && dashboardData?.totalIncome ? (
            `$ ${dashboardData?.totalIncome}`
          ) : (
            <Skeleton width={200} height={56} variant="text" />
          )}
        </div>

        <div className="dashboard__list">
          <DashboardItem
            title="Available for withdrawal"
            value={dashboardData?.availableForWithdrawal}
            loading={isLoading}
            isContainButton
            disabled
            // handleWithdrawal={handleWithdrawal}
            awaitingToken={awaitingToken}
            paymentToken={paymentToken}
            valueColor="orange"
          />

          <DashboardItem
            title="On hold"
            value={dashboardData?.onHold}
            loading={isLoading}
            // tooltip="some text about on hold..."
            valueColor="grey"
          />

          <DashboardItem
            title="Total withdrawal"
            value={dashboardData?.totalWithdrawal}
            loading={isLoading}
            valueColor="green"
          />

          <DashboardItem
            title="Total revenue"
            value={dashboardData?.totalRevenue}
            loading={isLoading}
            valueColor="green"
          />

          <DashboardItem
            title="Net revenue last 30 days"
            value={dashboardData?.netRevenueLast30Days}
            loading={isLoading}
            dateFrom={dashboardData?.netRevenueLast30DaysPeriod?.from}
            dateTo={dashboardData?.netRevenueLast30DaysPeriod?.to}
            percent={dashboardData?.netRevenueLast30DaysPercent}
            valueColor="green"
          />

          <DashboardItem
            title="Net revenue last month"
            value={dashboardData?.netRevenueLastMonth}
            loading={isLoading}
            dateFrom={dashboardData?.netRevenueLastMonthPeriod?.from}
            dateTo={dashboardData?.netRevenueLastMonthPeriod?.to}
            percent={dashboardData?.netRevenueLastMonthPercent}
            valueColor="green"
          />
        </div>
      </div>

      <div className="dashboard__section">
        <h2 className="dashboard__subtitle">Total Refunds:</h2>

        <div className="dashboard__value dashboard__value--refunds">
          {!isLoading && dashboardData?.totalRefunds ? (
            `$ ${dashboardData?.totalRefunds}`
          ) : (
            <Skeleton width={200} height={56} variant="text" />
          )}
        </div>

        <div className="dashboard__list">
          <DashboardItem
            title="Refunds last 30 days"
            value={dashboardData?.refundsLast30Days}
            loading={isLoading}
            valueColor="grey"
          />
        </div>
      </div>

      <DashboardWithdrawalDialog
        open={!!paymentToken}
        paymentToken={paymentToken}
        onAbort={onAbort}
        onSuccess={onSuccess}
      />

      <DashboardWithdrawalStatusDialog
        onClose={handleCloseStatusDialog}
        status={withdrawalStatus}
      />
    </div>
  );
};

export default DashboardSection;
