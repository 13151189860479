import './DashboardItem.scss';

import { ReactElement } from 'react';

import { Skeleton } from '@mui/material';

import clsx from 'clsx';
import moment from 'moment';

import Tooltip from 'app/components/Tooltip/Tooltip';
import UnigineButton from 'app/components/UnigineButton/UnigineButton';

import helpIcon from 'app/assets/icon-help.svg';

interface IDashboardItem {
  title?: string;
  value?: string;
  isContainButton?: boolean;
  disabled?: boolean;
  loading: boolean;
  dateFrom?: string;
  dateTo?: string;
  percent?: string;
  tooltip?: string;
  valueColor: string;
  handleWithdrawal?: () => void;
  awaitingToken?: boolean;
  paymentToken?: any;
}

const DashboardItem = ({
  title,
  value,
  isContainButton = false,
  disabled = false,
  loading,
  dateFrom,
  dateTo,
  percent,
  tooltip,
  valueColor,
  handleWithdrawal,
  awaitingToken,
  paymentToken,
}: IDashboardItem): ReactElement => {
  return (
    <div className="dashboard__item">
      <div className="dashboard__item-title-container">
        <h3 className="dashboard__item-title">{title}:</h3>

        {tooltip && (
          <Tooltip title={tooltip}>
            <img src={helpIcon} alt="" />
          </Tooltip>
        )}
      </div>

      <div className="dashboard__item-value-container">
        <div
          className={clsx('dashboard__item-value', {
            'dashboard__item-value--green': valueColor === 'green',
            'dashboard__item-value--grey': valueColor === 'grey',
            'dashboard__item-value--orange': valueColor === 'orange',
          })}
        >
          {!loading && value ? (
            `$ ${value}`
          ) : (
            <Skeleton
              width={150}
              height={48}
              variant="text"
              className="dashboard__item-value--skeleton"
            />
          )}
        </div>

        {!loading && isContainButton && value && (
          <UnigineButton
            className="dashboard__button"
            onClick={handleWithdrawal}
            disabled={disabled || awaitingToken || !!paymentToken}
          >
            {awaitingToken ? 'Waiting...' : 'Withdraw'}
          </UnigineButton>
        )}

        {!loading && percent && Math.abs(Number(percent)) !== 0 && (
          <div
            className={clsx('dashboard__item-percent', {
              'dashboard__item-percent--up': Number(percent) >= 0,
              'dashboard__item-percent--down': Number(percent) < 0,
            })}
          >
            {Math.abs(Number(percent))}%
          </div>
        )}

        {!loading && dateFrom && dateTo && (
          <div className="dashboard__item-dates">
            {moment(dateFrom).format('MMMM DD, YYYY')} - {moment(dateTo).format('MMMM DD, YYYY')}
          </div>
        )}
      </div>
    </div>
  );
};

export default DashboardItem;
