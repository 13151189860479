const getUniqueState = (): string => {
  let uniqueState = localStorage.getItem('jwt_unique_state');

  if (!uniqueState) {
    uniqueState = Math.random().toString(36).substring(2, 16);
    localStorage.setItem('jwt_unique_state', uniqueState);
  }

  return uniqueState;
};

export default getUniqueState;
