import Publisher from 'app/main/sections/Publisher/Publisher';

const PublisherConfig = [
  {
    path: 'publisher/:publisherId',
    element: <Publisher />,
  },
];

export default PublisherConfig;
