import { FC, useState } from 'react';
import clsx from 'clsx';

import Cart from 'app/components/Cart/Cart';
import SearchForm from 'app/components/SearchForm/SearchForm';
import CategoriesList from 'app/components/CategoriesList/CategoriesList';
import usePermissions from 'app/hooks/usePermissions';

import { IconButton } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';

interface IStorefrontHeader {
  isPinned?: boolean;
}

const StorefrontHeader: FC<IStorefrontHeader> = ({ isPinned = false }) => {
  const { displayCartButton } = usePermissions();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleMobileMenu = (): void => {
    setIsMobileMenuOpen((current) => !current);
  };

  return (
    <div className={clsx('header__bottom-container', 'header__bottom-container--pinned')}>
      <div className="header__mobile-button-container">
        <IconButton className="header__mobile-button" onClick={handleMobileMenu}>
          {isMobileMenuOpen ? <CloseIcon /> : <MenuIcon />}
        </IconButton>
      </div>

      <nav
        className={clsx('header__navigation navigation navigation--bottom navigation--mobile', {
          'navigation--opened': isMobileMenuOpen,
        })}
      >
        <CategoriesList />
      </nav>

      <div
        className={clsx('navigation-mobile-layout', {
          'navigation-mobile-layout--opened': isMobileMenuOpen,
        })}
        onClick={handleMobileMenu}
        aria-hidden="true"
      />

      <div className="to-right navigation-search-container">
        <div className="header__search search search__container to-right">
          <SearchForm />
        </div>
      </div>

      {displayCartButton && (
        <div className="header__cart-btn">
          <Cart />
        </div>
      )}
    </div>
  );
};

export default StorefrontHeader;
