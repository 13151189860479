import { defaultError } from 'app/configs/appConfig';
import IError from 'app/interfaces/IError';

const adaptError = (err: any): IError => {
  if (err.response?.data?.context?.message) {
    return { data: err.response.data, status: err.response.status };
  }

  return defaultError;
};

export default adaptError;
