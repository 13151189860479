import ICategory from 'app/interfaces/addons/ICategory';

interface ICategoryIdParams {
  categorySlug?: string;
  categories: ICategory[];
}

const getCategoryId = ({ categorySlug, categories }: ICategoryIdParams): string | undefined => {
  let categoryId = null;
  let filteredObject = null;

  if (categorySlug && categorySlug !== 'all') {
    [filteredObject] = categories.filter((category) => category.slug === categorySlug) || [];
  }

  categoryId = filteredObject?.id;

  return categoryId || categorySlug;
};

export default getCategoryId;
