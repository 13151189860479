import { globalRoles } from 'app/utils/constants/permissions';
import { ReactComponent as IconGear } from 'app/assets/sidebarIcons/icon_settings.svg';
import { ReactComponent as IconReports } from 'app/assets/sidebarIcons/icon_reports.svg';

const adminPortalSidebarData = [
  {
    title: 'Moderation',
    path: '/adminPortal/add-ons?addonVersionStatus[]=moderation&addonVersionStatus[]=revision',
    roles: [globalRoles.addonStoreAdmin],
    icon: <IconGear />,
  },
  {
    title: 'Reported reviews',
    path: '/adminPortal/reviews',
    roles: [globalRoles.addonStoreAdmin],
    icon: <IconReports />,
  },
];

export default adminPortalSidebarData;
