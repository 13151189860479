/* eslint-disable camelcase */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import IPlatform from 'app/interfaces/addons/IPlatform';

import axiosInstance from 'app/auth/axiosInstance';
import IError from 'app/interfaces/IError';
import { RootState } from 'app/store';

interface IPlatformSliceState {
  platform: IPlatform[] | null;
}

interface IPlatforms {
  platforms: IPlatform[];
  total: number;
}

const initialState: IPlatformSliceState = {
  platform: null,
};

export const getPlatformData = createAsyncThunk<IPlatforms, undefined, { rejectValue: IError }>(
  'addonsListSection/getPlatformData',
  async (props, { rejectWithValue }) => {
    try {
      const response = await axiosInstance({
        method: 'get',
        url: '/platforms',
      });
      return response.data;
    } catch (err: any) {
      if (err.response) {
        return rejectWithValue({ data: err.response.data, status: err.response.status });
      }

      return rejectWithValue({ data: { message: 'Something went wrong' }, status: 500 });
    }
  }
);

const platformSlice = createSlice({
  name: 'addonsListSection',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPlatformData.fulfilled, (state, action) => {
        const { platforms } = action.payload;
        state.platform = platforms;
      })
      .addCase(getPlatformData.rejected, (state, action) => {
        console.log(action.payload);
      });
  },
});

export const selectPlatform = ({ addonsListSection }: RootState): IPlatform[] | null =>
  addonsListSection.platformInfo.platform;

export default platformSlice.reducer;
