const addonNavigationMenuData = [
  {
    title: 'Base Info',
    path: 'base',
  },
  {
    title: 'Media',
    path: 'media',
  },
  {
    title: 'Package',
    path: 'package',
  },
  {
    title: 'Changelog',
    path: 'changelog',
  },
];

export default addonNavigationMenuData;
