import React, { ChangeEvent, FC, FormEvent } from 'react';
import { filesize } from 'filesize';
import clsx from 'clsx';

import { Button, Dialog, DialogContent, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from 'app/assets/icon_delete-picture.svg';

const MAX_SIZE = 5000000; // 5MB

interface IAddonDialogImageForm {
  isOpen: boolean;
  handleClose: () => void;
  images: File[];
  setImages: React.Dispatch<React.SetStateAction<File[]>>;
  handleSubmitImageForm: (event: FormEvent<HTMLFormElement>) => void;
}

const AddonDialogImageForm: FC<IAddonDialogImageForm> = ({
  isOpen,
  handleClose,
  images,
  setImages,
  handleSubmitImageForm,
}) => {
  const onImageChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const fileList = e.target.files;

    if (fileList) {
      const selectedImages = [...fileList].filter((file) =>
        ['image/jpeg', 'image/png'].includes(file.type)
      );
      setImages([...images, ...selectedImages]);
    }
  };

  const deleteDowloadedFile = (deletedIdx: number): void => {
    setImages(images.filter((img, idx) => idx !== deletedIdx));
  };
  const isImageError = images.map((image) => image.size > MAX_SIZE).includes(true);

  return (
    <Dialog open={isOpen} fullWidth maxWidth="md" onClose={handleClose} className="dialog">
      <DialogContent className="dialog-media__content dialog-media__content--form">
        <h2 className="dialog-media__title">Upload Images</h2>

        <div className="dialog-media__container">
          {images.length === 0 && <div>Add images for uploading</div>}
          <ul className="dialog-media__media-list">
            {images.map((image, index) => (
              <li key={index} className="dialog-media__media-item">
                <div className="dialog-media__image-preview-container">
                  <img
                    src={URL.createObjectURL(image)}
                    alt="asdg"
                    className="dialog-media__image-preview"
                  />
                </div>

                <div className="dialog-media__image-name">
                  {image.name}
                  {image.size > MAX_SIZE && (
                    <div className="dialog-media__error">
                      The added image size is more than 5 Mb, that’s why it won’t be uploaded.
                    </div>
                  )}
                </div>
                <div className="dialog-media__image-size">
                  {filesize(image.size, { base: 10 }).toString()}
                </div>
                <IconButton
                  className="dialog__delete-chosen-image"
                  onClick={() => deleteDowloadedFile(index)}
                >
                  <img src={DeleteIcon} alt="delete" />
                </IconButton>
              </li>
            ))}
          </ul>

          <div className="add-on-media__form-container">
            <form onSubmit={handleSubmitImageForm}>
              <div className="add-on-media__buttons-container">
                <label
                  htmlFor="add-image"
                  className={`dialog-media__label--file ${
                    isImageError && 'dialog-media__margin-top'
                  }`}
                >
                  + Add Image
                  <input
                    className="dialog-media__input--file"
                    type="file"
                    id="add-image"
                    multiple
                    accept="image/jpeg,image/png"
                    onChange={onImageChange}
                  />
                </label>

                <div>
                  {isImageError && (
                    <div className="dialog-media__error">Some files are larger then 5 MB</div>
                  )}
                  <Button
                    type="submit"
                    disabled={isImageError || images.length === 0}
                    className={clsx('dialog-media__button', {
                      'dialog-media__button-disabled': isImageError || images.length === 0,
                    })}
                  >
                    Upload
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <IconButton aria-label="close" className="dialog__close-button" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogContent>
    </Dialog>
  );
};

export default AddonDialogImageForm;
