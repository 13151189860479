import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from 'app/store';

interface ISidebarState {
  sidebar: string | null | boolean;
}

const initialState: ISidebarState = {
  sidebar: localStorage.getItem('sidebar'),
};

const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    toggleSidebar: (state, action) => {
      state.sidebar = action.payload;
      localStorage.setItem('sidebar', action.payload);
    },
  },
  extraReducers: () => {},
});

export const { toggleSidebar } = sidebarSlice.actions;

export default sidebarSlice.reducer;

export const sidebarSelector = ({ unigine }: RootState): string | null | boolean =>
  unigine.sidebar.sidebar;
