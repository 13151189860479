import { JSX } from 'react';
import { Link, useLocation } from 'react-router-dom';
import clsx from 'clsx';

import useAppSelector from 'app/hooks/useAppSelector';
import { userIsProcessingSelector } from 'app/auth/store/userSlice';

const NavigationList = (): JSX.Element => {
  const location = useLocation();
  const userIsProcessing = useAppSelector(userIsProcessingSelector);

  return (
    <ul className="navigation__list">
      <li className="navigation__item">
        {userIsProcessing ? (
          <div className="navigation__link">about unigine</div>
        ) : (
          <a className="navigation__link" href="https://unigine.com">
            about unigine
          </a>
        )}
      </li>

      <li className="navigation__item">
        {userIsProcessing ? (
          <div className="navigation__link">docs</div>
        ) : (
          <Link
            to="/documentation"
            className={clsx('navigation__link', {
              'active-link': location.pathname === '/documentation',
            })}
          >
            docs
          </Link>
        )}
      </li>

      <li className="navigation__item">
        {userIsProcessing ? (
          <div className="navigation__link">forum</div>
        ) : (
          <a
            className="navigation__link"
            href={`${process.env.REACT_APP_STORE_DEVELOPER_URL}/forum`}
          >
            forum
          </a>
        )}
      </li>
    </ul>
  );
};

export default NavigationList;
