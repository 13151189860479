import { createSlice, createAsyncThunk, EntityState, createEntityAdapter } from '@reduxjs/toolkit';
import axiosInstance from 'app/auth/axiosInstance';
import { defaultError } from 'app/configs/appConfig';
import IError from 'app/interfaces/IError';
import { IAdminReview, IDashboardReviewData, IReportData } from 'app/interfaces/reviews/IReview';
import { RootState } from 'app/store';
import adaptError from 'app/utils/helpers/adaptError';
import getPaginationParams from 'app/utils/helpers/getPaginationParams';

interface IAdminReviewsData {
  comments: IDashboardReviewData[];
  total: number;
  perPage: number;
}

interface IAdminReviewsParams {
  page?: number;
  sort?: string;
  direction?: string;
  isChecked?: string;
  isBanned?: string;
  perPage?: number;
}
interface IAdminReviewsSliceState {
  reviews: EntityState<IAdminReview>;
  totalReviews: number;
  totalPages: number;
  error: IError | null;
}

const adaptReview = (reviewData: IDashboardReviewData): IAdminReview => ({
  id: reviewData.id,

  addonVersion: {
    id: reviewData.rating.addonVersion.id,
    number: reviewData.rating.addonVersion.versionNumber,
  },

  addon: {
    title: reviewData.rating.addonVersion.addon.title,
    image: `${process.env.REACT_APP_STORE_API_URL}/media-files/${reviewData.rating.addonVersion.coverFile.id}`,
  },

  author: {
    name: `${reviewData.rating.user.firstname} ${reviewData.rating.user.lastname}`,
  },

  rating: reviewData.rating.rating,
  comment: {
    title: reviewData.header,
    body: reviewData.text,
  },

  isChecked: reviewData.reviewReports.every((r) => r.isChecked),
  isBanned: reviewData.isBanned || false,

  createDate: reviewData.createDate,
  updateDate: reviewData.updateDate,
  reportId: reviewData.reviewReports[0]?.id,
  reply: [],
});

const reviewsAdapter = createEntityAdapter<IAdminReview>({});

export const getReviewsData = createAsyncThunk<
  IAdminReviewsData,
  IAdminReviewsParams,
  { rejectValue: IError }
>('adminPortalSection/getReviewsData', async (props, { rejectWithValue }) => {
  const params = getPaginationParams(props);
  try {
    const response = await axiosInstance({
      url: 'admin-panel/comments',
      params,
    });

    return { ...response.data, perPage: props.perPage };
  } catch (err: any) {
    return rejectWithValue(adaptError(err));
  }
});

export const toggleCheck = createAsyncThunk<
  IReportData & { reviewId: string },
  { reportId: string; isChecked: boolean; reviewId: string },
  { rejectValue: IError }
>(
  'adminPortalSection/toggleCheck',
  async ({ reportId, isChecked, reviewId }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance({
        method: 'put',
        url: `/admin-panel/review-reports/${reportId}/check`,
        data: {
          is_checked: isChecked,
        },
      });

      return { ...response.data, isChecked, reviewId };
    } catch (err: any) {
      return rejectWithValue(adaptError(err));
    }
  }
);

export const toggleBan = createAsyncThunk<
  IReportData & { isBanned: boolean; reviewId: string },
  { reportId: string; isBanned: boolean; reviewId: string },
  { rejectValue: IError }
>('adminPortalSection/toggleBan', async ({ reportId, isBanned, reviewId }, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      method: 'put',
      url: `/admin-panel/review-reports/${reportId}/ban`,
      data: {
        is_banned: isBanned,
      },
    });

    return { ...response.data, isBanned, reviewId };
  } catch (err: any) {
    return rejectWithValue(adaptError(err));
  }
});

const initialState: IAdminReviewsSliceState = {
  reviews: reviewsAdapter.getInitialState(),
  totalReviews: 0,
  totalPages: 1,
  error: null,
};

const reviewsSlice = createSlice({
  name: 'adminPortalSection',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getReviewsData.fulfilled, (state, action) => {
        const { comments, total, perPage } = action.payload;

        reviewsAdapter.setAll(state.reviews, comments.map(adaptReview));
        state.totalReviews = total;
        state.totalPages = Math.ceil(total / perPage);

        state.error = null;
      })
      .addCase(toggleBan.fulfilled, (state, action) => {
        const { reviewId, isBanned } = action.payload;
        reviewsAdapter.updateOne(state.reviews, {
          id: reviewId,
          changes: {
            isBanned,
          },
        });
      })
      .addCase(toggleCheck.fulfilled, (state, action) => {
        const { reviewId, isChecked } = action.payload;
        reviewsAdapter.updateOne(state.reviews, {
          id: reviewId,
          changes: {
            isChecked,
          },
        });
      })
      .addCase(getReviewsData.rejected, (state, action) => {
        state.error = action.payload || defaultError;
      });
  },
});

export const { selectAll: reviewsInfoSelector } = reviewsAdapter.getSelectors(
  ({ adminPortalSection }: RootState) => adminPortalSection.reviewsInfo.reviews
);

export const reviewsTotalPagesSelector = ({ adminPortalSection }: RootState): number =>
  adminPortalSection.reviewsInfo.totalPages;

export const reviewsErrorSelector = ({ adminPortalSection }: RootState): IError | null =>
  adminPortalSection.reviewsInfo.error;

export default reviewsSlice.reducer;
