import { orderAddonVersionsStatusOptions } from 'app/configs/appConfig';
import IOrderStatus from 'app/interfaces/orders/IOrderStatus';

interface IGetOrderStatusesOptions {
  orderStatusesData: IOrderStatus[] | null;
}

const getOrderStatusesOptions = ({
  orderStatusesData,
}: IGetOrderStatusesOptions): IOrderStatus[] | undefined =>
  orderStatusesData?.filter((status: IOrderStatus) =>
    orderAddonVersionsStatusOptions.includes(status.slug)
  );

export default getOrderStatusesOptions;
