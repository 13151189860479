import { Navigate } from 'react-router-dom';

import AdminPortal from 'app/main/sections/AdminPortal/AdminPortal';
import PublishedAddonsSection from 'app/main/sections/AdminPortal/sections/AddonsSection/AddonsSection';
import AddonSection from 'app/main/sections/AdminPortal/sections/AddonSection/AddonSection';
import AddonBaseInfoTab from 'app/main/sections/AdminPortal/sections/AddonSection/tabs/AddonBaseInfoTab';
import AddonMediaTab from 'app/main/sections/AdminPortal/sections/AddonSection/tabs/AddonMediaTab';
import AddonChangelogTab from 'app/main/sections/AdminPortal/sections/AddonSection/tabs/AddonChangelogTab';
import AddonPackageTab from 'app/main/sections/AdminPortal/sections/AddonSection/tabs/AddonPackageTab';
import ReviewsSection from 'app/main/sections/AdminPortal/sections/ReviewsSection/ReviewsSection';

const AdminPortalConfig = [
  {
    path: 'adminPortal/',
    element: <Navigate replace to="add-ons" />,
  },
  {
    path: 'adminPortal/',
    element: <AdminPortal />,
    children: [
      {
        path: 'add-ons',

        element: <PublishedAddonsSection />,
      },
      {
        path: 'add-ons/:addonId',
        element: <Navigate replace to="base" />,
      },
      {
        path: 'reviews',
        element: <ReviewsSection />,
      },
      {
        path: 'add-ons/:addonId',
        element: <AddonSection />,
        children: [
          {
            path: 'base',
            element: <AddonBaseInfoTab />,
          },
          {
            path: 'media',
            element: <AddonMediaTab />,
          },
          {
            path: 'package',
            element: <AddonPackageTab />,
          },
          {
            path: 'changelog',
            element: <AddonChangelogTab />,
          },
        ],
      },
    ],
  },
];

export default AdminPortalConfig;
