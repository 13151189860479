import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { defaultError } from 'app/configs/appConfig';
import IError from 'app/interfaces/IError';
import { RootState } from 'app/store';

interface IReviewStatus {
  id: string;
  slug: string;
  value: string;
}

interface IReviewStatusSliceState {
  reviewStatuses: IReviewStatus[] | null;
}

export const getReviewsStatusData = createAsyncThunk<
  IReviewStatus[],
  void,
  { rejectValue: IError }
>('adminPortalSection/getReviewsStatusData', async (props, { rejectWithValue }) => {
  try {
    const options = [
      { id: '1', slug: 'all', value: 'All' },
      { id: '2', slug: 'checked', value: 'Replied' },
      { id: '3', slug: 'unchecked', value: 'Unchecked' },
      { id: '4', slug: 'banned', value: 'Banned' },
    ];
    return options;
  } catch (err: any) {
    if (err.response?.data?.context?.message) {
      return rejectWithValue({ data: err.response.data, status: err.response.status });
    }

    return rejectWithValue(defaultError);
  }
});

const initialState: IReviewStatusSliceState = {
  reviewStatuses: null,
};

const reviewStatusSlice = createSlice({
  name: 'adminPortalSection',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getReviewsStatusData.fulfilled, (state, action) => {
        state.reviewStatuses = action.payload;
      })
      .addCase(getReviewsStatusData.rejected, (state, action) => {
        console.log(action.payload);
      });
  },
});

export const selectReviewStatus = ({ adminPortalSection }: RootState): IReviewStatus[] | null =>
  adminPortalSection.reviewStatusInfo.reviewStatuses;

export default reviewStatusSlice.reducer;
