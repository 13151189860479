import './RadioGroup.scss';

import { JSX } from 'react';
import {
  Radio,
  RadioGroup as MuiRadioGroup,
  FormControl,
  FormHelperText,
  FormControlLabel,
  RadioGroupProps as MuiRadioGroupProps,
} from '@mui/material';
import { FieldError } from 'react-hook-form';

type RadioGroupProps<T> = {
  options?: T[];
  selectedValue?: string | null;
  onChange?: MuiRadioGroupProps['onChange'];
  getIsDisabled?: (item: T) => boolean;
  error?: FieldError;
  id?: string;
} & (T extends { value: string }
  ? { getValue?: (item: T) => string }
  : { getValue: (item: T) => string }) &
  (T extends { label: string }
    ? { getLabel?: (item: T) => string }
    : { getLabel: (item: T) => string });

export default function RadioGroup<T>({
  options = [],
  selectedValue = null,
  onChange,
  getLabel = (item) => item.label,
  getValue = (item) => item.value,
  getIsDisabled = () => false,
  error,
  id,
}: RadioGroupProps<T>): JSX.Element {
  return (
    <FormControl error={!!error} id={id} className="unigine-radio-group">
      <MuiRadioGroup value={selectedValue} onChange={onChange}>
        {options.map((option) => {
          const value = getValue(option);
          const label = getLabel(option);
          const disabled = getIsDisabled(option);
          return (
            <FormControlLabel
              key={value}
              label={label}
              control={<Radio size="small" value={value} disabled={disabled} />}
            />
          );
        })}
      </MuiRadioGroup>
      {error && <FormHelperText>{error?.message}</FormHelperText>}
    </FormControl>
  );
}
