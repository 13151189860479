import './AddonSectionEdit.scss';

import { useEffect, useState, FC } from 'react';

import useAppSelector from 'app/hooks/useAppSelector';
import useAppDispatch from 'app/hooks/useAppDispatch';

import { useParams, useNavigate } from 'react-router-dom';
import moment from 'moment';

import { CircularProgress } from '@mui/material';
import usePermissions from 'app/hooks/usePermissions';
import parseToNumber from 'app/utils/helpers/parseToNumber';

import { IPublishedAddonEditGeneralInfo } from 'app/interfaces/addons/IAddon';

import {
  addonErrorSelector,
  addonSelector,
  editAddonInfo,
  getAddonInfo,
} from 'app/main/sections/PublisherPanel/sections/AddonSection/store/addonInfoSlice';
import AddonEditForm from 'app/main/sections/PublisherPanel/sections/AddonSection/AddonEditForm';

const defaultValues = {
  title: '',
  price: '0',
  isFree: true,
  discounts: [],
};

const AddonSectionEdit: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { addonId } = useParams();

  const addonInfo = useAppSelector(addonSelector);
  const addonDataError = useAppSelector(addonErrorSelector);
  const { isNoRole } = usePermissions();

  const [isLoading, setIsLoading] = useState(true);
  const [_, setSubmissionError] = useState(null);

  const isAddonValid = addonInfo?.id === addonId;

  useEffect(() => {
    if (isAddonValid) {
      setIsLoading(false);
      return;
    }

    if (!isNoRole && addonId) {
      dispatch(getAddonInfo({ addonId }))
        .unwrap()
        .finally(() => setIsLoading(false));
    }
  }, [dispatch, addonId, isAddonValid, isNoRole]);

  const submitAddonData = async (addonData: IPublishedAddonEditGeneralInfo): Promise<void> => {
    try {
      if (addonId) {
        await dispatch(editAddonInfo({ addonId, addonData })).unwrap();
        navigate(`/publisherPanel/add-ons/${addonId}`);
      }
    } catch (err: any) {
      if (!Array.isArray(err.context)) {
        setSubmissionError(err);
        return;
      }
      throw err.context; // into the form component, to handle field errors
    }
  };

  let startingValues: IPublishedAddonEditGeneralInfo = { ...defaultValues };
  if (addonInfo) {
    const { title, price, isFree, discounts } = addonInfo;
    startingValues = {
      title,
      price,
      isFree,
      discounts: discounts.map((discount) => ({
        percentage: parseToNumber(discount.discount),
        from: moment.parseZone(discount.startDate).local(true).format('YYYY-MM-DD'),
        till:
          discount.endDate === null
            ? null
            : moment.parseZone(discount.endDate).local(true).format('YYYY-MM-DD'),
        quantity: discount.addonsForSale,
      })),
    };
  }

  return (
    <div className="add-on-section add-on-section--edit-price">
      {isAddonValid && !addonDataError && !isLoading && (
        <AddonEditForm
          title={addonInfo?.title}
          defaultValues={startingValues}
          onSubmit={submitAddonData}
        />
      )}

      {isLoading && (
        <div className="add-on-info-edit__spinner">
          <CircularProgress color="inherit" />
        </div>
      )}
    </div>
  );
};

export default AddonSectionEdit;
