import './AddonPackageTab.scss';

import { FC } from 'react';

import useAppSelector from 'app/hooks/useAppSelector';

import { CircularProgress } from '@mui/material';

import AddonPackageItem from 'app/components/AddonPackageItem/AddonPackageItem';

import {
  addonBaseInfoSelector,
  packagesSelector,
} from 'app/main/sections/AdminPortal/sections/AddonSection/store/baseInfoSlice';

const AddonPackageTab: FC = () => {
  const addonBaseInfo = useAppSelector(addonBaseInfoSelector);
  const packages = useAppSelector(packagesSelector);

  if (!addonBaseInfo || !packages) {
    return (
      <div className="orders__spinner">
        <CircularProgress color="inherit" />
      </div>
    );
  }

  const { status } = addonBaseInfo;

  return (
    <section className="add-on-section__admin-package">
      <div className="add-on-section__package-info">
        <div className="add-on-section__package-container">
          {packages.length === 0 && (
            <div className="add-on-section__package-no-package">No package file uploaded</div>
          )}
        </div>
      </div>
      <div className="add-on-packages__container add-on-packages__admin">
        <ul className="add-on-packages__list">
          {packages.map((packageInfo, i) => (
            <AddonPackageItem
              packageInfo={packageInfo}
              key={i}
              addonVersionStatus={status}
              source="admin"
            />
          ))}
        </ul>
      </div>
    </section>
  );
};

export default AddonPackageTab;
