import React, { useState, ReactElement } from 'react';
import { Editor } from 'slate';
import { Popover, TextField } from '@mui/material';

import UnigineButton from 'app/components/UnigineButton/UnigineButton';

import { editLink } from 'app/components/RichTextEditor/utils/link';

interface IInsertLinkPopup {
  open: boolean;
  onClose: () => void;
  editor: Editor;
  href: string;
  anchorEl: HTMLAnchorElement | null;
}

const EditLinkPopup = ({
  open,
  onClose,
  editor,
  href,
  anchorEl,
}: IInsertLinkPopup): ReactElement => {
  const [link, setLink] = useState<string>(href);
  const handleLinkSumbit = (): void => {
    if (link.includes('https://') || link.includes('http://')) {
      editLink(editor, link);
    } else {
      editLink(editor, `https://${link}`);
    }
    setLink('');
    onClose();
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === 'Enter') {
      if (link.includes('https://') || link.includes('http://')) {
        editLink(editor, link);
      } else {
        editLink(editor, `https://${link}`);
      }
      setLink('');
      onClose();
    }
  };

  return (
    <Popover
      className="insert-link-popover"
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <div className="insert-link-wrapper">
        <TextField
          className="unigine-form-field insert-link-textfield"
          placeholder="Type URL"
          size="small"
          variant="outlined"
          onChange={(e) => setLink(e.target.value)}
          onKeyDown={handleKeyDown}
          value={link}
          autoFocus
        />
        <UnigineButton className="insert-link-button" onClick={handleLinkSumbit}>
          Save link
        </UnigineButton>
      </div>
    </Popover>
  );
};

export default EditLinkPopup;
