/* eslint-disable camelcase */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import IError from 'app/interfaces/IError';
import IAddonSourceCode from 'app/interfaces/addons/IAddonSourceCode';

import axiosInstance from 'app/auth/axiosInstance';
import { RootState } from 'app/store';

interface ISourceLanguageSliceState {
  sourceLanguages: IAddonSourceCode[] | null;
}

interface ISourceLanguage {
  source_codes: IAddonSourceCode[];
  total: number;
}

const initialState: ISourceLanguageSliceState = {
  sourceLanguages: null,
};

export const getSourceLanguageData = createAsyncThunk<
  ISourceLanguage,
  undefined,
  { rejectValue: IError }
>('addonsListSection/getSourceLanguageData', async (props, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      method: 'get',
      url: '/source-codes',
    });

    return response.data;
  } catch (err: any) {
    if (err.response) {
      return rejectWithValue({ data: err.response.data, status: err.response.status });
    }

    return rejectWithValue({ data: { message: 'Something went wrong' }, status: 500 });
  }
});

const sourceLanguageSlice = createSlice({
  name: 'sourceLanguageSection',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSourceLanguageData.fulfilled, (state, action) => {
        const { source_codes } = action.payload;
        state.sourceLanguages = source_codes;
      })
      .addCase(getSourceLanguageData.rejected, (state, action) => {
        console.log(action.payload);
      });
  },
});

export const selectSourceLanguages = ({
  addonsListSection,
}: RootState): IAddonSourceCode[] | null => addonsListSection.sourceLanguageInfo.sourceLanguages;

export default sourceLanguageSlice.reducer;
