import escapeHtml from 'escape-html';
import { Text, Element, Editor } from 'slate';

const tags = {
  bold: 'strong',
  italic: 'em',
  strike: 'del',
};

const serialize = (node: Editor | Element | Text): string => {
  if (Text.isText(node)) {
    let string = escapeHtml(node.text);

    Object.entries(tags).forEach(([key, tag]) => {
      if (key in node) {
        string = `<${tag}>${string}</${tag}>`;
      }
    });

    return string;
  }

  const children = node.children.map((n) => serialize(n));

  if (!('type' in node)) {
    return children.join('');
  }

  switch (node.type) {
    case 'p':
      return `<p>${children.join('')}</p>`;
    case 'ul':
      return `<ul>${children.map((child) => `<li>${child}</li>`).join('')}</ul>`;
    case 'ol':
      return `<ol>${children.map((child) => `<li>${child}</li>`).join('')}</ol>`;
    case 'link':
      return `<a href=${node.href}>${children.join('')}</a>`;
    default:
      return children.join('');
  }
};

export default serialize;
