import './AddonMediaTab.scss';

import { FC } from 'react';

import { Card, CardActionArea } from '@mui/material';

import useAppSelector from 'app/hooks/useAppSelector';
import useAppDispatch from 'app/hooks/useAppDispatch';

import AddonMediaDialog from 'app/components/AddonDialogs/AddonMediaDialog';

import {
  addonBaseInfoSelector,
  openMediaDialog,
  closeMediaDialog,
  AddonMediaDialogSelector,
} from 'app/main/sections/AdminPortal/sections/AddonSection/store/baseInfoSlice';
import { ICoverFileNoMedia } from 'app/interfaces/addons/ICoverFile';
import { IVideoFileInPublisherPage } from 'app/interfaces/addons/IVideoFile';

const AddonMediaTab: FC = () => {
  const dispatch = useAppDispatch();

  const addonBaseInfo = useAppSelector(addonBaseInfoSelector);
  const mediaInfo = useAppSelector(AddonMediaDialogSelector);

  if (!addonBaseInfo) {
    return <>Loading...</>;
  }

  const { coverFile } = addonBaseInfo;

  const handleMediaCardClick = (
    media: ICoverFileNoMedia | IVideoFileInPublisherPage['data']
  ): void => {
    dispatch(openMediaDialog({ media }));
  };

  const displayImageTitle = addonBaseInfo.pictureFiles.length > 0;

  const displayVideoTitle = addonBaseInfo.videoFiles.length > 0;

  return (
    <section className="add-on-section add-on-section--media">
      <div className="add-on-section__content">
        <div className="media__container">
          <h2 className="media__list-title">Cover</h2>

          <ul className="media__list">
            {!addonBaseInfo.coverFile && (
              <li className="media__item media__item--cover">
                <div className="media__card--no-image">
                  <span>No Cover</span>
                </div>
              </li>
            )}

            {addonBaseInfo.coverFile && (
              <li className="media__item media__item--cover">
                <Card className="media__card">
                  <div className="media__action-container">
                    <CardActionArea
                      className="media__image-container"
                      onClick={() => handleMediaCardClick(coverFile)}
                    >
                      <img
                        className="media__image"
                        src={`${process.env.REACT_APP_STORE_API_URL}/media-files/${coverFile?.id}`}
                        alt={coverFile?.url}
                      />
                    </CardActionArea>
                  </div>

                  <h3 className="media__title">{coverFile?.id}</h3>
                </Card>
              </li>
            )}
          </ul>
        </div>

        <div className="media__container">
          {displayImageTitle && <h2 className="media__list-title">Other images</h2>}

          <ul className="media__list">
            {addonBaseInfo &&
              addonBaseInfo.pictureFiles.map((image) => (
                <li key={image.id} className="media__item">
                  <Card className="media__card">
                    <div className="media__action-container">
                      <CardActionArea
                        className="media__image-container"
                        onClick={() => handleMediaCardClick(image)}
                      >
                        <img
                          className="media__image"
                          src={`${process.env.REACT_APP_STORE_API_URL}/media-files/${image.id}`}
                          alt={image.url}
                        />
                      </CardActionArea>
                    </div>

                    <h3 className="media__title">{image.id}</h3>
                  </Card>
                </li>
              ))}
          </ul>
        </div>

        <div className="media__container">
          {displayVideoTitle && <h2 className="media__list-title">Videos</h2>}

          <ul className="media__list">
            {addonBaseInfo &&
              addonBaseInfo.videoFiles.map((video) => (
                <li key={video.id} className="media__item">
                  <Card className="media__card">
                    <div className="media__action-container">
                      <CardActionArea
                        className="media__image-container"
                        onClick={() => handleMediaCardClick(video.data)}
                      >
                        <img
                          className="media__image"
                          src={`https://img.youtube.com/vi/${video.data.videoId}/hqdefault.jpg`}
                          alt={video.data.videoId}
                        />
                      </CardActionArea>
                    </div>

                    <h3 className="media__title">{video.data.title}</h3>
                  </Card>
                </li>
              ))}
          </ul>
        </div>
      </div>

      <AddonMediaDialog type="admin" closeMediaDialog={closeMediaDialog} mediaInfo={mediaInfo} />
    </section>
  );
};

export default AddonMediaTab;
