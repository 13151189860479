import { useCallback, useEffect, useRef, useState, ReactElement } from 'react';
import { Dialog } from '@mui/material';

import UnigineButton from 'app/components/UnigineButton/UnigineButton';

import './DashboardWithdrawalDialog.scss';

const paymentProcessorUrl = 'https://payouts-widget.xsolla.com';

const PayoutState = {
  PROCESSING: 'PROCESSING',
  EXPIRED: 'EXPIRED',
  SUCCESSFUL: 'SUCCESSFUL',
};

interface IDashboardWithdrawalDialog {
  open: boolean;
  paymentToken: any;
  onSuccess: () => void;
  onAbort: (status: string) => void;
}

const DashboardWithdrawalDialog = ({
  open,
  paymentToken,
  onSuccess,
  onAbort,
}: IDashboardWithdrawalDialog): ReactElement => {
  const [payoutState, setPayoutState] = useState(PayoutState.PROCESSING);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const handleMessage = useCallback(
    (message: any) => {
      if (message.source !== iframeRef.current?.contentWindow) {
        return;
      }

      console.log('payout ui event: ', message);

      const messageData = message.data;

      const payoutName = messageData?.name;

      if (payoutName === 'xsolla.MassPayoutsWidget.errors.tokenExpired') {
        setPayoutState(PayoutState.EXPIRED);
        onAbort(PayoutState.EXPIRED);
      }

      if (payoutName === 'xsolla.MassPayoutsWidget.RequestWithdrawal.success') {
        setPayoutState(PayoutState.SUCCESSFUL);
      }
    },
    [onAbort]
  );

  useEffect(() => {
    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [handleMessage]);

  const handleClose = (): void => {
    switch (payoutState) {
      case PayoutState.PROCESSING:
        onAbort('');
        break;
      case PayoutState.EXPIRED:
        onAbort(PayoutState.EXPIRED);
        break;
      case PayoutState.SUCCESSFUL:
        onSuccess();
        break;
      default:
        break;
    }
  };

  return (
    <Dialog
      open={open}
      key={paymentToken}
      className="payment-dialog payment-dialog--payout"
      fullWidth
      maxWidth="md"
    >
      <div className="payment-dialog__content payment-dialog__content--payout">
        <iframe
          title="Payout interface"
          src={`${paymentProcessorUrl}?token=${paymentToken}`}
          className="payment-dialog__frame"
          ref={iframeRef}
          allow="camera"
        />
      </div>
      <UnigineButton onClick={handleClose}>Close Payout Interface</UnigineButton>
    </Dialog>
  );
};

export default DashboardWithdrawalDialog;
