import './MobileButtons.scss';
import { ReactComponent as FilterIcon } from 'app/assets/filter.svg';
import { ReactComponent as SortIcon } from 'app/assets/icon_sort.svg';
import { FC } from 'react';

interface IMobileButtonsProps {
  handleFiltersClick: () => void;
  handleSortClick: () => void;
}

const MobileButtons: FC<IMobileButtonsProps> = ({ handleFiltersClick, handleSortClick }) => {
  return (
    <div className="mobile__filters-container">
      <button type="button" className="mobile__filters-button" onClick={handleFiltersClick}>
        <FilterIcon /> Filters
      </button>
      <button type="button" className="mobile__filters-button" onClick={handleSortClick}>
        <SortIcon /> Sort by
      </button>
    </div>
  );
};

export default MobileButtons;
