import StorefrontHeader from 'app/components/StorefrontHeader/StorefrontHeader';
import './EULA.scss';
import { FC } from 'react';

const EULA: FC = () => {
  return (
    <>
      <StorefrontHeader />
      <div className="main text-wrapper">
        <h2>UNIGINE ADD-ON STORE END-USER LICENSE AGREEMENT</h2>
        <br />
        <br />
        <h3>TERMS AND DEFINITIONS</h3>
        <br />
        <br />
        <p>
          Add-On – 3D model, 3D scene, graphic object, software or any other work or content
          purchasable in Store
        </p>
        <br />
        <p>Store – Unigine’s website for browsing and purchasing Add-Ons</p>
        <br />
        <p>
          EULA – this Unigine Add-On Store End-User License Agreement. Current version of EULA is
          available via link
        </p>
        <br />
        <p>
          Unigine – UNIGINE HOLDING S.à r.l., 9bis rue Basse, 4963 Clemency, Luxembourg,
          registration №. B211502
        </p>
        <br />
        <p>
          User, You - individual or legal entity acting through its duly authorized representative
        </p>
        <br />
        <p>
          Personal Account – personal section of the Store, to which you gain access after signing
          up and/or signing in to the Store. In order to use the Store, you should sign up and
          receive user credentials (login, password) to sign in to a Personal Account. You may also
          use user credentials for unigine.com personal account, in case you have already registered
          a personal account at unigine.com
        </p>
        <br />
        <p>
          Intellectual Property Rights - any and all intellectual property rights wherever in the
          world and whenever arising (and including any application), including patent rights,
          copyright, trade secrets, know-how, confidential information, business names and domain
          names, computer programs, trademark laws, service marks, trade names, utility models,
          design rights, semi-conductor topography rights, database rights, goodwill or rights to
          sue for passing off, and any and all other proprietary rights worldwide
        </p>
        <br />
        <p>
          Software, Unigine SDK – a Unigine software development kit (SDK), which enables you to
          create Final Products
        </p>
        <br />
        <p>
          Final Product — a specialized software solution, developed using the Software and
          including a part of release builds of the Software engine
        </p>
        <br />
        <p>
          Unigine SDK License Agreement – a legal contract between you and Unigine or its affiliates
          which governs your use of Unigine SDK. Such contracts include direct contracts and EULAs.
          You can learn more about SDK EULAs here{' '}
          <a href="https://unigine.com/company/legal">https://unigine.com/company/legal/</a>
        </p>
        <br />
        <p>
          Terms – Unigine Add-On Store Terms of Service. Current version of Terms is available via
          link <a href="https://assets.unigine.com/tos">https://assets.unigine.com/tos</a>
        </p>
        <br />
        <br />
        <ol>
          <li>
            <h3>THE SUBJECT MATTER OF THE EULA</h3>
            <ol>
              <br />
              <li>This EULA governs your use of the Add-Ons.</li>
              <br />
              <li>
                You may browse Add-Ons through the Store. Your use of the Store is subject to
                Unigine Add-On Store Terms of service.
              </li>
              <br />
              <li>
                In order to download Add-Ons, you must have SDK Browser installed on your
                workstation. Your use of SDK Browser is subject to separate SDK Browser EULA, which
                is available via link{' '}
                <a href="https://unigine.com/company/legal/unigine-sdk-browser-eula">
                  https://unigine.com/company/legal/unigine-sdk-browser-eula
                </a>
                .
              </li>
              <br />
              <li>
                By downloading, installing, copying, accessing or otherwise using the Add-Ons, you
                agree to be bound by this EULA. All definitions and provisions of the Terms shall
                also apply in this EULA.
              </li>
            </ol>
          </li>
          <br />
          <br />
          <li>
            <h3>SCOPE OF LICENSE</h3>
            <ol>
              <br />
              <li>
                Unigine grants you non-exclusive, non-transferable, non-sublicensable license to use
                Add-Ons. The Add-Ons are licensed, not sold to you.
              </li>
              <br />
              <li>
                You may use Add-Ons in the following ways:
                <ol>
                  <br />
                  <br />
                  <li>develop Final Product with Unigine SDK using the Add-Ons</li>
                  <br />
                  <li>include Add-Ons as a part of the Final Product;</li>
                  <br />
                  <li>distribute Add-Ons as a part of the Final Product.</li>
                </ol>
              </li>
              <br />
              <br />
              <li>
                You may use Add-Ons only on those workstations on which Unigine SDK is installed.
                Number of such workstations and terms of using Unigine SDK are specified in your
                Unigine SDK License Agreement.
              </li>
              <br />
              <li>
                License is free. However, Unigine may at its sole discretion set the license fee in
                the future for all or particular Add-Ons.
              </li>
              <br />
              <li>
                Your Unigine SDK License Agreement may contain specific licensing terms for
                developing and using of Final Products. Using Add-Ons in connection with developing
                and using the Final Products, you must comply with such terms of Unigine SDK License
                Agreement.
              </li>
              <br />
              <li>
                List of available Add-Ons in the Store is provided by Unigine. Unigine may at its
                sole discretion add, delete, replace or modify any Add-On. Unigine may make certain
                Add-Ons available for users with certain Unigine SDK License Agreement types or
                conditions, revise or revoke such availability.
              </li>
              <br />
              <li>
                Term and Territory of license for Add-Ons shall be the same as in your Unigine SDK
                License Agreement.
              </li>
            </ol>
          </li>
          <br />
          <br />
          <li>
            <h3>INTELLECTUAL PROPERTY</h3>
            <ol>
              <br />
              <li>
                All title and Intellectual Property Rights in and to the Add-Ons (including but not
                limited to any software, images, photographs, animations, graphics, 3D graphics,
                video, audio, music, text, tutorials, and “applets” incorporated into the Add-Ons)
                and any copies of the Add-Ons are owned by Unigine. All rights not expressly granted
                to you are reserved by Unigine. For the sake of clarity and without limitation of
                the foregoing, use of Add-Ons, whether modified as permitted hereunder or
                unmodified, is limited to use as expressly provided in this EULA.
              </li>
              <br />
              <li>
                You are prohibited from:
                <ol>
                  <br />
                  <br />
                  <li>
                    Distribution of the Add-Ons (or its part) in any form whatsoever, including the
                    transfer of your Personal Account credentials to a third party;
                  </li>
                  <br />
                  <li>Complete or partial sublicensing of the Add-Ons in any form whatsoever;</li>
                  <br />
                  <li>
                    Reverse engineering and other actions with the Add-Ons aimed at obtaining
                    information about the implementation of algorithms used in it, creating
                    derivatives or modifications using or based on the Add-Ons;
                  </li>
                  <br />
                  <li>
                    Performing activities aimed at complete or partial copying of the Add-Ons
                    (emulation, decompiling, disassembly, decoding, modification and other similar
                    actions);
                  </li>
                  <br />
                  <li>
                    Publication of the Add-Ons in the public domain including the transmission of a
                    copy of the Add-Ons or any of its components over the Internet, granting access
                    (including terminal access) to the Add-Ons to several users simultaneously;
                  </li>
                  <br />
                  <li>
                    Enable a customer or user of a Final Product to sell, transfer, distribute,
                    lease, or lend the Add-Ons for commercial gain or commercialize Add-Ons within a
                    Final Product;
                  </li>
                  <br />
                  <li>
                    Use, reproduce, duplicate, publicly display, publicly perform, copy, modify,
                    adapt, translate, prepare derivative works of, distribute, transfer, license,
                    sublicense, rent, lease, lend, sell, trade, resell, or otherwise commercialize
                    or monetize any Add-On except as expressly permitted in this EULA.
                  </li>
                  <br />
                  <li>
                    If the Add-On is a software, modify this software except as instructed or
                    authorized by Provider in writing (including instruction or authorization in
                    Add-On documentation) or include the software within Final Product.
                  </li>
                </ol>
              </li>
              <br />
              <br />
              <li>
                You agree that Final Product or any other use of those Add-Ons shall not:
                <ol>
                  <br />
                  <br />
                  <li>
                    Infringe, misappropriate, or violate a third party’s patent, copyright,
                    trademark, trade secret, moral rights, or other intellectual property rights, or
                    rights of publicity or privacy;
                  </li>
                  <br />
                  <li>
                    Violate, or encourage any conduct that would violate, any applicable law or
                    regulation or would give rise to liability of any kind;
                  </li>
                  <br />
                  <li>Be fraudulent, false, misleading, or deceptive;</li>
                  <br />
                  <li>Be defamatory, obscene, pornographic, vulgar, or offensive;</li>
                  <br />
                  <li>
                    Promote discrimination, bigotry, racism, hatred, harassment, or harm against any
                    individual or group;
                  </li>
                  <br />
                  <li>Promote violence or actions that are threatening to any other person;</li>
                  <br />
                  <li>Promote illegal or harmful activities or substances.</li>
                </ol>
              </li>
            </ol>
            <br />
            <br />
            <li>
              <h3>INDEMNIFICATION</h3>
              <ol>
                <br />
                <li>
                  You will, at your own expense, indemnify and hold Unigine, and its subsidiaries
                  and affiliates, and all officers, directors, and employees thereof, harmless from
                  and against any and all claims, actions, liabilities, losses, damages, judgments,
                  grants, costs, and expenses, including reasonable legal fees (collectively,
                  “Claims”), arising out of any use of Add-Ons by you, any party related to you, or
                  any party acting upon your authorization in a manner that is not expressly
                  authorized in this EULA.
                </li>
              </ol>
            </li>
            <br />
            <br />
            <li>
              <h3>DISCLAIMER OF WARRANTIES</h3>
              <ol>
                <br />
                <li>
                  THE ADD-ONS ARE LICENSED "AS IS", AND UNIGINE DISCLAIMS ANY AND ALL OTHER
                  WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, ANY IMPLIED
                  WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE, TO THE EXTENT
                  AUTHORIZED BY LAW. WITHOUT LIMITATION OF THE FOREGOING, UNIGINE EXPRESSLY DOES NOT
                  WARRANT THAT THE ADD-ONS WILL MEET YOUR REQUIREMENTS OR THAT OPERATION OF THE
                  ADD-ONS WILL BE UNINTERRUPTED OR ERROR-FREE.
                </li>
                <br />
                <li>
                  YOUR USE OF THE ADD-ONS IS AT YOUR OWN DISCRETION AND RISK AND YOU ARE SOLELY
                  RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM, OR OTHER DEVICE, OR LOSS OF
                  DATA THAT RESULTS FROM SUCH USE.
                </li>
              </ol>
            </li>
            <br />
            <br />
            <li>
              <h3>LIMITATION OF LIABILITY</h3>
              <ol>
                <br />
                <li>
                  Under no circumstances and no legal theory, whether in tort, negligence, contract
                  or otherwise, shall Unigine or its affiliates be liable to you or any affiliated
                  person for any indirect, special, incidental, punitive, exemplary, consequential,
                  or extra-contractual damages of any kind, loss of goodwill, loss of personnel
                  salaries, lost profits or revenue, damages due to work stoppage and/or computer
                  failure or malfunction, and/or costs of procuring substitute software or services,
                  whether or not foreseeable, even if Unigine has been advised of the possibility or
                  probability of such damages.
                </li>
                <br />
                <li>
                  By accepting this EULA, you shall be fully liable for validity and correctness of
                  the data about you by you, including the cases when a third party gains access to
                  the Store through your fault. Unigine shall not be liable whatsoever in case if a
                  third party gains access to the Store. You must take all necessary measures to
                  prevent the disclosure of your login and password as well as the use of the
                  Add-Ons by any third party.
                </li>
              </ol>
            </li>
            <br />
            <br />
            <li>
              <h3>TERMINATION</h3>
              <ol>
                <br />
                <li>
                  If you want to terminate this EULA, you may do so by ceasing your use of any
                  Add-Ons.
                </li>
                <br />
                <li>
                  Without prejudice to any other rights, Unigine may terminate this EULA if you fail
                  to comply with or breach this EULA, the Terms or Unigine SDK License Agreement.
                </li>
              </ol>
            </li>
            <br />
            <br />
            <li>
              <h3>MISCELLANEOUS</h3>
              <ol>
                <br />
                <li>
                  You agree that Unigine may stop (permanently or temporarily) providing the Add-Ons
                  (or any features within the Add-Ons) at its sole discretion, without prior notice
                  to you.
                </li>
                <br />
                <li>
                  You agree to use the Add-Ons only for purposes that are permitted by (a) this EULA
                  and (b) any applicable law, regulation or generally accepted practices or
                  guidelines in the relevant jurisdictions. You agree to comply with all local laws
                  and regulations regarding the download, installation and/or use of the Add-Ons.
                </li>
              </ol>
            </li>
            <br />
            <br />
            <li>
              <h3>GENERAL TERMS</h3>
              <ol>
                <br />
                <li>
                  Governing Law. This EULA shall be construed and interpreted under English law. To
                  the extent permitted by law, the provisions of this EULA shall supersede any
                  provisions of the appropriate local and international laws. Any dispute,
                  controversy or claim arising out of or relating to this EULA, including without
                  limitation, the breach, termination, or invalidity thereof, shall, on the written
                  demand of either Party delivered to the other Party, be determined and settled by
                  arbitration under the UNCITRAL Arbitration Rules in effect on the date of this
                  Agreement.
                </li>
                <br />
                <li>
                  Severability. If any term or provision of this EULA is declared void or
                  unenforceable in a particular situation, by any judicial or administrative
                  authority, this declaration shall not affect the validity of enforceability of the
                  remaining terms and provisions hereof or the validity or enforceability of the
                  offending term or provision in any other situation.
                </li>
                <br />
                <li>
                  Names and headings. The name of these this EULA as well as headings of sections
                  and paragraphs contained herein are incorporated for reference purposes only and
                  shall not affect the meaning or interpretation of this EULA.
                </li>
                <br />
                <li>
                  Non-waiver. The failure of either party to enforce any rights granted hereunder or
                  to take action against the other party in the event of any breach hereunder shall
                  not be deemed a waiver by that party as to subsequent enforcement of rights or
                  subsequent actions in the event of future breaches.
                </li>
                <br />
                <li>
                  Amendments to the Terms. Unigine reserves the right to make amendments to this
                  EULA as it deems appropriate at any time. In case of any discrepancy between this
                  EULA and its current version, the current version shall always take precedence.
                  The current version is always available via link provided in “Terms and
                  definitions” section of this EULA. By continuing to use the Add-Ons after
                  publication of the amended EULA, you accept all terms and conditions of the
                  current version of the EULA.
                </li>
                <br />
                <li>
                  Restrictions. Unigine and you shall comply with all national and international
                  laws and regulations applicable to the Add-Ons as well as restrictions concerning
                  End-Users, procedures and regions of end-use established in EU and other countries
                  and regions including the ones regarding requirements for personal data
                  processing.
                </li>
                <br />
                <li>
                  Entire agreement. This EULA constitute the complete and exclusive agreement
                  between you and Unigine concerning subject matter hereof and supersedes all prior
                  or contemporaneous oral or written communications, proposals, representations,
                  understandings, or agreements not specifically incorporated herein.
                </li>
              </ol>
            </li>
          </li>
        </ol>
      </div>
    </>
  );
};

export default EULA;
