import clsx from 'clsx';

import './PriceDisplay.scss';
import { FC } from 'react';

interface IPriceDisplay {
  className?: string;
  price: string;
  discountedPrice?: string;
  isFree: boolean;
  isUnavailable?: boolean;
}

const PriceDisplay: FC<IPriceDisplay> = ({
  className,
  price,
  discountedPrice = price,
  isFree,
  isUnavailable,
}) => {
  const isOnSale = price !== discountedPrice;

  return (
    <div
      className={clsx(
        'price-display',
        {
          'price-display--unavailable': isUnavailable,
        },
        className
      )}
    >
      {isOnSale && (
        <span className="price-display__base-price">${parseFloat(price).toFixed(2)}</span>
      )}

      <strong
        className={clsx('price-display__price price-display__price--add-on-page', {
          'price-display__price--special': isOnSale,
        })}
      >
        {isFree ? 'Free' : `$ ${parseFloat(isOnSale ? discountedPrice : price).toFixed(2)}`}
      </strong>
    </div>
  );
};

export default PriceDisplay;
