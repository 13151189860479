import './ReviewsSection.scss';

import { useState, useEffect, FC } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';

import useAppDispatch from 'app/hooks/useAppDispatch';
import useAppSelector from 'app/hooks/useAppSelector';

import AdminPortalReviewCard from 'app/components/AdminPortalReviewCard/AdminPortalReviewCard';
import SortControl from 'app/components/SortControl/SortControl';
import FilterForm from 'app/components/FilterForm/FilterForm';
import UniginePagination from 'app/components/UniginePagination/UniginePagination';

import {
  getReviewsStatusData,
  selectReviewStatus,
} from 'app/main/sections/AdminPortal/sections/ReviewsSection/store/reviewStatusSlice';
import {
  getReviewsData,
  reviewsErrorSelector,
  reviewsInfoSelector,
  reviewsTotalPagesSelector,
  toggleBan,
  toggleCheck,
} from 'app/main/sections/AdminPortal/sections/ReviewsSection/store/reviewsSlice';

import { reviewsPerPage } from 'app/configs/appConfig';

interface IHandleCheck {
  checked: boolean;
  reportId?: string;
  reviewId: string;
}

interface IHandleBan {
  banned: boolean;
  reportId?: string;
  reviewId: string;
}

const ReviewsSection: FC = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useAppDispatch();
  const reviewsInfo = useAppSelector(reviewsInfoSelector);
  const reviewsError = useAppSelector(reviewsErrorSelector);
  const totalPages = useAppSelector(reviewsTotalPagesSelector);

  const reviewStatuses = useAppSelector(selectReviewStatus);

  const [searchParams] = useSearchParams();
  const [page, sort, direction, isChecked, isBanned, perPage] = [
    searchParams.get('page'),
    searchParams.get('sort') || undefined,
    searchParams.get('direction') || undefined,
    searchParams.get('isChecked') || undefined,
    searchParams.get('isBanned') || undefined,
    searchParams.get('perPage'),
  ];

  const currentPage = parseInt(page || '1', 10);
  const currentReviewsPerPage = perPage ? parseInt(perPage, 10) : reviewsPerPage;

  useEffect(() => {
    dispatch(getReviewsStatusData());
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getReviewsData({
        perPage: currentReviewsPerPage,
        page: currentPage,
        sort,
        direction,
        isChecked,
        isBanned,
      })
    ).finally(() => setLoading(false));
  }, [dispatch, page, sort, direction, isChecked, isBanned, currentReviewsPerPage, currentPage]);

  const handleCheck = ({ checked, reportId, reviewId }: IHandleCheck): void => {
    if (!reportId) {
      return;
    }
    dispatch(toggleCheck({ reportId, isChecked: checked, reviewId }));
  };
  const handleBan = ({ banned, reportId, reviewId }: IHandleBan): void => {
    if (!reportId) {
      return;
    }
    dispatch(toggleBan({ reportId, isBanned: banned, reviewId }));
  };

  return (
    <div className="publisher-panel__wrapper--no-header">
      <div className="add-on-table__wrapper columns">
        <div className="add-on-table__column add-on-table__column--main columns__column--content">
          <div className="add-on-table__title-wrapper">
            <h1 className="add-on-table__title">Reviews</h1>

            <SortControl name="sort" options={[{ value: 'title', label: 'Add-On' }]} />
          </div>

          {loading && (
            <div className="add-on-list__loading-container">
              <CircularProgress color="inherit" className="add-on-list__loading" />
            </div>
          )}

          {reviewsError && !loading && (
            <div className="add-on-list__loading-container">{reviewsError.data.message}</div>
          )}

          {!reviewsError && !loading && reviewsInfo && (
            <div className="reviews-section__cards-wrapper">
              {reviewsInfo.map((review) => (
                <AdminPortalReviewCard
                  key={review.id}
                  reviewData={review}
                  onCheck={() =>
                    handleCheck({
                      checked: !review.isChecked,
                      reportId: review.reportId,
                      reviewId: review.id,
                    })
                  }
                  onBan={() =>
                    handleBan({
                      banned: !review.isBanned,
                      reportId: review.reportId,
                      reviewId: review.id,
                    })
                  }
                />
              ))}
            </div>
          )}

          {!reviewsError && !loading && reviewsInfo && reviewsInfo.length !== 0 && (
            <div className="pagination__container">
              <UniginePagination
                currentPage={currentPage}
                totalPages={totalPages}
                defaultValue={reviewsPerPage}
              />
            </div>
          )}
        </div>
        <div className="add-on-table__column add-on-table__column--side columns__column--side">
          {reviewStatuses && (
            <FilterForm
              price={false}
              search={{
                name: 'search',
                label: 'Search by Add-On',
              }}
              filterParams={[
                {
                  name: 'isChecked',
                  title: 'Check Status',
                  type: 'radio',
                  options: [
                    { value: 'all', label: 'All' },
                    { value: 'true', label: 'Checked' },
                    { value: 'false', label: 'Unchecked' },
                  ],
                  nullValue: 'all',
                  getLabel: (item) => item.label,
                  getValue: (item) => item.value,
                  collapsed: false,
                },
                {
                  name: 'isBanned',
                  title: 'Ban Status',
                  type: 'radio',
                  options: [
                    { value: 'all', label: 'All' },
                    { value: 'true', label: 'Banned' },
                    { value: 'false', label: 'Not banned' },
                  ],
                  nullValue: 'all',
                  getLabel: (item) => item.label,
                  getValue: (item) => item.value,
                  collapsed: false,
                },
              ]}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ReviewsSection;
