import {
  createSlice,
  createAsyncThunk,
  isAnyOf,
  PayloadAction,
  createEntityAdapter,
  EntityState,
} from '@reduxjs/toolkit';
import axios from 'axios';
import axiosInstance from 'app/auth/axiosInstance';
import { defaultError, YOUTUBE_KEY } from 'app/configs/appConfig';
import IError from 'app/interfaces/IError';
import { IPublishedAddonVersionBaseInfo } from 'app/interfaces/addons/IAddonVersion';
import { RootState } from 'app/store';
import { IVideoInfo } from 'app/interfaces/addons/IVideoFile';
import { IEditAddonParams } from 'app/interfaces/addons/IEditAddon';
import IPackage from 'app/interfaces/addons/IPackage';
import adaptError from 'app/utils/helpers/adaptError';

export interface IMedia {
  videoId?: string;
  id?: string;
  previewUrl?: string;
  url?: string;
}

export interface IBaseInfoMediaDialog {
  isOpen?: boolean;
  media?: IMedia | null;
  isFormImage?: boolean;
  deleteImageId?: string | null;
  deleteVideoId?: string | null;
  allowToDeleteMedia?: boolean;
  isFormVideo?: boolean;
}

export interface IPackageData {
  description?: string;
  platformId: string;
  sourceCodeId: string;
  sdkVersions: string[];
}

export interface IHidePackageError {
  packageId: string;
  message: string;
}

const packagesAdapter = createEntityAdapter<IPackage>();

interface IBaseInfoSliceState {
  mediaDialog: IBaseInfoMediaDialog;
  info: IPublishedAddonVersionBaseInfo | null;
  packages: EntityState<IPackage>;
  errors: {
    baseInfoError: IError | null;
    publishError: IError | null;
    packageError: string | null;
    hidePackageError: IHidePackageError | null;
  };
}

const initialState: IBaseInfoSliceState = {
  mediaDialog: {
    isOpen: false,
    media: null,
    isFormImage: false,
    deleteImageId: null,
    deleteVideoId: null,
    allowToDeleteMedia: false,
    isFormVideo: false,
  },
  info: null,
  packages: packagesAdapter.getInitialState({}),
  errors: {
    baseInfoError: null,
    publishError: null,
    packageError: null,
    hidePackageError: null,
  },
};

export const getAddonBaseInfo = createAsyncThunk<
  IPublishedAddonVersionBaseInfo,
  { addonVersionId: string },
  { rejectValue: IError }
>('publisherPanelSection/getAddonBaseInfo', async ({ addonVersionId }, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      method: 'get',
      url: `/publisher-panel/addon-versions/${addonVersionId}`,
    });

    return response.data;
  } catch (err: any) {
    if (err.response?.data?.context?.message) {
      return rejectWithValue({ data: err.response.data, status: err.response.status });
    }

    return rejectWithValue(defaultError);
  }
});

export const editAddonBaseInfo = createAsyncThunk<
  IPublishedAddonVersionBaseInfo,
  { addonData: IEditAddonParams; addonVersionId: string },
  { rejectValue: IError }
>(
  'publisherPanelSection/editAddonBaseInfo',
  async ({ addonData, addonVersionId }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance({
        method: 'put',
        url: `/publisher-panel/addon-versions/${addonVersionId}`,
        data: addonData,
      });

      return response.data;
    } catch (err: any) {
      if (err.response?.data?.context?.message) {
        return rejectWithValue({ data: err.response.data, status: err.response.status });
      }

      return rejectWithValue(defaultError);
    }
  }
);

export const createAddonVersion = createAsyncThunk<
  IPublishedAddonVersionBaseInfo,
  { addonId: string },
  { rejectValue: IError }
>('publisherPanelSection/createAddonVersion', async ({ addonId }, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      method: 'post',
      url: '/publisher-panel/addon-versions',
      data: {
        addonId,
      },
    });

    return response.data;
  } catch (err: any) {
    if (err.response?.data?.context?.message) {
      return rejectWithValue({ data: err.response.data, status: err.response.status });
    }

    return rejectWithValue(defaultError);
  }
});

export const publishAddonVersion = createAsyncThunk<
  IPublishedAddonVersionBaseInfo,
  { addonVersionId: string },
  { rejectValue: IError }
>('publisherPanelSection/publishAddonVersion', async ({ addonVersionId }, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      method: 'put',
      url: `/publisher-panel/addon-versions/${addonVersionId}/moderation`,
    });

    return response.data;
  } catch (err: any) {
    if (err.response?.data?.context?.message) {
      return rejectWithValue({ data: err.response.data, status: err.response.status });
    }

    return rejectWithValue(defaultError);
  }
});

export const withdrawAddonVersion = createAsyncThunk<
  IPublishedAddonVersionBaseInfo,
  { addonVersionId: string },
  { rejectValue: IError }
>('publisherPanelSection/withdrawAddonVersion', async ({ addonVersionId }, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      method: 'put',
      url: `/publisher-panel/addon-versions/${addonVersionId}/withdraw-moderation`,
    });

    return response.data;
  } catch (err: any) {
    if (err.response?.data?.context?.message) {
      return rejectWithValue({ data: err.response.data, status: err.response.status });
    }

    return rejectWithValue(defaultError);
  }
});

export const getVideoInfoById = createAsyncThunk<IVideoInfo, string>(
  'publisherPanelSection/getVideoInfoById',
  async (videoId) => {
    const response = await axios({
      method: 'get',
      url: `https://www.googleapis.com/youtube/v3/videos`,
      params: {
        id: videoId,
        part: 'snippet',
        key: YOUTUBE_KEY,
      },
      headers: { Authorization: '' },
    });

    const { items } = await response.data;

    const { snippet } = items[0];

    const { description, channelId, channelTitle, title } = snippet;

    return {
      id: videoId,
      videoId,
      description,
      channelId,
      channelTitle,
      title,
    };
  }
);

export const addVideoFile = createAsyncThunk<
  IPublishedAddonVersionBaseInfo,
  { videoFile: IVideoInfo; addonVersionId: string },
  { rejectValue: IError }
>(
  'publisherPanelSection/addVideoFile',
  async ({ videoFile, addonVersionId }, { rejectWithValue }) => {
    try {
      const videoDataToSend = { data: videoFile };

      const response = await axiosInstance({
        method: 'post',
        url: `/publisher-panel/addon-versions/${addonVersionId}/videos`,
        data: videoDataToSend,
      });

      const addonBaseInfo = await response.data;

      return addonBaseInfo;
    } catch (err: any) {
      if (err.response?.data?.context?.message) {
        return rejectWithValue({ data: err.response.data, status: err.response.status });
      }

      return rejectWithValue(defaultError);
    }
  }
);

export const removeVideoFile = createAsyncThunk<
  IPublishedAddonVersionBaseInfo,
  { videoId: string; addonVersionId: string },
  { rejectValue: IError }
>(
  'publisherPanelSection/removeVideoFile',
  async ({ videoId, addonVersionId }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance({
        method: 'delete',
        url: `/publisher-panel/addon-versions/${addonVersionId}/videos/${videoId}`,
      });

      const addonBaseInfo = await response.data;

      return addonBaseInfo;
    } catch (err: any) {
      if (err.response?.data?.context?.message) {
        return rejectWithValue({ data: err.response.data, status: err.response.status });
      }

      return rejectWithValue(defaultError);
    }
  }
);

export const addPictureFile = createAsyncThunk<
  IPublishedAddonVersionBaseInfo,
  { images: File[]; addonVersionId: string },
  { rejectValue: IError }
>(
  'publisherPanelSection/addPictureFile',
  async ({ images, addonVersionId }, { rejectWithValue }) => {
    try {
      const promises: Promise<any>[] = [];
      // eslint-disable-next-line array-callback-return
      images.map((file) => {
        const formData = new FormData();
        formData.append('file', file);
        const promiseResult = axiosInstance({
          method: 'post',
          url: `/publisher-panel/addon-versions/${addonVersionId}/pictures`,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          data: formData,
        });
        promises.push(promiseResult);
      });

      const results = await Promise.all(promises);
      const sortedResults = results.sort(
        (a, b) => b.data.pictureFiles.length - a.data.pictureFiles.length
      );

      return sortedResults[0].data;
    } catch (err: any) {
      if (err.response?.data?.context?.message) {
        return rejectWithValue({ data: err.response.data, status: err.response.status });
      }

      return rejectWithValue(defaultError);
    }
  }
);

export const removePictureFile = createAsyncThunk<
  IPublishedAddonVersionBaseInfo,
  { imageId: string; addonVersionId: string },
  { rejectValue: IError }
>(
  'publisherPanelSection/removePictureFile',
  async ({ imageId, addonVersionId }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance({
        method: 'delete',
        url: `/publisher-panel/addon-versions/${addonVersionId}/pictures/${imageId}`,
      });

      const addonBaseInfo = await response.data;
      return addonBaseInfo;
    } catch (err: any) {
      if (err.response?.data?.context?.message) {
        return rejectWithValue({ data: err.response.data, status: err.response.status });
      }

      return rejectWithValue(defaultError);
    }
  }
);

export const addCoverFile = createAsyncThunk<
  IPublishedAddonVersionBaseInfo,
  { addonVersionId: string; file: File },
  { rejectValue: IError }
>('publisherPanelSection/addCoverFile', async ({ file, addonVersionId }, { rejectWithValue }) => {
  try {
    const formData = new FormData();
    formData.append('file', file);

    const response = await axiosInstance({
      method: 'post',
      url: `/publisher-panel/addon-versions/${addonVersionId}/cover`,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
    });

    const addonBaseInfo = await response.data;

    return addonBaseInfo;
  } catch (err: any) {
    if (err.response?.data?.context?.message) {
      return rejectWithValue({ data: err.response.data, status: err.response.status });
    }

    return rejectWithValue(defaultError);
  }
});

export const getUploadUrl = createAsyncThunk<
  { oneFileUploadUrl: string; chunkUploadUrl: string },
  { packageId: string },
  { rejectValue: string }
>('publisherPanelSection/getUploadUrl', async ({ packageId }, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      method: 'get',
      url: `/publisher-panel/packages/${packageId}/upload-link`,
    });

    const { uploadUrl, chunkUploadUrl } = await response.data;

    return {
      oneFileUploadUrl: uploadUrl,
      chunkUploadUrl,
    };
  } catch (err: any) {
    if (err.response?.data?.message) {
      return rejectWithValue(err.response.data.message);
    }

    return rejectWithValue(defaultError.data.message);
  }
});

export const uploadPackage = createAsyncThunk<
  IPublishedAddonVersionBaseInfo & { originalName?: string; fileSize?: string },
  {
    data: FormData | string | ArrayBuffer;
    uploadUrl: string;
    setUploadProgress: (arg: number) => void;
    abortController: { current: AbortController | null };
  },
  { rejectValue: string }
>(
  'publisherPanelSection/uploadPackage',
  async ({ data, uploadUrl, abortController, setUploadProgress }, { rejectWithValue }) => {
    try {
      abortController.current = new AbortController();
      const response = await axiosInstance({
        method: 'post',
        url: uploadUrl,
        data,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        signal: abortController.current.signal,
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          const percent = total ? Math.floor((loaded * 100) / total) : 0;

          setUploadProgress(percent);
        },
      });

      const uploadData = await response.data;

      return uploadData;
    } catch (err: any) {
      if (abortController?.current?.signal?.aborted) {
        return rejectWithValue(abortController?.current?.signal?.reason);
      }

      if (err.response?.data?.message) {
        return rejectWithValue(err.response.data.message);
      }

      return rejectWithValue(defaultError.data.message);
    }
  }
);

export const addPackage = createAsyncThunk<
  { package: IPackage; links: { uploadUrl: string; chunkUploadUrl: string } },
  { packageData: IPackageData; addonVersionId: string },
  { rejectValue: IError }
>(
  'publisherPanelSection/addPackage',
  async ({ packageData, addonVersionId }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance({
        method: 'post',
        url: '/publisher-panel/packages',
        data: {
          ...packageData,
          addonVersionId,
        },
      });

      return response.data;
    } catch (err: any) {
      return rejectWithValue(adaptError(err));
    }
  }
);

export const updatePackage = createAsyncThunk<
  IPackage,
  { packageData: IPackageData; packageId: string },
  { rejectValue: IError }
>(
  'publisherPanelSection/updatePackage',
  async ({ packageData, packageId }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance({
        method: 'put',
        url: `/publisher-panel/packages/${packageId}`,
        data: packageData,
      });

      const [_package] = response.data;
      return _package;
    } catch (err: any) {
      return rejectWithValue(adaptError(err));
    }
  }
);

export const hidePackage = createAsyncThunk<
  IPackage,
  { packageId: string; isHidden: boolean },
  { rejectValue: IHidePackageError }
>('publisherPanelSection/hidePackage', async ({ packageId, isHidden }, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      method: 'put',
      url: `/publisher-panel/packages/${packageId}/is-hidden`,
      data: {
        isHidden,
      },
    });

    return response.data;
  } catch (err: any) {
    return rejectWithValue({ packageId, message: err.response?.data?.context.message });
  }
});

export const deletePackage = createAsyncThunk<
  string,
  { packageId: string },
  { rejectValue: IError }
>('publisherPanelSection/deletePackage', async ({ packageId }, { rejectWithValue }) => {
  try {
    await axiosInstance({
      method: 'delete',
      url: `/publisher-panel/packages/${packageId}`,
    });

    return packageId;
  } catch (err: any) {
    return rejectWithValue(adaptError(err));
  }
});

const baseInfoSlice = createSlice({
  name: 'publisherPanelSection',
  initialState,
  reducers: {
    openMediaDialog: (state, action: PayloadAction<IBaseInfoMediaDialog>) => {
      const { media, allowToDeleteMedia, deleteImageId } = action.payload;
      state.mediaDialog.isOpen = true;
      if (media) {
        state.mediaDialog.media = media;
      }

      if (allowToDeleteMedia) {
        state.mediaDialog.allowToDeleteMedia = allowToDeleteMedia;
      }

      if (deleteImageId) {
        state.mediaDialog.deleteImageId = deleteImageId;
      }
      state.mediaDialog.deleteVideoId = action.payload.deleteVideoId;
      state.mediaDialog.isFormImage = action.payload.isFormImage;
      state.mediaDialog.isFormVideo = action.payload.isFormVideo;
    },
    closeMediaDialog: (state) => {
      state.mediaDialog.isOpen = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadPackage.fulfilled, (state, action) => {
        const { originalName, fileSize } = action.payload;

        if (originalName && state.info) {
          state.info.packageFileName = originalName;
        }

        if (fileSize && state.info) {
          state.info.packageSize = fileSize;
        }

        state.errors.publishError = null;
      })

      .addCase(addPackage.fulfilled, (state, action) => {
        const { package: _package } = action.payload; // Identifier expected. 'package' is a reserved word in strict mode.
        packagesAdapter.addOne(state.packages, _package);
      })

      .addCase(updatePackage.fulfilled, (state, action) => {
        packagesAdapter.updateOne(state.packages, {
          id: action.payload.id,
          changes: action.payload,
        });
      })

      .addCase(hidePackage.fulfilled, (state, action) => {
        const { id, isHidden } = action.payload;

        packagesAdapter.updateOne(state.packages, {
          id,
          changes: {
            isHidden,
          },
        });

        state.errors.hidePackageError = null;
      })

      .addCase(hidePackage.rejected, (state, action) => {
        if (action.payload) {
          state.errors.hidePackageError = action.payload;
        }
      })

      .addCase(deletePackage.fulfilled, (state, action) => {
        packagesAdapter.removeOne(state.packages, action.payload);
      })

      .addMatcher(
        isAnyOf(
          getAddonBaseInfo.fulfilled,
          editAddonBaseInfo.fulfilled,
          createAddonVersion.fulfilled,
          publishAddonVersion.fulfilled,
          withdrawAddonVersion.fulfilled,
          addPictureFile.fulfilled,
          removePictureFile.fulfilled,
          addVideoFile.fulfilled,
          removeVideoFile.fulfilled,
          addCoverFile.fulfilled
        ),
        (state, action) => {
          state.info = action.payload;

          if (action.payload.packages) {
            const { packages } = action.payload;
            packagesAdapter.setAll(state.packages, packages);
          }

          state.errors.baseInfoError = null;
          state.errors.publishError = null;
        }
      )

      .addMatcher(
        isAnyOf(
          getAddonBaseInfo.rejected,
          editAddonBaseInfo.rejected,
          createAddonVersion.rejected,
          addPictureFile.rejected,
          removePictureFile.rejected,
          addVideoFile.rejected,
          removeVideoFile.rejected,
          addCoverFile.rejected
        ),
        (state, action) => {
          state.info = null;
          state.errors.baseInfoError = action.payload ?? defaultError;
        }
      )
      .addMatcher(
        isAnyOf(publishAddonVersion.rejected, withdrawAddonVersion.rejected),
        (state, action) => {
          state.errors.publishError = action.payload ?? defaultError;
        }
      )
      .addMatcher(isAnyOf(getUploadUrl.pending, uploadPackage.pending), (state) => {
        state.errors.packageError = null;
      })
      .addMatcher(isAnyOf(getUploadUrl.rejected, uploadPackage.rejected), (state, action) => {
        state.errors.packageError = action.payload ?? defaultError.data.message;
      });
  },
});

export const { openMediaDialog, closeMediaDialog } = baseInfoSlice.actions;

export const AddonMediaDialogSelector = ({
  publisherPanelSection,
}: RootState): IBaseInfoMediaDialog => publisherPanelSection.addonBaseInfo.mediaDialog;

export const addonBaseInfoSelector = ({
  publisherPanelSection,
}: RootState): IPublishedAddonVersionBaseInfo | null => publisherPanelSection.addonBaseInfo.info;

export const { selectAll: packagesSelector, selectById: packageByIdSelect } =
  packagesAdapter.getSelectors(
    ({ publisherPanelSection }: RootState) => publisherPanelSection.addonBaseInfo.packages
  );

export const addonBaseInfoErrorSelector = ({ publisherPanelSection }: RootState): IError | null =>
  publisherPanelSection.addonBaseInfo.errors.baseInfoError;

export const addonBaseInfoPublishErrorSelector = ({
  publisherPanelSection,
}: RootState): IError | null => publisherPanelSection.addonBaseInfo.errors.publishError;

export const addonPackageErrorSelector = ({ publisherPanelSection }: RootState): string | null =>
  publisherPanelSection.addonBaseInfo.errors.packageError;

export const hidePackageErrorSelector = ({
  publisherPanelSection,
}: RootState): IHidePackageError | null =>
  publisherPanelSection.addonBaseInfo.errors.hidePackageError;

export default baseInfoSlice.reducer;
