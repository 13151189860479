import './AddonPackageDialog.scss';

import { FC } from 'react';
import { Dialog, DialogContent, LinearProgress } from '@mui/material';

import UnigineButton from 'app/components/UnigineButton/UnigineButton';

interface IUploadDialog {
  isProcessing: boolean;
  uploadPercentage: number;
  handleCancelUpload: () => void;
}

const AddonPackageUploadingDialog: FC<IUploadDialog> = ({
  isProcessing,
  uploadPercentage,
  handleCancelUpload,
}) => (
  <Dialog open={isProcessing} fullWidth maxWidth="xs" className="dialog dialog--package">
    <DialogContent className="dialog-uploading__content">
      <h3 className="dialog-uploading__title">
        Uploading package file (
        <div className="add-package__percent">{Math.round(uploadPercentage)}%</div>)
      </h3>
      <div className="add-package__progress-container">
        <LinearProgress
          variant="determinate"
          value={uploadPercentage}
          className="add-package__progress"
        />
      </div>
      <UnigineButton
        type="button"
        className="dialog-uploading__button"
        onClick={handleCancelUpload}
      >
        Cancel
      </UnigineButton>
    </DialogContent>
  </Dialog>
);

export default AddonPackageUploadingDialog;
