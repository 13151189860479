import { tooltips } from 'app/utils/constants/contentConstants';

const addonNavigationMenuData = [
  {
    title: 'Base Info',
    path: 'base',
  },
  {
    title: 'Media',
    path: 'media',
  },
  {
    title: 'Package',
    path: 'package',
  },
  {
    title: 'Changelog',
    path: 'changelog',
    tooltip: tooltips.addonVersionForm.changelog,
  },
];

export default addonNavigationMenuData;
