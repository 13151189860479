import StorefrontHeader from 'app/components/StorefrontHeader/StorefrontHeader';
import { FC } from 'react';

const TOS: FC = () => {
  return (
    <>
      <StorefrontHeader />
      <div className="text-wrapper">
        <h2>UNIGINE ADD-ON STORE TERMS OF SERVICE</h2>
        <br />
        <br />
        <h3>TERMS AND DEFINITIONS</h3>
        <br />
        <br />
        <p>Store – Unigine’s website for browsing and purchasing Add-Ons</p>
        <br />
        <p>
          Unigine – UNIGINE HOLDING S.à r.l., 9bis rue Basse, 4963 Clemency, Luxembourg,
          registration №. B211502
        </p>
        <br />
        <p>
          User, You - individual or legal entity acting through its duly authorized representative
        </p>
        <br />
        <p>
          Personal Account – personal section of the Store, to which you gain access after signing
          up and/or signing in to the Store. In order to use the Store, you should sign up and
          receive user credentials (login, password) to sign in to a Personal Account. You may also
          use user credentials for unigine.com personal account, in case you have already registered
          a personal account at unigine.com
        </p>
        <br />
        <p>
          Intellectual Property Rights - any and all intellectual property rights wherever in the
          world and whenever arising (and including any application), including patent rights,
          copyright, trade secrets, know-how, confidential information, business names and domain
          names, computer programs, trademark laws, service marks, trade names, utility models,
          design rights, semi-conductor topography rights, database rights, goodwill or rights to
          sue for passing off, and any and all other proprietary rights worldwide
        </p>
        <br />
        <p>
          Unigine SDK License Agreement – legal contract between you and Unigine or its affiliates
          which governs your use of UNIGINE SDK
        </p>
        <br />
        <p>
          EULA – Unigine Add-On Store End-User License Agreement. Current version of EULA is
          available via link{' '}
          <a href="https://assets.unigine.com/eula"> https://assets.unigine.com/eula</a>
        </p>
        <br />
        <p>
          Terms – these Unigine Add-On Store Terms of Service. Current version of Terms is available
          via link <a href="https://assets.unigine.com/tos">https://assets.unigine.com/tos</a>
        </p>
        <br />
        <br />
        <ol>
          <li>
            <h3>THE SUBJECT MATTER OF THE TERMS</h3>
            <ol>
              <br />
              <li>These Terms govern your use of the Store.</li>
              <br />
              <li>You may use the Store to browse and purchase Add-Ons.</li>
              <br />
              <li>
                In order to download and install Add-Ons, you must have SDK Browser installed on
                your workstation. Your use of SDK Browser is subject to separate SDK Browser EULA,
                which is available via link{' '}
                <a href="https://unigine.com/company/legal/unigine-sdk-browser-eula">
                  https://unigine.com/company/legal/unigine-sdk-browser-eula
                </a>
                .
              </li>
              <br />
              <li>You may use downloaded Add-Ons within the scope of license described in EULA.</li>
            </ol>
          </li>
          <br />
          <br />
          <li>
            <h3>INTELLECTUAL PROPERTY</h3>
            <ol>
              <br />
              <li>
                You agree that Unigine owns all right, title and interest in and to the Store and
                the Add-Ons, including without limitation all applicable Intellectual Property
                Rights in the Store and Add-Ons. You agree that you will not, and will not allow any
                third party to:
                <ol>
                  <br />
                  <br />
                  <li>
                    Decompile, reverse engineer, disassemble or otherwise attempt to derive source
                    code from the Store or the Add-Ons;
                  </li>
                  <br />
                  <li>
                    Use the Store or Add-Ons to access, copy, transfer, transcode or retransmit
                    content in violation of any law or third-party rights;
                  </li>
                  <br />
                  <li>
                    Remove, obscure, or alter Unigine's or any third party's copyright notices,
                    trademarks, or other proprietary rights notices affixed to or contained within
                    the Store or Add-Ons;
                  </li>
                  <br />
                  <li>
                    Access (or attempt to access) the Store by any means other than through the
                    interface that is provided by Unigine, specifically through any automated means
                    (including use of scripts, crawlers or similar technologies);
                  </li>
                  <br />
                  <li>Transfer your Personal Account credentials to a third party.</li>
                </ol>
              </li>
            </ol>
          </li>
          <br />
          <br />
          <li>
            <h3>INDEMNIFICATION</h3>
            <ol>
              <br />
              <li>
                You will, at your own expense, indemnify and hold Unigine, and its subsidiaries and
                affiliates, and all officers, directors, and employees thereof, harmless from and
                against any and all claims, actions, liabilities, losses, damages, judgments,
                grants, costs, and expenses, including reasonable legal fees (collectively,
                “Claims”), arising out of any use of the Store or Add-Ons by you, any party related
                to you, or any party acting upon your authorization in a manner that is not
                expressly authorized in these Terms.
              </li>
            </ol>
            <br />
            <br />
            <li>
              <h3>DISCLAIMER OF WARRANTIES</h3>
              <ol>
                <br />
                <li>
                  THE STORE IS LICENSED "AS IS", AND UNIGINE DISCLAIMS ANY AND ALL OTHER WARRANTIES,
                  WHETHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTIES
                  OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE, TO THE EXTENT AUTHORIZED
                  BY LAW. WITHOUT LIMITATION OF THE FOREGOING, UNIGINE EXPRESSLY DOES NOT WARRANT
                  THAT THE STORE WILL MEET YOUR REQUIREMENTS OR THAT OPERATION OF THE STORE WILL BE
                  UNINTERRUPTED OR ERROR-FREE.
                </li>
                <br />
                <li>
                  YOUR USE OF THE STORE IS AT YOUR OWN DISCRETION AND RISK AND YOU ARE SOLELY
                  RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM, OR OTHER DEVICE, OR LOSS OF
                  DATA THAT RESULTS FROM SUCH USE.
                </li>
              </ol>
            </li>
            <br />
            <br />
            <li>
              <h3>LIMITATION OF LIABILITY</h3>
              <ol>
                <br />
                <li>
                  Under no circumstances and no legal theory, whether in tort, negligence, contract
                  or otherwise, shall Unigine or its affiliates be liable to you or any affiliated
                  person for any indirect, special, incidental, punitive, exemplary, consequential,
                  or extra-contractual damages of any kind, loss of goodwill, loss of personnel
                  salaries, lost profits or revenue, damages due to work stoppage and/or computer
                  failure or malfunction, and/or costs of procuring substitute software or services,
                  whether or not foreseeable, even if Unigine has been advised of the possibility or
                  probability of such damages.
                </li>
                <br />
                <li>
                  By accepting these Terms, you shall be fully liable for validity and correctness
                  of the data about you by you, including the cases when a third-party gains access
                  to the Store through your fault. Unigine shall not be liable whatsoever in case if
                  a third-party gains access to the Store. You must take all necessary measures to
                  prevent the disclosure of your login and password as well as the use of the Store
                  by any third party.
                </li>
              </ol>
            </li>
            <br />
            <br />
            <li>
              <h3>TERMINATION</h3>
              <ol>
                <br />
                <li>
                  If you want to terminate these Terms, you may do so by ceasing your use of the
                  Store.
                </li>
                <br />
                <li>
                  Without prejudice to any other rights, Unigine may terminate this Terms if you
                  fail to comply with these Terms, the EULA or Unigine SDK License Agreement.
                </li>
              </ol>
            </li>
            <br />
            <br />
            <li>
              <h3>MISCELLANEOUS</h3>
              <ol>
                <br />
                <li>By using the Store, you agree to be bound by these Terms.</li>
                <br />
                <li>
                  You operate in Store via your Personal Account. In order to use the Store, you
                  must be logged in your Personal Account.
                </li>
                <br />
                <li>
                  Unigine may stop (permanently or temporarily) providing the Store (or any features
                  within the Store) at its sole discretion, without prior notice to you.
                </li>
                <br />
                <li>
                  You agree to use the Store only for purposes that are permitted by (a) these Terms
                  and (b) any applicable law, regulation or generally accepted practices or
                  guidelines in the relevant jurisdictions. You agree to comply with all local laws
                  and regulations regarding the download, installation and/or use of the Add-Ons.
                </li>
                <br />
                <li>
                  You agree to comply with any and all applicable tax laws, including the reporting
                  and payment of any taxes arising in connection with your use of the Store or the
                  purchase of Add-Ons through the Store, and that the reporting and payment of any
                  such applicable taxes are your responsibility.
                </li>
                <br />
                <li>
                  You also agree that Unigine Privacy Policy Terms (
                  <a href="https://unigine.com/company/legal/policy-privacy">
                    https://unigine.com/company/legal/policy-privacy
                  </a>
                  ) applies to your use of the Store.
                </li>
              </ol>
            </li>
            <br />
            <br />
            <li>
              <h3>GENERAL TERMS</h3>
              <ol>
                <br />
                <li>
                  Governing Law. These Terms shall be construed and interpreted under English law.
                  To the extent permitted by law, the provisions of these Terms shall supersede any
                  provisions of the appropriate local and international laws. Any dispute,
                  controversy or claim arising out of or relating to these Terms, including without
                  limitation, the breach, termination, or invalidity thereof, shall, on the written
                  demand of either Party delivered to the other Party, be determined and settled by
                  arbitration under the UNCITRAL Arbitration Rules in effect on the date of this
                  Agreement.
                </li>
                <br />
                <li>
                  Severability. If any term or provision of these Terms is declared void or
                  unenforceable in a particular situation, by any judicial or administrative
                  authority, this declaration shall not affect the validity of enforceability of the
                  remaining terms and provisions hereof or the validity or enforceability of the
                  offending term or provision in any other situation.
                </li>
                <br />
                <li>
                  Names and headings. The name of these Terms as well as headings of sections and
                  paragraphs contained herein are incorporated for reference purposes only and shall
                  not affect the meaning or interpretation of these Terms.
                </li>
                <br />
                <li>
                  Non-waiver. The failure of either party to enforce any rights granted hereunder or
                  to take action against the other party in the event of any breach hereunder shall
                  not be deemed a waiver by that party as to subsequent enforcement of rights or
                  subsequent actions in the event of future breaches.
                </li>
                <br />
                <li>
                  Amendments to the Terms. Unigine reserves the right to make amendments to these
                  Terms as it deems appropriate at any time. In case of any discrepancy between
                  these Terms and its current version, the current version shall always take
                  precedence. The current version is always available via link provided in “Terms
                  and definitions” section of these Terms. By continuing to use Store after
                  publication of the amended Terms, you accept all terms and conditions of the
                  current version of the Terms.
                </li>
                <br />
                <li>
                  Restrictions. Unigine and you shall comply with all national and international
                  laws and regulations applicable to the Store as well as restrictions concerning
                  End-Users, procedures and regions of end-use established in EU and other countries
                  and regions including the ones regarding requirements for personal data
                  processing.
                </li>
                <br />
                <li>
                  Entire agreement. These Terms constitute the complete and exclusive agreement
                  between you and Unigine concerning subject matter hereof and supersedes all prior
                  or contemporaneous oral or written communications, proposals, representations,
                  understandings, or agreements not specifically incorporated herein.
                </li>
              </ol>
            </li>
          </li>
        </ol>
      </div>
    </>
  );
};

export default TOS;
