interface ITooltips {
  addonForm: IObjectWithStrings;
  addonVersionForm: IObjectWithStrings;
  addonStatuses: IObjectWithStrings;
  addonVersionStatuses: IObjectWithStrings;
  orders: IObjectWithStrings;
  publisherSettingsForm: IObjectWithStrings;
  withdrawAddon: string;
  archiveAddon: string;
  newAddonVersion: string;
  getFromArchive: string;
  publishAddon: string;
  restartUploading: string;
  resumeUploading: string;
}
interface IObjectWithStrings {
  [key: string]: string;
}

export const tooltips: ITooltips = {
  addonForm: {
    title: `Name of your add-on pack — a word or a sequence of words 
that is unique for the Add-On Store. It is also used for search. 
The title can be edited after the add-on is published.`,

    price: `The price of your add-on in US dollars, 
the minimum is 5 US dollars. This price will be displayed 
to the customer, thus it includes all applicable fees that are charged 
by the platform. However, it doesn’t include taxes that are specific 
to the customer’s country.`,

    discounts: `A discount can be set at any moment — not necessarily 
at publishing the add-on, you can do it later by 
editing the already published add-on. The discount 
can be set starting from a certain date, for a specified period 
and/or for a certain number of items you want to 
sell at a discount price. If you set a time and item limit, 
the discount will remain in effect until whichever 
condition occurs first — the discount period is over 
or the discounted items are sold out.`,

    supportedVersions: `An SDK version on which the add-on 
runs smoothly, has no material/API or any other issues.`,

    categories: `Choose at least one category that 
is appropriate for your add-on. This will help your customers 
find the add-on.`,

    products: `If an add-on uses features from higher SDK licenses, 
which are not available in the lower ones, the add-on 
can’t be used appropriately across all licenses. However, 
features from lower licenses are all available in higher ones, 
so if you’ve created an add-on for the Community Edition 
it will definitely work well with all other editions.`,

    os: `The operating system (Windows, Linux, or both) 
with which your add-on performs smoothly.`,

    tags: `Keywords that simplify the search process 
by filtering a group of add-ons. Type a word and press Enter to 
add it as a tag. If the tag has already been added it will drop down 
for selection.

Tags cannot be changed if the add-on is published, 
you can add or edit them during creation or versioning.`,

    description: `The text that will be displayed as the descriptive 
information for your add-on to help customers understand what is inside 
the package, for what purposes it is intended, and any useful technical 
details (such as a number of textures or meshes, texture resolution, 
polygon count, animation, rigging, audio file type, etc). 
This text is also used for search.

    `,

    sysreq: `Any specific requirements to the hardware and/or software 
that are different from the requirements to SDK such as 
specific model of hardware, extra input/output device, etc.`,
  },

  addonVersionForm: {
    versionNumber: `The version number of your add-on. 
We recommend following the widely adopted semantic versioning 
approach (Major.Minor.Patch)`,
    changelog: `Add here the info on any changes, bug fixes, 
and new features in every new version. Note that the
text here will replace any previously added text,
so don’t delete the info from previous releases 
if you want to keep it.`,
  },

  addonStatuses: {
    draft: `The add-on has not been submitted to the Add-On Store yet. 
It is visible to you only.`,

    moderation: `The add-on has been sent to the UNIGINE moderator 
for review. This process may take up to 5 business days. Please wait.`,

    published: `The add-on has passed the UNIGINE moderator’s review 
and is placed on the Add-On Store.`,

    banned: `The add-on does not comply with the guidelines 
and has been declined after the UNIGINE moderator’s review.`,

    archived: `The add-on is withdrawn from the Add-On Store display 
and can no longer be found and acquired by customers. The customers 
who acquired the add-on before it was archived can still find it 
in the list of purchased add-ons.`,
  },

  addonVersionStatuses: {
    draft: `The add-on version has not been submitted to the Add-On Store yet.
It is visible to you only.`,
    moderation: `The add-on has been sent to the UNIGINE moderator for review.
This process may take up to 5 business days. Please wait.`,
    published: `The add-on version has passed the UNIGINE moderator’s review
and is placed on the Add-On Store.`,
    banned: `The add-on does not comply with the guidelines
and has been declined after the UNIGINE moderator’s review.`,
  },

  publisherSettingsForm: {
    name: "The Publisher's name to be displayed on the Publisher's info page and with the published add-ons.",
    email:
      'A valid email that will be used for forwarding messages from users. This field is mandatory.',
    site_link: 'A link to your website or any other platform showcasing your works.',
  },

  withdrawAddon: `Cancel the moderation of a submitted add-on. 
It will remain in the list of uploaded add-ons as a draft`,

  archiveAddon: `Withdraw the add-on from the Add-On Store display
and make unavailable for purchase.`,

  newAddonVersion: `Creating a new version is available if the add-on does not have unpublished versions (draft, moderation)`,

  getFromArchive: `Resumes the status which the add-on had before being archived.`,

  publishAddon: `To publish the add-on version, you should upload:
  — Cover and images in the Media tab
  — Package file
`,

  orders: {
    inOrder: 'Add-On package is added to existing unpaid order.',
    unavailable: 'Under the current license you have no access to this add-on.',
    orderNotRefundable:
      'Your order is non-refundable, as it seems that all non-free add-ons in your order have been downloaded. Additionally, please note that free add-ons are also non-refundable.',
  },

  restartUploading: 'Upload once again from scratch',

  resumeUploading: 'Сontinue uploading',
};

export const tips: IObjectWithStrings = {
  freeAddons: `
    The add-on selling option is currently unavailable.
    <br />
    At the moment, you can only create free add-ons.
  `,

  logInToReview: 'To leave feedback, you need to get the add-on first.',

  moderation: `
    Wait until your add-on version is verified.
  `,

  publishButton: `
    To submit this add-on version for moderation,
    click the Publish button.
  `,

  withdrawButton: `
    Cancel the moderation of a submitted add-on
    version. This version will be kept as a draft.
  `,

  media: `
    <p>
      The cover image is displayed on the Add-On Store display. Your add-on won't be published
      without the cover image.
    </p>
    <p>
      Recommended aspect ratio of the Cover image is 4:3, recommended resolution is 240 x 180.
    </p>
    <p>
      Other images and videos are displayed on the Add-On page. At least one image or video
      should be added. Recommended aspect ratio of the other images is 16:9.
    </p>
  `,

  package: `
    Only files with the UPACKAGE extension can be uploaded. Guidelines on how to prepare
    an add-on pack for publishing can be found
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="${process.env.REACT_APP_STORE_HOST_URL}/documentation/preparing_asset_packages"
    >
      here
    </a>.
  `,
  packageBaseInfo: `These attributes are not editable. They depends on packages attributes.`,

  mobileOrders: `Order details is not accessible on mobile devices. Please use a desktop or a laptop PC to view it.`,
};

export const formFieldErrors: IObjectWithStrings = {
  required: 'Fill in the field',
  titleMaxLength: 'Title must be at most 200 characters long',
  titleCharacters:
    'Title can only contain Latin characters, numbers, spaces, and the characters @ # + - % & * \\ / , . _ ( ) [ ] { } < >',
  priceMaxValue: 'The price must be less than 1 000 000',
  priceMinValue: 'The price must be at least 5',
  discountItems: 'The Add-Ons for sale field can empty or >0',
  minFromDate: 'The date "from" must not be less than the one already set',
  maxFromDate: 'The date "from" must not be more than the date "till"',
  minTillDate: 'The date "till" must not be less than the date "from" or today',
  minRangePrice: 'The lowest possible add-on price is 5 USD.',
  maxRangePrice: 'The maximum possible add-on price is 999 999 USD.',
  minRangeMoreMaxRange: 'The minimum price must be less than the maximum.',

  richTextCharacters:
    'The field can only contain Latin characters, numbers, spaces, and the characters @ # + - % & * \\ / , . ; : ! " \' _ ( ) [ ] { } < >',
  richTextLength: 'The value must be at most 1000 characters long',

  tagsCharacters: 'Tags can only contain Latin characters, numbers, and spaces.',
  reviewDescriptionLength: 'The short description must be at most 255 characters long',
  reviewDetailsLength: 'The details field must be at most 1000 characters long',
  reviewReplyLength: 'The reply field must be at most 1000 characters long',
  messageMaxLength: 'Message must be at most 200 characters long.',
  emailPattern: 'Email should follow the format <...>@<>.<>.',
};

export const contactPublisherForm: IObjectWithStrings = {
  emailSuccess: 'Email successfully sent!',
  emailError: 'Something went wrong. Please, try again later.',
};

export const packageErrors: IObjectWithStrings = {
  required: 'Please select .upackage file.',
  format: 'Wrong file format, only .upackage format is accepted.',
  size: 'Maximum file size is 20 Gb.',
  nameLength: 'The maximum length of the file name is 200 symbols.',
  aborted: 'Request aborted by user.',
};

export const terms: IObjectWithStrings = {
  addonStoreAgreement: 'I have read and accepted the terms of the Add-On Store provider agreement',
  xsollaAgreement:
    "I am notified that I should go through the Xsolla payment provider's verification and tax interview in order to receive a payout",
};

export const withdrawalStatus: IObjectWithStrings = {
  titleSuccess: 'YOUR Payout Request HAS BEEN processed Successfully!',
  descriptionSuccess: `Money transfer operations will take some time.
  For all other questions regarding the payouts, please contact <a href="#">XSolla Support</a>.`,
  titleFailure: 'Something Went wrong with your Payout Request.',
  descriptionFailure: `Please try again or contact <a href="#">XSolla Support</a> for clarification.`,
  descriptionExpired: 'Please try again',
};

export const packageFileStatusMessages = {
  success: 'File is processed',
  processing: 'Package is Processing',
  fileError: 'Something Went Wrong With Your Package',
  storageError: 'Something Went Wrong. We are Working It Out',
};

export const createAddonNotificationInfo =
  "Before creating your add-on make sure you've read and followed the <a href='https://store.unigine.com/documentation/preparing_addon_packages/' target='_blank'>package creating</a> and <a href='https://store.unigine.com/documentation/publishing_addon_packages/' target='_blank'>publishing</a> guidelines.";

export const confirmNavigateTitle =
  'Are you sure you want to leave the page? All your entered data will be lost.';
