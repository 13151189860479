import './AddonsManagerAutocomplete.scss';

import { FC, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';

import PlusIcon from 'app/assets/icon_plus.svg';
import UnigineButton from 'app/components/UnigineButton/UnigineButton';

import useAppSelector from 'app/hooks/useAppSelector';
import useAppDispatch from 'app/hooks/useAppDispatch';

import {
  addManager,
  addonsManagersOptionsSelector,
} from 'app/main/sections/PublisherPanel/sections/AddonsManagersSection/store/addonsManagersSlice';
import IManager from 'app/interfaces/IManager';

const AddonsManagerAutocomplete: FC = () => {
  const dispatch = useAppDispatch();
  const [selectedManager, setSelectedManager] = useState<IManager | null>(null);
  const options = useAppSelector(addonsManagersOptionsSelector);

  const handleChangeAutocomplete = (e: any, value: IManager | null): void => {
    setSelectedManager(value);
  };

  const handleClickAddManager = (): void => {
    if (selectedManager) {
      dispatch(addManager({ manager: selectedManager })).then(() => {
        setSelectedManager(null);
      });
    }
  };

  return (
    <div className="add-ons-managers__autocomplete-container">
      <Autocomplete
        className="add-ons-managers__autocomplete"
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => `${option.name} (${option.email})`}
        options={options}
        onChange={handleChangeAutocomplete}
        value={selectedManager}
        renderInput={(params) => <TextField {...params} label="Find user by name or email" />}
      />

      <UnigineButton
        type="button"
        className="add-ons-managers__button"
        onClick={handleClickAddManager}
        disabled={!selectedManager}
      >
        <img src={PlusIcon} alt="Add Manager" /> Add Manager
      </UnigineButton>
    </div>
  );
};

export default AddonsManagerAutocomplete;
