import { JSX, useRef } from 'react';
import { Control, Controller, useWatch } from 'react-hook-form';
import { IconButton, TextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment';

import PercentageInput from 'app/components/PercentageInput/PercentageInput';
import IntegerInput from 'app/components/IntegerInput/IntegerInput';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import CloseIcon from '@mui/icons-material/Close';
import { formFieldErrors } from 'app/utils/constants/contentConstants';
import clsx from 'clsx';

interface IDiscountFieldsProps {
  control: Control<any>;
  arrayName: string;
  index: number;
  removeRow: () => void;
}

const DiscountFields = ({
  control,
  arrayName,
  index,
  removeRow,
}: IDiscountFieldsProps): JSX.Element => {
  const from = useWatch({ control, name: `${arrayName}.${index}.from` });
  const till = useWatch({ control, name: `${arrayName}.${index}.till` });

  const fromRef = useRef(from);

  // if past start date already specified, it can stay, otherwise, can only set present or future dates
  const minFrom = moment.min(moment(fromRef.current), moment(new Date())).format('YYYY-MM-DD');
  const maxFrom = till;

  const minTill = moment.max(moment(new Date()), moment(from)).format('YYYY-MM-DD');

  const validateMinFrom = (v: string): boolean => {
    return moment(v).diff(moment(minFrom)) >= 0;
  };
  const validateMaxFrom = (v: string): boolean => {
    if (!maxFrom) {
      return true;
    }
    return moment(v).diff(moment(maxFrom)) <= 0;
  };
  const validateMinTill = (v: string): boolean => {
    if (!v) {
      return true;
    }
    return moment(v).diff(moment(minTill)) >= 0;
  };

  return (
    <div className="add-on-form__discount-row">
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Controller
          name={`${arrayName}.${index}.percentage`}
          control={control}
          rules={{ required: formFieldErrors.required }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              value={value}
              onChange={(e) => onChange(parseFloat(e.target.value))}
              size="small"
              className="unigine-form-field"
              variant="outlined"
              label="Discount %"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                inputComponent: PercentageInput,
              }}
              required
              error={!!error}
              helperText={error?.message}
            />
          )}
        />
        <Controller
          name={`${arrayName}.${index}.from`}
          control={control}
          rules={{
            required: formFieldErrors.required,
            validate: {
              minFrom: (v) => validateMinFrom(v) || formFieldErrors.minFromDate,
              maxFrom: (v) => validateMaxFrom(v) || formFieldErrors.maxFromDate,
            },
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <DesktopDatePicker
              inputFormat="DD.MM.yy"
              className="unigine-form-field"
              label="From"
              value={value}
              onChange={(e) => onChange(moment(e).format('YYYY-MM-DD'))}
              minDate={minFrom}
              maxDate={maxFrom}
              renderInput={(params) => (
                <TextField
                  size="small"
                  variant="outlined"
                  error={!!error}
                  required
                  helperText={error?.message}
                  {...params}
                />
              )}
            />
          )}
        />
        <Controller
          name={`${arrayName}.${index}.till`}
          control={control}
          rules={{
            validate: {
              minTill: (v) => validateMinTill(v) || formFieldErrors.minTillDate,
            },
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <DesktopDatePicker
              inputFormat="DD.MM.yy"
              className="unigine-form-field"
              label="Till"
              value={value === 'Invalid date' ? null : value}
              onChange={(e) => onChange(e && moment(e).format('YYYY-MM-DD'))}
              disablePast
              minDate={minTill}
              renderInput={(params) => (
                <>
                  <TextField
                    size="small"
                    variant="outlined"
                    error={!!error}
                    helperText={error?.message}
                    {...params}
                  />
                  <IconButton
                    onClick={() => onChange(null)}
                    className={clsx('add-on-form__discount-date-clear', {
                      'add-on-form__discount-date-clear--hide': !value || value === 'Invalid date',
                    })}
                  >
                    <CloseIcon />
                  </IconButton>
                </>
              )}
            />
          )}
        />
        <Controller
          name={`${arrayName}.${index}.quantity`}
          control={control}
          rules={{ validate: (v) => v !== 0 || formFieldErrors.discountItems }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              value={value}
              onChange={(e) => onChange(parseFloat(e.target.value))}
              size="small"
              className="unigine-form-field"
              variant="outlined"
              label="Add-Ons for sale"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                inputProps: {
                  max: 9999999,
                },
                inputComponent: IntegerInput,
              }}
              error={!!error}
              helperText={error?.message}
            />
          )}
        />
        <IconButton onClick={removeRow} size="small" className="add-on-form__discount-remove-btn">
          <DeleteIcon />
        </IconButton>
      </LocalizationProvider>
    </div>
  );
};

export default DiscountFields;
