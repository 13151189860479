import './AddonRating.scss';

import { FC, JSX } from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as GreenStarEmpty } from 'app/assets/icon_star_green_empty.svg';
import { ReactComponent as GreenStarHalfQuarter } from 'app/assets/icon_star_green_half_quarter.svg';
import { ReactComponent as GreenStarQuarter } from 'app/assets/icon_star_green_quarter.svg';
import { ReactComponent as GreenStarHalf } from 'app/assets/icon_star_green_half.svg';
import { ReactComponent as GreenStarThreeQuarter } from 'app/assets/icon_star_green_three_quarters.svg';
import { ReactComponent as GreenStarFilled } from 'app/assets/icon_star_green_filled.svg';

import Tip from 'app/components/Tip/Tip';

interface IAddonRatingProps {
  addonId: string;
  versionId: string;
  totalRating: string;
  reviewsCount?: number;
  isLoggedIn?: boolean;
}

const AddonRating: FC<IAddonRatingProps> = ({
  totalRating: rawRating,
  reviewsCount,
  addonId,
  versionId,
  isLoggedIn = false,
}) => {
  const totalRating = parseFloat(rawRating);
  const starFinalView = (): JSX.Element => {
    if (totalRating === 5) {
      return <GreenStarFilled />;
    }
    if (totalRating >= 4 && totalRating < 5) {
      return <GreenStarThreeQuarter />;
    }
    if (totalRating >= 3 && totalRating < 4) {
      return <GreenStarHalf />;
    }
    if (totalRating >= 2 && totalRating < 3) {
      return <GreenStarQuarter />;
    }
    if (totalRating >= 1 && totalRating < 2) {
      return <GreenStarHalfQuarter />;
    }
    return <GreenStarEmpty />;
  };

  let linkText = `Read ${reviewsCount} Reviews`;
  if (!reviewsCount) {
    linkText = isLoggedIn ? 'Leave a review' : 'No reviews';
  }

  const linkContents = (
    <>
      <div className="add-on-star__rating-container">
        <div className="add-on-star__wrapper">{starFinalView()}</div>
        <span className="add-on-star__rating-number">
          {totalRating ? totalRating.toFixed(1) : '?'} / 5
        </span>
      </div>
      {linkText}
    </>
  );

  return (
    <div className="add-on-widgets">
      {isLoggedIn && (
        <Link
          className="add-on-widgets__widget add-on-widgets__widget--reviews-link"
          to={`/add-on/${addonId}/reviews?sort=rating&direction=DESC&version=${versionId}`}
          aria-label="Reviews"
        >
          {linkContents}
        </Link>
      )}

      {!isLoggedIn &&
        (reviewsCount ? (
          <Link
            className="add-on-widgets__widget add-on-widgets__widget--reviews-link"
            to={`/add-on/${addonId}/reviews?sort=rating&direction=DESC&version=${versionId}`}
            aria-label="Reviews"
          >
            {linkContents}
          </Link>
        ) : (
          <div className="add-on-widgets__widget add-on-widgets__widget--reviews-link add-on-widgets__widget--reviews-link-inactive">
            {linkContents}
          </div>
        ))}

      {!reviewsCount && isLoggedIn && (
        <Tip className="add-on-widgets__tip">
          There are no reviews yet. Be the first to write a review.
        </Tip>
      )}
    </div>
  );
};

export default AddonRating;
