import './AddonNavigationMenu.scss';

import { JSX, MouseEvent } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import clsx from 'clsx';

import UnigineTooltip from 'app/components/Tooltip/Tooltip';
import { useConfirmNavigateDialog } from 'app/components/ConfirmNavigateDialog/ConfirmNavigateDialog';

import { confirmNavigateTitle } from 'app/utils/constants/contentConstants';

interface IAddonNavMenuProps {
  addonNavigationMenuData: {
    title: string;
    path: string;
    tooltip?: string;
  }[];
}

const AddonNavigationMenu = ({ addonNavigationMenuData }: IAddonNavMenuProps): JSX.Element => {
  const { search, pathname } = useLocation();
  const navigate = useNavigate();

  const params = new URLSearchParams(search);

  const { ConfirmNavigateDialog, confirmAction, CANCEL_ACTION, CONFIRM_ACTION } =
    useConfirmNavigateDialog(confirmNavigateTitle);

  const handleNavClick = async (
    e: MouseEvent<HTMLAnchorElement>,
    linkTo: string
  ): Promise<void> => {
    if (!pathname.includes('/edit')) {
      return;
    }

    e.preventDefault();

    const choice = await confirmAction();

    if (choice === CANCEL_ACTION) {
      return;
    }

    if (choice === CONFIRM_ACTION) {
      navigate(linkTo);
    }
  };

  return (
    <nav className="add-on-navigation">
      <ul className="add-on-navigation__list">
        {addonNavigationMenuData.map((item) => {
          const key = item.path;
          const linkTo = `${item.path}?${params.toString()}`;

          let linkComponent = (
            <li key={key} className="add-on-navigation__item">
              <NavLink
                onClick={(e) => handleNavClick(e, linkTo)}
                to={linkTo}
                className={({ isActive }) =>
                  clsx('add-on-navigation__link', {
                    'add-on-navigation__link--active': isActive,
                  })
                }
                aria-label={item.title}
              >
                {item.title}
              </NavLink>
            </li>
          );

          if (item.tooltip) {
            linkComponent = (
              <UnigineTooltip key={key} title={item.tooltip}>
                {linkComponent}
              </UnigineTooltip>
            );
          }

          return linkComponent;
        })}
      </ul>

      <ConfirmNavigateDialog />
    </nav>
  );
};

export default AddonNavigationMenu;
