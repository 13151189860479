import './AddonPackageItem.scss';

import { FC, MouseEvent, useEffect, useRef, useState } from 'react';
import { filesize } from 'filesize';
import clsx from 'clsx';

import { FormControlLabel, FormHelperText, IconButton } from '@mui/material';

import { ReactComponent as EditSquareIcon } from 'app/assets/icon_edit_square.svg';
import { ReactComponent as PackageBoxIcon } from 'app/assets/icon_package_box.svg';
import { ReactComponent as DeleteIcon } from 'app/assets/icon_delete-picture.svg';
import { ReactComponent as ReplaceIcon } from 'app/assets/icon_replace_package.svg';
import { ReactComponent as DownloadIcon } from 'app/assets/icon_download_package.svg';

import CheckboxUnigine from 'app/components/CheckboxUnigine/CheckboxUnigine';
import PackageFileStatus from 'app/components/PackageFileStatus/PackageFileStatus';
import UnigineButton from 'app/components/UnigineButton/UnigineButton';
import PlatformPoster from 'app/components/PlatformPoster/PlatformPoster';
import DownloadPackage from 'app/components/DownloadPackage/DownloadPackage';

import IAddonStatus from 'app/interfaces/addons/IAddonStatus';
import IPackage from 'app/interfaces/addons/IPackage';
import { PackageSource } from 'app/configs/appConfig';

import { IHidePackageError } from 'app/main/sections/PublisherPanel/sections/AddonSection/store/baseInfoSlice';

interface IAddonPackageItem {
  packageInfo: IPackage;
  onDelete?: (packageId: string) => void;
  onEditInfo?: (packageId: string) => void;
  onHide?: ({ packageId, isHidden }: { packageId: string; isHidden: boolean }) => Promise<void>;
  onReplace?: ({
    target: { files },
    packageId,
    packageName,
  }: {
    target: { files: FileList | null };
    packageId: string;
    packageName: string;
  }) => Promise<void>;
  addonVersionStatus: IAddonStatus;
  isProcessing?: boolean;
  packageError?: string;
  source: PackageSource;
  hidePackageError?: IHidePackageError | null;
}

const AddonPackageItem: FC<IAddonPackageItem> = ({
  packageInfo,
  onDelete,
  addonVersionStatus,
  onHide,
  onEditInfo,
  onReplace,
  isProcessing,
  source,
  packageError,
  hidePackageError,
}) => {
  const displayActions = addonVersionStatus.value === 'Draft';
  const displayHideAndDelete = packageInfo.packageFileStatus?.slug !== 'processing';
  const isPackageActionDisabled = packageInfo.packageFileStatus?.slug === 'processing';
  const isDownloadDisabled =
    packageInfo.packageFileStatus?.slug === 'processing' ||
    packageInfo.packageFileStatus?.slug === 'no_package_file';

  const isPackageFile =
    packageInfo.packageFileStatus?.value !== 'no package file' && packageInfo.packageSize !== 0;

  const uploadButtonLabel = isPackageFile ? 'Replace Package' : 'Upload Package';

  const [isHiddenInProccess, setIsHiddenInProcess] = useState(false);

  const expandableRef = useRef<HTMLDivElement>(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [showExpandBtn, setShowExpandBtn] = useState(false);
  useEffect(() => {
    if (expandableRef.current) {
      const { scrollHeight, clientHeight } = expandableRef.current;
      setShowExpandBtn(scrollHeight > clientHeight);
    }
  }, [packageInfo]);
  const handleHide = (): void => {
    setIsHiddenInProcess(true);
    if (onHide) {
      onHide({ packageId: packageInfo.id, isHidden: packageInfo.isHidden }).then(() => {
        setIsHiddenInProcess(false);
      });
    }
  };

  const onPackageClick = (e: MouseEvent<HTMLInputElement>): void => {
    e.currentTarget.value = '';
  };

  return (
    <li className="add-on-packages__item">
      <div className="add-on-packages__status-container">
        {packageInfo.packageFileStatus && (
          <PackageFileStatus packageStatusInfo={packageInfo.packageFileStatus} />
        )}
      </div>
      <div className="add-on-packages__actions-container">
        {displayHideAndDelete && onHide && (
          <FormControlLabel
            label="Hide package"
            className="add-on-packages__action-hide"
            control={
              <CheckboxUnigine
                disabled={isHiddenInProccess}
                name="hide-add-on"
                onChange={handleHide}
                checked={packageInfo.isHidden}
                className="checkbox-group__checkbox"
              />
            }
          />
        )}

        {hidePackageError && hidePackageError.packageId === packageInfo.id && (
          <FormHelperText error>{hidePackageError.message}</FormHelperText>
        )}

        {displayActions && displayHideAndDelete && onDelete && (
          <IconButton
            aria-label="close"
            title="Delete media file"
            className="add-on-packages__action-delete"
            onClick={() => onDelete(packageInfo.id)}
          >
            <DeleteIcon />
            Delete Package
          </IconButton>
        )}
      </div>

      <div className="add-on-packages__main">
        <div className="add-on-packages__file-name">
          <PackageBoxIcon />
          {packageInfo.packageFileName || 'No package'}
        </div>

        {isPackageFile && (
          <div className="add-on-packages__size">
            File Size {filesize(packageInfo.packageSize).toString()}
          </div>
        )}

        <div className="add-on-packages__buttons-wrapper">
          <div className="add-on-packages__buttons">
            {displayActions && onReplace && (
              <div>
                <label
                  htmlFor={`replacePackage-${packageInfo.id}`}
                  className={clsx('add-on-packages__label--replace-package', {
                    'add-package__label--disabled': isProcessing || isPackageActionDisabled,
                  })}
                >
                  <ReplaceIcon />
                  {uploadButtonLabel}
                  <input
                    className="add-package__file"
                    type="file"
                    id={`replacePackage-${packageInfo.id}`}
                    accept=".upackage"
                    onChange={(event) =>
                      onReplace({
                        target: event.target,
                        packageId: packageInfo.id,
                        packageName: packageInfo.packageName,
                      })
                    }
                    onClick={onPackageClick}
                    disabled={isProcessing || isPackageActionDisabled}
                  />
                </label>
              </div>
            )}
            <DownloadPackage
              packageId={packageInfo.id}
              className="add-on-packages__button add-on-packages__button--download"
              disabled={isDownloadDisabled}
              source={source}
            >
              <DownloadIcon />
              Download Package
            </DownloadPackage>
          </div>

          {!!packageError && <FormHelperText error>{packageError}</FormHelperText>}
        </div>
      </div>

      <div className="add-on-packages__description-container">
        {packageInfo.description && packageInfo.description !== '<p></p>' ? (
          <div className="add-on-packages__description-wrapper">
            <div
              ref={expandableRef}
              className={clsx('add-on-packages__description', {
                'add-on-packages__description--open': isExpanded,
              })}
              dangerouslySetInnerHTML={{ __html: packageInfo.description }}
            />
            {showExpandBtn && !isExpanded && (
              <button
                type="button"
                onClick={() => setIsExpanded(true)}
                className="package__expand-btn"
              >
                Read more
              </button>
            )}
          </div>
        ) : (
          <div className="add-on-packages__description add-on-packages__description--no-description">
            No description
          </div>
        )}

        {displayActions && onEditInfo && (
          <UnigineButton
            className="add-on-packages__edit-button"
            onClick={() => onEditInfo(packageInfo.id)}
            disabled={isPackageActionDisabled}
          >
            <EditSquareIcon /> <span>Edit Info</span>
          </UnigineButton>
        )}
      </div>

      <div className="add-on-packages__details-container">
        <PlatformPoster platform={packageInfo.platform} />

        <div className="add-on-packages__versions">
          <div className="add-on-packages__versions-row">
            <div className="add-on-packages__versions-row-name">SDK Versions</div>
            <div>{packageInfo.sdkVersions.map((ver) => ver.value).join(', ')}</div>
          </div>
          <div className="add-on-packages__versions-row">
            <div className="add-on-packages__versions-row-name">Source Code</div>
            <div>{packageInfo.sourceCode.value}</div>
          </div>
        </div>
      </div>
    </li>
  );
};

export default AddonPackageItem;
