import { FC } from 'react';

import { ReactComponent as CrossIcon } from 'app/assets/icon_cross.svg';
import { ReactComponent as WindowsIcon } from 'app/assets/icon_windows.svg';
import { ReactComponent as LinuxIcon } from 'app/assets/icon_linux.svg';

import IPlatform from 'app/interfaces/addons/IPlatform';

interface PlatformIconProps {
  platform: IPlatform;
  className?: string;
}

const PlatformIcon: FC<PlatformIconProps> = ({ platform, className }) => {
  switch (platform.value) {
    case 'windows':
      return <WindowsIcon className={className} />;
    case 'cross':
      return <CrossIcon className={className} />;
    case 'linux':
      return <LinuxIcon className={className} />;
    default:
      return null;
  }
};

export default PlatformIcon;
