import { useState, useEffect, FC } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import useAppSelector from 'app/hooks/useAppSelector';
import useAppDispatch from 'app/hooks/useAppDispatch';
import usePermissions from 'app/hooks/usePermissions';

import { CircularProgress } from '@mui/material';

import {
  getCurrentPublisherInfo,
  currentPublisherInfoErrorSelector,
} from 'app/main/sections/PublisherPanel/sections/SettingsSection/store/settingsSlice';

const SettingsSectionWrapper: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const { publisherPanelUserAdmin, isNoRole } = usePermissions();

  useEffect(() => {
    if (isNoRole) {
      return;
    }

    if (!publisherPanelUserAdmin) {
      navigate('/publisherPanel/add-ons');
    }
  }, [isNoRole, navigate, publisherPanelUserAdmin]);

  useEffect(() => {
    if (!isNoRole) {
      dispatch(getCurrentPublisherInfo()).then(() => {
        setIsLoading(false);
      });
    }
  }, [dispatch, isNoRole]);
  const error = useAppSelector(currentPublisherInfoErrorSelector);

  return (
    <>
      {isLoading && (
        <div className="orders__spinner">
          <CircularProgress color="inherit" />
        </div>
      )}
      {error && !isLoading && (
        <span className="orders__error">Sorry, something went wrong when loading the data.</span>
      )}
      {!error && !isLoading && <Outlet />}
    </>
  );
};

export default SettingsSectionWrapper;
