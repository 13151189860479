import { createSlice, createAsyncThunk, createEntityAdapter, EntityState } from '@reduxjs/toolkit';
import { getFreeAddon } from 'app/main/sections/Addon/store/baseInfoSlice';
import axiosInstance from 'app/auth/axiosInstance';

import {
  IPublisherDataInPublisherPage,
  IPublisherByIdDataResponse,
} from 'app/interfaces/publisher/IPublisher';
import { IAddonInList } from 'app/interfaces/addons/IAddon';
import IError from 'app/interfaces/IError';

import { RootState } from 'app/store';

const publisherAddonsAdapter = createEntityAdapter<IAddonInList>();

interface IPublisherSliceState {
  publisher: IPublisherDataInPublisherPage | null;
  publisherAddons: EntityState<IAddonInList>;
  errors: IError | null;
  errorsPublisherAddons: IError | null;
}

const initialState: IPublisherSliceState = {
  publisher: null,
  publisherAddons: publisherAddonsAdapter.getInitialState({}),
  errors: null,
  errorsPublisherAddons: null,
};

interface IPublisherParams {
  publisherId: string;
}

interface IAddonsList {
  addonVersions: IAddonInList[];
  total: number;
}

export const getPublisherData = createAsyncThunk<
  IPublisherByIdDataResponse,
  IPublisherParams,
  { rejectValue: IError }
>('publisherSection/getPublisherData', async ({ publisherId }, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      method: 'get',
      url: `/publishers/${publisherId}`,
    });

    return response.data;
  } catch (err: any) {
    if (err.response) {
      return rejectWithValue({ data: err.response.data, status: err.response.status });
    }

    return rejectWithValue({ data: { message: 'Something went wrong' }, status: 500 });
  }
});

export const getPublisherAddonVersionsData = createAsyncThunk<
  IAddonsList,
  IPublisherParams,
  { rejectValue: IError }
>('publisherSection/getPublisherAddonsData', async ({ publisherId }, { rejectWithValue }) => {
  try {
    const params = { publisherId };
    const response = await axiosInstance({
      method: 'get',
      url: '/addon-versions',
      params,
    });

    return response.data;
  } catch (err: any) {
    if (err.response) {
      return rejectWithValue({ data: err.response.data, status: err.response.status });
    }

    return rejectWithValue({ data: { message: 'Something went wrong' }, status: 500 });
  }
});

const publisherSlice = createSlice({
  name: 'publisherSection',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPublisherData.fulfilled, (state, action) => {
        const publisherInfoData = action.payload;

        const imageMedia = publisherInfoData.pictureFiles.map(({ id: mediaId }) => ({
          id: mediaId,
          type: 'picture',
          url: `${process.env.REACT_APP_STORE_API_URL}/media-files/${mediaId}`,
          previewUrl: `${process.env.REACT_APP_STORE_API_URL}/media-files/${mediaId}?isPreview=1`,
        }));

        const videoMedia = publisherInfoData.videoFiles.map(({ id, data }) => ({
          id,
          data,
          type: 'video',
        }));

        const media = [...videoMedia, ...imageMedia];

        state.publisher = { ...publisherInfoData, media };
        state.errors = null;
      })
      .addCase(getPublisherData.rejected, (state, action) => {
        state.publisher = null;
        state.errors = action.payload ?? { data: { message: 'Something went wrong' }, status: 500 };
      })
      .addCase(getPublisherAddonVersionsData.fulfilled, (state, action) => {
        const { addonVersions } = action.payload;
        publisherAddonsAdapter.setAll(state.publisherAddons, addonVersions);
        state.errorsPublisherAddons = null;
      })
      .addCase(getPublisherAddonVersionsData.rejected, (state, action) => {
        publisherAddonsAdapter.removeAll(state.publisherAddons);
        state.errorsPublisherAddons = action.payload ?? {
          data: { message: 'Something went wrong' },
          status: 500,
        };
      })
      .addCase(getFreeAddon.fulfilled, (state, action) => {
        const { versionId } = action.payload;
        const addonInfo = state.publisherAddons.entities[versionId]?.addon;
        if (addonInfo) {
          publisherAddonsAdapter.updateOne(state.publisherAddons, {
            id: versionId,
            changes: { addon: { ...addonInfo, ownedStatus: 'purchased' } },
          });
        }
      });
  },
});

export const publisherInfoSelector = ({
  publisherSection,
}: RootState): IPublisherDataInPublisherPage | null => publisherSection.publisherInfo.publisher;

export const errorInfoSelector = ({ publisherSection }: RootState): IError | null =>
  publisherSection.publisherInfo.errors;

export const errorPublisherAddonsInfoSelector = ({ publisherSection }: RootState): IError | null =>
  publisherSection.publisherInfo.errorsPublisherAddons;

export const { selectAll: publisherAddonsSelector } = publisherAddonsAdapter.getSelectors(
  ({ publisherSection }: RootState) => publisherSection.publisherInfo.publisherAddons
);

export default publisherSlice.reducer;
