import './TagsAutocomplete.scss';
import { forwardRef, useState } from 'react';
import { Autocomplete, AutocompleteProps, Chip, TextField, TextFieldProps } from '@mui/material';

import useAppDispatch from 'app/hooks/useAppDispatch';
import { getTagsData } from 'app/main/sections/PublisherPanel/sections/AddonSection/store/tagsSlice';

interface ITagsAutocompleteProps
  extends Omit<TextFieldProps<'outlined'>, 'value' | 'onChange' | 'validation'> {
  value: { value: string }[];
  onChange: (values: { value: string }[]) => void;
  validation: {
    pattern: RegExp;
    message: string;
  };
}

type ChangeHandler = AutocompleteProps<string, true, true, true>['onChange'];
type InputChangeHandler = AutocompleteProps<string, true, true, true>['onInputChange'];

const TagsAutocomplete = forwardRef<HTMLDivElement, ITagsAutocompleteProps>(
  ({ value, onChange, validation, ...props }, ref) => {
    const dispatch = useAppDispatch();
    const [isLoadingAutocomplete, setIsLoadingAutocomplete] = useState(false);
    const [autocompleteTagsOptions, setAutocompleteTagsOptions] = useState<{ value: string }[]>([]);
    const [validationError, setValidationError] = useState<string | null>(null);

    const doSearch: InputChangeHandler = (_, inputValue) => {
      setValidationError(null);

      if (inputValue) {
        const excepts = value.map((tag) => tag.value);
        setIsLoadingAutocomplete(true);

        dispatch(getTagsData({ query: inputValue, excepts }))
          .unwrap()
          .then((payload) => {
            setAutocompleteTagsOptions(payload.tags);
            setIsLoadingAutocomplete(false);
          });
      }
    };

    const handleOnChangeAutocomplete: ChangeHandler = (_e, values) => {
      setValidationError(null);
      const checkedValues: { value: string }[] = [];
      values.forEach((v) => {
        const match = new RegExp(validation.pattern).test(v);
        if (!match) {
          setValidationError(validation.message);
        } else {
          checkedValues.push({ value: v });
        }
      });
      return onChange(checkedValues);
    };

    return (
      <Autocomplete
        multiple
        autoHighlight
        freeSolo
        limitTags={10}
        loading={isLoadingAutocomplete}
        filterSelectedOptions
        disableClearable
        id="multiple-limit-tags"
        className="autocomplete"
        isOptionEqualToValue={(option, val) => option === val}
        options={[...autocompleteTagsOptions.map((option) => option.value)]}
        onChange={handleOnChangeAutocomplete}
        onInputChange={doSearch}
        value={value.map((option) => option.value)}
        renderTags={(val, getTagProps) =>
          val.map((option, index) => (
            <Chip
              color="primary"
              label={option}
              size="small"
              {...getTagProps({ index })}
              deleteIcon={<div className="autocomplete__delete-icon" />}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            ref={ref}
            {...props}
            {...params}
            className="autocomplete__input"
            placeholder="Enter tag name..."
            error={props.error || !!validationError}
            helperText={props.helperText || validationError}
          />
        )}
      />
    );
  }
);

export default TagsAutocomplete;
