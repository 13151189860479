import './PublisherReviewCard.scss';

import { FC } from 'react';
import moment from 'moment';

import { ReactComponent as ReplyIcon } from 'app/assets/icon_reply.svg';
import { ReactComponent as ReportIcon } from 'app/assets/icon_report.svg';
import { ReactComponent as DisabledReportIcon } from 'app/assets/icon_report-disabled.svg';
import { ReactComponent as EditIcon } from 'app/assets/icon_edit.svg';

import ReviewDashboardCard from 'app/components/ReviewDashboardCard/ReviewDashboardCard';
import UnigineButton from 'app/components/UnigineButton/UnigineButton';
import breakLines from 'app/utils/helpers/breakLines';
import { IPublisherReview } from 'app/interfaces/reviews/IReview';

interface IPublisherReviewCardProps {
  reviewData: IPublisherReview;
  onReply: () => void;
  onReport: () => void;
  onEditReply: () => void;
}

const PublisherReviewCard: FC<IPublisherReviewCardProps> = ({
  reviewData,
  onReply,
  onReport,
  onEditReply,
}) => {
  const handleReport = (): void => {
    if (reviewData.isReported) {
      return;
    }
    onReport();
  };

  return (
    <div className="publisher-review">
      <ReviewDashboardCard
        reviewData={reviewData}
        actions={
          <>
            {!(reviewData.reply.length > 0) && (
              <ReviewDashboardCard.ActionButton onClick={onReply}>
                <ReplyIcon />
                Reply
              </ReviewDashboardCard.ActionButton>
            )}
            <ReviewDashboardCard.ActionButton
              disabled={reviewData.isReported}
              secondary
              onClick={handleReport}
            >
              {reviewData.isReported ? <DisabledReportIcon /> : <ReportIcon />}
              Report
            </ReviewDashboardCard.ActionButton>
          </>
        }
      />

      {reviewData.reply.length > 0 && (
        <div className="publisher-review-reply">
          <ReplyIcon className="publisher-review-reply__icon" />

          <div className="publisher-review-reply__content">
            <span className="publisher-review-reply__date">
              Replied on{' '}
              <time dateTime={reviewData.reply[0].updateDate}>
                {moment(reviewData.reply[0].updateDate).format('YYYY-MM-DD hh:mm')}
              </time>
            </span>
            <p className="publisher-review-reply__text">{breakLines(reviewData.reply[0].text)}</p>
          </div>

          <UnigineButton onClick={onEditReply} className="publisher-review-reply__btn">
            <EditIcon />
            Edit reply
          </UnigineButton>
        </div>
      )}
    </div>
  );
};

export default PublisherReviewCard;
