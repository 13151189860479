import IAddonSourceCode from 'app/interfaces/addons/IAddonSourceCode';

interface IGetSourceLanguageIds {
  sourceLanguagesData: IAddonSourceCode[] | null;
  sourceLanguageParam: string[];
}

const getSourceLanguageIds = ({
  sourceLanguagesData,
  sourceLanguageParam,
}: IGetSourceLanguageIds): string[] | null =>
  sourceLanguagesData &&
  sourceLanguagesData
    .filter((lang) => sourceLanguageParam.includes(lang.slug))
    .map((lang) => lang.id);

export default getSourceLanguageIds;
