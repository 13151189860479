import './RatingFilter.scss';

import { FC, useEffect, useState } from 'react';
import clsx from 'clsx';

import { ReactComponent as Star } from 'app/assets/icon_star_green_empty.svg';

interface IRatingFilterProps {
  defaultValue: number;
  onChange: (value: number) => void;
}

const RatingFilter: FC<IRatingFilterProps> = ({ defaultValue, onChange }) => {
  const [rating, setRating] = useState(defaultValue);
  const [hover, setHover] = useState(0);

  const handleRatingChange = (i: number): void => {
    const newRating = i === rating ? 0 : i;
    onChange(newRating);
    setRating(newRating);
  };

  useEffect(() => {
    if (!defaultValue) {
      setRating(0);
      setHover(0);
    }
  }, [defaultValue]);

  return (
    <div className="rating__wrapper">
      {[...Array(5)].map((_, index) => {
        index += 1;

        return (
          <button
            type="button"
            key={index}
            className={clsx('star__button', {
              'star__button--filled': index <= (hover || rating),
            })}
            onClick={() => handleRatingChange(index)}
            onMouseEnter={() => setHover(index)}
            onMouseLeave={() => setHover(rating)}
          >
            <Star className="star" />
          </button>
        );
      })}
    </div>
  );
};

export default RatingFilter;
