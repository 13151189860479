import { FC, Fragment } from 'react';
import { Link } from 'react-router-dom';

import './CategoryTags.scss';
import clsx from 'clsx';
import ICategory from 'app/interfaces/addons/ICategory';

interface ICategoryTags {
  categories: ICategory[] | null;
  className?: string;
}

const CategoryTags: FC<ICategoryTags> = ({ categories, className }) => {
  return (
    <div className={clsx('category-tags', className)}>
      {categories &&
        categories.map((categoryData, i, arr) => {
          return (
            <Fragment key={categoryData.id}>
              <Link className="category-tags__link" to={`/${categoryData.slug}`}>
                {categoryData.name}
              </Link>
              {arr.length - 1 !== i && ', '}
            </Fragment>
          );
        })}
    </div>
  );
};

export default CategoryTags;
