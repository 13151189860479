import './AddonSection.scss';

import { FC, useEffect, useState } from 'react';
import { Link, Outlet, useParams, useSearchParams } from 'react-router-dom';
import clsx from 'clsx';

import { CircularProgress } from '@mui/material';

import AddonNavigationMenu from 'app/components/AddonNavigationMenu/AddonNavigationMenu';
import AddonStatus from 'app/components/AddonStatus/AddonStatus';
import VersionControl from 'app/components/VersionControl/VersionControl';
import UnigineButton from 'app/components/UnigineButton/UnigineButton';
import UnigineTooltip from 'app/components/Tooltip/Tooltip';

import PublishIcon from 'app/assets/icon_publish.svg';

import { getPlatformData } from 'app/main/sections/Addons/store/platformSlice';
import { getProductsData } from 'app/main/sections/Addons/store/productsSlice';
import { getVersionsData } from 'app/main/sections/Addons/store/versionsSlice';

import useAppDispatch from 'app/hooks/useAppDispatch';
import useAppSelector from 'app/hooks/useAppSelector';
import usePermissions from 'app/hooks/usePermissions';
import {
  addonBaseInfoErrorSelector,
  addonBaseInfoPublishErrorSelector,
  addonBaseInfoSelector,
  getAddonBaseInfo,
  publishAddonVersion,
  rejectAddonVersion,
} from 'app/main/sections/AdminPortal/sections/AddonSection/store/baseInfoSlice';
import {
  addonErrorSelector,
  addonSelector,
  getAddonInfo,
  toggleAddonArchiveStatus,
  toggleAddonBanStatus,
} from 'app/main/sections/AdminPortal/sections/AddonSection/store/addonInfoSlice';
import addonNavigationMenuData from 'app/main/sections/AdminPortal/sections/AddonSection/addonNavigationMenuData';

const AddonSection: FC = () => {
  const dispatch = useAppDispatch();
  const { addonId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [loadingAddonInfo, setLoadingAddonInfo] = useState(true);
  const { isNoRole } = usePermissions();

  const addonError = useAppSelector(addonErrorSelector);
  const error = useAppSelector(addonBaseInfoErrorSelector);
  const publishError = useAppSelector(addonBaseInfoPublishErrorSelector);
  const [searchParams] = useSearchParams();
  const addonInfo = useAppSelector(addonSelector);
  const addonBaseInfo = useAppSelector(addonBaseInfoSelector);

  useEffect(() => {
    if (!isNoRole && addonId) {
      Promise.all([
        dispatch(getAddonInfo({ addonId })),
        dispatch(getVersionsData()),
        dispatch(getProductsData()),
        dispatch(getPlatformData()),
      ]).finally(() => setLoadingAddonInfo(false));
    }
  }, [addonId, dispatch, isNoRole]);

  useEffect(() => {
    if (!loadingAddonInfo && !addonError && addonInfo) {
      // setIsLoading(true);

      const addonVersionFromParams = searchParams.get('add-on-version');

      const addonVersionIdCurrent = addonInfo.addonVersions.filter(
        (version) => version.isCurrent
      )[0]?.id;

      const addonVersionId = addonVersionFromParams || addonVersionIdCurrent;

      dispatch(getAddonBaseInfo({ addonVersionId }))
        .unwrap()
        .finally(() => setIsLoading(false));
    }

    if (addonError) {
      setIsLoading(false);
    }
  }, [addonError, addonInfo, dispatch, loadingAddonInfo, searchParams]);

  if (isLoading) {
    return (
      <div className="add-on-section-wrapper">
        <div className="add-on-section__add-on-wrapper">
          <AddonStatus status="loading" />

          <div className="add-on-section__title-wrapper">
            <h1 className="add-on-section__title">
              <Link to="/adminPortal/add-ons">Add-Ons for Moderation</Link>
            </h1>
          </div>
        </div>

        <AddonNavigationMenu addonNavigationMenuData={addonNavigationMenuData} />

        <section className="add-on-section add-on-section--loading">
          <div className="orders__spinner">
            <CircularProgress color="inherit" />
          </div>
        </section>
      </div>
    );
  }

  if (addonError) {
    return (
      <div className="add-on-section-wrapper">
        <div className="add-on-section__add-on-wrapper">
          <AddonStatus status="error" />

          <div className="add-on-section__title-wrapper">
            <h1 className="add-on-section__title">
              <Link to="/adminPortal/add-ons">Add-Ons for Moderation</Link>
            </h1>
          </div>
        </div>

        <AddonNavigationMenu addonNavigationMenuData={addonNavigationMenuData} />

        <section className="add-on-section">
          <div className="add-on-section__info">
            <div className="add-on-section__block">{addonError.data.context?.message}</div>
          </div>
        </section>
      </div>
    );
  }

  if (error) {
    return (
      <div className="add-on-section-wrapper">
        <div className="add-on-section__add-on-wrapper">
          <AddonStatus status="error" />

          <div className="add-on-section__title-wrapper">
            <h1 className="add-on-section__title">
              <Link to="/adminPortal/add-ons">Add-Ons for Moderation</Link>
            </h1>
          </div>
        </div>

        <AddonNavigationMenu addonNavigationMenuData={addonNavigationMenuData} />

        <section className="add-on-section">
          <div className="add-on-section__info">
            <div className="add-on-section__block">{error.data.context?.message}</div>
          </div>
        </section>
      </div>
    );
  }

  if (isLoading || !addonInfo || !addonId || !addonBaseInfo) {
    return (
      <div className="add-on-section-wrapper">
        <div className="add-on-section__add-on-wrapper">
          <AddonStatus status="loading" />

          <div className="add-on-section__title-wrapper">
            <h1 className="add-on-section__title">
              <Link to="/publisherPanel/add-ons">My Uploaded Add-Ons</Link>
            </h1>
          </div>
        </div>

        <AddonNavigationMenu addonNavigationMenuData={addonNavigationMenuData} />

        <section className="add-on-section add-on-section--loading">
          <div className="orders__spinner">
            <CircularProgress color="inherit" />
          </div>
        </section>
      </div>
    );
  }

  const {
    title,
    price,
    discountedPrice,
    addonVersions,
    addonStatus,
    isFree,
    isBanned,
    isArchived,
    publisher,
  } = addonInfo;

  const { status, id: addonVersionId, packageFileStatus } = addonBaseInfo;

  const packageError =
    packageFileStatus?.slug === 'file_error' || packageFileStatus?.slug === 'storage_error';

  const displayArchiveButton = !isArchived;
  const disableArchiveButton = isBanned;

  const displayGetFromArchiveButton = isArchived;
  const disableGetFromArchiveButton = isBanned;

  const displayBanButton = !isBanned;

  const displayUnbanButton = isBanned;

  const displayPublishAndRejectButtons = status.value === 'Moderation' && !isBanned;

  const sortAddonVersions = addonVersions
    .map((addonVersion) => {
      let label = addonVersion.versionNumber;

      if (addonVersion.status.value !== 'Published') {
        label = `${addonVersion.versionNumber} (${addonVersion.status.value})`;
      }

      return {
        value: addonVersion.id,
        label,
        isCurrent: addonVersion.isCurrent,
        status: addonVersion.status,
      };
    })
    .sort((a, b) => Number(b.isCurrent) - Number(a.isCurrent));

  const handleToggleArchiveClick = (): void => {
    setIsLoading(true);
    dispatch(toggleAddonArchiveStatus({ addonId, isArchived: !isArchived })).then(() => {
      setIsLoading(false);
    });
  };

  const handleToggleBanClick = (): void => {
    setIsLoading(true);
    dispatch(toggleAddonBanStatus({ addonId, isBanned: !isBanned })).then(() => {
      setIsLoading(false);
    });
  };

  const handlePublishClick = (): void => {
    setIsLoading(true);

    dispatch(publishAddonVersion({ addonVersionId }))
      .unwrap()
      .then(() => {
        dispatch(getAddonInfo({ addonId }));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleRejectClick = (): void => {
    setIsLoading(true);

    dispatch(rejectAddonVersion({ addonVersionId }))
      .unwrap()
      .then(() => {
        dispatch(getAddonInfo({ addonId }));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="add-on-section-wrapper">
      <div className="add-on-section__add-on-wrapper">
        <div className="add-on-section__add-on-top-container">
          <AddonStatus status={addonStatus.slug} />

          <div className="add-on-section__add-on-actions-container">
            {displayBanButton && (
              <UnigineButton
                className="add-on-section__add-on-action add-on-section__add-on-action--archive"
                onClick={handleToggleBanClick}
              >
                Ban Add-On
              </UnigineButton>
            )}

            {displayUnbanButton && (
              <UnigineButton
                className="add-on-section__add-on-action add-on-section__add-on-action--archive"
                onClick={handleToggleBanClick}
              >
                Unban Add-On
              </UnigineButton>
            )}

            {displayArchiveButton && (
              <UnigineButton
                className={clsx(
                  'add-on-section__add-on-action add-on-section__add-on-action--archive',
                  {
                    'add-on-section__add-on-action--disabled': disableArchiveButton,
                  }
                )}
                disabled={disableArchiveButton}
                onClick={handleToggleArchiveClick}
              >
                Archive
              </UnigineButton>
            )}

            {displayGetFromArchiveButton && (
              <UnigineButton
                className={clsx(
                  'add-on-section__add-on-action add-on-section__add-on-action--archive',
                  {
                    'add-on-section__add-on-action--disabled': disableGetFromArchiveButton,
                  }
                )}
                disabled={disableGetFromArchiveButton}
                onClick={handleToggleArchiveClick}
              >
                Get From Archive
              </UnigineButton>
            )}
          </div>
        </div>

        <div className="add-on-section__title-wrapper">
          <div className="add-on-section__title-link-wrapper">
            <h1 className="add-on-section__title" aria-label={title}>
              <button
                type="button"
                className="add-on-section__title-action"
                aria-label={`Copy "${title}" to clipboard`}
                title={title}
                onClick={() => navigator.clipboard.writeText(title)}
              />
              {title}
            </h1>

            <div className="add-on-section__link-wrapper">
              <UnigineTooltip title="Publisher Profile">
                <a target="_blank" href={`/publisher/${publisher.id}`} rel="noreferrer">
                  {publisher.name}
                </a>
              </UnigineTooltip>{' '}
              <UnigineTooltip title="Send an Email">
                <a href={`mailto:${publisher.email}`}>({publisher.email})</a>
              </UnigineTooltip>
            </div>
          </div>

          <div className="add-on-section__price-wrapper">
            <div className="add-on-section__price-container">
              {isFree && <span className="add-on-section__price--free">FREE</span>}

              {price !== discountedPrice && !isFree && (
                <span className="add-on-section__price--line">$ {price}</span>
              )}

              {!isFree && <span className="add-on-section__price">$ {discountedPrice}</span>}
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="add-on-section__version-container">
          {addonInfo && addonBaseInfo && (
            <VersionControl
              name="add-on-version"
              label="VERSION"
              versions={sortAddonVersions}
              addon={{ isBanned, status }}
            />
          )}

          <div className="add-on-section__action-container">
            {displayPublishAndRejectButtons && (
              <div className="add-on-section__buttons-container">
                <UnigineButton
                  className="add-on-section__action-button add-on-section__reject-button"
                  onClick={handleRejectClick}
                >
                  Reject
                </UnigineButton>

                <UnigineButton
                  className="add-on-section__action-button add-on-section__publish-button"
                  onClick={handlePublishClick}
                  disabled={!!publishError || packageError}
                >
                  <img src={PublishIcon} alt="Publish add-on" /> Publish
                </UnigineButton>
              </div>
            )}
          </div>
        </div>
      </div>

      <AddonNavigationMenu addonNavigationMenuData={addonNavigationMenuData} />

      <div>
        <Outlet />
      </div>
    </div>
  );
};

export default AddonSection;
