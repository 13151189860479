import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from 'app/auth/axiosInstance';
import IError from 'app/interfaces/IError';
import { IAddAddonResponse, IAddAddonFieldValues } from 'app/interfaces/addons/IAddAddon';

export const addAddon = createAsyncThunk<
  IAddAddonResponse,
  { addonData: IAddAddonFieldValues },
  { rejectValue: IError }
>('addAddonSection/addAddon', async ({ addonData }, { rejectWithValue }) => {
  const { title, description, price, isFree, categories, products, requirements, discounts, tags } =
    addonData;

  const apiData = {
    title,
    price,
    is_free_addon: isFree,
    discounts: discounts.map(({ percentage, from, till, quantity }) => ({
      discount: percentage,
      start_date: from,
      end_date: till,
      addons_for_sale: quantity,
    })),
    categories,
    products,
    description,
    system_requirements: requirements,
    tags: tags.map((tag) => tag.value),
  };

  try {
    const response = await axiosInstance({
      method: 'post',
      url: '/publisher-panel/addons',
      data: apiData,
    });
    return response.data;
  } catch (err: any) {
    if (err.response) {
      return rejectWithValue({ data: err.response.data, status: err.response.status });
    }

    return rejectWithValue({
      data: { message: 'Something went wrong to add addon' },
      status: 500,
    });
  }
});

const addAddonSlice = createSlice({
  name: 'addAddonSection',
  initialState: {},
  reducers: {},
  extraReducers: () => {},
});

export default addAddonSlice.reducer;
