import { combineReducers } from '@reduxjs/toolkit';
import categories from 'app/store/unigine/categoriesSlice';
import sidebar from 'app/store/unigine/sidebarSlice';
import cart from 'app/store/unigine/cartSlice';

const unigineReducers = combineReducers({
  categories,
  sidebar,
  cart,
});

export default unigineReducers;
