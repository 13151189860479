import { combineReducers } from '@reduxjs/toolkit';
import publishedAddonsInfo from 'app/main/sections/AdminPortal/sections/AddonsSection/store/publishedAddonsSlice';
import addon from 'app/main/sections/AdminPortal/sections/AddonSection/store/addonInfoSlice';
import addonBaseInfo from 'app/main/sections/AdminPortal/sections/AddonSection/store/baseInfoSlice';
import reviewStatusInfo from 'app/main/sections/AdminPortal/sections/ReviewsSection/store/reviewStatusSlice';
import reviewsInfo from 'app/main/sections/AdminPortal/sections/ReviewsSection/store/reviewsSlice';

const reducer = combineReducers({
  addon,
  addonBaseInfo,
  publishedAddonsInfo,
  reviewStatusInfo,
  reviewsInfo,
});

export default reducer;
