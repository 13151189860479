import Documentation from 'app/main/sections/Documentation/Documentation';

const DocumentationConfig = [
  {
    path: '/documentation',
    element: <Documentation />,
  },
];

export default DocumentationConfig;
