import React, { ChangeEvent, FormEvent, FC } from 'react';
import { Button, Dialog, DialogContent, IconButton, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import getYouTubeVideoIdFromUrl from 'app/utils/helpers/getYouTubeVideoIdFromUrl';
import UnigineTooltip from 'app/components/Tooltip/Tooltip';
import { IVideoInfo } from 'app/interfaces/addons/IVideoFile';

interface IAddonDialogVideoForm {
  isOpen: boolean;
  handleClose: () => void;
  video: IVideoInfo | null;
  videoId: string;
  setVideoId: React.Dispatch<React.SetStateAction<string>>;
  handleSubmitVideoForm: (event: FormEvent<HTMLFormElement>) => void;
}

const AddonDialogVideoForm: FC<IAddonDialogVideoForm> = ({
  isOpen,
  handleClose,
  handleSubmitVideoForm,
  video,
  videoId,
  setVideoId,
}) => {
  const handleVideoInputChange = (evt: ChangeEvent<HTMLInputElement>): void => {
    const videoLink = evt.target.value;
    const id = getYouTubeVideoIdFromUrl(videoLink);

    if (id) {
      setVideoId(id);
    }
  };

  const videoInputError = typeof videoId === 'undefined';
  const helperText = videoInputError
    ? 'Wrong format. Please try https://www.youtube.com/watch?v=[videoId].'
    : '';
  return (
    <Dialog open={isOpen} fullWidth maxWidth="md" onClose={handleClose} className="dialog">
      <DialogContent className="dialog-media__content dialog-media__content--form">
        <h2 className="dialog-media__title">Add video</h2>

        <div className="dialog-media__container dialog-media__container--fg1">
          <form className="dialog-media__form--video" onSubmit={handleSubmitVideoForm}>
            <UnigineTooltip title="YouTube video link">
              <TextField
                fullWidth
                label="YouTube video link"
                variant="outlined"
                className="dialog-media__input--text"
                onChange={handleVideoInputChange}
                helperText={helperText}
                error={videoInputError}
              />
            </UnigineTooltip>

            <div className="add-on-media__buttons-container add-on-media__buttons-container--mta">
              {video && videoId && (
                <iframe
                  width="380"
                  height="240"
                  src={`https://www.youtube.com/embed/${videoId}`}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  className="dialog-media__iframe"
                />
              )}

              <div className="dialog-media__video-description">
                {video && videoId && <div className="dialog-media__video-title">{video.title}</div>}
                {video && videoId && (
                  <div className="dialog-media__video-channel">{video.channelTitle}</div>
                )}

                <Button
                  type="submit"
                  className="dialog-media__button dialog-media__button--right"
                  disabled={!video}
                >
                  Add
                </Button>
              </div>
            </div>
          </form>
        </div>

        <IconButton aria-label="close" className="dialog__close-button" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogContent>
    </Dialog>
  );
};

export default AddonDialogVideoForm;
