import './AddonInfobox.scss';

import { FC, JSX } from 'react';
import clsx from 'clsx';

import PublisherLink from 'app/components/PublisherLink/PublisherLink';
import CategoryTags from 'app/components/CategoryTags/CategoryTags';
import PriceDisplay from 'app/components/PriceDisplay/PriceDisplay';
import { IAddon } from 'app/interfaces/addons/IAddon';
import ICategory from 'app/interfaces/addons/ICategory';

interface IAddonInfoboxProps {
  addon: IAddon & { previewSrc: string; categories: ICategory[] };
  purchaseButtonSlot: JSX.Element;
  className?: string;
}

interface IStickyHeaderProps extends Omit<IAddonInfoboxProps, 'className'> {
  offset: number;
}

const AddonInfobox: FC<IAddonInfoboxProps> & {
  StickyHeader: typeof StickyHeader;
} = ({ addon, purchaseButtonSlot = null, className }) => {
  const {
    previewSrc,
    title,
    publisher,
    categories,

    price,
    discountedPrice,
    isFree,
    ownedStatus,
  } = addon;

  const isUnavailable = ownedStatus === 'not available';
  const isPurchased = ownedStatus === 'purchased';
  const isObtained = ownedStatus === 'obtained';

  return (
    <div className={clsx('add-on-infobox', className)}>
      <img className="add-on-infobox__image" src={previewSrc} alt={title} />
      <div className="add-on-infobox__details">
        <PublisherLink
          publisher={publisher}
          // className="add-on-infobox__publisher-link"
        />
        <h2 className="add-on-infobox__title">{title}</h2>
        <CategoryTags categories={categories} />
      </div>
      <div className="add-on-infobox__price">
        <PriceDisplay
          price={price}
          discountedPrice={discountedPrice}
          isFree={isFree}
          isUnavailable={isUnavailable}
          className="add-on-infobox__price-display"
        />
        <div className="add-on-infobox__purchase-btn-wrapper">
          {isPurchased || isObtained ? (
            <div className="purchased-notice">{ownedStatus}</div>
          ) : (
            purchaseButtonSlot
          )}
        </div>
      </div>
    </div>
  );
};

const StickyHeader: FC<IStickyHeaderProps> = ({ addon, offset, purchaseButtonSlot }) => {
  return (
    <div className="add-on-sticky-header" style={{ top: `${offset}px` }}>
      <AddonInfobox
        className="add-on-infobox--sticky"
        addon={addon}
        purchaseButtonSlot={purchaseButtonSlot}
      />
    </div>
  );
};

AddonInfobox.StickyHeader = StickyHeader;

export default AddonInfobox;
