import { combineReducers } from '@reduxjs/toolkit';
import publisherInfo from 'app/main/sections/Publisher/store/publisherSlice';
import contactPublisher from 'app/main/sections/Publisher/store/contactPublisherSlice';

const reducer = combineReducers({
  publisherInfo,
  contactPublisher,
});

export default reducer;
