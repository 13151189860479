import './SortControl.scss';

import { useEffect, useMemo, JSX } from 'react';
import { useSearchParams } from 'react-router-dom';
import { InputLabel, IconButton } from '@mui/material';
import clsx from 'clsx';

import mergeSearchParam from 'app/utils/helpers/mergeSearchParam';
import UnigineSelect from 'app/components/UnigineSelect/UnigineSelect';

import { ReactComponent as SortDescendingIcon } from 'app/assets/icon_sort_desc.svg';

type SortControlProps<T> = {
  name: string;
  label?: string;
  options: T[];

  setDefault?: boolean;
  defaultDirection?: 'ASC' | 'DESC';
} & (T extends { value: string }
  ? { getValue?: (item: T) => string }
  : { getValue: (item: T) => string }) &
  (T extends { label: string }
    ? { getLabel?: (item: T) => string }
    : { getLabel: (item: T) => string });

const hasDefaultDirection = (option: any): option is { defaultDirection: 'ASC' | 'DESC' } =>
  ['ASC', 'DESC'].includes(option.defaultDirection);

export default function SortControl<T>({
  name,
  label = 'Sort by',
  options = [],
  setDefault = true,
  defaultDirection = 'ASC',
  getLabel = (item) => item.label || '',
  getValue = (item) => item.value || '',
}: SortControlProps<T>): JSX.Element {
  const [searchParams, setSearchParams] = useSearchParams();
  const value = searchParams.get(name) || (setDefault ? getValue(options[0]) : '');

  let direction: string = defaultDirection; // try default direction first
  if (hasDefaultDirection(options[0])) {
    direction = options[0].defaultDirection; // override with per-option default, if any
  }
  direction = searchParams.get('direction') || direction; // an already set searchParam overrides defaults

  const currentSearchParams = useMemo(() => {
    const paramsWithSort = mergeSearchParam(searchParams, name, value);
    const paramsWithSortAndDirection = mergeSearchParam(paramsWithSort, 'direction', direction);

    return paramsWithSortAndDirection;
  }, [direction, name, searchParams, value]);

  useEffect(() => {
    if (setDefault) {
      setSearchParams(currentSearchParams, { replace: true });
    }
  }, [name, setDefault, setSearchParams, currentSearchParams]);

  const handleChange: Parameters<typeof UnigineSelect>[0]['onChange'] = (evt) => {
    const newValue = evt.target.value;

    const paramsWithNewValue = mergeSearchParam(currentSearchParams, name, newValue);

    const option = options.find((opt) => getValue(opt) === newValue);

    if (hasDefaultDirection(option)) {
      const paramsWithNewValueAndDefaultDirection = mergeSearchParam(
        paramsWithNewValue,
        'direction',
        option.defaultDirection
      );

      setSearchParams(paramsWithNewValueAndDefaultDirection);
    } else {
      setSearchParams(paramsWithNewValue);
    }
  };

  const handleChangeDirection = (): void => {
    const newDirection = direction === 'ASC' ? 'DESC' : 'ASC';

    const paramsWithNewDirection = mergeSearchParam(currentSearchParams, 'direction', newDirection);

    if (value) {
      setSearchParams(paramsWithNewDirection);
    } else {
      setSearchParams(mergeSearchParam(paramsWithNewDirection, name, getValue(options[0])));
    }
  };

  return (
    <div className="sort-control">
      <InputLabel id="orders-sort">{label}</InputLabel>

      <UnigineSelect
        className="sort-control__select"
        labelId="orders-sort"
        name="sort"
        value={value}
        onChange={handleChange}
        options={options}
        getLabel={getLabel}
        getValue={getValue}
      />

      <IconButton onClick={handleChangeDirection} className="sort-control__direction-btn">
        <SortDescendingIcon
          className={clsx('sort-control__direction-icon', {
            'sort-control__direction-icon--flipped': direction === 'ASC',
          })}
        />
      </IconButton>
    </div>
  );
}
