import './Layout.scss';

import { FC } from 'react';
import { useRoutes } from 'react-router-dom';

import { CircularProgress } from '@mui/material';

import useAppSelector from 'app/hooks/useAppSelector';

import Footer from 'app/components/Footer/Footer';
import Header from 'app/components/Header/Header';

import routes from 'app/configs/routesConfig';
import { userIsProcessingSelector } from 'app/auth/store/userSlice';

const Layout: FC = () => {
  const element = useRoutes(routes);

  const userIsProcessing = useAppSelector(userIsProcessingSelector);

  return (
    <div className="layout">
      <Header />
      {userIsProcessing ? (
        <main className="content-wrapper content-wrapper--loading">
          <CircularProgress color="inherit" className="content-wrapper__loading" />
        </main>
      ) : (
        <main className="content-wrapper">{element}</main>
      )}
      <Footer />
    </div>
  );
};

export default Layout;
