import { createSlice, createAsyncThunk, isAnyOf } from '@reduxjs/toolkit';

import ITag from 'app/interfaces/addons/ITag';
import IError from 'app/interfaces/IError';

import axiosInstance from 'app/auth/axiosInstance';
import { RootState } from 'app/store';

interface ITagsSliceState {
  tags: ITag[] | null;
  topTags: ITag[] | null;
}

interface ITags {
  tags: ITag[];
}

const initialState: ITagsSliceState = {
  tags: null,
  topTags: null,
};

export const getTagsData = createAsyncThunk<ITags, undefined, { rejectValue: IError }>(
  'addonsListSection/getTagsData',
  async (props, { rejectWithValue }) => {
    try {
      const response = await axiosInstance({
        method: 'get',
        url: '/tags',
      });
      return response.data;
    } catch (err: any) {
      if (err.response) {
        return rejectWithValue({ data: err.response.data, status: err.response.status });
      }

      return rejectWithValue({ data: { message: 'Something went wrong' }, status: 500 });
    }
  }
);

export const getTopTagsData = createAsyncThunk<ITags, undefined, { rejectValue: IError }>(
  'addonsListSection/getTopTagsData',
  async (props, { rejectWithValue }) => {
    try {
      const response = await axiosInstance({
        method: 'get',
        url: '/tags/top',
        params: {
          limit: 10,
        },
      });

      return response.data;
    } catch (err: any) {
      if (err.response) {
        return rejectWithValue({ data: err.response.data, status: err.response.status });
      }

      return rejectWithValue({ data: { message: 'Something went wrong' }, status: 500 });
    }
  }
);

const tagsSlice = createSlice({
  name: 'addonsListSection',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTagsData.fulfilled, (state, action) => {
        const { tags } = action.payload;
        state.tags = tags;
      })
      .addCase(getTopTagsData.fulfilled, (state, action) => {
        const { tags } = action.payload;
        state.topTags = tags;
      })
      .addMatcher(isAnyOf(getTagsData.rejected, getTopTagsData.rejected), (state, action) => {
        console.log(action.payload);
      });
  },
});

export const selectTags = ({ addonsListSection }: RootState): ITag[] | null =>
  addonsListSection.tagsInfo.tags;

export const selectTopTags = ({ addonsListSection }: RootState): ITag[] | null =>
  addonsListSection.tagsInfo.topTags;

export default tagsSlice.reducer;
