import { forwardRef } from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';

interface IIntegerInputProps extends NumericFormatProps {
  max?: number;
}

const IntegerInput = forwardRef<
  HTMLInputElement,
  Omit<IIntegerInputProps, 'defaultValue'> & { defaultValue?: string | number | readonly string[] }
>(({ max = Infinity, ...props }, ref) => (
  <NumericFormat
    {...props}
    defaultValue={props.defaultValue?.toString()}
    getInputRef={ref}
    allowNegative={false}
    allowLeadingZeros={false}
    decimalScale={0}
    fixedDecimalScale
    isAllowed={({ floatValue: value }) => !value || (value >= 0 && value <= max)}
  />
));

export default IntegerInput;
