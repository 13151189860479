import ICategory from 'app/interfaces/addons/ICategory';

interface ICategoryTitle {
  pageName?: string;
  categories?: ICategory[];
  searchText?: string;
}

const getCategoryTitle: (arg: ICategoryTitle) => string = ({
  pageName,
  categories,
  searchText,
}) => {
  let title = '';
  let filteredObject = null;

  if (pageName && pageName !== 'all' && pageName !== 'search' && categories) {
    [filteredObject] = categories.filter((category) => category.slug === pageName) || [];
    title = filteredObject?.name;
  }

  if (pageName && pageName === 'all') {
    title = 'All Add-Ons';
  }

  if (pageName && pageName === 'search') {
    title = 'Search Results';
  }

  if (searchText) {
    title = `Results for: "${searchText}"`;
  }

  return title;
};

export default getCategoryTitle;
