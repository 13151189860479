import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import IOrderStatus from 'app/interfaces/orders/IOrderStatus';
import IError from 'app/interfaces/IError';
import axiosInstance from 'app/auth/axiosInstance';

import { RootState } from 'app/store';

interface IOrdersStatusesSliceState {
  orderStatuses: IOrderStatus[] | null;
  errors: IError | null;
}

interface IOrdersStatuses {
  order_statuses: IOrderStatus[];
}

const initialState: IOrdersStatusesSliceState = {
  orderStatuses: null,
  errors: null,
};

export const getOrderStatuses = createAsyncThunk<
  IOrdersStatuses,
  undefined,
  { rejectValue: IError }
>('ordersSection/getOrderStatuses', async (props, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      method: 'get',
      url: `/order-statuses`,
    });

    return response.data;
  } catch (err: any) {
    if (err.response) {
      return rejectWithValue({ data: err.response.data, status: err.response.status });
    }

    return rejectWithValue({ data: { message: 'Something went wrong' }, status: 500 });
  }
});

const statusesSlice = createSlice({
  name: 'ordersSection/statuses',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOrderStatuses.fulfilled, (state, action) => {
        state.orderStatuses = action.payload.order_statuses;
        state.errors = null;
      })
      .addCase(getOrderStatuses.rejected, (state, action) => {
        if (action.payload) {
          state.errors = action.payload;
        }
      });
  },
});

export const orderStatusesSelector = ({ ordersSection }: RootState): IOrderStatus[] | null =>
  ordersSection.statusesInfo.orderStatuses;

export default statusesSlice.reducer;
