import { combineReducers } from '@reduxjs/toolkit';
import ordersData from 'app/main/sections/Orders/store/ordersSlice';
import statusesInfo from 'app/main/sections/Orders/store/statusesSlice';
import displayedOrder from 'app/main/sections/Orders/store/orderSlice';
import payments from 'app/main/sections/Orders/store/paymentsSlice';
import refund from 'app/main/sections/Orders/store/refundSlice';

const reducer = combineReducers({
  ordersData,
  statusesInfo,
  displayedOrder,
  payments,
  refund,
});

export default reducer;
