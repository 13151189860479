import './ConfirmNavigateDialog.scss';

import { FC, useRef, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogContent, IconButton } from '@mui/material';

import UnigineButton from 'app/components/UnigineButton/UnigineButton';
import { CANCEL_ACTION, CONFIRM_ACTION } from 'app/configs/appConfig';

interface IConfirmNavigateDialog {
  isOpen: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
  title: string;
}

interface UseConfirmNavigateDialog {
  (title: IConfirmNavigateDialog['title']): {
    confirmAction: () => Promise<string>;
    ConfirmNavigateDialog: FC;
    CONFIRM_ACTION: string;
    CANCEL_ACTION: string;
  };
}

const ConfirmNavigateDialog: FC<IConfirmNavigateDialog> = ({
  isOpen,
  handleClose,
  handleConfirm,
  title,
}) => (
  <Dialog
    open={isOpen}
    onClose={handleClose}
    maxWidth="xs"
    className="dialog dialog--confirm-dialog"
  >
    <DialogContent className="dialog-confirm__content">
      <h3 className="dialog-confirm__title">{title}</h3>

      <div className="dialog-confirm__actions-container">
        <UnigineButton type="button" className="dialog-confirm__button" onClick={handleConfirm}>
          Confirm
        </UnigineButton>

        <UnigineButton
          type="button"
          className="dialog-confirm__button dialog-confirm__button--white"
          onClick={handleClose}
        >
          Cancel
        </UnigineButton>
      </div>

      <IconButton aria-label="close" className="dialog__close-button" onClick={handleClose}>
        <CloseIcon />
      </IconButton>
    </DialogContent>
  </Dialog>
);

const useConfirmNavigateDialog: UseConfirmNavigateDialog = (title) => {
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const choiceFn = useRef<(choice: string) => void>();

  const confirmAction = async (): Promise<string> => {
    setIsConfirmDialogOpen(true);

    return new Promise((resolve) => {
      choiceFn.current = (choice) => {
        resolve(choice);
        setIsConfirmDialogOpen(false);
      };
    });
  };

  const handleCloseConfirmDialog = (): void => {
    choiceFn.current?.(CANCEL_ACTION);

    setIsConfirmDialogOpen(false);
  };

  const handleConfirm = (): void => {
    choiceFn.current?.(CONFIRM_ACTION);

    setIsConfirmDialogOpen(false);
  };

  const WrappedConfirmNavigateDialog: FC = () => (
    <ConfirmNavigateDialog
      title={title}
      handleConfirm={handleConfirm}
      handleClose={handleCloseConfirmDialog}
      isOpen={isConfirmDialogOpen}
    />
  );

  return {
    confirmAction,
    ConfirmNavigateDialog: WrappedConfirmNavigateDialog,
    CONFIRM_ACTION,
    CANCEL_ACTION,
  };
};

export { useConfirmNavigateDialog };
export default ConfirmNavigateDialog;
