import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import IAddonStatus from 'app/interfaces/addons/IAddonStatus';
import IError from 'app/interfaces/IError';

import axiosInstance from 'app/auth/axiosInstance';
import { RootState } from 'app/store';

interface IStatusSliseState {
  statuses: IAddonStatus[] | null;
}

interface IStatuses {
  statuses: IAddonStatus[];
  total: number;
}

const initialState: IStatusSliseState = {
  statuses: null,
};

export const getStatusData = createAsyncThunk<IStatuses, undefined, { rejectValue: IError }>(
  'addonsListSection/getStatusData',
  async (props, { rejectWithValue }) => {
    try {
      const response = await axiosInstance({
        method: 'get',
        url: '/addon-statuses',
      });
      return response.data;
    } catch (err: any) {
      if (err.response) {
        return rejectWithValue({ data: err.response.data, status: err.response.status });
      }

      return rejectWithValue({ data: { message: 'Something went wrong' }, status: 500 });
    }
  }
);

const statusSlice = createSlice({
  name: 'addonsListSection',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getStatusData.fulfilled, (state, action) => {
        const { statuses } = action.payload;
        state.statuses = statuses;
      })
      .addCase(getStatusData.rejected, (state, action) => {
        console.log(action.payload);
      });
  },
});

export const selectStatus = ({ addonsListSection }: RootState): IAddonStatus[] | null =>
  addonsListSection.statusInfo.statuses;

export default statusSlice.reducer;
