import './ContactPublisherDialog.scss';

import { FC, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';

import useAppSelector from 'app/hooks/useAppSelector';

import { Dialog, DialogContent, IconButton, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { FormGrid, FormRow } from 'app/components/FormGrid/FormGrid';

import UnigineButton from 'app/components/UnigineButton/UnigineButton';

import {
  contactPublisherErrorSelector,
  contactPublisherSelector,
} from 'app/main/sections/Publisher/store/contactPublisherSlice';

import { contactPublisherForm, formFieldErrors } from 'app/utils/constants/contentConstants';

import { MAX_LENGTH_CONTACT_FORM_MESSAGE, regExps } from 'app/configs/appConfig';

import { IContactFormPublisher } from 'app/interfaces/publisher/IPublisher';

interface IContactPublisherDialog {
  isOpen: boolean;
  isMailSended: boolean;
  onClose: () => void;
  defaultUserName: string;
  defaultUserEmail: string;
  onContactFormSubmit: (values: IContactFormPublisher) => Promise<void>;
}

const ContactPublisherDialog: FC<IContactPublisherDialog> = ({
  isOpen,
  isMailSended,
  onClose,
  defaultUserName,
  defaultUserEmail,
  onContactFormSubmit,
}) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid },
  } = useForm({
    defaultValues: {
      userName: defaultUserName,
      userEmail: defaultUserEmail,
      message: '',
    },
    mode: 'onChange',
  });

  const contactPublisherData = useAppSelector(contactPublisherSelector);
  const contactPublisherError = useAppSelector(contactPublisherErrorSelector);

  useEffect(() => {
    reset((formValues) => ({
      ...formValues,
      message: '',
    }));
  }, [isOpen, reset]);

  return (
    <Dialog open={isOpen} fullWidth maxWidth="md" onClose={onClose} className="dialog">
      <DialogContent className="dialog-media__content dialog-media__content--form">
        <h2>Contact publisher</h2>
        <div className="dialog-contact__form">
          <form
            id="update-publisher-data-form"
            onSubmit={handleSubmit(onContactFormSubmit)}
            className="add-add-on__form add-on-form settings-edit__form"
          >
            <FormGrid>
              <FormRow required label="Your Name">
                <Controller
                  control={control}
                  name="userName"
                  rules={{ required: formFieldErrors.required }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      size="small"
                      className="unigine-form-field"
                      variant="outlined"
                      fullWidth
                      required
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              </FormRow>
              <FormRow required label="Your Email">
                <Controller
                  control={control}
                  name="userEmail"
                  rules={{
                    required: formFieldErrors.required,
                    pattern: {
                      value: regExps.email,
                      message: formFieldErrors.emailPattern,
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      size="small"
                      className="unigine-form-field"
                      variant="outlined"
                      fullWidth
                      required
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              </FormRow>
              <FormRow required label="Message">
                <Controller
                  control={control}
                  name="message"
                  rules={{
                    maxLength: {
                      value: MAX_LENGTH_CONTACT_FORM_MESSAGE,
                      message: formFieldErrors.messageMaxLength,
                    },
                    required: formFieldErrors.required,
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      multiline
                      rows={8}
                      className="unigine-form-field"
                      variant="outlined"
                      fullWidth
                      required
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              </FormRow>
            </FormGrid>
            <div className="dialog-contact__button-wrapper to-right">
              {contactPublisherData && isMailSended && (
                <p className="dialog-contact__success-message">
                  {contactPublisherForm.emailSuccess}
                </p>
              )}
              {contactPublisherError && isMailSended && (
                <p className="dialog-contact__error-message">{contactPublisherForm.emailError}</p>
              )}
              <UnigineButton
                disabled={!isValid || isMailSended}
                type="submit"
                className="dialog-contact__submit"
              >
                Submit
              </UnigineButton>
            </div>
          </form>
        </div>
        <IconButton aria-label="close" className="dialog__close-button" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogContent>
    </Dialog>
  );
};

export default ContactPublisherDialog;
