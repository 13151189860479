import { FC } from 'react';

import ReviewDashboardCard from 'app/components/ReviewDashboardCard/ReviewDashboardCard';
import { IAdminReview } from 'app/interfaces/reviews/IReview';

interface IAdminReviewCardProps {
  reviewData: IAdminReview;
  onCheck: () => void;
  onBan: () => void;
}

const AdminPortalReviewCard: FC<IAdminReviewCardProps> = ({ reviewData, onCheck, onBan }) => {
  return (
    <ReviewDashboardCard
      reviewData={reviewData}
      actions={
        <>
          <ReviewDashboardCard.ActionButton onClick={onCheck}>
            {reviewData.isChecked ? 'Uncheck' : 'Check'}
          </ReviewDashboardCard.ActionButton>
          <ReviewDashboardCard.ActionButton secondary onClick={onBan}>
            {reviewData.isBanned ? 'Unban' : 'Ban'}
          </ReviewDashboardCard.ActionButton>
        </>
      }
    />
  );
};

export default AdminPortalReviewCard;
