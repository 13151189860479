/* eslint-disable camelcase */
import ISdkVersion from 'app/interfaces/addons/ISdkVersion';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from 'app/auth/axiosInstance';
import IError from 'app/interfaces/IError';
import { RootState } from 'app/store';

interface IVersions {
  sdk_versions: ISdkVersion[];
  total: number;
}

interface IVersionsSliceState {
  versions: ISdkVersion[] | null;
}

const initialState: IVersionsSliceState = {
  versions: null,
};

export const getVersionsData = createAsyncThunk<IVersions, undefined, { rejectValue: IError }>(
  'addonsListSection/getVersionsData',
  async (props, { rejectWithValue }) => {
    try {
      const response = await axiosInstance({
        method: 'get',
        url: '/sdk-versions',
      });
      return response.data;
    } catch (err: any) {
      if (err.response) {
        return rejectWithValue({ data: err.response.data, status: err.response.status });
      }

      return rejectWithValue({ data: { message: 'Something went wrong' }, status: 500 });
    }
  }
);

const versionsSlice = createSlice({
  name: 'addonsListSection',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getVersionsData.fulfilled, (state, action) => {
        const { sdk_versions } = action.payload;
        state.versions = sdk_versions;
      })
      .addCase(getVersionsData.rejected, (state, action) => {
        console.log(action.payload);
      });
  },
});

export const selectVersions = ({ addonsListSection }: RootState): ISdkVersion[] | null =>
  addonsListSection.versionsInfo.versions;

export default versionsSlice.reducer;
