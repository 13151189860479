import { logoutUser } from 'app/auth/store/userSlice';
import useAppDispatch from 'app/hooks/useAppDispatch';
import { Location } from 'react-router-dom';

interface IOnLogoutParams {
  dispatch: ReturnType<typeof useAppDispatch>;
  location: Location;
}

const onLogout = ({ dispatch }: IOnLogoutParams): void => {
  const idLogoutUrl = `${process.env.REACT_APP_STORE_ID_URL}/logout?redirect_uri=${process.env.REACT_APP_STORE_HOST_URL}`;

  dispatch(logoutUser({ isProcessing: true }));

  window.location.replace(idLogoutUrl);
};

export default onLogout;
