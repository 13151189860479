import { useState } from 'react';

interface UseMobileDialogReturnValue {
  isFiltersOpen: boolean;
  handleFiltersClick: () => void;
  handleCloseFilters: () => void;

  isSortOpen: boolean;
  handleSortClick: () => void;
  handleCloseSort: () => void;
}

const useMobileDialog = (): UseMobileDialogReturnValue => {
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [isSortOpen, setIsSortOpen] = useState(false);

  const handleFiltersClick = (): void => {
    setIsFiltersOpen((current) => !current);
  };

  const handleCloseFilters = (): void => {
    setIsFiltersOpen(false);
  };

  const handleSortClick = (): void => {
    setIsSortOpen((current) => !current);
  };

  const handleCloseSort = (): void => {
    setIsSortOpen(false);
  };

  return {
    isFiltersOpen,
    isSortOpen,
    handleFiltersClick,
    handleCloseFilters,
    handleSortClick,
    handleCloseSort,
  };
};

export default useMobileDialog;
