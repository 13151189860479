import EULA from 'app/main/sections/LicenseAgreements/EULA/EULA';
import ProviderAgreement from 'app/main/sections/LicenseAgreements/ProviderAgreement/ProviderAgreement';
import TOS from 'app/main/sections/LicenseAgreements/TOS/TOS';

const LicenseAgreementsConfig = [
  {
    path: '/eula',
    element: <EULA />,
  },
  {
    path: '/tos',
    element: <TOS />,
  },
  {
    path: '/provider-agreement',
    element: <ProviderAgreement />,
  },
];

export default LicenseAgreementsConfig;
