import { FC } from 'react';
import clsx from 'clsx';

import AddonsList from 'app/components/AddonsList/AddonsList';

import { IAddonInList } from 'app/interfaces/addons/IAddon';
import IError from 'app/interfaces/IError';

import { BIG_SLIDER_ADDONS_COUNT, SMALL_SLIDER_ADDONS_COUNT } from 'app/configs/appConfig';

interface IRelatedPublisherAddons {
  publisherAddonsData: IAddonInList[];
  errorPublisherAddons: IError | null;
  sliderSize: 'small' | 'big';
}

const PublisherRelatedAddons: FC<IRelatedPublisherAddons> = ({
  publisherAddonsData,
  errorPublisherAddons,
  sliderSize,
}) => {
  const addonsToEnableSlider =
    sliderSize === 'big' ? BIG_SLIDER_ADDONS_COUNT : SMALL_SLIDER_ADDONS_COUNT;
  return (
    <div
      className={clsx('publisher__related-wrapper', {
        'publisher__related-wrapper--slider': publisherAddonsData.length > addonsToEnableSlider,
      })}
    >
      <div className="publisher__related-title">Publisher Add-Ons</div>
      {!errorPublisherAddons ? (
        <div className="publisher__add-ons">
          {publisherAddonsData && (
            <AddonsList
              isLoading={false}
              sliderSize={sliderSize}
              isPublisherPage
              noAddonText="No addons"
            >
              {publisherAddonsData}
            </AddonsList>
          )}
        </div>
      ) : (
        <div>Error while loading data</div>
      )}
    </div>
  );
};

export default PublisherRelatedAddons;
