import './Order.scss';

import { useState, useEffect, FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';

import useAppSelector from 'app/hooks/useAppSelector';
import useAppDispatch from 'app/hooks/useAppDispatch';
import useLoginOnGuests from 'app/hooks/useLoginOnGuests';

import ErrorPage from 'app/main/errors/ErrorPage';
import AddonDialog from 'app/main/sections/Addon/AddonDialog';
import StorefrontHeader from 'app/components/StorefrontHeader/StorefrontHeader';
import OrderBody from 'app/components/OrderBody/OrderBody';
import UnigineButton from 'app/components/UnigineButton/UnigineButton';
import IconRefresh from 'app/assets/icon_refresh.svg';

import {
  getOrder,
  orderErrorsSelector,
  orderSelector,
} from 'app/main/sections/Orders/store/orderSlice';
import {
  getPaymentInfo,
  paymentErrorsSelector,
  paymentSelector,
} from 'app/main/sections/Orders/store/paymentsSlice';

const OrderPaymentStatus: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { orderId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);

  const order = useAppSelector(orderSelector);
  const payment = useAppSelector(paymentSelector);

  const errors = useAppSelector(orderErrorsSelector);
  const paymentErrors = useAppSelector(paymentErrorsSelector);
  const isOrderValid = !!order && order.id === orderId;

  useLoginOnGuests();

  useEffect(() => {
    if (!orderId) {
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    Promise.all([dispatch(getOrder({ orderId })), dispatch(getPaymentInfo({ orderId }))]).finally(
      () => {
        setIsLoading(false);
      }
    );
  }, [dispatch, orderId]);

  if (errors && !isLoading) {
    const { data, status } = errors;
    return <ErrorPage status={status} text={data.message} />;
  }

  if (paymentErrors && !isLoading) {
    const { data, status } = paymentErrors;
    return <ErrorPage status={status} text={data.message} />;
  }

  const paymentStatus = payment?.paymentStatus?.slug;
  const orderStatus = payment?.order?.orderStatus?.slug;

  const handleRefresh = (): void => {
    setIsProcessing(true);
    if (orderId) {
      dispatch(getPaymentInfo({ orderId })).then(() => {
        setIsProcessing(false);
      });
    }
  };

  const handleToOrder = (): void => {
    navigate(`/orders/${orderId}`);
  };

  const displayRefreshButton = paymentStatus === 'pending';
  const displayToOrderButton =
    (paymentStatus === 'canceled' && orderStatus === 'not_paid') ||
    (paymentStatus === 'new' && orderStatus === 'pending_payment') ||
    (paymentStatus === 'new' && orderStatus === 'canceled');

  return (
    <>
      <StorefrontHeader />
      <div className="main order-page">
        {isLoading && (
          <div className="order-page__spinner">
            <CircularProgress color="inherit" />
          </div>
        )}

        {errors && !isLoading && (
          <span className="order-page__error-message">
            Sorry, something went wrong when loading the data.
          </span>
        )}

        {!errors && !isLoading && isOrderValid && (
          <>
            <div className="order-page__header">
              {paymentStatus === 'new' &&
                (orderStatus === 'canceled' ? (
                  <h1 className="order-page__title">Your order #{order.orderNumber} is canceled</h1>
                ) : (
                  <h1 className="order-page__title">
                    Payment for your order #{order.orderNumber} is pending
                  </h1>
                ))}

              {paymentStatus === 'completed' && (
                <h1 className="order-page__title">
                  Your order #{order.orderNumber} has been processed successfully!
                </h1>
              )}

              {paymentStatus === 'canceled' &&
                (orderStatus === 'canceled' ? (
                  <h1 className="order-page__title">Your order #{order.orderNumber} is canceled</h1>
                ) : (
                  <h1 className="order-page__title">
                    Payment for your order #{order.orderNumber} is canceled
                  </h1>
                ))}
            </div>

            <OrderBody order={order} />

            <div className="order-page__action-container">
              {displayRefreshButton && (
                <UnigineButton
                  className="order-page__button order-page__button--refresh"
                  onClick={handleRefresh}
                  disabled={isProcessing}
                >
                  {isProcessing ? (
                    <CircularProgress color="inherit" size={27} />
                  ) : (
                    <img src={IconRefresh} alt="refund" />
                  )}{' '}
                  Refresh Status
                </UnigineButton>
              )}

              {displayToOrderButton && (
                <UnigineButton onClick={handleToOrder}>To Order</UnigineButton>
              )}
            </div>
          </>
        )}

        <AddonDialog hidePurchaseButton />
      </div>
    </>
  );
};

export default OrderPaymentStatus;
