import { Location } from 'react-router-dom';

const getPaginationLink = ({
  location,
  page,
}: {
  location: Location;
  page: number | null;
}): string => {
  const params = new URLSearchParams(location.search);

  if (!page || page <= 1) {
    params.delete('page');
  } else {
    params.set('page', page.toFixed(0));
  }

  const paginationLink = `${location.pathname}?${params.toString()}`;

  return paginationLink;
};

export default getPaginationLink;
