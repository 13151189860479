import './CreateAddonNotification.scss';

import { useState, useEffect, FC, ChangeEvent } from 'react';
import { Dialog, DialogActions } from '@mui/material';

import iconInfo from 'app/assets/icon_info.svg';
import { createAddonNotificationInfo } from 'app/utils/constants/contentConstants';
import UnigineButton from 'app/components/UnigineButton/UnigineButton';
import CheckboxUnigine from 'app/components/CheckboxUnigine/CheckboxUnigine';

const CreateAddonNotification: FC = () => {
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);

  useEffect(() => {
    const addonNotificationData = localStorage.getItem('openCreateAddonNotification');
    // isOpenAgain может быть только null или false
    const isOpenAgain = addonNotificationData === null;
    if (isOpenAgain) {
      setIsNotificationOpen(true);
    }
  }, []);

  const handleClose = (): void => {
    setIsNotificationOpen(false);
  };

  const handleChangeCheckbox = (e: ChangeEvent<HTMLInputElement>): void => {
    if (e.target.checked) {
      localStorage.setItem('openCreateAddonNotification', 'false');
    } else {
      localStorage.removeItem('openCreateAddonNotification');
    }
  };

  return (
    <div>
      <Dialog open={isNotificationOpen} maxWidth="md" onClose={handleClose}>
        <div className="addon-notification__wrapper">
          <div className="addon-notification__content">
            <img src={iconInfo} alt="icon-info" className="addon-notification__icon-info" />
            <div dangerouslySetInnerHTML={{ __html: createAddonNotificationInfo }} />
          </div>
          <DialogActions className="addon-notification__buttons">
            <div className="addon-notification__checkbox">
              <CheckboxUnigine onChange={(e) => handleChangeCheckbox(e)} />
              <p>Don't show again</p>
            </div>
            <UnigineButton className="addon-notification__close-button" onClick={handleClose}>
              Close
            </UnigineButton>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
};

export default CreateAddonNotification;
