import { FC } from 'react';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

import AddonTableItem from 'app/components/AddonTableItem/AddonTableItem';

import './OrderedItem.scss';
import clsx from 'clsx';

interface OrderedItemProps {
  addon: Parameters<typeof AddonTableItem>[0]['addon'];
  price: string;
  discountedPrice?: string;
  isFree?: boolean;
  isRefunded?: boolean;
  showLink?: boolean;
  onClick?: Parameters<typeof AddonTableItem>[0]['onClick'];
}

const OrderedItem: FC<OrderedItemProps> = ({
  addon,
  price,
  discountedPrice,
  isFree,
  isRefunded,
  showLink,
  onClick,
}) => {
  return (
    <AddonTableItem
      addon={addon}
      className={clsx('ordered-item', { 'ordered-item--refunded': isRefunded })}
      onClick={onClick}
      linkTo={!showLink || onClick ? `/add-on/${addon.id}` : undefined}
    >
      <div className="ordered-item__info">
        <AddonTableItem.PriceDisplay
          price={price}
          discountedPrice={discountedPrice}
          isFree={isFree}
          isRefunded={isRefunded}
        />

        {showLink && (
          <Button
            component={Link}
            to={`/add-on/${addon.id}`}
            className="ordered-item__details-link"
          >
            View Full Details
          </Button>
        )}
      </div>
    </AddonTableItem>
  );
};

export default OrderedItem;
