import './Tip.scss';

import { forwardRef, HTMLAttributes } from 'react';
import clsx from 'clsx';

interface ITipProps extends HTMLAttributes<HTMLDivElement> {
  compact?: boolean;
  content?: string;
}

const Tip = forwardRef<HTMLDivElement, ITipProps>(
  ({ content, className, compact, children, ...props }, ref) => (
    <aside
      ref={ref}
      className={clsx('unigine-tip', { 'unigine-tip--compact': compact }, className)}
      {...props}
    >
      {content ? (
        <div className="unigine-tip__text" dangerouslySetInnerHTML={{ __html: content }} />
      ) : (
        <div className="unigine-tip__text">{children}</div>
      )}
    </aside>
  )
);

export default Tip;
