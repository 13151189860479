import './Header.scss';
import { FC } from 'react';

import logo from 'app/assets/unigine-logo.svg';
import addonStoreLogo from 'app/assets/asset-store-logo-experimental.svg';
import NavigationListLoading from 'app/components/NavigationList/NavigationListLoading';

const HeaderLoading: FC = () => {
  return (
    <header className="header">
      <div className="content-container content-container--header">
        <div className="header__top-container">
          <div className="header__logo logo logo__container">
            <div className="logo__link">
              <img src={logo} alt="Unigine logo" className="logo__unigine" />
              <img src={addonStoreLogo} alt="Add-On Store (Experimental)" />
            </div>
          </div>

          <nav className="header__navigation navigation navigation--top">
            <NavigationListLoading />
          </nav>
        </div>
      </div>
    </header>
  );
};

export default HeaderLoading;
