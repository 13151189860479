import './AddonBaseInfoTab.scss';

import { FC, Fragment } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';

import EditSquareIcon from 'app/assets/icon_edit_square.svg';
import useAppSelector from 'app/hooks/useAppSelector';
import { platformNames } from 'app/configs/appConfig';

import { addonSelector } from 'app/main/sections/PublisherPanel/sections/AddonSection/store/addonInfoSlice';
import { addonBaseInfoSelector } from 'app/main/sections/PublisherPanel/sections/AddonSection/store/baseInfoSlice';

import Tip from 'app/components/Tip/Tip';
import { tips } from 'app/utils/constants/contentConstants';

const AddonBaseInfoTab: FC = () => {
  const addonBaseInfo = useAppSelector(addonBaseInfoSelector);
  const addonInfo = useAppSelector(addonSelector);
  const { addonId } = useParams();
  const [searchParams] = useSearchParams();

  if (!addonBaseInfo || !addonInfo) {
    return <>Loading...</>;
  }

  const {
    versionNumber,
    sdkVersions,
    categories,
    description,
    systemRequirements,
    tags,
    products,
    platforms,
    status,
  } = addonBaseInfo;

  const { isBanned } = addonInfo;

  const displayEditBaseInfoButton = status.value === 'Draft' && !isBanned;

  return (
    <>
      <section className="add-on-section border-bottom">
        <div className="add-on-section__info">
          <div className="add-on-section__block">
            <div className="add-on-section__block-name">Version number</div>
            <div className="add-on-section__block-content">{versionNumber}</div>
          </div>

          <div className="add-on-section__block">
            <div className="add-on-section__block-name">Categories</div>
            <div className="add-on-section__block-content">
              {categories &&
                categories.map((categoryData, i, arr) => {
                  return (
                    <Fragment key={categoryData.id}>
                      <Link className="add-on-section__category-link" to={`/${categoryData.slug}`}>
                        {categoryData.name}
                      </Link>
                      <span className="comma-color">{arr.length - 1 === i ? '' : ', '}</span>
                    </Fragment>
                  );
                })}
            </div>
          </div>

          <div className="add-on-section__block">
            <div className="add-on-section__block-name">Required licences</div>
            <div className="add-on-section__block-content">
              {products &&
                products.map((product, i, arr) => {
                  return (
                    <Fragment key={product.id}>
                      {product.name}
                      {arr.length - 1 === i ? '' : <br />}
                    </Fragment>
                  );
                })}
            </div>
          </div>

          <div className="add-on-section__block">
            <div className="add-on-section__block-name">Tags</div>
            <div className="add-on-section__block-content add-on-section__block-content--tag">
              {tags &&
                tags.map((tag) => (
                  <span key={tag.id} className="add-on-section__tag">
                    {tag.value}
                  </span>
                ))}
            </div>
          </div>

          <div className="add-on-section__block">
            <div className="add-on-section__block-name">Description</div>
            <div
              className="add-on-section__block-content add-on-section__block-content--text"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </div>

          <div className="add-on-section__block">
            <div className="add-on-section__block-name">System requirements</div>
            <div
              className="add-on-section__block-content add-on-section__block-content--text"
              dangerouslySetInnerHTML={{ __html: systemRequirements }}
            />
          </div>
        </div>

        <div className="add-on-section__actions">
          {displayEditBaseInfoButton && (
            <Link
              className="add-on-section__action-button add-on-section__action-button--edit-content"
              to={`/publisherPanel/add-ons/${addonId}/base/edit?${searchParams.toString()}`}
            >
              <img src={EditSquareIcon} alt="Edit add-on Base Info" /> Edit Base Info
            </Link>
          )}
        </div>
      </section>
      <section className="add-on-section__packages-block">
        <div>
          <div className="add-on-section__block">
            <div className="add-on-section__block-name">Supported sdk versions</div>
            <div className="add-on-section__block-content">
              {sdkVersions && sdkVersions.length !== 0
                ? sdkVersions.map((ver) => ver.value).join(', ')
                : ''}
            </div>
          </div>
          <div className="add-on-section__block">
            <div className="add-on-section__block-name">Platform</div>
            <div className="add-on-section__block-content">
              {platforms && platforms.length !== 0
                ? platforms.map((platform) => platformNames[platform.value]).join(', ')
                : ''}
            </div>
          </div>
        </div>
        <Tip compact className="add-on-section__base-info-tip" content={tips.packageBaseInfo} />
      </section>
    </>
  );
};

export default AddonBaseInfoTab;
