import { JSX } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import moment from 'moment';
import { IOrderInList } from 'app/interfaces/orders/IOrders';

interface IOrderItem {
  order: IOrderInList;
}

const OrderItem = ({ order }: IOrderItem): JSX.Element => {
  const isOrderDiscounted = order.discount !== '0.00';
  const isOrderFree = order.total === '0.00';
  return (
    <Link to={`/orders/${order.id}`} className="order">
      <strong className={`order__status order__status--${order.orderStatus.slug}`}>
        {order.orderStatus.value}
      </strong>
      <div className="order__info">
        <span className="order__date">
          Order on{' '}
          <time dateTime={order.createDate}>
            {moment(order.createDate).format('DD-MM-YYYY H:mm')}
          </time>
        </span>
        <span className="order__number">#&nbsp;{order.orderNumber}</span>
      </div>
      {isOrderFree ? (
        <div className="order__price-wrapper">
          <span className="order__price order__price--free">Free</span>
        </div>
      ) : (
        <div className="order__price-wrapper">
          {isOrderDiscounted && !isOrderFree && (
            <span className="order__base-price">$&nbsp;{order.price}</span>
          )}
          <span className={clsx('order__price', { 'order__price--sale': isOrderDiscounted })}>
            $&nbsp;{order.total}
          </span>
        </div>
      )}
      <div className="order__action">Open details</div>
    </Link>
  );
};

export default OrderItem;
