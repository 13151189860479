import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from 'app/auth/axiosInstance';
import IError from 'app/interfaces/IError';
import { IPublisherInAddon } from 'app/interfaces/publisher/IPublisher';

interface IStartPublishingResponseData extends Omit<IPublisherInAddon, 'mediaFiles'> {}

export const startPublishing = createAsyncThunk<
  IStartPublishingResponseData,
  undefined,
  { rejectValue: IError }
>('startPublishingSection/startPublishing', async (props, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      method: 'post',
      url: '/publishers',
    });

    return response.data;
  } catch (err: any) {
    if (err.response) {
      return rejectWithValue({ data: err.response.data, status: err.response.status });
    }

    return rejectWithValue({
      data: { message: 'Something went wrong with getting publisher response' },
      status: 500,
    });
  }
});

export const checkPublisher = createAsyncThunk<
  IStartPublishingResponseData,
  undefined,
  { rejectValue: IError }
>('startPublishingSection/checkPublisher', async (props, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      method: 'get',
      url: '/account/publisher',
    });

    return response.data;
  } catch (err: any) {
    if (err.response) {
      return rejectWithValue({ data: err.response.data, status: err.response.status });
    }

    return rejectWithValue({
      data: {
        message: 'Something went wrong to check info about publisher from starting publishing',
      },
      status: 500,
    });
  }
});

const startPublishingSlice = createSlice({
  name: 'startPublishingSection',
  initialState: {},
  reducers: {},
  extraReducers: () => {},
});

export default startPublishingSlice.reducer;
