import './VersionControl.scss';

import { useSearchParams } from 'react-router-dom';

import AddonVersionStatus from 'app/components/AddonVersionStatus/AddonVersionStatus';
import UnigineSelect from 'app/components/UnigineSelect/UnigineSelect';
import { InputLabel } from '@mui/material';
import mergeSearchParam from 'app/utils/helpers/mergeSearchParam';

import { JSX } from 'react';
import IAddonStatus from 'app/interfaces/addons/IAddonStatus';

interface IVersionControl<T> {
  name: string;
  label: string;
  versions: T[];
  addon: { status: IAddonStatus; isBanned: boolean };
}

type ChangeHandler = Parameters<typeof UnigineSelect>[0]['onChange'];

const VersionControl = <T extends { label: string; value: string }>({
  name,
  label,
  versions,
  addon,
}: IVersionControl<T>): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { status, isBanned } = addon;

  const addonVersionStatus = isBanned ? 'banned' : status.value;

  const value = searchParams.get(name) || versions[0].value;
  const handleChange: ChangeHandler = (evt) => {
    setSearchParams(mergeSearchParam(searchParams, name, evt.target.value));
  };

  return (
    <div className="version-control">
      <AddonVersionStatus status={addonVersionStatus} />

      <div className="sort-control">
        <InputLabel id="addon-version-control">{label}</InputLabel>
        <UnigineSelect
          labelId="addon-version-control"
          name="addon-version-control"
          value={value}
          onChange={handleChange}
          options={versions}
          getLabel={(item) => item?.label || ''}
          getValue={(item) => item?.value || ''}
        />
      </div>
    </div>
  );
};

export default VersionControl;
