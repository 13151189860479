import './Stars.scss';

import { FC } from 'react';

import { ReactComponent as WhiteStarEmpty } from 'app/assets/icon_star_white_empty.svg';
import { ReactComponent as GreenStarEmpty } from 'app/assets/icon_star_green_empty.svg';
import { ReactComponent as GreenStarQuarter } from 'app/assets/icon_star_green_quarter.svg';
import { ReactComponent as GreenStarHalf } from 'app/assets/icon_star_green_half.svg';
import { ReactComponent as GreenStarThreeQuarter } from 'app/assets/icon_star_green_three_quarters.svg';
import { ReactComponent as GreenStarFilled } from 'app/assets/icon_star_green_filled.svg';

interface IStarsProps {
  totalRating: number;
  ratingCount?: number | undefined;
}

const Stars: FC<IStarsProps> = ({ ratingCount, totalRating }) => {
  const isZeroRating = Math.round(totalRating) === 0;
  const filledStars = Math.abs(Math.round(totalRating));
  const fractionalPart = Math.abs(totalRating) - filledStars;

  return (
    <div className="stars__rating-container">
      <div className="stars__wrapper">
        {[...Array(5)].map((_, i) => {
          let star = <GreenStarEmpty key={i} />;

          if (i < filledStars) {
            star = <GreenStarFilled key={i} />;
          } else if (i === filledStars && fractionalPart > 0 && fractionalPart <= 0.25) {
            return <GreenStarQuarter key={i} />;
          } else if (i === filledStars && fractionalPart > 0.25 && fractionalPart <= 0.5) {
            return <GreenStarHalf key={i} />;
          } else if (i === filledStars && fractionalPart > 0.5 && fractionalPart <= 0.75) {
            return <GreenStarThreeQuarter key={i} />;
          } else if (isZeroRating) {
            return <WhiteStarEmpty key={i} />;
          }

          return star;
        })}
      </div>

      {ratingCount !== undefined && <span className="number-reviews">({ratingCount})</span>}
    </div>
  );
};

export default Stars;
