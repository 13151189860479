import { FC } from 'react';
import './Tooltip.scss';

import { Tooltip, TooltipProps } from '@mui/material';

const UnigineTooltip: FC<TooltipProps> = ({
  title,
  children,
  placement = 'bottom-end',
  className = '',
}) => (
  <Tooltip
    classes={{
      popperArrow: 'unigine-tooltip',
      tooltipArrow: 'unigine-tooltip__popup',
      arrow: 'unigine-tooltip__popup-arrow',
      tooltip: className,
    }}
    arrow
    placement={placement}
    title={title}
  >
    {children}
  </Tooltip>
);

export default UnigineTooltip;
