import './RefundResult.scss';
import { FC } from 'react';
import useAppSelector from 'app/hooks/useAppSelector';

import StorefrontHeader from 'app/components/StorefrontHeader/StorefrontHeader';
import useLoginOnGuests from 'app/hooks/useLoginOnGuests';
import { refundErrorSelector, refundSelector } from 'app/main/sections/Orders/store/refundSlice';
import RefundConstraints from 'app/main/sections/Orders/RefundResult/RefundConstraints';

const RefundResult: FC = () => {
  const refundRequest = useAppSelector(refundSelector);
  const refundError = useAppSelector(refundErrorSelector);

  useLoginOnGuests();

  // the xsolla transaction id OR internal refund id to show on the error page
  const displayedId = refundRequest?.id || refundError?.transactionId;

  const refundText = RefundConstraints({ apiResult: refundError?.type, requestId: displayedId });
  return (
    <>
      <StorefrontHeader />

      {(refundRequest || refundError) && (
        <div className="main refund-result-page">
          <h1 className="refund-result-title">{refundText.title}</h1>
          <div
            className="refund-result-text"
            dangerouslySetInnerHTML={{ __html: refundText.text }}
          />
        </div>
      )}
    </>
  );
};

export default RefundResult;
