import { FC } from 'react';
import { CircularProgress, IconButton } from '@mui/material';
import { Delete } from '@mui/icons-material';

import AddonTableItem from 'app/components/AddonTableItem/AddonTableItem';
import { cartContentsSelector } from 'app/store/unigine/cartSlice';

import './CartItem.scss';

interface CartItemProps {
  cartItem: ReturnType<typeof cartContentsSelector>[number];

  isRemoving: boolean;
  onRemove: () => void;
}

const CartItem: FC<CartItemProps> = ({ cartItem, isRemoving, onRemove }) => {
  const {
    addonVersion: {
      coverFile,
      categories,
      addon: { id, title, publisher, price, discountedPrice, isFree },
    },
  } = cartItem;

  return (
    <AddonTableItem
      addon={{ id, title, coverFile, categories, publisher }}
      linkTo={`/add-on/${id}`}
      className="cart-table-item"
    >
      <div className="cart-table-item__info">
        <AddonTableItem.PriceDisplay
          price={price}
          discountedPrice={discountedPrice}
          isFree={isFree}
        />

        <IconButton className="cart-table-item__delete-btn" onClick={onRemove}>
          {isRemoving ? (
            <CircularProgress size={24} className="cart-table-item__delete-btn-spinner" />
          ) : (
            <Delete />
          )}
        </IconButton>
      </div>
    </AddonTableItem>
  );
};

export default CartItem;
