import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import useAppSelector from 'app/hooks/useAppSelector';
import usePermissions from 'app/hooks/usePermissions';
import onLogin from 'app/utils/onLogin';
import { userStateSelector } from 'app/auth/store/userSlice';

const useLoginOnGuests = () => {
  const userState = useAppSelector(userStateSelector);
  const { isGuest } = usePermissions();
  const location = useLocation();

  useEffect(() => {
    if (isGuest) {
      onLogin({ STATE: userState, location });
    }
  }, [isGuest, location, userState]);

  return { isGuest };
};

export default useLoginOnGuests;
