import { FC } from 'react';
import clsx from 'clsx';

import AddonTableItem from 'app/components/AddonTableItem/AddonTableItem';
import IOrderAddonVersion from 'app/interfaces/orders/IOrderAddonVersion';

import './AddonOrderItem.scss';
import moment from 'moment';

interface AddonOrderItemProps {
  addon: IOrderAddonVersion;
  userName: string | null;
}

const AddonOrderItem: FC<AddonOrderItemProps> = ({ addon, userName }) => {
  const {
    addonVersion: {
      addon: { title, id, isFree },
      categories,
      coverFile,
    },
    order: {
      orderNumber,
      createDate: orderDate,

      user: { name: ownerName },

      orderStatus: { slug: orderStatus },
    },
    addonTotalPrice: discountedPrice,
    addonPrice: price,
  } = addon;

  const isRefunded = orderStatus === 'refunded';

  return (
    <AddonTableItem
      addon={{ id, title, categories, coverFile }}
      className={clsx('addon-order-item', { 'addon-order-item--refunded': isRefunded })}
      linkTo={`/publisherPanel/add-ons/${id}`}
    >
      <div className="addon-order-item__info">
        <div className="addon-order-item__purchase-details">
          Purchased on {moment(orderDate).format('YYYY-MM-DD')}
          <br />
          by {ownerName === userName ? 'me' : ownerName}
          <p className="addon-order-item__order">Order # {orderNumber}</p>
        </div>

        <AddonTableItem.PriceDisplay
          price={price}
          discountedPrice={discountedPrice}
          isFree={isFree}
          isRefunded={isRefunded}
        />
      </div>
    </AddonTableItem>
  );
};

export default AddonOrderItem;
