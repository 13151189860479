import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';

import IError from 'app/interfaces/IError';

import axiosInstance from 'app/auth/axiosInstance';
import { RootState } from 'app/store';

import { IAddon } from 'app/interfaces/addons/IAddon';
import { IAddonVersion } from 'app/interfaces/addons/IAddonVersion';
import adaptError from 'app/utils/helpers/adaptError';

interface IAddonParams {
  addonId: string;
}

interface IAddonVersionParams {
  addonVersionId: string;
}

interface IGetFreeAddonParams {
  addonId: string;
  versionId: string;
}

interface IGetFreeAddonResponse {
  addonId: string;
  versionId: string;
}

interface IGetAddonVersionDownloadLinkParams {
  addonVersionId: string;
}

interface IGetAddonVersionDownloadLinkResponse {
  addonVersionId: string;
}

interface IAddonDialog {
  openAddonId: string | null;
  info: IAddon | null;
  version: IAddonVersion | null;
  error: IError | null;
}

interface IBaseInfoSliceState {
  info: IAddon | null;
  version: IAddonVersion | null;
  errors: IError | null;
  addonDialog: IAddonDialog;
}

const initialState: IBaseInfoSliceState = {
  info: null,
  version: null,
  errors: null,
  addonDialog: {
    openAddonId: null,
    info: null,
    version: null,
    error: null,
  },
};

export const getAddonBaseInfo = createAsyncThunk<IAddon, IAddonParams, { rejectValue: IError }>(
  'addonSection/getAddonBaseInfo',
  async ({ addonId }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance({
        method: 'get',
        url: `/addons/${addonId}`,
      });

      return response.data;
    } catch (err: any) {
      if (err.response) {
        return rejectWithValue({ data: err.response.data, status: err.response.status });
      }

      return rejectWithValue({ data: { message: 'Something went wrong' }, status: 500 });
    }
  }
);

export const getAddonVersionData = createAsyncThunk<
  IAddonVersion,
  IAddonVersionParams,
  { rejectValue: IError }
>('addonSection/getAddonVersionData', async ({ addonVersionId }, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      method: 'get',
      url: `/addon-versions/${addonVersionId}`,
    });
    return response.data;
  } catch (err: any) {
    return rejectWithValue(adaptError(err));
  }
});

export const getAddonDialogBaseInfo = createAsyncThunk<
  IAddon,
  IAddonParams,
  { rejectValue: IError }
>('addonSection/getAddonDialogBaseInfo', async ({ addonId }, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      method: 'get',
      url: `/addons/${addonId}`,
    });

    return response.data;
  } catch (err: any) {
    if (err.response) {
      return rejectWithValue({ data: err.response.data, status: err.response.status });
    }

    return rejectWithValue({ data: { message: 'Something went wrong' }, status: 500 });
  }
});

export const getAddonDialogVersionData = createAsyncThunk<
  IAddonVersion,
  IAddonVersionParams,
  { rejectValue: IError }
>('addonSection/getAddonDialogVersionData', async ({ addonVersionId }, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      method: 'get',
      url: `/addon-versions/${addonVersionId}`,
    });
    return response.data;
  } catch (err: any) {
    return rejectWithValue(adaptError(err));
  }
});

export const getFreeAddon = createAsyncThunk<IGetFreeAddonResponse, IGetFreeAddonParams>(
  'addonSection/getFreeAddon',
  async ({ addonId, versionId }) => {
    const response = await axiosInstance({
      method: 'post',
      url: `/orders/free`,
      data: {
        addon_version_id: versionId,
      },
    });

    await response.data;
    return { addonId, versionId };
  }
);

export const getAddonVersionDownloadLink = createAsyncThunk<
  IGetAddonVersionDownloadLinkResponse,
  IGetAddonVersionDownloadLinkParams,
  { rejectValue: string }
>('addonSection/getAddonVersionDownloadLink', async ({ addonVersionId }, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      method: 'get',
      url: `/addon-versions/${addonVersionId}/package-download-link`,
    });
    const addonLink = await response.data;
    return addonLink;
  } catch (err: any) {
    if (err.response) {
      return rejectWithValue(err.response?.data.context.message);
    }

    return rejectWithValue('Something went wrong');
  }
});

export const getPackageDownloadLink = createAsyncThunk<
  string,
  { packageId: string },
  { rejectValue: IError }
>('addonSection/getPackageDownloadLink', async ({ packageId }, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      method: 'get',
      url: `/packages/${packageId}/download-link`,
    });

    return response.data;
  } catch (err: any) {
    return rejectWithValue(adaptError(err));
  }
});

const baseInfoSlice = createSlice({
  name: 'addonSection',
  initialState,
  reducers: {
    openAddonDialog: (state, action: PayloadAction<{ id: string }>) => {
      state.addonDialog.openAddonId = action.payload.id;
    },
    closeAddonDialog: (state) => {
      state.addonDialog.openAddonId = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAddonBaseInfo.fulfilled, (state, action) => {
        state.info = action.payload;
        state.errors = null;
      })
      .addCase(getAddonVersionData.fulfilled, (state, action) => {
        state.version = action.payload;
      })
      .addCase(getAddonBaseInfo.rejected, (state, action) => {
        state.info = null;
        state.errors = action.payload ?? { data: { message: 'Something went wrong' }, status: 500 };
      })
      .addCase(getAddonDialogBaseInfo.fulfilled, (state, action) => {
        state.addonDialog.info = action.payload;
        state.addonDialog.error = null;
      })
      .addCase(getAddonDialogVersionData.fulfilled, (state, action) => {
        state.addonDialog.version = action.payload;
      })
      .addCase(getAddonDialogBaseInfo.rejected, (state, action) => {
        state.addonDialog.info = null;
        state.addonDialog.error = action.payload ?? {
          data: { message: 'Something went wrong' },
          status: 500,
        };
      })
      .addCase(getFreeAddon.fulfilled, (state, action) => {
        const { addonId } = action.payload;
        if (state.info?.id === addonId) {
          state.info.ownedStatus = 'purchased';
        }

        if (state.addonDialog.info?.id === addonId) {
          state.addonDialog.info.ownedStatus = 'purchased';
        }
      });
  },
});

export const addonBaseInfoSelector = ({ addonSection }: RootState): IAddon | null =>
  addonSection.addonBaseInfo.info;
export const addonVersionSelector = ({ addonSection }: RootState): IAddonVersion | null =>
  addonSection.addonBaseInfo.version;
export const addonBaseInfoErrorsSelector = ({ addonSection }: RootState): IError | null =>
  addonSection.addonBaseInfo.errors;
export const addonDialogSelector = ({ addonSection }: RootState): IAddonDialog =>
  addonSection.addonBaseInfo.addonDialog;

export const { openAddonDialog, closeAddonDialog } = baseInfoSlice.actions;

export default baseInfoSlice.reducer;
