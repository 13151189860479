import './PackageListDialog.scss';

import { FC } from 'react';
import { Dialog, DialogContent, IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

import { IAddonVersionInAddon } from 'app/interfaces/addons/IAddonVersion';
import { PackageGroupedList } from 'app/components/PackageList/PackageList';

interface PackageListDialogProps {
  canDownload?: boolean;
  versions: IAddonVersionInAddon[];
  open: boolean;
  onClose: () => void;
}

const PackageListDialog: FC<PackageListDialogProps> = ({
  canDownload = false,
  versions,
  open,
  onClose,
}) => {
  return (
    <Dialog maxWidth="lg" className="dialog package-list-dialog" open={open} onClose={onClose}>
      <DialogContent className="dialog__content">
        <h2 className="package-list-dialog__heading">Available packages</h2>

        <div className="package-list-dialog__packages">
          <PackageGroupedList
            canDownload={canDownload}
            groups={versions.map((v) => ({
              label: `Version ${v.versionNumber}`,
              packages: v.packages,
            }))}
          />
        </div>

        <IconButton aria-label="close" className="dialog__close-button" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogContent>
    </Dialog>
  );
};

export default PackageListDialog;
