import { FC } from 'react';
import { Dialog, DialogContent, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface IAddonDialogImage {
  url: string;
  id: string;
  isOpen: boolean;
  handleClose: () => void;
}

const AddonDialogImage: FC<IAddonDialogImage> = ({ url, id, handleClose, isOpen }) => (
  <Dialog open={isOpen} /* fullWidth */ maxWidth="md" onClose={handleClose} className="dialog">
    <DialogContent className="dialog-media__content">
      <img
        className="dialog-media__image"
        src={`${process.env.REACT_APP_STORE_API_URL}/media-files/${id}`}
        alt={url}
      />

      <IconButton aria-label="close" className="dialog__close-button" onClick={handleClose}>
        <CloseIcon />
      </IconButton>
    </DialogContent>
  </Dialog>
);

export default AddonDialogImage;
