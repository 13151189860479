import { FC } from 'react';

const NavigationListLoading: FC = () => {
  return (
    <ul className="navigation__list">
      <li className="navigation__item">
        <div className="navigation__link">about unigine</div>
      </li>

      <li className="navigation__item">
        <div className="navigation__link">docs</div>
      </li>

      <li className="navigation__item">
        <div className="navigation__link">forum</div>
      </li>
    </ul>
  );
};

export default NavigationListLoading;
