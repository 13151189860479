import { useMemo } from 'react';

import useAppSelector from 'app/hooks/useAppSelector';
import { userRolesSelector } from 'app/auth/store/userSlice';
import { permissions } from 'app/utils/constants/permissions';

type PermissionFlags = {
  [Property in keyof typeof permissions]?: boolean;
};

const usePermissions = (): PermissionFlags => {
  const roles = useAppSelector(userRolesSelector);

  return useMemo(() => {
    const userPermissions: PermissionFlags = {};

    // seems to be the only way to get object keys with the right string union type
    const permissionKeys = Object.keys(permissions) as (keyof typeof permissions)[];

    permissionKeys.forEach((key) => {
      userPermissions[key] = permissions[key].some((role) => roles.includes(role));
    });
    return userPermissions;
  }, [roles]);
};

export default usePermissions;
