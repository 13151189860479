import ITag from 'app/interfaces/addons/ITag';

interface IGetTagsCanonicalNames {
  tagsData: ITag[] | null;
  tagsParam: string[];
}
const getTagsCanonicalNames = ({ tagsData, tagsParam }: IGetTagsCanonicalNames): string[] | null =>
  tagsData &&
  tagsData.filter((tag) => tagsParam.includes(tag.value)).map((tag) => tag.canonicalName);

export default getTagsCanonicalNames;
