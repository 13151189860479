import { useLocation } from 'react-router-dom';

import { userSelector, userStateSelector } from 'app/auth/store/userSlice';
import onLogin from 'app/utils/onLogin';
import useAppSelector from 'app/hooks/useAppSelector';
import useAppDispatch from 'app/hooks/useAppDispatch';
import type { RootDispatch } from 'app/store';

type DispatchOrLogin = RootDispatch | ((action: Parameters<RootDispatch>[0]) => void);

const useLoginGuard = (): DispatchOrLogin => {
  const dispatch = useAppDispatch();
  const userState = useAppSelector(userStateSelector);
  const location = useLocation();

  const {
    userData: { id: userId },
  } = useAppSelector(userSelector);

  return (action) => {
    if (!userId) {
      return onLogin({ STATE: userState, location });
    }

    return dispatch(action);
  };
};

export default useLoginGuard;
