import axios from 'axios';
import { createSlice, createAsyncThunk, isAnyOf } from '@reduxjs/toolkit';
import axiosInstance from 'app/auth/axiosInstance';
import { YOUTUBE_KEY } from 'app/configs/appConfig';
import IError from 'app/interfaces/IError';
import { RootState } from 'app/store';

import { ICurrentPublisherDataResponse } from 'app/interfaces/publisher/IPublisher';
import IMediaDialogInfo from 'app/interfaces/publisher/IMediaDialogInfo';
import { IVideoInfo } from 'app/interfaces/addons/IVideoFile';

interface IEditPublisherInfo {
  description?: string;
  name: string;
  contacts: {
    email?: string;
    discord?: string;
    facebook?: string;
    instagram?: string;
    linkedin?: string;
    twitter?: string;
    youtube?: string;
  };
}

interface ICurrentPublisherInfoSliceState {
  currentPublisher: ICurrentPublisherDataResponse | null;
  mediaDialog: IMediaDialogInfo;
  error: IError | null;
}

export const getCurrentPublisherInfo = createAsyncThunk<
  ICurrentPublisherDataResponse,
  undefined,
  { rejectValue: IError }
>('publisherPanelSection/getCurrentPublisherInfo', async (props, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      method: 'get',
      url: '/publisher-panel/publishers/current',
    });

    return response.data;
  } catch (err: any) {
    if (err.response) {
      return rejectWithValue({ data: err.response.data, status: err.response.status });
    }

    return rejectWithValue({
      data: { message: 'Something went wrong to get publisher info' },
      status: 500,
    });
  }
});

export const editCurrentPublisherInfo = createAsyncThunk<
  ICurrentPublisherDataResponse,
  IEditPublisherInfo,
  { rejectValue: IError }
>('publisherPanelSection/editCurrentPublisherInfo', async (dataToUpdate, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      method: 'put',
      url: '/publisher-panel/publishers/current',
      data: dataToUpdate,
    });

    return response.data;
  } catch (err: any) {
    if (err.response) {
      return rejectWithValue({ data: err.response.data, status: err.response.status });
    }

    return rejectWithValue({
      data: { message: 'Something went wrong to get edit info' },
      status: 500,
    });
  }
});

export const getVideoInfoById = createAsyncThunk<IVideoInfo, string>(
  'publisherPanelSection/getVideoInfoById',
  async (videoId) => {
    const response = await axios({
      method: 'get',
      url: `https://www.googleapis.com/youtube/v3/videos`,
      params: {
        id: videoId,
        part: 'snippet',
        key: YOUTUBE_KEY,
      },
      headers: { Authorization: '' },
    });

    const { items } = await response.data;

    const { snippet } = items[0];

    const { description, channelId, channelTitle, title } = snippet;

    return {
      id: videoId,
      videoId,
      description,
      channelId,
      channelTitle,
      title,
    };
  }
);

export const addVideoFile = createAsyncThunk<
  ICurrentPublisherDataResponse,
  { videoFile: IVideoInfo },
  { rejectValue: IError }
>('publisherPanelSection/addVideoFile', async ({ videoFile }, { rejectWithValue }) => {
  try {
    const videoDataToSend = { data: videoFile };
    const response = await axiosInstance({
      method: 'post',
      url: `/publisher-panel/publishers/current/videos`,
      data: videoDataToSend,
    });

    return response.data;
  } catch (err: any) {
    if (err.response) {
      return rejectWithValue({ data: err.response.data, status: err.response.status });
    }

    return rejectWithValue({
      data: { message: 'Something went wrong to add videoFile' },
      status: 500,
    });
  }
});

export const removeVideoFile = createAsyncThunk<
  ICurrentPublisherDataResponse,
  string,
  { rejectValue: IError }
>('publisherPanelSection/removeVideoFile', async (videoId, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      method: 'delete',
      url: `/publisher-panel/publishers/current/videos/${videoId}`,
    });

    return response.data;
  } catch (err: any) {
    if (err.response) {
      return rejectWithValue({ data: err.response.data, status: err.response.status });
    }

    return rejectWithValue({
      data: { message: 'Something went wrong to remove videoFile' },
      status: 500,
    });
  }
});

export const addPictureFile = createAsyncThunk<
  ICurrentPublisherDataResponse,
  { images: File[] },
  { rejectValue: IError }
>('publisherPanelSection/addPictureFile', async ({ images }, { rejectWithValue }) => {
  try {
    const promises: Promise<any>[] = [];
    // eslint-disable-next-line array-callback-return
    images.map((file: File) => {
      const formData = new FormData();
      formData.append('file', file);
      const promiseResult = axiosInstance({
        method: 'post',
        url: '/publisher-panel/publishers/current/pictures',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      });
      promises.push(promiseResult);
    });
    const results = await Promise.all(promises);
    const sortedResults = results.sort(
      (a, b) => b.data.pictureFiles.length - a.data.pictureFiles.length
    );
    return sortedResults[0].data;
  } catch (err: any) {
    if (err.response) {
      return rejectWithValue({ data: err.response.data, status: err.response.status });
    }

    return rejectWithValue({
      data: { message: 'Something went wrong to add picture files' },
      status: 500,
    });
  }
});

export const removePictureFile = createAsyncThunk<
  ICurrentPublisherDataResponse,
  string,
  { rejectValue: IError }
>('publisherPanelSection/removePictureFile', async (imageId, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      method: 'delete',
      url: `/publisher-panel/publishers/current/pictures/${imageId}`,
    });

    return response.data;
  } catch (err: any) {
    if (err.response) {
      return rejectWithValue({ data: err.response.data, status: err.response.status });
    }

    return rejectWithValue({
      data: { message: 'Something went wrong to remove picture files' },
      status: 500,
    });
  }
});

export const addAvatarFile = createAsyncThunk<
  ICurrentPublisherDataResponse,
  File,
  { rejectValue: IError }
>('publisherPanelSection/addAvatarFile', async (file, { rejectWithValue }) => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    const response = await axiosInstance({
      method: 'post',
      url: '/publisher-panel/publishers/current/avatar',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
    });

    return response.data;
  } catch (err: any) {
    if (err.response) {
      return rejectWithValue({ data: err.response.data, status: err.response.status });
    }

    return rejectWithValue({
      data: { message: 'Something went wrong to add avatar file' },
      status: 500,
    });
  }
});

const initialState: ICurrentPublisherInfoSliceState = {
  currentPublisher: null,
  mediaDialog: {
    isOpen: false,
    media: null,
    isFormImage: false,
    isFormVideo: false,
  },
  error: null,
};

const currentPublisherInfoSlice = createSlice({
  name: 'publisherPanelSection',
  initialState,
  reducers: {
    openMediaDialog: (state, action) => {
      state.mediaDialog.isOpen = true;
      state.mediaDialog.media = action.payload.media;
      state.mediaDialog.isFormImage = action.payload.isFormImage;
      state.mediaDialog.isFormVideo = action.payload.isFormVideo;
    },
    closeMediaDialog: (state) => {
      state.mediaDialog.isOpen = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        isAnyOf(
          getCurrentPublisherInfo.fulfilled,
          editCurrentPublisherInfo.fulfilled,
          addPictureFile.fulfilled,
          removePictureFile.fulfilled,
          removeVideoFile.fulfilled,
          addVideoFile.fulfilled,
          addAvatarFile.fulfilled
        ),
        (state, action) => {
          state.currentPublisher = action.payload;
          state.error = null;
        }
      )
      .addMatcher(
        isAnyOf(
          getCurrentPublisherInfo.rejected,
          editCurrentPublisherInfo.rejected,
          addPictureFile.rejected,
          removePictureFile.rejected,
          removeVideoFile.rejected,
          addVideoFile.rejected,
          addAvatarFile.rejected
        ),
        (state, action) => {
          state.currentPublisher = null;
          if (action.payload) {
            state.error = action.payload;
          }
        }
      );
  },
});

export const { openMediaDialog, closeMediaDialog } = currentPublisherInfoSlice.actions;

export const AddonMediaDialogSelector = ({ publisherPanelSection }: RootState): IMediaDialogInfo =>
  publisherPanelSection.publisherInfo.mediaDialog;

export const currentPublisherInfoSelector = ({
  publisherPanelSection,
}: RootState): ICurrentPublisherDataResponse | null =>
  publisherPanelSection.publisherInfo.currentPublisher;

export const currentPublisherInfoErrorSelector = ({
  publisherPanelSection,
}: RootState): IError | null => publisherPanelSection.publisherInfo.error;

export default currentPublisherInfoSlice.reducer;
