import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from 'app/auth/axiosInstance';
import IError from 'app/interfaces/IError';
import {
  IPublishedAddonEditGeneralInfo,
  IPublishedAddonGeneralInfo,
} from 'app/interfaces/addons/IAddon';
import { RootState } from 'app/store';

interface IPublishedAddonSliceState {
  addonInfo: IPublishedAddonGeneralInfo | null;
  error: IError | null;
}

interface IGetAddonInfoParams {
  addonId: string;
}

interface IEditAddonInfoParams extends IGetAddonInfoParams {
  addonData: IPublishedAddonEditGeneralInfo;
}

interface ItoggleAddonArchiveStatusParams extends IGetAddonInfoParams {
  isArchived: boolean;
}

export const getAddonInfo = createAsyncThunk<
  IPublishedAddonGeneralInfo,
  IGetAddonInfoParams,
  { rejectValue: IError }
>('publisherPanelSection/getAddonInfo', async ({ addonId }, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      method: 'get',
      url: `/publisher-panel/addons/${addonId}`,
    });

    const addonData = await response.data;

    return addonData;
  } catch (err: any) {
    if (err.response) {
      return rejectWithValue({ data: err.response.data, status: err.response.status });
    }

    return rejectWithValue({
      data: { message: 'Something went wrong to get addon info' },
      status: 500,
    });
  }
});

export const editAddonInfo = createAsyncThunk<
  IPublishedAddonGeneralInfo,
  IEditAddonInfoParams,
  { rejectValue: IError }
>('publisherPanelSection/editAddonInfo', async ({ addonId, addonData }, { rejectWithValue }) => {
  const { title, price, isFree, discounts } = addonData;
  const apiData = {
    title,
    price,
    is_free_addon: isFree,
    discounts: discounts.map(({ percentage, from, till, quantity }) => ({
      discount: percentage,
      start_date: from,
      end_date: till,
      addons_for_sale: typeof quantity === 'number' ? quantity : null,
    })),
  };

  try {
    const { data } = await axiosInstance({
      method: 'put',
      url: `/publisher-panel/addons/${addonId}`,
      data: apiData,
    });

    return data;
  } catch (err: any) {
    if (err.response) {
      return rejectWithValue({ data: err.response.data, status: err.response.status });
    }

    return rejectWithValue({
      data: { message: 'Something went wrong to edit addon info' },
      status: 500,
    });
  }
});

export const toggleAddonArchiveStatus = createAsyncThunk<
  IPublishedAddonGeneralInfo,
  ItoggleAddonArchiveStatusParams,
  { rejectValue: IError }
>(
  'publisherPanelSection/toggleAddonArchiveStatus',
  async ({ addonId, isArchived }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance({
        method: 'put',
        url: `/publisher-panel/addons/${addonId}`,
        data: {
          is_archived: isArchived,
        },
      });

      const addonData = await response.data;

      return addonData;
    } catch (err: any) {
      if (err.response) {
        return rejectWithValue({ data: err.response.data, status: err.response.status });
      }

      return rejectWithValue({
        data: { message: 'Something went wrong to archive addon' },
        status: 500,
      });
    }
  }
);

export const getPublisherAddonPackageDownloadLink = createAsyncThunk<
  string,
  { addonVersionId: string },
  { rejectValue: IError }
>('addonSection/getAddonVersionDownloadLink', async ({ addonVersionId }, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      method: 'get',
      url: `/publisher-panel/addon-versions/${addonVersionId}/package-download-link`,
    });
    const addonPackageLink = await response.data;
    return addonPackageLink;
  } catch (err: any) {
    if (err.response) {
      return rejectWithValue({ data: err.response.data, status: err.response.status });
    }

    return rejectWithValue({
      data: { message: 'Something went wrong to get publisher download link' },
      status: 500,
    });
  }
});

export const getPublisherPackageDownloadLink = createAsyncThunk<
  string,
  { packageId: string },
  { rejectValue: IError }
>('addonSection/getPublisherPackageDownloadLink', async ({ packageId }, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      method: 'get',
      url: `/publisher-panel/packages/${packageId}/download-link`,
    });
    const addonPackageLink = await response.data;
    return addonPackageLink;
  } catch (err: any) {
    if (err.response) {
      return rejectWithValue({ data: err.response.data, status: err.response.status });
    }

    return rejectWithValue({
      data: { message: 'Something went wrong to get package download link' },
      status: 500,
    });
  }
});

const initialState: IPublishedAddonSliceState = {
  addonInfo: null,
  error: null,
};

const addonSlice = createSlice({
  name: 'publisherPanelSection',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAddonInfo.fulfilled, (state, action) => {
        state.addonInfo = action.payload;
        state.error = null;
      })
      .addCase(getAddonInfo.rejected, (state, action) => {
        state.addonInfo = null;
        if (action.payload) {
          state.error = action.payload || 'Something went wrong.';
        }
      })
      .addCase(editAddonInfo.fulfilled, (state, action) => {
        if (state.addonInfo && action.payload) {
          state.addonInfo.title = action.payload.title;
          state.addonInfo.price = action.payload.price;
        }
      })
      .addCase(toggleAddonArchiveStatus.fulfilled, (state, action) => {
        state.addonInfo = action.payload;
        state.error = null;
      })
      .addCase(toggleAddonArchiveStatus.rejected, (state, action) => {
        state.addonInfo = null;
        if (action.payload) {
          state.error = action.payload;
        }
      });
  },
});

export const addonSelector = ({
  publisherPanelSection,
}: RootState): IPublishedAddonGeneralInfo | null => publisherPanelSection.addon.addonInfo;

export const addonErrorSelector = ({ publisherPanelSection }: RootState): IError | null =>
  publisherPanelSection.addon.error;

export default addonSlice.reducer;
