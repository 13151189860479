import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import IAddonVersionStatus from 'app/interfaces/addons/IAddonVersionStatus';
import IError from 'app/interfaces/IError';

import axiosInstance from 'app/auth/axiosInstance';
import { RootState } from 'app/store';

interface IAddonVersionStatusSliceState {
  statuses: IAddonVersionStatus[] | null;
}

interface IAddonVersionStatuses {
  statuses: IAddonVersionStatus[] | null;
  total: number;
}

const initialState: IAddonVersionStatusSliceState = {
  statuses: null,
};

export const getAddonVersionStatusData = createAsyncThunk<
  IAddonVersionStatuses,
  undefined,
  { rejectValue: IError }
>('addonsListSection/getAddonVersionStatusData', async (props, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      method: 'get',
      url: '/addon-version-statuses',
    });
    return response.data;
  } catch (err: any) {
    if (err.response) {
      return rejectWithValue({ data: err.response.data, status: err.response.status });
    }

    return rejectWithValue({ data: { message: 'Something went wrong' }, status: 500 });
  }
});

const addonVersionStatusSlice = createSlice({
  name: 'addonsListSection',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAddonVersionStatusData.fulfilled, (state, action) => {
        const { statuses } = action.payload;
        state.statuses = statuses;
      })
      .addCase(getAddonVersionStatusData.rejected, (state, action) => {
        console.log(action.payload);
      });
  },
});

export const selectAddonVersionStatus = ({
  addonsListSection,
}: RootState): IAddonVersionStatus[] | null => addonsListSection.addonVersionStatusInfo.statuses;

export default addonVersionStatusSlice.reducer;
