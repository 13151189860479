export const globalRoles = {
  noRole: 'NO_ROLE',
  guest: 'GUEST',

  user: 'ROLE_USER',
  userWithoutSdk: 'ROLE_USER_WITHOUT_SDK',

  addonManager: 'ROLE_STORE_MANAGER',
  addonSupport: 'ROLE_STORE_SUPPORT',
  addonModerator: 'ROLE_STORE_MODERATOR',
  addonFuture: 'ROLE_ASSET_FUTURE', // future feature

  developerSupport: 'ROLE_DEVELOPER_SUPPORT',

  companyAdmin: 'ROLE_COMPANY_ADMIN',
  addonStoreAdmin: 'ROLE_ADMIN_STORE',

  superAdmin: 'ROLE_SUPER_ADMIN',
} as const;

export const permissions = {
  hideStartPublishingButton: [globalRoles.addonManager],
  displayPublisherPanelMenuItem: [globalRoles.addonManager],
  displayAdminPortalMenuItem: [globalRoles.addonStoreAdmin],
  displayCartButton: [globalRoles.user],

  isNoRole: [globalRoles.noRole],
  isGuest: [globalRoles.guest],
  isAddonManager: [globalRoles.addonManager],
  isCompanyAdmin: [globalRoles.companyAdmin],

  notPublisherPanelUser: [globalRoles.guest, globalRoles.user, globalRoles.userWithoutSdk],
  publisherPanelUser: [
    globalRoles.addonManager,
    globalRoles.addonSupport,
    globalRoles.addonModerator,
  ],
  publisherPanelUserAdmin: [globalRoles.companyAdmin],
  notAdminPortalUser: [globalRoles.guest, globalRoles.user, globalRoles.userWithoutSdk],
  adminPortalUser: [
    globalRoles.addonStoreAdmin,
    globalRoles.addonSupport,
    globalRoles.addonModerator,
  ],
};
