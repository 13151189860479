import './Category.scss';

import { FC, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { CircularProgress } from '@mui/material';

import ErrorPage from 'app/main/errors/ErrorPage';
import AddonsList from 'app/components/AddonsList/AddonsList';
import StorefrontHeader from 'app/components/StorefrontHeader/StorefrontHeader';
import FilterForm from 'app/components/FilterForm/FilterForm';
import SortControl from 'app/components/SortControl/SortControl';
import MobileDialog from 'app/components/MobileDialog/MobileDialog';
import MobileButtons from 'app/components/MobileButtons/MobileButtons';
import UniginePagination from 'app/components/UniginePagination/UniginePagination';

import AddonDialog from 'app/main/sections/Addon/AddonDialog';

import getConcreteCategories from 'app/utils/helpers/getConcreteCategories';
import getCategoryTitle from 'app/utils/helpers/getCategoryTitle';
import getVersionsIds from 'app/utils/helpers/getVersionsIds';
import getProductsIds from 'app/utils/helpers/getProductsIds';
import getCategoriesIds from 'app/utils/helpers/getCategoriesIds';
import getTagsCanonicalNames from 'app/utils/helpers/getTagsCanonicalNames';
import getPlatformIds from 'app/utils/helpers/getPlatformIds';
import getSourceLanguageIds from 'app/utils/helpers/getSourceLanguageIds';
import useMobileDialog from 'app/hooks/useMobileDialog';

import useAppDispatch from 'app/hooks/useAppDispatch';
import useAppSelector from 'app/hooks/useAppSelector';

import {
  addonsListErrorsSelector,
  getAddonsVersionsListData,
  selectAddonsVersions,
  totalPagesSelector,
} from 'app/main/sections/Addons/store/addonsListSlice';
import { categoriesSelector } from 'app/store/unigine/categoriesSlice';
import { getVersionsData, selectVersions } from 'app/main/sections/Addons/store/versionsSlice';
import { getProductsData, selectProducts } from 'app/main/sections/Addons/store/productsSlice';
import {
  getTagsData,
  getTopTagsData,
  selectTags,
  selectTopTags,
} from 'app/main/sections/Addons/store/tagsSlice';
import { getPlatformData, selectPlatform } from 'app/main/sections/Addons/store/platformSlice';
import {
  getSourceLanguageData,
  selectSourceLanguages,
} from 'app/main/sections/Addons/store/sourceLanguagesSlice';

import ISdkVersion from 'app/interfaces/addons/ISdkVersion';
import IProduct from 'app/interfaces/addons/IProduct';
import IPlatform from 'app/interfaces/addons/IPlatform';
import IAddonSourceCode from 'app/interfaces/addons/IAddonSourceCode';
import ITag from 'app/interfaces/addons/ITag';
import ICategory from 'app/interfaces/addons/ICategory';

import { addonsPerPage } from 'app/configs/appConfig';

const Search: FC = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingDictionaries, setIsLoadingDictionaries] = useState(true);
  const {
    isFiltersOpen,
    isSortOpen,
    handleCloseFilters,
    handleCloseSort,
    handleFiltersClick,
    handleSortClick,
  } = useMobileDialog();

  const categories = useAppSelector(categoriesSelector);
  const addonVersionsData = useAppSelector(selectAddonsVersions);
  const totalPages = useAppSelector(totalPagesSelector);
  const errors = useAppSelector(addonsListErrorsSelector);
  const searchText = searchParams.get('q') || '';
  const versionsData = useAppSelector(selectVersions);
  const productsData = useAppSelector(selectProducts);
  const tagsData = useAppSelector(selectTags);
  const topTagsData = useAppSelector(selectTopTags);
  const platformData = useAppSelector(selectPlatform);
  const sourceLanguagesData = useAppSelector(selectSourceLanguages);

  const categoriesData = useMemo(() => getConcreteCategories(categories), [categories]);

  const [page, sort, direction, perPage] = [
    searchParams.get('page'),
    searchParams.get('sort'),
    searchParams.get('direction'),
    searchParams.get('perPage'),
  ];
  const currentPage = parseInt(page || '1', 10);
  const currentAddonsPerPage = perPage ? parseInt(perPage, 10) : addonsPerPage;

  const title = useMemo(() => getCategoryTitle({ pageName: 'search', searchText }), [searchText]);

  useEffect(() => {
    Promise.all([
      dispatch(getVersionsData()),
      dispatch(getProductsData()),
      dispatch(getTagsData()),
      dispatch(getTopTagsData()),
      dispatch(getPlatformData()),
      dispatch(getSourceLanguageData()),
    ]).then(() => {
      setIsLoadingDictionaries(false);
    });
  }, [dispatch]);

  useEffect(() => {
    if (!isLoadingDictionaries) {
      setIsLoading(true);

      const versionsParam = searchParams.getAll('versions[]');
      const productsParam = searchParams.getAll('products[]');
      const categoriesParam = searchParams.getAll('categories[]');
      const tagsParam = searchParams.getAll('tags[]');
      const platformParam = searchParams.getAll('platform[]');
      const sourceLanguageParam = searchParams.getAll('source[]');

      const price = {
        mode: searchParams.get('is_free'),
        min: searchParams.get('start_price'),
        max: searchParams.get('end_price'),
      };

      const rating = searchParams.get('rating');
      const versionsIds = getVersionsIds({ versionsData, versionsParam });
      const productsIds = getProductsIds({ productsData, productsParam });
      const categoriesIds = getCategoriesIds({ categoriesData, categoriesParam });
      const tagsCanonicalNames = getTagsCanonicalNames({ tagsData, tagsParam });
      const platformIds = getPlatformIds({ platformData, platformParam });
      const sourceLanguageIds = getSourceLanguageIds({ sourceLanguagesData, sourceLanguageParam });

      dispatch(
        getAddonsVersionsListData({
          perPage: currentAddonsPerPage,
          page: currentPage,
          categories: categoriesIds,
          products: productsIds,
          versions: versionsIds,
          tags: tagsCanonicalNames,
          search: searchText,
          platform: platformIds,
          sourceLanguages: sourceLanguageIds,
          price,
          rating,
          sort,
          direction,
        })
      ).then(() => setIsLoading(false));
    }
  }, [
    categoriesData,
    dispatch,
    isLoadingDictionaries,
    searchParams,
    productsData,
    versionsData,
    tagsData,
    platformData,
    sourceLanguagesData,
    currentPage,
    searchText,
    sort,
    direction,
    currentAddonsPerPage,
  ]);

  if (errors) {
    const { data, status } = errors;
    return <ErrorPage status={status} text={data.message} />;
  }

  return (
    <>
      <StorefrontHeader isPinned />

      <div className="main category main--filters">
        <MobileButtons handleFiltersClick={handleFiltersClick} handleSortClick={handleSortClick} />

        <div className="category__wrapper columns">
          <div className="category__column category__column--main columns__column--content">
            <div className="category__title-wrapper">
              <h1 className="category__title">{title}</h1>
              <SortControl name="sort" options={[{ value: 'title', label: 'Title' }]} />
            </div>
            {isLoading && (
              <div className="add-on-list__loading-container">
                <CircularProgress color="inherit" className="add-on-list__loading" />
              </div>
            )}

            {errors && !isLoading && (
              <span className="orders__error">
                Sorry, something went wrong when loading the data.
              </span>
            )}

            {!errors && !isLoading && addonVersionsData && (
              <AddonsList isLoading={isLoading} noAddonText="No add-ons">
                {addonVersionsData}
              </AddonsList>
            )}

            {!errors && !isLoading && addonVersionsData && addonVersionsData.length !== 0 && (
              <div className="pagination__container">
                <UniginePagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  defaultValue={addonsPerPage}
                />
              </div>
            )}
          </div>

          <div className="category__column category__column--side columns__column--side">
            <FilterForm
              search={null}
              filterParams={[
                {
                  name: 'categories',
                  title: 'Categories',
                  type: 'checkbox',
                  options: categoriesData || [],
                  getLabel: (item: ICategory) => item.name,
                  getValue: (item: ICategory) => item.slug,
                  collapsed: false,
                },
                {
                  name: 'versions',
                  title: 'Supported versions',
                  type: 'checkbox',
                  options: versionsData || [],
                  getLabel: (item: ISdkVersion) => item.value,
                  getValue: (item: ISdkVersion) => item.slug,
                  collapsed: false,
                },
                {
                  name: 'products',
                  title: 'Products',
                  type: 'checkbox',
                  options: productsData || [],
                  getLabel: (item: IProduct) => item.name,
                  getValue: (item: IProduct) => item.slug,
                  collapsed: false,
                },
                {
                  name: 'platform',
                  title: 'Platform',
                  type: 'checkbox',
                  options: platformData || [],
                  getLabel: (item: IPlatform) => item.value,
                  getValue: (item: IPlatform) => item.id,
                  collapsed: false,
                },
                {
                  name: 'source',
                  title: 'Source code',
                  type: 'checkbox',
                  options: sourceLanguagesData || [],
                  getLabel: (item: IAddonSourceCode) => item.value,
                  getValue: (item: IAddonSourceCode) => item.slug,
                  collapsed: false,
                },
                {
                  name: 'tags',
                  title: 'Tags',
                  type: 'tags',
                  options: topTagsData || [],
                  getLabel: (item: ITag) => item.value,
                  getValue: (item: ITag) => item.value,
                },
              ]}
              rating
            />
          </div>
        </div>

        <AddonDialog />

        <MobileDialog isOpen={isFiltersOpen} handleClose={handleCloseFilters} title="Filters">
          <FilterForm
            filterParams={[
              {
                name: 'categories',
                title: 'Categories',
                type: 'checkbox',
                options: categoriesData || [],
                getLabel: (item) => item.name,
                getValue: (item) => item.slug,
                collapsed: false,
              },
              {
                name: 'versions',
                title: 'Supported versions',
                type: 'checkbox',
                options: versionsData || [],
                getLabel: (item) => item.value,
                getValue: (item) => item.slug,
                collapsed: false,
              },
              {
                name: 'products',
                title: 'Products',
                type: 'checkbox',
                options: productsData || [],
                getLabel: (item) => item.name,
                getValue: (item) => item.slug,
                collapsed: false,
              },
              {
                name: 'platform',
                title: 'Platform',
                type: 'checkbox',
                options: platformData || [],
                getLabel: (item) => item.value,
                getValue: (item) => item.id,
                collapsed: false,
              },
              {
                name: 'source',
                title: 'Source code',
                type: 'checkbox',
                options: sourceLanguagesData || [],
                getLabel: (item) => item.value,
                getValue: (item) => item.slug,
                collapsed: false,
              },
              {
                name: 'tags',
                title: 'Tags',
                type: 'tags',
                options: topTagsData || [],
                getLabel: (item) => item.value,
                getValue: (item) => item.value,
              },
            ]}
            rating
          />
        </MobileDialog>

        <MobileDialog isOpen={isSortOpen} handleClose={handleCloseSort} title="Sort By">
          <SortControl name="sort" options={[{ value: 'title', label: 'Title' }]} />
        </MobileDialog>
      </div>
    </>
  );
};

export default Search;
