import { Location } from 'react-router-dom';

interface IOnLogin {
  STATE: string;
  location: Location;
}

const onLogin = ({ STATE, location }: IOnLogin): void => {
  const REDIRECT_URI = `${process.env.REACT_APP_STORE_HOST_URL}/sso`;
  const ID_URL = `${process.env.REACT_APP_STORE_ID_URL}`;

  if (location) {
    const backUrl = location.pathname + location.search;
    window.localStorage.setItem('backUrl', backUrl);
  } else {
    window.localStorage.removeItem('backUrl');
  }

  const idLoginURL = `${ID_URL}/oauth/authorize?state=${STATE}&scope=user%20oauth&response_type=code&approval_prompt=auto&redirect_uri=${REDIRECT_URI}&client_id=store`;

  window.location.replace(idLoginURL);
};

export default onLogin;
