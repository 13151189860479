import { Navigate } from 'react-router-dom';

import Addon from 'app/main/sections/Addon/Addon';
import AddonReviews from 'app/main/sections/Addon/AddonReviews';
import AddonMainPage from 'app/main/sections/Addon/AddonMainPage';

const AddonConfig = [
  {
    path: 'add-on/:addonId',
    element: <Navigate replace to="description" />,
  },
  {
    path: 'add-on/:addonId',
    element: <Addon />,
    children: [
      {
        path: 'reviews',
        element: <AddonReviews />,
      },
      {
        path: ':tabId',
        element: <AddonMainPage />,
      },
    ],
  },
];

export default AddonConfig;
