import { FC } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { FormControl } from '@mui/base';
import { InputLabel, Tab, Tabs } from '@mui/material';

import AddonBaseInfo from 'app/main/sections/Addon/AddonBaseInfo';
import MediaViewer from 'app/main/sections/Addon/AddonMediaViewer';
import DescriptionTab from 'app/main/sections/Addon/tabs/AddonDescription';
import PackagesTab from 'app/main/sections/Addon/tabs/AddonPackages';
import UnigineSelect from 'app/components/UnigineSelect/UnigineSelect';

import useAppSelector from 'app/hooks/useAppSelector';
import {
  addonBaseInfoSelector,
  addonVersionSelector,
} from 'app/main/sections/Addon/store/baseInfoSlice';
import combineMedia from 'app/utils/helpers/combineMedia';
import mergeSearchParam from 'app/utils/helpers/mergeSearchParam';

export const tabs = [
  { id: 'description', title: 'Base Info', component: DescriptionTab },
  { id: 'packages', title: 'Packages', component: PackagesTab },
  // { id: 'reviews', title: 'Reviews', component: <ReviewsTab /> },
  // { id: 'versions', title: 'Versions', component: <VersionsTab /> },
  // { id: 'publisher-info', title: 'Publisher info', component: <PublisherInfoTab /> },
];

const AddonMainPage: FC = () => {
  const { tabId } = useParams();
  const [_, setSearchParams] = useSearchParams();

  const addon = useAppSelector(addonBaseInfoSelector);
  const loadedVersion = useAppSelector(addonVersionSelector);

  const isVersionValid = addon?.publishedAndRevisionAddonVersions.find(
    (version) => version.id === loadedVersion?.id
  );
  const displayedVersion = isVersionValid ? loadedVersion : null;

  const media = combineMedia({
    pictureFiles: displayedVersion?.pictureFiles || [],
    videoFiles: displayedVersion?.videoFiles || [],
  });

  const TabComponent = tabs.find((tab) => tab.id === tabId)?.component;

  const handleVersionChange = (versionId: string): void => {
    setSearchParams((params) => mergeSearchParam(params, 'version', versionId));
  };

  return (
    <div className="main add-on-page">
      <article className="add-on-page__add-on add-on">
        <div className="add-on__main-content add-on__main-content--add-on-page">
          <section className="add-on__version-section">
            <FormControl className="add-on__version-select">
              <InputLabel>Displayed version:</InputLabel>
              <UnigineSelect
                name="version"
                options={addon?.publishedAndRevisionAddonVersions || []}
                value={displayedVersion?.id || ''}
                onChange={(evt) => {
                  handleVersionChange(evt.target.value);
                }}
                getLabel={(v) => v.versionNumber}
                getValue={(v) => v.id}
              />
            </FormControl>

            <section className="add-on__media">
              <MediaViewer media={media} key={displayedVersion?.id} />
            </section>

            <div className="add-on-tabs">
              <Tabs
                value={tabId}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                TabIndicatorProps={{}}
                className="add-on-tabs__tabs"
              >
                {tabs.map(({ id, title }) => (
                  <Tab
                    key={id}
                    className="add-on-tabs__tab"
                    value={id}
                    component={Link}
                    role="button"
                    // set default get params for reviews tab
                    to={`/add-on/${addon?.id}/${id}?version=${displayedVersion?.id}`}
                    label={title}
                    disableRipple
                  />
                ))}
              </Tabs>

              <div className="add-on-tabs__content-container">
                {TabComponent && displayedVersion && (
                  <TabComponent addonVersion={displayedVersion} />
                )}
              </div>
            </div>
          </section>

          <section className="add-on__base-info">
            <AddonBaseInfo addon={addon} addonVersion={displayedVersion} />
          </section>
        </div>
      </article>
    </div>
  );
};

export default AddonMainPage;
