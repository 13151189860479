import { FC } from 'react';

import AddonTableItem from 'app/components/AddonTableItem/AddonTableItem';

import { Link } from 'react-router-dom';
import { Button } from '@mui/material';

import './PurchasedItem.scss';

interface PurchasedItemProps {
  addon: Parameters<typeof AddonTableItem>[0]['addon'];
  orderNumber: number;
  orderDate: string;
  ownerName: string;
  userName: string | null;
  onClick: Parameters<typeof AddonTableItem>[0]['onClick'];
}

const PurchasedItem: FC<PurchasedItemProps> = ({
  addon,

  orderNumber,
  orderDate,
  ownerName,
  userName,
  onClick,
}) => (
  <AddonTableItem
    className="purchased-item"
    addon={addon}
    linkTo={`/add-on/${addon.id}`}
    onClick={onClick}
  >
    <div className="purchased-item__purchase-info">
      <div className="purchased-item__purchase-details">
        Purchased on {orderDate} by {ownerName === userName ? 'me' : ownerName}
        <p className="purchased-add-on__order">Order # {orderNumber}</p>
      </div>

      <Button component={Link} to={`/add-on/${addon.id}`} className="purchased-item__details-link">
        View Full Details
      </Button>
    </div>
  </AddonTableItem>
);

export default PurchasedItem;
