import { combineReducers } from '@reduxjs/toolkit';
import addonsList from 'app/main/sections/Addons/store/addonsListSlice';
import versionsInfo from 'app/main/sections/Addons/store/versionsSlice';
import sourceLanguageInfo from 'app/main/sections/Addons/store/sourceLanguagesSlice';
import productsInfo from 'app/main/sections/Addons/store/productsSlice';
import tagsInfo from 'app/main/sections/Addons/store/tagsSlice';
import platformInfo from 'app/main/sections/Addons/store/platformSlice';
import statusInfo from 'app/main/sections/Addons/store/statusSlice';
import addonVersionStatusInfo from 'app/main/sections/Addons/store/addonVersionStatusSlice';

const reducer = combineReducers({
  addonsList,
  versionsInfo,
  productsInfo,
  tagsInfo,
  platformInfo,
  statusInfo,
  addonVersionStatusInfo,
  sourceLanguageInfo,
});

export default reducer;
